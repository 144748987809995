import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateStartIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import NavigateEndIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const Pagination = ({
  totalPageCount,
  currentPage,
  setCurrentPage,
  paginationRange,
}) => {
  const handleNext = () => {
    const disabled = totalPageCount <= currentPage ? true : false;
    !disabled && setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    const disabled = currentPage === 1 ? true : false;
    !disabled && setCurrentPage(currentPage - 1);
  };

  const handleStart = () => {
    const disabled = currentPage === 1 ? true : false;
    !disabled && setCurrentPage(1);
  };

  const handleEnd = () => {
    const disabled = totalPageCount === currentPage ? true : false;
    !disabled && setCurrentPage(totalPageCount);
  };

  return (
    <div className="flex items-center justify-center mt-3">
      <div
        className="bg-primaryBorder rounded px-1 md:px-2  py-1 mr-1 md:mr-3"
        onClick={handleStart}
      >
        <NavigateStartIcon />
      </div>
      <div
        className="bg-primaryBorder rounded px-1 md:px-2 py-1 mr-1 md:mr-3"
        onClick={handlePrevious}
      >
        <NavigateBeforeIcon />
      </div>
      {paginationRange?.map((range) => (
        <div
          key={range}
          className={`${
            currentPage === range ? "bg-primary text-white" : "bg-primaryBorder"
          } px-2 md:px-3 py-1 mx-0.5 md:mx-1 rounded-lg text-sm font-sans font-normal`}
          onClick={() => setCurrentPage(range)}
        >
          {range}
        </div>
      ))}
      <div
        className="bg-primaryBorder rounded px-1 md:px-2 py-1 ml-1 md:ml-3"
        onClick={handleNext}
      >
        <NavigateNextIcon />
      </div>
      <div
        className="bg-primaryBorder rounded px-1 md:px-2 py-1 ml-1 md:ml-3"
        onClick={handleEnd}
      >
        <NavigateEndIcon />
      </div>
    </div>
  );
};

export default Pagination;
