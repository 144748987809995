import {
  CREATE_SUBSCRIBER_LOADING,
  CREATE_SUBSCRIBER_SUCCESS,
  CREATE_SUBSCRIBER_ERROR,
  CREATE_SUBSCRIBER_RESET,
} from "../actions/SubscriberAction";

const initialState = {
  loading: false,
  success: false,
  data: null,
  error: null,
};

const SubscriberReducer = function (state = initialState, action) {
  switch (action.type) {
    case CREATE_SUBSCRIBER_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        data: null,
        error: null,
      };
    }
    case CREATE_SUBSCRIBER_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        data: null,
        error: null,
      };
    }
    case CREATE_SUBSCRIBER_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: null,
      };
    }
    case CREATE_SUBSCRIBER_ERROR: {
      return {
        loading: false,
        success: false,
        data: null,
        error: action.payload
          ? action.payload.data.error.message
          : "Error Occured",
      };
    }
    default: {
      return state;
    }
  }
};

export default SubscriberReducer;
