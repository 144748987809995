import React from "react";
import "../styles/map.css";

const Marker = ({ text, tooltip, $hover, projectOnClick }) => {
  const handleClick = () => {
    projectOnClick(tooltip);
  };

  return (
    <div className={$hover ? "circle hover" : "circle"} onClick={handleClick}>
      <span className="circleText" title={tooltip}>
        {text}
      </span>
    </div>
  );
};

export default Marker;
