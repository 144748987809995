import ArtistService from "services/ArtistService";

export const GET_INVITED_ARTIST_LOADING = "GET_INVITED_ARTIST_LOADING";
export const GET_INVITED_ARTIST_SUCCESS = "GET_INVITED_ARTIST_SUCCESS";
export const GET_INVITED_ARTIST_ERROR = "GET_INVITED_ARTIST_ERROR";

export const SEND_INVITE_ARTIST_LOADING = "SEND_INVITE_ARTIST_LOADING";
export const SEND_INVITE_ARTIST_SUCCESS = "SEND_INVITE_ARTIST_SUCCESS";
export const SEND_INVITE_ARTIST_ERROR = "SEND_INVITE_ARTIST_ERROR";

export const ALL_ARTIST_LOADING = "ALL_ARTIST_LOADING";
export const ALL_ARTIST_SUCCESS = "ALL_ARTIST_SUCCESS";
export const ALL_ARTIST_ERROR = "ALL_ARTIST_ERROR";

export const NEW_ARTIST_LOADING = "NEW_ARTIST_LOADING";
export const NEW_ARTIST_SUCCESS = "NEW_ARTIST_SUCCESS";
export const NEW_ARTIST_ERROR = "NEW_ARTIST_ERROR";

export const PENDING_ARTIST_LOADING = "PENDING_ARTIST_LOADING";
export const PENDING_ARTIST_SUCCESS = "PENDING_ARTIST_SUCCESS";
export const PENDING_ARTIST_ERROR = "PENDING_ARTIST_ERROR";

export const MATCHING_ARTIST_LOADING = "MATCHING_ARTIST_LOADING";
export const MATCHING_ARTIST_SUCCESS = "MATCHING_ARTIST_SUCCESS";
export const MATCHING_ARTIST_ERROR = "MATCHING_ARTIST_ERROR";

export const LINK_ARTIST_STUDIO_LOADING = "LINK_ARTIST_STUDIO_LOADING";
export const LINK_ARTIST_STUDIO_SUCCESS = "LINK_ARTIST_STUDIO_SUCCESS";
export const LINK_ARTIST_STUDIO_ERROR = "LINK_ARTIST_STUDIO_ERROR";

export const UPDATE_PORTFOLIO_STATUS_LOADING =
  "UPDATE_PORTFOLIO_STATUS_LOADING";
export const UPDATE_PORTFOLIO_STATUS_SUCCESS =
  "UPDATE_PORTFOLIO_STATUS_SUCCESS";
export const UPDATE_PORTFOLIO_STATUS_ERROR = "UPDATE_PORTFOLIO_STATUS_ERROR";

export const DELETE_PORTFOLIO_LOADING = "DELETE_PORTFOLIO_LOADING";
export const DELETE_PORTFOLIO_SUCCESS = "DELETE_PORTFOLIO_SUCCESS";
export const DELETE_PORTFOLIO_ERROR = "DELETE_PORTFOLIO_ERROR";

export const UPDATE_ARTIST_TECHNIQUE_STATUS_LOADING =
  "UPDATE_ARTIST_TECHNIQUE_STATUS_LOADING";
export const UPDATE_ARTIST_TECHNIQUE_STATUS_SUCCESS =
  "UPDATE_ARTIST_TECHNIQUE_STATUS_SUCCESS";
export const UPDATE_ARTIST_TECHNIQUE_STATUS_ERROR =
  "UPDATE_ARTIST_TECHNIQUE_STATUS_ERROR";

export const MY_ARTIST_LOADING = "MY_ARTIST_LOADING";
export const MY_ARTIST_SUCCESS = "MY_ARTIST_SUCCESS";
export const MY_ARTIST_ERROR = "MY_ARTIST_ERROR";

export const DELETE_INVITE_ARTIST_LOADING = "DELETE_INVITE_ARTIST_LOADING";
export const DELETE_INVITE_ARTIST_SUCCESS = "DELETE_INVITE_ARTIST_SUCCESS";
export const DELETE_INVITE_ARTIST_ERROR = "DELETE_INVITE_ARTIST_ERROR";

export const DELETE_ARTIST_LOADING = "DELETE_ARTIST_LOADING";
export const DELETE_ARTIST_SUCCESS = "DELETE_ARTIST_SUCCESS";
export const DELETE_ARTIST_ERROR = "DELETE_ARTIST_ERROR";

export const BLOCK_ARTIST_LOADING = "BLOCK_ARTIST_LOADING";
export const BLOCK_ARTIST_SUCCESS = "BLOCK_ARTIST_SUCCESS";
export const BLOCK_ARTIST_ERROR = "BLOCK_ARTIST_ERROR";

export const DELETE_ARTIST_STUDIO_WORK_DAYS_LOADING =
  "DELETE_ARTIST_STUDIO_WORK_DAYS_LOADING";
export const DELETE_ARTIST_STUDIO_WORK_DAYS_SUCCESS =
  "DELETE_ARTIST_STUDIO_WORK_DAYS_SUCCESS";
export const DELETE_ARTIST_STUDIO_WORK_DAYS_ERROR =
  "DELETE_ARTIST_STUDIO_WORK_DAYS_ERROR";

export const UPDATE_TMP_PORTFOLIO_STATUS_LOADING =
  "UPDATE_TMP_PORTFOLIO_STATUS_LOADING";
export const UPDATE_TMP_PORTFOLIO_STATUS_SUCCESS =
  "UPDATE_TMP_PORTFOLIO_STATUS_SUCCESS";
export const UPDATE_TMP_PORTFOLIO_STATUS_ERROR =
  "UPDATE_TMP_PORTFOLIO_STATUS_ERROR";

export const DELETE_TMP_PORTFOLIO_LOADING = "DELETE_TMP_PORTFOLIO_LOADING";
export const DELETE_TMP_PORTFOLIO_SUCCESS = "DELETE_TMP_PORTFOLIO_SUCCESS";
export const DELETE_TMP_PORTFOLIO_ERROR = "DELETE_TMP_PORTFOLIO_ERROR";

export const UNBLOCK_ARTIST_LOADING = "UNBLOCK_ARTIST_LOADING";
export const UNBLOCK_ARTIST_SUCCESS = "UNBLOCK_ARTIST_SUCCESS";
export const UNBLOCK_ARTIST_ERROR = "UNBLOCK_ARTIST_ERROR";

// Hishem Artist module
export const getInvitedArtists = () => async (dispatch) => {
  dispatch({
    type: GET_INVITED_ARTIST_LOADING,
  });
  try {
    const res = await ArtistService.getInvitedArtists();

    return dispatch({
      type: GET_INVITED_ARTIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: GET_INVITED_ARTIST_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const sendInvite = (data) => async (dispatch) => {
  dispatch({
    type: SEND_INVITE_ARTIST_LOADING,
  });
  try {
    const res = await ArtistService.sendInvitation(data);
    return dispatch({
      type: SEND_INVITE_ARTIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: SEND_INVITE_ARTIST_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const getAllArtists = () => async (dispatch) => {
  dispatch({
    type: ALL_ARTIST_LOADING,
  });
  try {
    const res = await ArtistService.getAllArtists();
    return dispatch({
      type: ALL_ARTIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: ALL_ARTIST_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const getNewArtists = () => async (dispatch) => {
  dispatch({
    type: NEW_ARTIST_LOADING,
  });
  try {
    const res = await ArtistService.getNewArtists();
    return dispatch({
      type: NEW_ARTIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: NEW_ARTIST_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const getPendingPortfolios = () => async (dispatch) => {
  dispatch({
    type: PENDING_ARTIST_LOADING,
  });
  try {
    const res = await ArtistService.getPendingArtistPortfolios();
    return dispatch({
      type: PENDING_ARTIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: PENDING_ARTIST_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const getMatchingArtistsByProjectAndStudio =
  (id, studioId) => async (dispatch) => {
    dispatch({
      type: MATCHING_ARTIST_LOADING,
    });
    try {
      const res = await ArtistService.getMatchingArtistsByProjectAndStudio(
        id,
        studioId
      );
      return dispatch({
        type: MATCHING_ARTIST_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      return dispatch({
        type: MATCHING_ARTIST_ERROR,
        payload: {
          code: err.response.status,
          message: err.response.data,
        },
      });
    }
  };

export const linkArtistStudio = (data) => async (dispatch) => {
  dispatch({
    type: LINK_ARTIST_STUDIO_LOADING,
  });
  try {
    const res = await ArtistService.linkArtistStudio(data);
    return dispatch({
      type: LINK_ARTIST_STUDIO_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: LINK_ARTIST_STUDIO_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const updateArtistPortfolioStatus = (data, id) => async (dispatch) => {
  dispatch({
    type: UPDATE_PORTFOLIO_STATUS_LOADING,
  });
  try {
    const res = await ArtistService.updateArtistPortfolioStatus(data, id);
    return dispatch({
      type: UPDATE_PORTFOLIO_STATUS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: UPDATE_PORTFOLIO_STATUS_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const deleteArtistPortfolio = (id) => async (dispatch) => {
  dispatch({
    type: DELETE_PORTFOLIO_LOADING,
  });
  try {
    const res = await ArtistService.deleteArtistPortfolio(id);
    return dispatch({
      type: DELETE_PORTFOLIO_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: DELETE_PORTFOLIO_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const confirmTechniqueforArtist = (id, data) => async (dispatch) => {
  dispatch({
    type: UPDATE_ARTIST_TECHNIQUE_STATUS_LOADING,
  });
  try {
    const res = await ArtistService.confirmTechniqueforArtist(id, data);
    return dispatch({
      type: UPDATE_ARTIST_TECHNIQUE_STATUS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: UPDATE_ARTIST_TECHNIQUE_STATUS_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const getMyArtists = () => async (dispatch) => {
  dispatch({
    type: MY_ARTIST_LOADING,
  });
  try {
    const res = await ArtistService.getMyArtists();
    return dispatch({
      type: MY_ARTIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: MY_ARTIST_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const deleteInvite = (id) => async (dispatch) => {
  dispatch({
    type: DELETE_INVITE_ARTIST_LOADING,
  });
  try {
    await ArtistService.deleteInvitation(id);
    return dispatch({
      type: DELETE_INVITE_ARTIST_SUCCESS,
    });
  } catch (err) {
    return dispatch({
      type: DELETE_INVITE_ARTIST_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const deleteArtistAction = (id, data) => async (dispatch) => {
  dispatch({
    type: DELETE_ARTIST_LOADING,
  });
  try {
    const res = await ArtistService.deleteArtist(id, data);
    return dispatch({
      type: DELETE_ARTIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: DELETE_ARTIST_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const blockArtistAction = (id, data) => async (dispatch) => {
  dispatch({
    type: BLOCK_ARTIST_LOADING,
  });
  try {
    const res = await ArtistService.blockArtist(id);
    return dispatch({
      type: BLOCK_ARTIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: BLOCK_ARTIST_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const deleteArtistStudioWorkDay = (data) => async (dispatch) => {
  dispatch({
    type: DELETE_ARTIST_STUDIO_WORK_DAYS_LOADING,
  });
  try {
    const res = await ArtistService.deleteArtistStudioWorkDay(data);
    return dispatch({
      type: DELETE_ARTIST_STUDIO_WORK_DAYS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: DELETE_ARTIST_STUDIO_WORK_DAYS_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const updateTmpArtistPortfolioStatus =
  (data, id) => async (dispatch) => {
    dispatch({
      type: UPDATE_TMP_PORTFOLIO_STATUS_LOADING,
    });
    try {
      const res = await ArtistService.updateTmpArtistPortfolioStatus(data, id);
      return dispatch({
        type: UPDATE_TMP_PORTFOLIO_STATUS_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      return dispatch({
        type: UPDATE_TMP_PORTFOLIO_STATUS_ERROR,
        payload: {
          code: err.response.status,
          message: err.response.data,
        },
      });
    }
  };

export const deleteTmpArtistPortfolio = (id) => async (dispatch) => {
  dispatch({
    type: DELETE_TMP_PORTFOLIO_LOADING,
  });
  try {
    const res = await ArtistService.deleteTmpArtistPortfolio(id);
    return dispatch({
      type: DELETE_TMP_PORTFOLIO_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: DELETE_TMP_PORTFOLIO_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const unblockArtistAction = (id, data) => async (dispatch) => {
  dispatch({
    type: UNBLOCK_ARTIST_LOADING,
  });
  try {
    const res = await ArtistService.unblockArtist(id);
    return dispatch({
      type: UNBLOCK_ARTIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: UNBLOCK_ARTIST_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};
