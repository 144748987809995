import axios from "../../http-config";
import localStorageService from "../../services/localStorageService";
import { setLandingState } from "./LandingActions";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SIGN_OUT = "SIGN_OUT";
export const LOGGED_IN_USER = "LOGGED_IN_USER";

export const signIn = (data) => async (dispatch) => {
  dispatch({
    type: LOGIN_LOADING,
  });
  try {
    let res = await axios({
      method: "POST",
      url: `/users/login`,
      data,
    });
    let result = res.data;
    localStorageService.removeItem("jwt_token");
    localStorageService.removeItem("auth_user");
    localStorageService.removeItem("notification");
    delete axios.defaults.headers.common["Authorization"];
    if (result.token) {
      localStorageService.setItem("jwt_token", result.token);
      localStorageService.setItem("auth_user", result.user);
      localStorageService.setItem("notification", result.notification);
      axios.defaults.headers["content-type"] = "application/json";
      axios.defaults.headers.common["Authorization"] = "Bearer " + result.token;
    }
    return dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response && err.response.status === 403) {
      return dispatch({
        type: LOGIN_ERROR,
        payload: err.response,
      });
    } else {
      return dispatch({
        type: LOGIN_ERROR,
        payload: err.response,
      });
    }
  }
};

export const signOut = () => async (dispatch) => {
  // localStorageService.removeItem("jwt_token");
  // localStorageService.removeItem("auth_user");
  // localStorageService.removeItem("side_item");
  localStorage.clear();
  dispatch(setLandingState(false));
  delete axios.defaults.headers.common["Authorization"];
  return dispatch({
    type: SIGN_OUT,
    payload: null,
  });
};

export const getLoginStatus = () => async (dispatch) => {
  const user = localStorageService.getItem("auth_user");
  return dispatch({
    type: LOGGED_IN_USER,
    payload: user,
  });
};
