import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import AlertMessage from "components/AlertMessage";

const managerValidationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const ResetPassword = (props) => {
  const history = useHistory();

  const handleFormSubmit = (values) => {
    let obj = {
      email: values.email,
    };

    props.onSubmit(obj);
  };

  const goToPrevPage = () => {
    history.goBack();
  };

  return (
    <div className="flex flex-1 flex-col min-h-screen items-center">
      <div className="flex flex-1 flex-col h-full max-w-sm items-center justify-between mx-4">
        <div
          onClick={() =>
            history.replace({
              pathname: "/",
            })
          }
          className="mt-4"
        >
          <object
            type="image/svg+xml"
            data="/2ndSkin.svg"
            className="h-20 w-auto content-center mx-auto object-cover pointer-events-none"
          >
            2ndSkin
          </object>
        </div>

        <div>
          <h2 className="text-2xl font-bold text-primary font-sans text-center">
            Forgot Password
          </h2>
          <p className="mt-4 text-sm text-tertiary font-sans text-center">
            Please enter your email we will send you a link to your inbox to
            reset your password
          </p>

          <div className="mt-4">
            <div className="mt-0">
              <Formik
                initialValues={{ email: "" }}
                validationSchema={managerValidationSchema}
                validateOnMount={true}
                onSubmit={(values) => {
                  handleFormSubmit(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} className="">
                    <div className="overflow-hidden">
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        label="Email"
                        autoComplete="username"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <p className="text-red font-sans">
                        {errors.email && touched.email && errors.email}
                      </p>
                    </div>

                    <div className="flex items-center mt-6">
                      <Button
                        type="submit"
                        width="full"
                        textsize="base"
                        label="Reset"
                      />
                    </div>

                    <div className="mt-4 flex justify-center">
                      <a
                        href="#/"
                        onClick={goToPrevPage}
                        className="font-normal text-base text-primary hover:text-primary font-Lato"
                      >
                        Goto Login
                      </a>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            {props.error}
            {props.mail}
          </div>
        </div>

        <p className="text-tertiary text-sm font-sans font-normal text-center mb-4">
          &copy; 2024 Ink Foundation, All rights reserved.
        </p>
      </div>
      <AlertMessage
        message={props.alertMessage}
        showAlert={props.showAlert}
        setShowAlert={props.setShowAlert}
        onAlertClose={props.onAlertClose}
      />
    </div>
  );
};

export default withRouter(ResetPassword);
