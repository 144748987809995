import React from "react";
import Rating from "@mui/material/Rating";
import { ARTIST_RATING_STATUS } from "layouts/AdminConstants";
import Button from "components/UI/Button";
import { ReactComponent as RatingIcon } from "../../../../assets/ico_rating.svg";
import { ReactComponent as RatingEmptyIcon } from "../../../../assets/ico_rating_empty.svg";
import { SERVER_URL } from "utils/constants";
import moment from "moment";
import { USER_ROLE_CLIENT } from "utils/constants";

const Reviews = (props) => {
  const getPhoto = (artistRating) => {
    return (
      <div className="content-right ml-1 md:ml-2 col-start-1">
        {!(props.role === USER_ROLE_CLIENT) && (
          <img
            className="h-12 w-12 rounded-full border border-tertiary object-cover object-center"
            src={
              artistRating?.user?.photoUrl
                ? SERVER_URL + artistRating.user.photoUrl
                : `${SERVER_URL}uploads/default-images/DefaultProfile.jpeg`
            }
            alt=""
          />
        )}
        {props.role === USER_ROLE_CLIENT && (
          <img
            className="h-12 w-12 rounded-full border border-tertiary object-cover object-center"
            src={
              artistRating?.project?.user?.photoUrl
                ? SERVER_URL + artistRating.project.user.photoUrl
                : `${SERVER_URL}uploads/default-images/DefaultProfile.jpeg`
            }
            alt=""
          />
        )}
      </div>
    );
  };

  return (
    <div className="w-full">
      <section className="mt-0 pb-4 md:pb-8" aria-labelledby="gallery-heading">
        <ul
          className={`grid grid-cols-1 gap-2 md:gap-6 lg:gap-4 md:grid-cols-${
            props.role === USER_ROLE_CLIENT ? "1" : "2"
          } xl:grid-cols-${
            props.role === USER_ROLE_CLIENT ? "2" : "3"
          } 3xl:grid-cols-${props.role === USER_ROLE_CLIENT ? "3" : "4"}`}
        >
          {props.artistRatings &&
            props.artistRatings.map((artistRating) => (
              <li
                key={artistRating.id}
                className="col-span-1"
                onClick={() => props.projectOnClick(artistRating.projectId)}
              >
                {/* Row 1 */}
                <div className="flex grid grid-cols-5 gap-0.5 rounded-2xl shadow py-4 mt-1 border border-cardDropShadowColor bg-bcard">
                  <div className="col-start-2 text-tertiary text-base font-normal font-sans col-start-2">
                    {`#${artistRating.projectId}`}
                  </div>
                  <div className="text-tertiary text-tiny md:text-sm font-normal font-sans col-span-2 h-full">
                    {moment(artistRating.createdAt).format("DD MMM, hh:mm")}
                  </div>
                  {!artistRating.isViewed && (
                    <div className="bg-primary rounded-md py-1 px-2 text-tiny font-bold font-sans text-white flex w-fit box-content mx-2 justify-center">
                      NEW
                    </div>
                  )}

                  {/* Row 2 */}
                  {getPhoto(artistRating)}

                  <div className="flex flex-col col-span-2 col-start-2">
                    <div className="text-black text-base font-normal font-sans">
                      {props.role === USER_ROLE_CLIENT
                        ? artistRating?.project?.user?.firstName
                        : artistRating?.user?.firstName}
                    </div>
                    <div className="text-tertiary text-tiny font-normal font-sans">
                      {props.role === USER_ROLE_CLIENT
                        ? artistRating?.project?.user?.birthYear
                        : artistRating?.user?.birthYear}
                    </div>
                    <Rating
                      name="simple-controlled"
                      value={artistRating.rating}
                      icon={
                        <RatingIcon
                          style={{
                            width: "14px",
                            height: "14px",
                            marginLeft: 1,
                            marginRight: 1,
                            marginBottom: 4,
                            marginTop: 4,
                          }}
                        />
                      }
                      emptyIcon={
                        <RatingEmptyIcon
                          style={{
                            width: "14px",
                            height: "14px",
                            marginLeft: 1,
                            marginRight: 1,
                            marginBottom: 4,
                            marginTop: 4,
                          }}
                        />
                      }
                    />
                  </div>

                  {/* Row 3 */}

                  <div className="flex flex-row gap-1 col-start-2 col-span-3 flex-wrap w-fill">
                    {artistRating.isCreative && (
                      <div
                        className={`border-primaryGradient border
                        rounded-md py-0 px-2 text-base font-normal font-sans`}
                      >
                        Creative
                      </div>
                    )}

                    {artistRating.isClean && (
                      <div
                        className={`border-primaryGradient border rounded-md py-0 px-2 text-base font-normal font-sans`}
                      >
                        Clean
                      </div>
                    )}

                    {artistRating.isGoodCommunication && (
                      <div
                        className={`border-primaryGradient border rounded-md py-0 px-2 text-base font-normal font-sans`}
                      >
                        Good Communication
                      </div>
                    )}

                    {!artistRating.isCreative && !artistRating.isClean && !artistRating.isGoodCommunication && (
                      <div
                        className={`border-primaryGradient border
                      rounded-md py-0 px-2 text-base font-normal font-sans`}
                      >
                        N/R
                      </div>
                    )}
                  </div>

                  {/* Row 4 */}

                  <div
                    className={` ${
                      artistRating.review ? "text-black" : "text-tertiary"
                    } text-tiny font-normal font-sans col-start-2 col-span-3 overflow-hidden truncate my-1 ${
                      props.role === USER_ROLE_CLIENT ? "mb-5" : ""
                    }`}
                  >
                    {artistRating.review ? artistRating.review : "No Comment"}
                  </div>

                  {/* Row 4 */}

                  {!(props.role === USER_ROLE_CLIENT) && (
                    <div className="mb-0 mt-1 col-start-2 col-span-2">
                      {artistRating.artistRatingStatusId !=
                      ARTIST_RATING_STATUS.PUBLISH.id ? (
                        <Button
                          type="button"
                          label="Publish"
                          textsize="base"
                          onClick={(event) => {
                            event.stopPropagation();
                            props.changeArtistRatingStatus(
                              artistRating.id,
                              ARTIST_RATING_STATUS.PUBLISH.id
                            );
                          }}
                          background={"none"}
                          color={"publish"}
                          bordercolor={"publish"}
                          // width={"28"}
                          px={"2"}
                          paddingv={1}
                          font={"normal"}
                          hovertext={"white"}
                          hoverbg={"publish"}
                        />
                      ) : (
                        <Button
                          type="button"
                          label="Undisclose"
                          textsize="base"
                          onClick={(event) => {
                            event.stopPropagation();
                            props.changeArtistRatingStatus(
                              artistRating.id,
                              ARTIST_RATING_STATUS.UNDISCLOSE.id
                            );
                          }}
                          background={"none"}
                          color={"undisclose"}
                          bordercolor={"undisclose"}
                          // width={"36"}
                          px={"2"}
                          paddingv={1}
                          font={"normal"}
                          hovertext={"white"}
                          hoverbg={"undisclose"}
                        />
                      )}
                    </div>
                  )}
                </div>
              </li>
            ))}
        </ul>
      </section>
    </div>
  );
};

export default Reviews;
