import { GET_GRAPHS_DATA_LOADING, GET_GRAPHS_DATA_SUCCESS, GET_GRAPHS_DATA_FAILURE } from "redux/actions/AccountingAction";

const initialState = {
    loading : false,
    success : false,
    error : null,
    graphs : null
}

const graphsReducer = ( state = initialState, action ) => {
    switch(action.type){
        case GET_GRAPHS_DATA_LOADING : {
            return {
                ...state,
                loading : true,
                success : false,
                error : false,
                graphs : null
            }
        }
        case GET_GRAPHS_DATA_SUCCESS : {
            return {
                ...state,
                loading : false,
                success : true,
                error : false,
                graphs : action.payload
            }
        }
        case GET_GRAPHS_DATA_FAILURE : {
            return {
                ...state,
                loading : false,
                success : false,
                error : true,
                graphs : null
            }
        }
        default : return {...state}
    }
}

export default graphsReducer