import React, { useState } from "react";
import EditStyle from "./EditStyle";
import { USER_ROLE_PROJECT_ADMIN } from "../../CONSTANTS";

const Styles = (props) => {
  const [editOpen, setEditOpen] = useState(false);
  const handleEditOpen = () => setEditOpen(true);

  const stylesdata = [];
  let styleId;
  if (props?.data?.length > 0) {
    props.data.forEach(function (value, index) {
      stylesdata.push({ id: index, name: value.style.styleName });
      styleId = value.styleId;
    });
  }
  let show = false;
  if (
    props?.role === USER_ROLE_PROJECT_ADMIN &&
    props?.projectStatus === "New Project"
  ) {
    show = true;
  }
  return (
    <div className="flex flex-col gap-0 mt-6">
      <div className="flex  gap-2 text-sm">
        {stylesdata.map((style, index) => (
          <div
            key={index}
            className="col-span-1 bg-primary text-white px-2 py-1 rounded-lg text-base font-sans flex justify-center font-normal"
          >
            {style.name}
          </div>
        ))}
      </div>
      {show && (
        <div
          className="text-primary text-base justify-center py-1"
          onClick={handleEditOpen}
        >
          Edit Style
          {editOpen && (
            <EditStyle
              open={editOpen}
              setOpen={setEditOpen}
              loggedInUserId={props.loggedInUserId}
              onSubmit={props.onSubmit}
              data={props.data}
              styleId={styleId}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Styles;
