import {
  SIGNUP_ERROR,
  SIGNUP_LOADING,
  SIGNUP_SUCCESS,
} from "redux/actions/UserActions";

const initialState = {
  signupLoading: false,
  signupSuccess: false,
  signupError: null,
  signupData: null,
};

const UserReducerNew = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_LOADING: {
      return {
        ...state,
        signupLoading: true,
        signupSuccess: false,
        signupError: null,
        signupData: null,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        signupLoading: false,
        signupSuccess: true,
        signupError: null,
        signupData: action.payload,
      };
    }

    case SIGNUP_ERROR: {
      return {
        ...state,
        signupLoading: false,
        signupSuccess: false,
        signupError: action.payload ? action.payload : "Unknown Error Occured",
        signupData: null,
      };
    }
    default:
      return { ...state };
  }
};

export default UserReducerNew;
