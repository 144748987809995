import axios from "../http-config";

class SubscriberService {

    createSubscriber(data) {
        return axios({
            method: "POST",
            url: `/subscriber`,
            data,
        });
    }

}

export default new SubscriberService();