import * as React from "react";
import Artists from "./Artists";

const NewArtists = (props) => {
  return (
    <Artists
      data={props.newArtists}
      countries={props.countries}
      techniques={props.techniques}
      styles={props.styles}
      studios={props.studios}
    />
  );
};

export default NewArtists;
