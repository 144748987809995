import {
    GET_COUNTRIES_LOADING,
    GET_COUNTRIES_SUCCESS,
    GET_COUNTRIES_ERROR,
    GET_COUNTRY_LOADING,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_ERROR
} from "../actions/CountryAction";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null
};

const CountryReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_COUNTRY_LOADING:
        case GET_COUNTRIES_LOADING: {
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null
            };
        }
        case GET_COUNTRY_SUCCESS:
        case GET_COUNTRIES_SUCCESS: {
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };
        }
        case GET_COUNTRY_ERROR:
        case GET_COUNTRIES_ERROR: {
            return {
                loading: false,
                success: false,
                data: null,
                error: action.payload ? action.payload.data.error.message : "Error Occured",
            };
        }
        default: {
            return state;
        }
    }
};

export default CountryReducer;