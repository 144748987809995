import * as React from "react";
import PriceComponent from "./PriceComponent";

const EditArtists = (props) => {
  return (
    <div>
      <PriceComponent page="editArtists" {...props} title="Edit Artists" />
    </div>
  );
};

export default EditArtists;
