import axios from "../http-config";

class NotificationService {
  getUnReadNotications(id) {
    return axios({
      method: "GET",
      url: `/notifications/un-read-user/${id}`,
    });
  }
  
  markNotificationsAsReadforUser(id) {
    return axios({
      method: "PATCH",
      url: `/notifications/user/${id}`,
    });
  }

  getAllNotifications(id) {
    return axios({
      method: "GET",
      url: `/notifications/all-user/${id}`,
    });
  }

  markNotificationRowAsRead(rowId) {
    return axios({
      method: "PATCH",
      url: `/notifications/${rowId}`,
    });
  }

  markNotificationsAsReadforUserAndType(id, type) {
    return axios({
      method: "PATCH",
      url: `/notifications/user/${id}/type/${type}`,
    });
  }


}

export default new NotificationService();
