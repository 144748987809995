import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FaqView from "./FaqView";
import { addFaq, deleteFaq, getData, updateFaq } from "redux/actions/faqAction";

const options = [
  { id: 1, name: "General", value: "general" },
  { id: 2, name: "Artists", value: "artists" },
  { id: 3, name: "Client", value: "client" },
];

const FaqContainer = (props) => {
  const [selectedOption, setSelectedOption] = useState(1);
  const [showForm, setForm] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const faqState = useSelector((state) => state.faq);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showForm) {
      dispatch(getData());
    }
  }, [showForm, dispatch, faqState.create, faqState.patch, faqState.update]);

  const onSubmit = (data, id) => {
    if (id) {
      dispatch(
        updateFaq(id, {
          questionEN: data.questEng,
          questionDE: data.questGer,
          answerEN: data.ansEng,
          answerDE: data.ansGer,
          categoryId: selectedOption,
        })
      );
      setEditedData(null);
    } else {
      dispatch(
        addFaq({
          questionEN: data.questEng,
          questionDE: data.questGer,
          answerEN: data.ansEng,
          answerDE: data.ansGer,
          categoryId: selectedOption,
        })
      );
    }

    setForm(false);
  };

  const deleteFaqItem = (data) => {
    dispatch(
      deleteFaq(data, {
        isDeleted: true,
      })
    );
  };

  const editFaq = (data) => {
    setEditedData(data);
    setForm(true);
  };

  return (
    <FaqView
      faqState={faqState}
      onSubmit={onSubmit}
      showForm={showForm}
      setForm={setForm}
      loginData={props.loginData}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      options={options}
      deleteFaq={deleteFaqItem}
      editFaq={editFaq}
      editedData={editedData}
    />
  );
};
export default FaqContainer;
