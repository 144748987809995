import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_LOADING,
  SIGN_OUT,
  LOGGED_IN_USER
} from "../actions/LoginActions";

const initialState = {
  success: false,
  loading: false,
  error: null,
  loginData: null,
};

const LoginReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_LOADING: {
      return {
        ...state,
        loading: true,
        error: null,
        loginData: null,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        loginData: action.payload.user,
        error: null,
      };
    }
    case LOGIN_ERROR: {
      return {
        success: false,
        loading: false,
        loginData: null,
        error: action.payload
          ? action.payload.data.error.message
          : "Error Occured",
      };
    }
    case SIGN_OUT: {
      return {
        ...state,
        success: true,
        loading: false,
        loginData: null,
        error: null,
      };
    }
    case LOGGED_IN_USER: {
      return {
        ...state,
        success: true,
        loading: false,
        loginData: action.payload,
        error: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default LoginReducer;
