import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReviewsView from "./ReviewsView";
import { REVIEWS_TABS } from "../../../layouts/AdminConstants";
import {
  getArtistRatings,
  updateArtistRatingAsViewed,
  updateArtistRatingStatus,
} from "redux/actions/ReviewsAction";
import { socket } from "utils/socketContext";

const ReviewsContainer = (props) => {
  const [selectedTab, setSelectedTab] = useState(REVIEWS_TABS.ALL.id);
  const reviewsData = useSelector((state) => state.artistrating.reviewsData);
  const [firstTime, setFirstTime] = useState(false);
  const dispatch = useDispatch();

  let artistRatings = reviewsData ? Object.assign([], reviewsData) : [];

  useEffect(() => {
    if (!firstTime) {
      dispatch(getArtistRatings());
      dispatch(updateArtistRatingAsViewed());
      setFirstTime(true);
    }
  }, []);

  useEffect(() => {   
    //listens for the event project notification update from the backend
    if (socket) {
      socket.on("reviewCreateNotificationToAdmin", (isTrue) => {
        if (isTrue) {
          dispatch(getArtistRatings());
        }
    });
    }    
  }, [socket]);

  const changeArtistRatingStatus = (artistRatingId, artistRatingStatusId) => {
    dispatch(
      updateArtistRatingStatus(artistRatingId, artistRatingStatusId)
    ).then(() => {
      dispatch(getArtistRatings());
    });
  };

  const projectOnClick = (projectId) => {
    props.history.push({
      pathname: "/admin/project-details/" + projectId,
    });
  };

  return (
    <div>
      <ReviewsView
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        loginData={props.loginData}
        artistRatings={artistRatings}
        changeArtistRatingStatus={changeArtistRatingStatus}
        projectOnClick={projectOnClick}
      />
    </div>
  );
};

export default ReviewsContainer;
