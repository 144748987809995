import axios from "../http-config";

class SupportService {
  getSupportTicketSubjects() {
    return axios({
      method: "GET",
      url: `/supportticket/subjects`,
    });
  }

  getSupportTicketsByUserTypeId(id) {
    return axios({
      method: "GET",
      url: `/supportticket/usertype/${id}`,
    });
  }

  createSupportTicket(data) {
    return axios({
      method: "POST",
      url: "/supportticket",
      data,
    });
  }

  createSupportTicketComment(data) {
    return axios({
      method: "POST",
      url: "/supportticket/comment",
      data,
    });
  }

  createSupportChat(data) {
    return axios({
      method: "POST",
      url: "/supportticket/chat",
      data,
    });
  }

  closeSupportChat(data) {
    return axios({
      method: "PATCH",
      url: `/supportticket/chat-close`,
      data,
    });
  }

  getCloseCaseStatus(id, fId, tId) {
    return axios({
      method: "GET",
      url: `/supportticket/chat-status/${id}/${fId}/${tId}`,
    });
  }

  getUserChat(pid, fId, tId) {
    return axios({
      method: "GET",
      url: `/supportticket/user-chat/${pid}/${fId}/${tId}`,
    });
  }

  getUnreadSupportChatsForUser(pid, uId) {
    return axios({
      method: "GET",
      url: `/supportticket/unread-support/${pid}/${uId}`,
    });
  }

  getUnreadCommentChatsForUser(pid, uId) {
    return axios({
      method: "GET",
      url: `/supportticket/unread-comments/${pid}/${uId}`,
    });
  }

  getUnreadCountClientArtistChatsForAdmin(pid, msgToSocket, msgFromSocket ) {
    return axios({
      method: "GET",
      url: `/supportticket/unread-comments-admin/${pid}/${msgToSocket}/${msgFromSocket}`,
    });
  }

  setReadStatusforAdminChat(data) {
    return axios({
      method: "POST",
      url: `/supportticket/status-comments-admin`,
      data
    });
  }
}

export default new SupportService();
