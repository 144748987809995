export const ROOT = "/";
export const LANDING = "/landing";
export const AUTH = "/auth";
export const ADMIN = "/admin";

export const LOGIN = "/login";
export const REGISTER = "/register";
export const FAQ = "/faq";
export const HOME = "/home";
export const RESETPASSWORD = "/resetpassword";
export const PROFILE = "/profile";
export const CHANGEPASSWORD = "/changepassword";
export const VERIFYEMAIL = "/verifyemail";
export const ABOUTUS = "/about";
export const SUPPORT = "/support";
export const MAKE_TATOO = "/makeTattoo";
export const MAKE_TATOO_THANK = "/makeTattooThanks";
export const TERMS = "/terms"

