import { COLOR_LOADING, GET_COLOR_SUCCESS, GET_COLOR_ERROR } from "../actions/ColorAction";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null
};

const ColorReducer = function (state = initialState, action) {
    switch (action.type) {
        case COLOR_LOADING: {
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null
            };
        }
        case GET_COLOR_SUCCESS: {
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };
        }
        case GET_COLOR_ERROR: {
            return {
                loading: false,
                success: false,
                data: null,
                error: action.payload ? action.payload.data.error.message : "Error Occured",
            };
        }
        default: {
            return state;
        }
    }
};

export default ColorReducer;