import axios from "../../http-config";

export const CLIENT_LOADING = "CLIENT_LOADING";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_PROJECTS_HISTORY = "GET_CLIENTS_PROJECTS_HISTORY";
export const GET_CLIENT_ERROR = "GET_CLIENT_ERROR";
export const CLIENT_PROJECTS_HISTORY_LOADING =
  "CLIENT_PROJECTS_HISTORY_LOADING";
export const CLIENT_PROJECTS_HISTORY_ERROR = "CLIENT_PROJECTS_HISTORY_ERROR";

export const CLIENT_PROJECTS_CURRENT_LOADING = "CLIENT_PROJECTS_CURRENT_LOADING";
export const CLIENT_PROJECTS_CURRENT_SUCCESS = "CLIENT_PROJECTS_CURRENT_SUCCESS";
export const CLIENT_PROJECTS_CURRENT_ERROR = "CLIENT_PROJECTS_CURRENT_ERROR";

export const getClientList = () => async (dispatch) => {
  dispatch({
    type: CLIENT_LOADING,
  });
  try {
    let res = await axios({
      method: "GET",
      url: `/users/admin/clients`,
    });
    return dispatch({
      type: GET_CLIENTS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: GET_CLIENT_ERROR,
      payload: err.response,
    });
  }
};

export const getClientById = (id) => async (dispatch) => {
  dispatch({
    type: CLIENT_LOADING,
  });
  try {
    let res = await axios({
      method: "GET",
      url: `/users/${id}`,
    });
    return dispatch({
      type: GET_CLIENT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: GET_CLIENT_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const getClientCurrentProjects = (id) => async (dispatch) => {
  dispatch({
    type: CLIENT_PROJECTS_CURRENT_LOADING,
  });
  try {
    let res = await axios({
      method: "GET",
      url: `/projects/projects-current/${id}`,
    });
    return dispatch({
      type: CLIENT_PROJECTS_CURRENT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: CLIENT_PROJECTS_CURRENT_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const getClientProjectsHistory = (id) => async (dispatch) => {
  dispatch({
    type: CLIENT_PROJECTS_HISTORY_LOADING,
  });
  try {
    let res = await axios({
      method: "GET",
      url: `/projects/projects-history/${id}`,
    });

    return dispatch({
      type: GET_CLIENTS_PROJECTS_HISTORY,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: CLIENT_PROJECTS_CURRENT_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};
