import React, { useState, useEffect } from "react";
import ArtistProjects from "./ArtistProjects";

const CurrentProjects = (props) => {
  const [checkedProcess, setCheckedProcess] = useState(false);
  const [checkedWaiting, setCheckedWaiting] = useState(false);

  const [projectListData, setProjectListData] = useState(
    props?.artistsProjects?.project
  );
  let inProcessCount = 0;
  let inWaitingCount = 0;

  useEffect(() => {
    setProjectListData(props?.artistsProjects?.project);
  }, [props]);

  let inProcessData =
    props.artistsProjects && props.artistsProjects.project
      ? props.artistsProjects.project.filter(
          (item) => item.projectStatusId === 11
        )
      : [];
  inProcessCount = inProcessData.length;

  let inWaitingData =
    props.artistsProjects && props.artistsProjects.project
      ? props.artistsProjects.project.filter(
          (item) => item.projectStatusId !== 11
        )
      : [];
  inWaitingCount = inWaitingData.length;

  const handleChangeInProcess = (isInProcessChecked) => {
    setCheckedProcess(isInProcessChecked);
    if (
      (!isInProcessChecked && !checkedWaiting) ||
      (isInProcessChecked && checkedWaiting)
    ) {
      setProjectListData(props?.artistsProjects?.project);
    } else if (isInProcessChecked && !checkedWaiting) {
      setProjectListData(inProcessData);
    } else if (!isInProcessChecked && checkedWaiting) {
      setProjectListData(inWaitingData);
    }
  };

  const handleChangeInWaiting = (isInWaitingChecked) => {
    setCheckedWaiting(isInWaitingChecked);
    if (
      (!isInWaitingChecked && !checkedProcess) ||
      (isInWaitingChecked && checkedProcess)
    ) {
      setProjectListData(props?.artistsProjects?.project);
    } else if (isInWaitingChecked && !checkedProcess) {
      setProjectListData(inWaitingData);
    } else if (!isInWaitingChecked && checkedProcess) {
      setProjectListData(inProcessData);
    }
  };

  return (
    <div>
      <div className="flex flex-col lg:flex-row mb-4 mt-4">
        <div className="flex mb-2 lg:mb-0 mr-4 items-center">
          <input
            className={`form-checkbox h-5 w-5 border border-gray rounded text-primary  align-top float-left mr-2 focus:ring-0`}
            type="checkbox"
            name="isInProcess"
            id="isInProcess"
            checked={checkedProcess}
            onChange={(e) => handleChangeInProcess(e.target.checked)}
          />
          <label className="flex text-base" htmlFor="isInProcess">
            In Process {inProcessCount}
          </label>
        </div>
        <div className="flex mr-4 items-center">
          <input
            className="form-checkbox h-5 w-5 border border-gray rounded text-primary align-top float-left mr-2 focus:ring-0"
            type="checkbox"
            name="isInWaiting"
            id="isInWaiting"
            checked={checkedWaiting}
            onChange={(e) => handleChangeInWaiting(e.target.checked)}
          />
          <label className="flex text-base" htmlFor="isInWaiting">
            Waiting for Confirmation {inWaitingCount}
          </label>
        </div>
      </div>
      <ArtistProjects
        selectedTab={props.selectedTab}
        data={projectListData}
        role={props.role}
      />
    </div>
  );
};

export default CurrentProjects;
