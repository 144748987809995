/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import RoomMessageInput from "./RoomMessageInput";
import RoomMessages from "./RoomMessages";
import { SOCKET_CONNECTION_URL_PORT } from "utils/constants";
import { socket } from "utils/socketContext";

const CommentRoomChat = (props) => {
  let commentTicketId = props.selectedTicketId;

  let show = false;
  if (props?.projectStatus !== "Project Done") {
    show = true;
  }

  useEffect(() => {
    if (props.projectId && props.loggedInUserId && socket) {
      let username = props.projectId + "#" + props.loggedInUserId;
      socket.emit("registerRc", username);
    }
  }, [props?.projectId, props?.loggedInUserId, SOCKET_CONNECTION_URL_PORT]);

  return (
    <div className="flex flex-col gap-4 w-full text-sm">
      {show && socket && (
        <div className="chat-container">
          <RoomMessages
            socket={socket}
            setMsgCount={props.setMsgCount}
            commentTicketId={commentTicketId}
            projectId={props.projectId}
            loggedInUserId={props.loggedInUserId}
            isFileUploaded={props.isFileUploaded}
            setIsFileUploaded={props.setIsFileUploaded}
            selectedTabUserType={props?.selectedTabUserType}
            loggedInUserType={props?.loggedInUserType}
          />
          <RoomMessageInput
            socket={socket}
            loggedInUserName={props.loggedInUserName}
            loggedInUserPhotoUrl={props.loggedInUserPhotoUrl}
            loggedInUserId={props.loggedInUserId}
            projectId={props.projectId}
            setIsFileUploaded={props.setIsFileUploaded}
          />
        </div>
      )}
    </div>
  );
};

export default CommentRoomChat;
