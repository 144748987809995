/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { ReactComponent as SupportIcon } from "../assets/ico_support.svg";
import { ReactComponent as AddIcon } from "../assets/ico_plus.svg";
import { ReactComponent as PencilIcon } from "../assets/ico_pencil.svg";
import { tabTypes, USER_ROLE_MANGER_ID } from "../layouts/AdminConstants";
import { SERVER_URL } from "utils/constants";

export default function ManagerGrid(props) {
  let button;
  let sShow = true;

  if (props.selectedTab === tabTypes["My Managers"]) {
    if (props.roleId === 1 && props.typeId === 3) {
      sShow = false;
      button = (
        <div className="flex justify-center items-center h-46">
          <a
            href="#"
            onClick={props.handleAddManager}
            className="md:flex inline-flex items-center mx-4 w-full py-2 border-primary rounded-3xl text-primary bg-white border no-underline border-solid text-base font-sans font-semibold justify-center"
          >
            Add Manager
          </a>
        </div>
      );
    } else {
      button = (
        <li
          key={"add_manager_card"}
          className="flex col-span-1 bg-white rounded-lg shadow p-0 border-dashed  border-2 border-primary ... h-46"
        >
          {/* <div className="lg:bg-none lg:px-0 lg:flex items-center justify-center"> */}
            <a
              href={"/admin/manager/" + tabTypes["My Managers"]}
              className="flex w-full content-center items-center justify-center p-14"
            >
              <div className="flex content-center items-center flex-col w-full h-full">
                <AddIcon className="h-6 w-6" />
                <h2 className="text-primary ml-2 mt-2 text-base font-sans font-semibold justify-center flex">
                  Add Manager
                </h2>
              </div>
            </a>
          {/* </div> */}
        </li>
      );
    }
  } else if (props.selectedTab === tabTypes["Partner Studios Managers"]) {
    button = (
      <div className="sm:col-span-1 sm:flex-col sm:justify-start sm:px-4 mb-10 md:mb-0 h-46">
        <a
          href={"/admin/manager/" + tabTypes["Partner Studios Managers"]}
          className="md:flex inline-flex items-center mr-4 px-8 py-2 border-primary rounded-3xl text-primary bg-white border no-underline border-solid text-base font-sans font-semibold justify-center"
        >
          Add Manager
        </a>
      </div>
    );
  }

  return (
    <>
      {/* Gallery */}
      {props.roleId === 1 && props.typeId === 3 ? (
        <section
          className="mt-8 pb-12 flex grid grid-cols-8"
          aria-labelledby="gallery-heading"
        >
          <ul className="col-span-8 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
            {props.managers &&
              props.managers.length !== 0 &&
              props.managers.map(
                (manager, index) =>
                  manager.id != props.loginData.id && (
                    <li
                      key={index}
                      className="c2"
                    >
                      <div className="flex grid grid-cols-4 px-0 py-2 rounded-xl shadow-lg">
                        <div className="flex justify-center">
                          <img
                            className="h-12 w-12 bg-gray-300 rounded-full flex-shrink-0 object-cover object-center"
                            src={
                              manager.photoUrl
                                ? SERVER_URL + manager.photoUrl
                                : SERVER_URL +
                                  "uploads/default-images/DefaultProfile.jpeg"
                            }
                            alt=""
                          />
                        </div>
                        <div className="col-start-2 col-span-2 truncate w-40">
                          <h3 className="text-black truncate text-base font-sans">
                            {manager.firstName} {manager.lastName}
                          </h3>
                          <p className="text-black text-base font-sans font-normal">
                            {manager.email}
                          </p>
                          <p className="text-black text-base font-sans font-normal">
                            ({manager.phoneCode}) {manager.phone}
                          </p>
                          <div className="flex">
                            {manager.user_role.userRole === "ADMIN" ? (
                              <p className="text-primary text-base font-sans font-bold">
                                Admin
                              </p>
                            ) : (
                              <br />
                            )}
                            {sShow && manager.isMarketPlaceDecision ? (
                              <p className="ml-5 sm:ml-20 text-primary text-base font-sans font-bold">
                                Market Place Decision
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="flex justify-center">
                          {!sShow && (
                            <a
                              href="#"
                              onClick={(e) => {
                                props.handleEditManager(manager.id);
                              }}
                            >
                              <PencilIcon className="h-6 w-6" />
                            </a>
                          )}
                        </div>
                      </div>
                    </li>
                  )
              )}
          </ul>
          <div className="col-span-12 md:col-span-4 lg:col-span-3 mt-5">
            {button}
          </div>
        </section>
      ) : (
        <section className="mt-8 pb-12" aria-labelledby="gallery-heading">
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3">
            {props.managers &&
              props.managers.length !== 0 &&
              props.managers.map((manager, index) => (
                <li
                  key={index}
                  className="col-span-1 bg-bcard rounded-xl shadow-sm p-2"
                >
                  <div className="w-full flex items-center justify-between p-2 space-x-6 h-20">
                    <img
                      className="h-12 w-12 bg-gray-300 rounded-full flex-shrink-0 object-cover object-center"
                      src={
                        manager.photoUrl
                          ? SERVER_URL + manager.photoUrl
                          : SERVER_URL +
                            "uploads/default-images/DefaultProfile.jpeg"
                      }
                      alt=""
                    />
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <h3 className="text-gray-900 truncate text-base font-sans font-semibold">
                          {manager.firstName} {manager.lastName}
                        </h3>
                      </div>
                      {sShow && manager.isSupport && (
                        <SupportIcon className="h-5 w-5" />
                      )}
                    </div>
                    {!sShow && (
                      <a
                        href="#"
                        onClick={(e) => {
                          props.handleEditManager(manager.id);
                        }}
                      >
                        <PencilIcon className="h-6 w-6" />
                      </a>
                    )}
                    {sShow && props.roleId !== USER_ROLE_MANGER_ID && (
                      <a
                        href={
                          "/admin/manager/" +
                          props.selectedTab +
                          "/" +
                          manager.id
                        }
                      >
                        <PencilIcon className="h-6 w-6" />
                      </a>
                    )}
                  </div>

                  <div>
                    <p className="ml-2 px-2 text-black text-base font-sans font-normal">
                      {manager.email}
                    </p>
                    <p className="ml-2 px-2 text-black text-base font-sans font-normal">
                      ({manager.phoneCode}) {manager.phone}
                    </p>
                    <div className="flex mt-2 px-2 mx-2 md:grid md:grid-cols-6 flex-col">
                      {manager.user_role.userRole === "ADMIN" ? (
                        <p className="text-primary text-base font-sans font-bold cols-span-2">
                          Admin
                        </p>
                      ) : (
                        <p className="text-primary text-base font-sans font-bold cols-span-2">
                        
                      </p>
                      )}
                      {sShow && manager.isMarketPlaceDecision ? (
                        <p className=" text-primary text-base font-sans font-bold col-start-3 col-span-4">
                          Market Place Decision
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </li>
              ))}
            {(props.loginData.userRoleId === 1 ||
              props.loginData.userRoleId === 2) &&
              button}
          </ul>
        </section>
      )}
    </>
  );
}
