import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";

// components

// views
import HomeContainer from "views/home/HomeContainer";
import ManagerContainer from "views/manager/ManagerAddEditContainer";
import SaveStudioContainer from "views/studio/SaveStudioContainer";
import ProjectDetailsContainer from "views/project/projectDetails/ProjectDetailsContainer";
import ArtistAdminContainer from "views/artistadmin/ArtistAdminContainer";
import ClientContainer from "views/hichem/client/ClientContainer";
import Profile from "views/profile/ProfileContainer.js";
import SaveArtistProfileContainer from "views/artist/ArtistProfile/SaveArtistProfileContainer";
import SignOut from "views/SignOut";
import ReviewsContainer from "views/hichem/reviews/ReviewsContainer";
import FaqContainer from "views/hichem/faq/FaqContainer";
import AccountingContainer from "views/hichem/accounting/AccountingContainer";
import NewsletterContainer from "views/hichem/newsletter/NewsletterContainer";
import MarketplaceContainer from "views/hichem/marketplace/MarketplaceContainer";
import SupportContainer from "views/hichem/support/SupportContainer";
import ChatContainer from "views/hichem/chat/ChatContainer";
import WithdrawnContainer from "views/hichem/withdrawn/WithdrawnContainer";
import ProjectsContainer from "views/hichem/projects/ProjectsContainer";
import ClientProjectContainer from "views/hichem/client/ClientProjectContainer";
import ArtistHomeContainer from "views/artist/ArtistHome/ArtistHomeContainer";
import NotificationContainer from "views/notification/NotificationContainer";

export default function AdminLayout(props) {
  const [loginData, setloginData] = useState(props.loginData)
  return (
    <>
      <main>
        <Switch>
          <Route path="/admin/manager" exact render={(props) => <HomeContainer {...props} loginData={loginData}/>} />
          {/* Manager Routes */}
          <Route path="/admin/manager" exact render={(props) => <ManagerContainer {...props} loginData={loginData}/>} />
          <Route path="/admin/manager/:id" exact render={(props) => <ManagerContainer {...props} loginData={loginData}/>} />
          <Route path="/admin/manager/:id/:uid" exact render={(props) => <ManagerContainer {...props} loginData={loginData}/>} />
          {/* Studio Routes */}
          <Route path="/admin/studio" exact render={(props) => <SaveStudioContainer {...props} loginData={loginData}/>} />
          <Route path="/admin/studio/:selectedTab" exact render={(props) => <SaveStudioContainer {...props} loginData={loginData}/>} />
          <Route path="/admin/studio/:selectedTab/:id" exact render={(props) => <SaveStudioContainer {...props} loginData={loginData}/>} />
          {/* Project Routes */}

          {/* Artist Routes */}
          <Route path="/admin/artist" exact render={(props) => <ArtistAdminContainer {...props} loginData={loginData}/>} />
          <Route path="/admin/artistprofile/:artistId" exact render={(props) => <SaveArtistProfileContainer {...props} loginData={loginData}/>} />
          <Route path="/admin/artist/:id" exact render={(props) => <ArtistHomeContainer {...props} loginData={loginData} />} />
         
          <Route path="/admin/client" exact render={(props) => <ClientContainer {...props} loginData={loginData}/>} />
          <Route path="/admin/client/project/:id" exact render={(props) => <ClientProjectContainer {...props} loginData={loginData}/>} />

          <Route path="/admin/profile" exact render={(props) => <Profile {...props} loginData={loginData}/>} />
          <Route path="/admin/signout" component={SignOut} />
          
          <Route path="/admin/project-details/:id" exact render={(props) => <ProjectDetailsContainer {...props} loginData={loginData}/>}/>
          <Route path="/admin/reviews" exact render={(props) => <ReviewsContainer {...props} loginData={loginData}/>}/>
          <Route path="/admin/faq" exact render={(props) => <FaqContainer {...props} loginData={loginData}/>}/>
          <Route path="/admin/accounting" exact render={(props) => <AccountingContainer {...props} loginData={loginData}/>}/>
          <Route path="/admin/newsletter" exact render={(props) => <NewsletterContainer {...props} loginData={loginData}/>}/>
          <Route path="/admin/marketplace" exact render={(props) => <MarketplaceContainer {...props} loginData={loginData}/>}/>
          <Route path="/admin/support" exact render={(props) => <SupportContainer {...props} loginData={loginData}/>}/>
          <Route path="/admin/chat" exact render={(props) => <ChatContainer {...props} loginData={loginData}/>}/>
          <Route path="/admin/withdrawn" exact render={(props) => <WithdrawnContainer {...props} loginData={loginData}/>}/>
          <Route path="/" exact render={(props) => <ProjectsContainer {...props} loginData={loginData}/>}/>
          <Route path="/admin/accounting" exact render={(props) => <AccountingContainer {...props} loginData={loginData}/>}/>
          <Route path="/admin/notification" exact render={(props) => <NotificationContainer {...props} loginData={loginData}/>}/>
        </Switch>
      </main>
    </>
  );
}
