import * as React from 'react'
import ArtistOfferPriceComponent from "./ArtistOfferPriceComponent"

const ArtistAcceptProject = (props) => {
    return (
       <div>
         <ArtistOfferPriceComponent {...props} title="Accept Project" />
       </div>
    )
}

export default ArtistAcceptProject