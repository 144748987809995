import ManagerService from "services/ManagerService";

export const MANAGER_LOADING = "MANAGER_LOADING";
export const GET_MANAGER_BY_TYPE_SUCCESS = "GET_MANAGER_BY_TYPE_SUCCESS";
export const GET_MANAGER_SUCCESS = "GET_MANAGER_SUCCESS";
export const CREATE_MANAGER_SUCCESS = "CREATE_MANAGER_SUCCESS";
export const UPDATE_MANAGER_SUCCESS = "UPDATE_MANAGER_SUCCESS";
export const DELETE_MANAGER_SUCCESS = "DELETE_MANAGER_SUCCESS";
export const MANAGER_ERROR = "MANAGER_ERROR";

export const getManagersByStudioType = (id) => async (dispatch) => {
  dispatch({
    type: MANAGER_LOADING,
  });
  try {
    let res = await ManagerService.getManagersByStudioType(id);
    return dispatch({
      type: GET_MANAGER_BY_TYPE_SUCCESS,
      payload: res.data.user,
    });
  } catch (err) {
    return dispatch({
      type: MANAGER_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const getManager = (id) => async (dispatch) => {
  dispatch({
    type: MANAGER_LOADING,
  });
  try {
    let res = await ManagerService.getManager(id);

    return dispatch({
      type: GET_MANAGER_SUCCESS,
      payload: res.data.user,
    });
  } catch (err) {
    return dispatch({
      type: MANAGER_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const addManager = (data) => async (dispatch) => {
  dispatch({
    type: MANAGER_LOADING,
  });
  try {
    let res = await ManagerService.createManager(data);

    return dispatch({
      type: CREATE_MANAGER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: MANAGER_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const editManager = (data) => async (dispatch) => {
  dispatch({
    type: MANAGER_LOADING,
  });
  try {
    let res = await ManagerService.updateManager(data.id, data);
    return dispatch({
      type: UPDATE_MANAGER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: MANAGER_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const deleteManager = (uid) => async (dispatch) => {
  dispatch({
    type: MANAGER_LOADING,
  });
  try {
    let res = await ManagerService.deleteManager(uid);

    return dispatch({
      type: DELETE_MANAGER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: MANAGER_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};
