/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "components/UI/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  createSupportChat,
  closeSupportChat,
  getCloseCaseStatus,
} from "redux/actions/SupportAction";
import AlertMessage from "components/AlertMessage";
import { ReactComponent as FileIcon } from "../../../../assets/ic_file_upload.svg";

const commentsValidationSchema = yup.object({});
const NewMessage = ({
  socket,
  supportTicketId,
  loggedInUserId,
  loggedInUserName,
  loggedInUserPhotoUrl,
  projectId,
  userSelected,
  btnHide,
  suffix,
  msgCount,
  setIsFileUploaded,
  isRefreshRequired,
  setIsRefreshRequired,
  selectedTabUserType,
  loggedInUserType
}) => {
  const dispatch = useDispatch();
  const caseStatus = useSelector((state) => state?.support?.data?.statusId);
  // const caseStatus1 = useSelector((state) => state?.support?.data);
  // console.log(caseStatus1);
  const [caseStatusOnClick, setCaseStatusOnClick] = useState(false);
  // console.log('caseStatus1');
  const [showAlert, setShowAlert] = useState(false);
  const [alertId, setAlertId] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [fileSelected, setFileSelected] = useState(null);
  const [confirmable, setConfirmable] = useState(true);
  
  useEffect(() => {  
    if (suffix === '#S')  {
      dispatch(getCloseCaseStatus(projectId, loggedInUserId, userSelected));
      if (!btnHide && isRefreshRequired ) {      
        setCaseStatusOnClick(false);
      }
    }
  }, [userSelected, isRefreshRequired, msgCount[supportTicketId], socket, suffix]);

  const handleFormSubmit = (values) => {
    if (!values.comment) {
      return false;
    }
    let obj = {
      comment: values.comment,
      fromUserId: loggedInUserId,
      msgToSocket: supportTicketId,
      loggedInUserName: loggedInUserName,
      loggedInUserPhotoUrl: loggedInUserPhotoUrl,
      msgFromSocket: projectId + "#" + loggedInUserId + suffix,
      projectId: projectId,
      isFile: false,
      toUserType: selectedTabUserType,
      loggedInUserType: loggedInUserType
    };
    const supportObj = {
      msgToSocket: supportTicketId,
      fromUserId: loggedInUserId,
      comment: values.comment,
      msgFromSocket: projectId + "#" + loggedInUserId + suffix,
      projectId: projectId,
      isFile: false
    };
    socket.emit("message", obj);
    values.comment = "";
    onSubmit(supportObj);
    formik.resetForm();
    setCaseStatusOnClick(false);
    setIsFileUploaded(false);
  };

  const onSubmit = (supportObj) => {
    dispatch(createSupportChat(supportObj)).then(() => {
      // Refresh 
      setIsFileUploaded(true);
      setIsFileUploaded(false);
    });
  };

  const projectCloseChat = () => {
    const closeObj = {
      toId: userSelected,
      fromId: loggedInUserId,
      projectId: projectId,
    };
    dispatch(closeSupportChat(closeObj));
    setAlertId("note");
    setAlertMessage("Case Closed.");
    setShowAlert(true);
    setConfirmable(false);
    setCaseStatusOnClick(true);
    setIsRefreshRequired(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { comment: "" },
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
    validationSchema: commentsValidationSchema,
  });

  const handleFileUpload = (e, file) => {
    setAlertId("filesend");
    setAlertMessage("Are you sure, you want to send this file?");
    setShowAlert(true);
    setConfirmable(true);
    setFileSelected(file);
    e.target.value = null;
  };

  const onPressYes = (alertId) => {
    if (alertId === "filesend") {
      socket.emit(
        "upload",
        { file: fileSelected, name: fileSelected.name },
        (status) => {
          if (status && status.message !== "false") {
            const supportObj = {
              msgToSocket: supportTicketId,
              fromUserId: loggedInUserId,
              comment: status.message,
              msgFromSocket: projectId + "#" + loggedInUserId + suffix,
              projectId: projectId,
              isFile: true,
            };
            onSubmit(supportObj);
            let obj = {
              comment: status.message,
              fromUserId: loggedInUserId,
              msgToSocket: supportTicketId,
              loggedInUserName: loggedInUserName,
              loggedInUserPhotoUrl: loggedInUserPhotoUrl,
              msgFromSocket: projectId + "#" + loggedInUserId + suffix,
              projectId: projectId,
              isFile: true,              
              toUserType: selectedTabUserType,
              loggedInUserType: loggedInUserType
            };
            socket.emit("messageFile", obj);           
            setIsFileUploaded(true);
          }
        }
      );
    }
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex grid grid-cols-6 gap-4 mt-5">
        <textarea
          className="flex col-start-1 col-span-6 font-sans border border-tertiary rounded focus:outline-none focus:ring-0 focus:ring-transparent  p-2 text-base"
          label="Comment"
          type="text"
          name="comment"
          id="comment"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.comment}
          rows={3}
        />
        <label
          htmlFor={"file-upload"}
          className="py-3 md:py:0 col-start-1 col-span-6 md:col-start-1 md:col-span-2 bg-primary text-white rounded-full  text-base font-semibold flex w-full px-3 justify-center items-center"
        >
          {" "}
          Choose File
          <FileIcon className="ml-2 w-5 h-5" />
          <input
            type="file"
            id="file-upload"
            onChange={(event) => {
              handleFileUpload(event, event.target.files[0]);
            }}
            className="w-max"
            accept=".pdf,.png,.jpeg,.jpg,.docx"
            style={{ display: "none" }}
          />
        </label>
        {!btnHide && (
          <div className="self-end col-start-1 col-span-6 md:col-start-3 md:col-span-2 xl:col-span-2">
            <button
              label="Submit"
              type="submit"
              disabled={
                caseStatus === 2 ||
                msgCount[supportTicketId] === 0 ||
                caseStatusOnClick
              }
              className="bg-publish disabled:bg-tertiary text-white text-base font-semibold py-3 px-4 flex w-full justify-center rounded-full"
              onClick={() => projectCloseChat()}
            >
              Close Case
            </button>
          </div>
        )}
        <div className="self-end col-start-1 col-span-6 md:col-start-5 xl:col-start-5 md:col-span-2 xl:col-span-2 font sans">
          <Button label="Submit" type="submit" font="font-semibold"/>
        </div>
      </div>
      <div>
        <AlertMessage
          id={alertId}
          message={alertMessage}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          confirmable={confirmable}
          onPressYes={onPressYes}
        />
      </div>
    </form>
  );
};

export default NewMessage;
