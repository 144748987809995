import React, { useState } from "react";
import Accordion from "./Accordion";
import EditArtists from "./EditArtists";
import {
  getProjectStatusByName,
  USER_ROLE_PROJECT_ADMIN,
  USER_ROLE_PROJECT_MYSTUDIO_ARTIST,
  USER_ROLE_CLIENT,
} from "../../CONSTANTS";
import AdminDateTimeOffer from "./AdminDateTimeOffer";
import AppointmentDates from "./AppointmentDates";
import AddEditAdminDateTimeOffer from "./AddEditAdminDateTimeOffer";
import { Formik } from "formik";
import ButtonHollow from "components/ButtonHollow";
import ConfirmedAppointmentDates from "./ConfirmedAppointmentDates";
import FinalArtistInfo from "./FinalArtistInfo";
import AlertMessage from "components/AlertMessage";

const statusNameArray = [
  "Artist Confrimed",
  "Appointment Date Confirmation",
  "Appointment Date Confirmed",
  "Project Done",
];
const statusIdsArray = [9, 10, 11, 5];

const ArtistInfo = (props) => {
  const [editOpen, setEditOpen] = useState(false);
  const handleEditOpen = () => setEditOpen(true);

  const [dateEditOpen, setDateEditOpen] = useState(false);
  const handleDateEditOpen = () => setDateEditOpen(true);

  const [dateAddOpen, setDateAddOpen] = useState(false);
  const handleDateAddOpen = () => setDateAddOpen(true);

  const [showAlert, setShowAlert] = useState(false);
  const [alertId, setAlertId] = useState();
  const [alertMessage, setAlertMessage] = useState();

  let bShow = false,
    cShow = false;

  if (props?.role === USER_ROLE_PROJECT_ADMIN || props?.role === "Admin") {
    bShow = true;
  }

  if (props?.role === USER_ROLE_CLIENT) {
    cShow = true;
  }

  let userdata = props.data;
  if (statusNameArray.includes(props?.projectStatus)) {
    let filteredData = props.data.filter((counterOff) =>
      statusIdsArray.includes(counterOff.statusId)
    );
    userdata = filteredData;
  }

  const handleFormSubmit = (values) => {
    setAlertId("markProjectDone");
    setAlertMessage("Are you sure, to mark this project Done?");
    setShowAlert(true);
  };

  const onPressYes = (alertId) => {
    if (alertId === "markProjectDone") {
      let projectStatusId = getProjectStatusByName.Project_Done;
      let obj = {
        status: {
          statusId: projectStatusId,
          loggedInUserId: props.loggedInUserId,
        },
      };
      props.onSubmit(obj);
    }
  };

  const handleCancelOffer = (values) => {
    let projectStatusId = getProjectStatusByName.New_Project;
    let obj = {
      cancelOffer: {
        statusId: projectStatusId,
        loggedInUserId: props.loggedInUserId,
      },
    };
    props.onSubmit(obj);
  };

  const handleMarkStudioDone = (values) => {
    let projectStatusId = getProjectStatusByName.Studio_Done;
    let obj = {
      status: {
        statusId: projectStatusId,
        loggedInUserId: props.loggedInUserId,
      },
    };
    props.onSubmit(obj);
  };

  return (
    <div className="w-full">
      {(bShow || props?.role === "Admin") &&
        (props?.projectStatus === "Accepted Project by Admin" ||
          props?.projectStatus === "Counter Offer by Admin") && (
          <div className="text-sm flex flex-row justify-between">
            <div style={{ color: "#bfbbbb" }}> Artists: </div>
            <div className="text-primary" onClick={handleEditOpen}>
              {" "}
              Edit{" "}
            </div>
            {editOpen && (
              <EditArtists
                open={editOpen}
                setOpen={setEditOpen}
                loggedInUserId={props.loggedInUserId}
                onSubmit={props.onSubmit}
                data={props.data}
                role={props.role}
                projectId={props.projectId}
              />
            )}
          </div>
        )}
      {props?.projectStatus === "Appointment Date Confirmed" && (
        <ConfirmedAppointmentDates userdata={userdata[0]} {...props} />
      )}
      {cShow && ![3, 4, 5, 13].includes(props.projectStatusId) && (
        <div className="text-sm flex flex-row justify-between mt-3">
          {statusNameArray.includes(props?.projectStatus) ? (
            <div style={{ color: "#bfbbbb" }}> Artist: </div>
          ) : (
            <div className="font-sans text-base" style={{ color: "#AFAFAF" }}>
              {" "}
              {props?.projectStatus === "Accepted Project by Admin"
                ? "Artists"
                : "Counter Offers"}
            </div>
          )}
          {/* <div className="text-primary"> View All </div> */}
        </div>
      )}
      

      {(props?.projectStatus === "Project Done" || props?.projectStatus === "Refunded") && (
        <FinalArtistInfo userdata={userdata[0]} {...props} />
      )}

      {userdata &&
        userdata.length !== 0 &&
        (props?.projectStatus !== "Project Done") && (props?.projectStatus !== "Refunded")  &&
        userdata.map((item, index) =>
          props?.role === USER_ROLE_PROJECT_MYSTUDIO_ARTIST ? (
            item.userId === props.loggedInUserId ? (
              <Accordion key={index} user={item} {...props} />
            ) : (
              ""
            )
          ) : (
            <Accordion key={index} user={item} {...props} />
          )
        )}

      {props?.projectStatus === "Appointment Date Confirmed" &&
        props?.role !== USER_ROLE_PROJECT_MYSTUDIO_ARTIST && (
          <Formik
            initialValues={{}}
            onSubmit={(values) => {
              handleFormSubmit(values);
            }}
          >
            {({ values, errors, touched, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <div className="mx-4 pb-6">
                  <button
                    label="Submit"
                    type="submit"
                    className="border border-publish disabled:bg-tertiary text-publish text-base font-semibold py-3 px-4 flex w-full justify-center rounded-full mt-5"
                  >
                    Mark as Done
                  </button>
                </div>
              </form>
            )}
          </Formik>
        )}

      {props?.role === "Admin" &&
        (props?.projectStatus === "Accepted Project by Admin" ||
          props?.projectStatus === "Counter Offer by Admin") && (
          <Formik
            initialValues={{}}
            onSubmit={(values) => {
              handleCancelOffer(values);
            }}
          >
            {({ values, errors, touched, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <div className="mx-4 pb-6">
                  <ButtonHollow
                    type="submit"
                    style={{
                      borderRadius: 25,
                      height: "35px",
                      width: "100%",
                      fontSize: "10px",
                    }}
                  >
                    Cancel Offer
                  </ButtonHollow>
                </div>
              </form>
            )}
          </Formik>
        )}

      {props?.role === "Admin" && props?.projectStatus === "Project Done" && (
        <Formik
          initialValues={{}}
          onSubmit={(values) => {
            handleMarkStudioDone(values);
          }}
        >
          {({ values, errors, touched, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="mx-4 pb-6">
                <ButtonHollow
                  type="submit"
                  style={{
                    borderRadius: 25,
                    height: "35px",
                    width: "100%",
                    fontSize: "10px",
                  }}
                >
                  Done
                </ButtonHollow>
              </div>
            </form>
          )}
        </Formik>
      )}

      {cShow && props?.projectStatus === "Artist Confrimed" && (
        <div className="text-sm flex flex-row justify-between">
          <div className="text-primary">
            {" "}
            Proposed dates will appear here shortly{" "}
          </div>
        </div>
      )}

      {bShow && props?.projectStatus === "Artist Confrimed" && (
        <div className="text-gray text-base font-sans mb-4">
          {"Date & Time proposal:"}
          <AdminDateTimeOffer
            userdata={userdata[0]?.studio?.studio_work_day}
            artistId={userdata[0]?.userId}
            clientProposedDates={props.clientProposedDates}
            artistWorkDays={userdata[0]?.user?.studio_artists}
            {...props}
          />
        </div>
      )}

      {props?.projectStatus === "Appointment Date Confirmation" && (
        <div className="text-gray text-base font-sans mb-4">
          <div className="flex flex-row md:ml-4 my-4 mb-4">
            {"Date & Time proposal:"}
            <div className="flex flex-1" />
            {bShow && (
              <div className="text-primary" onClick={handleDateEditOpen}>
                {" "}
                Edit{" "}
                {dateEditOpen && (
                  <AddEditAdminDateTimeOffer
                    open={dateEditOpen}
                    setOpen={setDateEditOpen}
                    loggedInUserId={props.loggedInUserId}
                    onSubmit={props.onSubmit}
                    data={props.data}
                    role={props.role}
                    userdata={userdata[0]?.studio?.studio_work_day}
                    dates={userdata[0]?.counter_offer_dates}
                    artistId={userdata[0]?.userId}
                    page="editDate"
                    clientProposedDates={props.clientProposedDates}
                    addNewDate={false}
                  />
                )}
              </div>
            )}
          </div>

          <AppointmentDates
            userdata={userdata[0].counter_offer_dates}
            studioartists ={userdata[0].user.studio_artists}
            {...props}
          />
          {bShow && (
            <div className="text-primary mt-4 md:ml-4 w-full" onClick={handleDateAddOpen}>
              {" "}
              Add Date and Time{" "}
              {bShow && dateAddOpen && (
                <AddEditAdminDateTimeOffer
                  open={dateAddOpen}
                  setOpen={setDateAddOpen}
                  loggedInUserId={props.loggedInUserId}
                  onSubmit={props.onSubmit}
                  data={props.data}
                  role={props.role}
                  userdata={userdata[0]?.studio?.studio_work_day}
                  dates={userdata[0]?.counter_offer_dates}
                  artistId={userdata[0]?.userId}
                  page=""
                  clientProposedDates={props.clientProposedDates}
                  addNewDate={true}
                  artistWorkDays={userdata[0]?.user?.studio_artists}
                />
              )}
            </div>
          )}
        </div>
      )}
      <AlertMessage
        id={alertId}
        message={alertMessage}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        confirmable={true}
        onPressYes={onPressYes}
      />
    </div>
  );
};

export default ArtistInfo;
