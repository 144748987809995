import React, { useEffect, useState } from 'react'
import Dropdown from '../../landing/support/components/Dropdown'
import EditSharpIcon from '@mui/icons-material/EditSharp';

const paymentMethods = [
    {id : 1, name : 'Paypal', value : 'paypal', email : 'aa@gamil.com'},
    {id : 2, name : 'Google Pay', value : 'googlePay', email : 'go@gamil.com'},
    {id : 3, name : 'Paytm', value : 'paytm', email : 'yo@gamil.com'},
]

const PaymentMethod = () => {
    const [ payMode, setPayMode ] = useState()

    useEffect(() => {
    }, [payMode])

    return (
        <div className='w-full rounded-lg overflow-hidden shadow-lg bg-white w-full px-8 py-3 text-sm flex flex-col gap-4'>
            <div className='font-bold'>Payout account:</div>
            <Dropdown label="Select payment method">
                <select className="w-full bg-white border-transparent focus:outline-0"
                        onChange={(e) => setPayMode(e.target.value)}
                        id="paymentMethod"
                        name="paymentMethod"
                >
                {
                    paymentMethods.map(item => (
                        <option key={item.id} value={item.value} className="">{item.name}</option>
                    ))
                }
                </select>
            </Dropdown>
            <img src="/Paypal.svg" alt="logo"/>
            <div className='flex flex-row justify-between'>
                <div>hishem@company.com</div>
                <EditSharpIcon />
            </div>
        </div>
    )
}
export default PaymentMethod