import NotificationService from "services/NotificationService";

export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";

export const NOTIFICATION_USERTYPE_LOADING = "NOTIFICATION_USERTYPE_LOADING";
export const NOTIFICATION_USERTYPE_ERROR = "NOTIFICATION_USERTYPE_ERROR";
export const NOTIFICATION_USERTYPE_SUCCESS = "NOTIFICATION_USERTYPE_SUCCESS";



export const getUnReadNotications = (id) => async (dispatch) => {
  dispatch({
    type: NOTIFICATION_LOADING,
  });
  try {
    let res = await NotificationService.getUnReadNotications(id);

    return dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: NOTIFICATION_ERROR,
      payload: err.response,
    });
  }
};

export const markNotificationsAsReadforUser = (id) => async (dispatch) => {
  dispatch({
    type: NOTIFICATION_LOADING,
  });
  try {
    let res = await NotificationService.markNotificationsAsReadforUser(id);
    return dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: NOTIFICATION_ERROR,
      payload: err.response,
    });
  }
};

export const getAllNotifications = (id) => async (dispatch) => {
  dispatch({
    type: NOTIFICATION_LOADING,
  });
  try {
    let res = await NotificationService.getAllNotifications(id);

    return dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: NOTIFICATION_ERROR,
      payload: err.response,
    });
  }
};

export const markNotificationRowAsRead = (rowId) => async (dispatch) => {
  dispatch({
    type: NOTIFICATION_LOADING,
  });
  try {
    let res = await NotificationService.markNotificationRowAsRead(rowId);
    return dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: NOTIFICATION_ERROR,
      payload: err.response,
    });
  }
};


export const markNotificationsAsReadforUserAndType = (id, type) => async (dispatch) => {
  dispatch({
    type: NOTIFICATION_USERTYPE_LOADING,
  });
  try {
    let res = await NotificationService.markNotificationsAsReadforUserAndType(id, type);
    return dispatch({
      type: NOTIFICATION_USERTYPE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: NOTIFICATION_USERTYPE_ERROR,
      payload: err.response,
    });
  }
};

