import React, { useState } from "react";
import {
  navigations,
  userNavigations,
  tabTypes,
  USER_ROLE_MANGER_ID,
} from "../../layouts/AdminConstants";
import SideNavBar from "components/Navbars/SideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";
import ManagerAddEdit from "components/ManagerAddEdit";

const ManagerAddEditView = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  let title;
  if (props.selectedManagerTab === tabTypes["My Managers"]) {
    title = props.selectedManagerId
      ? "Edit Manager (My Studio)"
      : "Add New Manager (My Studio)";
  } else if (
    props.selectedManagerTab === tabTypes["Partner Studios Managers"]
  ) {
    title = props.selectedManagerId
      ? "Edit Manager (Partner Studio)"
      : "Add New Manager (Partner Studio)";
  }

  return (
    <div className="h-full flex">
      <SideNavBar
        navigations={navigations(
          props.loginData.userRoleId !== USER_ROLE_MANGER_ID
        )}
        mobileMenuOpen={mobileMenuOpen}
        handleMenu={handleMenuOpen}
        bg={"bgg"}
        loginID={props.loginData.id}
      />
      {/* Content area */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={props.loginData}
        />

        {/* Main content */}
        <div className="flex-1 grid md:grid-cols-5 md:gap-10">
          <div className="bg-white sm:mb-12 sm:mx-0 col-start-1 md:col-start-1 col-end-5">
            <main className="overflow-hidden">
              <div className="bg-warm-gray-50">
                <div className="py-4">
                  <div className="relative z-10 mx-auto pl-4 pr-4 sm:px-4 lg:px-8">
                    <h1 className="mt-2 pt-2 xs:text-xl sm:text-2xl md:text-2xl lg:text-2xl font-semibold text-primary font-sans">
                      {title}
                    </h1>
                  </div>
                </div>
              </div>
              <ManagerAddEdit {...props} />
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerAddEditView;
