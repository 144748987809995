import * as yup from "yup";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import { useEffect } from "react";
import { FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import CryptoJS from "crypto-js";
import { SCERETPASS } from "../utils/constants";
import SweetAlert2 from "react-sweetalert2";

const changePwdValidationSchema = yup.object({
  oldPassword: yup.string().required("This field is required"),
  newPassword: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .max(16, "Password should be of maximum 16 characters length")
    .matches(
      /^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
      "Must Contain One uppercase, One lowercase, One number and One special case Character"
    )
    .required("This field is required"),
  confirmPassword: yup.string().when("newPassword", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: yup
      .string()
      .oneOf(
        [yup.ref("newPassword")],
        "New Password and Confirmed password should match."
      ),
  }),
});

export default function ChangePassword(props) {
  const history = useHistory();
  const handleFormSubmit = (values) => {
    var encrypted = CryptoJS.AES.encrypt(
      values.newPassword,
      SCERETPASS
    ).toString();
    var oldPasswordEncrypted = CryptoJS.AES.encrypt(
      values.oldPassword,
      SCERETPASS
    ).toString();

    let obj = {
      newPassword: encrypted,
      oldPassword: oldPasswordEncrypted
    };
    props.onSubmit(obj);
    if (studioAdminScreen) {
      props.handleClickCancelChangePassword();
    }
  };

  const studioAdminScreen =
    props?.loginData?.userRoleId === 1 && props?.loginData?.userTypeId === 3;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: changePwdValidationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  useEffect(() => {
    if (props.showAlert) {
      formik.setFieldValue("oldPassword", "");
      formik.setFieldValue("newPassword", "");
      formik.setFieldValue("confirmPassword", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showAlert]);

  return (
    <>
      <div className={`w-full`}>
        <div className="min-h-screen w-full">
          <div className="w-full flex-col justify-start">
            <div className={`${studioAdminScreen ? "" : "mx-auto"} w-full`}>
              <div className="md:mt-18 w-full">
                <div className="md:mt-16 w-full">
                  <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit} className="w-full">
                      <div
                        className={`${
                          studioAdminScreen ? "md:mr-10" : "md:mx-4"
                        } md:grid md:grid-cols-6 md:gap-4`}
                      >
                        {studioAdminScreen && (
                          <div className="text-sm mt-2 mb-3 text-tertiary md:col-start-1 md:col-span-6">
                            ChangePassword
                          </div>
                        )}

                        <div className="overflow-hidden md:col-start-1 md:col-span-3">
                          <Input
                            type="password"
                            name="oldPassword"
                            id="oldPassword"
                            label="Old Password"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.oldPassword}
                          />
                          <p className="text-red font-sans">
                            {formik.errors.oldPassword}
                          </p>
                        </div>

                        <div className="overflow-hidden md:col-start-1 md:col-span-3">
                          <Input
                            type="password"
                            name="newPassword"
                            id="newPassword"
                            label="New Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.newPassword}
                          />
                          <p className="text-red font-sans">
                            {formik.errors.newPassword}
                          </p>
                        </div>

                        <div className="overflow-hidden md:col-start-1 md:col-span-3">
                          <Input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            label="Confirm Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPassword}
                          />
                          <p className="text-red font-sans">
                            {formik.errors.confirmPassword}
                          </p>
                        </div>
                        <div className="items-center mt-6 md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-1 xl:col-start-1 xl:col-span-1">
                          <Button
                            type="submit"
                            width="full"
                            textsize="base"
                            label="Save"
                          />
                        </div>

                        <div className="md:col-start-1 md:col-span-3 lg:col-start-2 lg:col-span-1 xl:col-start-2 xl:col-span-1 sm:flex-col sm:justify-start sm:px-0 mb-10 md:mb-0 mt-6">
                          <Button
                            type="button"
                            label="Cancel"
                            textsize="base"
                            background={"none"}
                            color={"black"}
                            bordercolor={"primary"}
                            onClick={
                              (formik.resetForm,
                              () => {
                                if (studioAdminScreen) {
                                  props.handleClickCancelChangePassword();
                                } else {
                                  history.goBack();
                                }
                              })
                            }
                          />
                        </div>
                      </div>
                    </form>
                  </FormikProvider>
                </div>
                {props.error}
              </div>
              <SweetAlert2
                icon={props.alertMessageIcon}
                show={props.showAlert}
                text={props.alertMessage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
