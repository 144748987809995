import React, { useEffect, useState } from "react";
import Card from "./Card";
import CheckBox from "components/UI/CheckBox";
import { zIndex } from "tailwindcss/defaultTheme";

const CardLayout = ({ data, selectedTab, projectOnClick }) => {
  const [openChecked, setOpenChecked] = useState(false);
  const [closedChecked, setClosedChecked] = useState(false);
  const [cardData, setData] = useState(data);
  const openCount = data && data.filter((item) => item.chatStatusId === 1)?.length;
  const closedCount =
    data && data.filter((item) => item.chatStatusId === 2)?.length;


  useEffect(() => {
    if (data) {
      const state =
        openChecked && closedChecked
          ? "both"
          : openChecked
          ? 1
          : closedChecked
          ? 2
          : "";
      const updatedData = !state
        ? data
        : state === "both"
        ? data
        : data.filter((item) => item.chatStatusId === state);
      setData(updatedData);
    }
  }, [openChecked, closedChecked, data]);

  return (
    <div>
      <div className="text-sm flex flex-row gap-4 my-2">
        <div className="flex">
          <CheckBox
            checked={openChecked && true}
            onChange={() => setOpenChecked(!openChecked)}
          />
          <div className="flex gap-2">
            Open
            <div>{openCount}</div>
          </div>
        </div>
        <div className="flex">
          <CheckBox
            checked={closedChecked && true}
            onChange={() => setClosedChecked(!closedChecked)}
          />
          <div className="flex gap-2">
            Recently Closed
            <div>{closedCount}</div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:gap-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
        {cardData?.map((item, index) => (
          <Card
            key={index}
            data={item}
            selectedTab={selectedTab}
            projectOnClick={projectOnClick}
          />
        ))}
      </div>
    </div>
  );
};

export default CardLayout;
