import {
  STYLE_LOADING,
  STYLE_SUCCESS,
  STYLE_ERROR,
  STYLES_LOADING,
  STYLES_SUCCESS,
  STYLES_ERROR,
} from "../actions/StyleAction";

const initialState = {
  stylesLoading: false,
  stylesSuccess: false,
  stylesData: null,
  stylesError: null,
  loading: false,
  success: false,
  data: null,
  error: null,
};

const StyleReducer = function (state = initialState, action) {
  switch (action.type) {
    case STYLES_LOADING: {
      return {
        ...state,
        stylesLoading: true,
        stylesSuccess: false,
        stylesData: null,
        stylesError: null,
      };
    }
    case STYLES_SUCCESS: {
      return {
        ...state,
        stylesLoading: false,
        stylesSuccess: true,
        stylesData: action.payload,
        stylesError: null,
      };
    }
    case STYLES_ERROR: {
      return {
        stylesLoading: false,
        stylesSuccess: false,
        stylesData: null,
        stylesError: action.payload
          ? action.payload.data.error.message
          : "Error Occured",
      };
    }
    case STYLE_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        data: null,
        error: null,
      };
    }
    case STYLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: null,
      };
    }
    case STYLE_ERROR: {
      return {
        loading: false,
        success: false,
        data: null,
        error: action.payload
          ? action.payload.data.error.message
          : "Error Occured",
      };
    }
    default: {
      return state;
    }
  }
};

export default StyleReducer;
