import React, { useState } from "react";
import AcceptProject from "./AcceptProject";
import CounterOffer from "./CounterOffer";
import ArtistAcceptProject from "./ArtistAcceptProject";
import Button from "components/UI/Button";
import { USER_ROLE_PROJECT_ADMIN } from "../../CONSTANTS";
import { HIDE_DISABLE_FLAG } from "utils/constants";

const Buttons = (props) => {
  let isAcceptBtn = false,
    isOfferBtn = false,
    isMarketBtn = false;
  const [acceptOpen, setAcceptOpen] = useState(false);
  const [offerOpen, setOfferOpen] = useState(false);
  const handleAcceptOpen = () => setAcceptOpen(true);
  const handleOfferOpen = () => setOfferOpen(true);

  if (props?.projectStatus === "New Project") {
    if (props?.role === USER_ROLE_PROJECT_ADMIN) {
      isAcceptBtn = true;
      isOfferBtn = true;
      isMarketBtn = true;
    }
    if (props?.role === "Admin") {
      isAcceptBtn = true;
      isOfferBtn = true;
    }
  }

  if (
    props?.role === "PSArtist" &&
    props?.counterOfferData &&
    props?.counterOfferData?.length > 0
  ) {
    let foundObj = props.counterOfferData.find(
      (item) => item.userId === props.loggedInUserId
    );
    if (foundObj && foundObj.statusId === 0) {
      isAcceptBtn = true;
      isOfferBtn = true;
    } else {
      isAcceptBtn = false;
      isOfferBtn = false;
    }
  }

  const sendToMarket = (a) => {
    let obj = {
      market: {
        isInMarketPlace: true,
        loggedInUserId: props.loggedInUserId,
      },
    };
    props.onSubmit(obj);
  };

  return (
    <div className="flex flex-col gap-6 w-full mt-5">
      {isAcceptBtn && (
        <div>
          <Button label={"Accept"} textsize="base" onClick={handleAcceptOpen} font="normal"/>
          {(props?.role === USER_ROLE_PROJECT_ADMIN ||
            props?.role === "Admin") &&
            acceptOpen && (
              <AcceptProject
                open={acceptOpen}
                setOpen={setAcceptOpen}
                loggedInUserId={props.loggedInUserId}
                onSubmit={props.onSubmit}
                data={props.data}
                role={props.role}
                projectId={props.projectId}
                budget={props.budget}
                cOffData={props.counterOfferData}
                pClientId={props.pClientId}
              />
            )}
          {props?.role === "PSArtist" && acceptOpen && (
            <ArtistAcceptProject
              page="acceptProject"
              open={acceptOpen}
              setOpen={setAcceptOpen}
              loggedInUserId={props.loggedInUserId}
              onSubmit={props.onSubmit}
              data={props.data}
              projectId={props.projectId}
            />
          )}
        </div>
      )}
      {isOfferBtn && (
        <div>
          <Button
            type="button"
            label="Counter Offer"
            textsize="base"
            onClick={handleOfferOpen}
            background={"none"}
            color={"primary"}
            bordercolor={"primary"}
            font="normal"
          />
          {(props?.role === USER_ROLE_PROJECT_ADMIN ||
            props?.role === "Admin") &&
            offerOpen && (
              <CounterOffer
                open={offerOpen}
                setOpen={setOfferOpen}
                loggedInUserId={props.loggedInUserId}
                onSubmit={props.onSubmit}
                data={props.data}
                budget={props.budget}
                role={props.role}
                projectId={props.projectId}
                cOffData={props.counterOfferData}
                pClientId={props.pClientId}
              />
            )}
          {props?.role === "PSArtist" && offerOpen && (
            <ArtistAcceptProject
              page="counterOffer"
              open={offerOpen}
              setOpen={setOfferOpen}
              loggedInUserId={props.loggedInUserId}
              onSubmit={props.onSubmit}
              data={props.data}
              projectId={props.projectId}
            />
          )}
        </div>
      )}

      {isMarketBtn && !props.isInMarketPlace && !HIDE_DISABLE_FLAG && (
        <Button
          type="button"
          label="Send To Market Place"
          textsize="base"
          onClick={sendToMarket}
          background={"none"}
          color={"marketPlace"}
          bordercolor={"marketPlace"}
        />
      )}
    </div>
  );
};

export default Buttons;
