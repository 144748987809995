import localaxios from "../http-config";

class PaymentService {
  initializePayment(data) {
    return localaxios({
      method: "POST",
      url: `/payments/initialize`,
      data,
    });
  }

  savePaymentDetails(data) {
    return localaxios({
      method: "POST",
      url: `/payments`,
      data,
    });
  }

  updatePaymentDetails(pId, data) {
    return localaxios({
      method: "PATCH",
      url: `/payments/${pId}`,
      data,
    });
  }

  refundPayment(id, data) {
    return localaxios({
      method: "POST",
      url: `/payments/refund/${id}`,
      data,
    });
  }
}

export default new PaymentService();
