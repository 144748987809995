import ArtistPortfolioService from "services/ArtistPortfolioService";

export const ARTISTPORTFOLIO_RESET = "ARTISTPORTFOLIO_RESET"

export const GET_ARTISTPORTFOLIO_BY_ID_LOADING = "GET_ARTISTPORTFOLIO_BY_ID_LOADING";
export const GET_ARTISTPORTFOLIO_BY_ID_SUCCESS = "GET_ARTISTPORTFOLIO_BY_ID_SUCCESS";
export const GET_ARTISTPORTFOLIO_BY_ID_ERROR = "GET_ARTISTPORTFOLIO_BY_ID_ERROR";

export const GET_ARTISTPORTFOLIOS_BY_ARTISTID_LOADING = "GET_ARTISTPORTFOLIOS_BY_ARTISTID_LOADING";
export const GET_ARTISTPORTFOLIOS_BY_ARTISTID_SUCCESS = "GET_ARTISTPORTFOLIOS_BY_ARTISTID_SUCCESS";
export const GET_ARTISTPORTFOLIOS_BY_ARTISTID_ERROR = "GET_ARTISTPORTFOLIOS_BY_ARTISTID_ERROR";

export const CREATE_ARTISTPORTFOLIO_LOADING = "CREATE_ARTISTPORTFOLIO_LOADING";
export const CREATE_ARTISTPORTFOLIO_SUCCESS = "CREATE_ARTISTPORTFOLIO_SUCCESS";
export const CREATE_ARTISTPORTFOLIO_ERROR = "CREATE_ARTISTPORTFOLIO_ERROR";

export const UPDATE_ARTISTPORTFOLIO_LOADING = "UPDATE_ARTISTPORTFOLIO_LOADING";
export const UPDATE_ARTISTPORTFOLIO_SUCCESS = "UPDATE_ARTISTPORTFOLIO_SUCCESS";
export const UPDATE_ARTISTPORTFOLIO_ERROR = "UPDATE_ARTISTPORTFOLIO_ERROR";

export const GET_TMP_ARTISTPORTFOLIO_BY_ID_LOADING = "GET_TMP_ARTISTPORTFOLIO_BY_ID_LOADING";
export const GET_TMP_ARTISTPORTFOLIO_BY_ID_SUCCESS = "GET_TMP_ARTISTPORTFOLIO_BY_ID_SUCCESS";
export const GET_TMP_ARTISTPORTFOLIO_BY_ID_ERROR = "GET_TMP_ARTISTPORTFOLIO_BY_ID_ERROR";

export const resetArtistPortfolio = () => async (dispatch) => {
    dispatch({
        type: ARTISTPORTFOLIO_RESET,
    });
}

export const getArtistPortfolioById = (id) => async (dispatch) => {
    dispatch({
        type: GET_ARTISTPORTFOLIO_BY_ID_LOADING,
    });
    try {
        let res = await ArtistPortfolioService.getArtistPortfolioById(id);

        return dispatch({
            type: GET_ARTISTPORTFOLIO_BY_ID_SUCCESS,
            payload: res.data.artistPortfolio,
        });
    } catch (err) {
        return dispatch({
            type: GET_ARTISTPORTFOLIO_BY_ID_ERROR,
            payload: err.response,
        });
    }
};

export const getTmpArtistPortfolioById = (id) => async (dispatch) => {
    dispatch({
        type: GET_TMP_ARTISTPORTFOLIO_BY_ID_LOADING,
    });
    try {
        let res = await ArtistPortfolioService.getTmpArtistPortfolioById(id);

        return dispatch({
            type: GET_TMP_ARTISTPORTFOLIO_BY_ID_SUCCESS,
            payload: res.data.artistPortfolio,
        });
    } catch (err) {
        return dispatch({
            type: GET_TMP_ARTISTPORTFOLIO_BY_ID_ERROR,
            payload: err.response,
        });
    }
};

export const getArtistPortfoliosByArtistId = (artistId) => async (dispatch) => {
    dispatch({
        type: GET_ARTISTPORTFOLIOS_BY_ARTISTID_LOADING,
    });
    try {
        let res = await ArtistPortfolioService.getArtistPortfoliosByArtistId(
            artistId
        );

        return dispatch({
            type: GET_ARTISTPORTFOLIOS_BY_ARTISTID_SUCCESS,
            payload: res.data.artistPortfolio,
        });
    } catch (err) {
        return dispatch({
            type: GET_ARTISTPORTFOLIOS_BY_ARTISTID_ERROR,
            payload: err.response,
        });
    }
};

export const createArtistPortfolio = (data) => async (dispatch) => {
    dispatch({
        type: CREATE_ARTISTPORTFOLIO_LOADING,
    });
    try {
        let res = await ArtistPortfolioService.createArtistPortfolio(data);
        return dispatch({
            type: CREATE_ARTISTPORTFOLIO_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        return dispatch({
            type: CREATE_ARTISTPORTFOLIO_ERROR,
            payload: err.response,
        });
    }
};

export const updateArtistPortfolio = (id, data) => async (dispatch) => {
    dispatch({
        type: UPDATE_ARTISTPORTFOLIO_LOADING,
    });
    try {
        let res = await ArtistPortfolioService.updateArtistPortfolio(id, data);
        return dispatch({
            type: UPDATE_ARTISTPORTFOLIO_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        return dispatch({
            type: UPDATE_ARTISTPORTFOLIO_ERROR,
            payload: err.response,
        });
    }
};