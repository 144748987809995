import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import SweetAlert2 from "react-sweetalert2";
import { Modal } from "antd";

export function RouterPrompt(props) {
  const { when, onOK, onCancel, title, okText, cancelText } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    // <SweetAlert2
    //   show={showPrompt}
    //   title={"Warning"}
    //   text={title}
    //   confirmButtonText={okText}
    //   cancelButtonText={cancelText}
    //   showCancelButton={true}
    //   onConfirm={(result) => {
    //     if (result.isConfirmed) {
    //       handleOK();
    //     } else {
    //       handleCancel();
    //     }
    //   }}
    // />

    <Modal
      title={"Warning!!!"}
      open={showPrompt}
      onOk={handleOK}
      okText={okText}
      onCancel={handleCancel}
      cancelText={cancelText}
      closable={true}
      cancelButtonProps={{
        style: { borderColor: "#C7A33F", colorAdjust: "#000000" },
      }}
      okButtonProps={{
        style: { backgroundColor: "#C7A33F"},
        color : "#ffffff"
      }}
    >
      There are unsaved changes. Are you sure want to leave this page ?
    </Modal>
  ) : null;
}
