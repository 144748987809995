import {
  SEND_INVITE_ARTIST_SUCCESS,
  SEND_INVITE_ARTIST_ERROR,
  SEND_INVITE_ARTIST_LOADING,
  DELETE_INVITE_ARTIST_LOADING,
  DELETE_INVITE_ARTIST_SUCCESS,
  DELETE_INVITE_ARTIST_ERROR
} from "../actions/AdminArtistAction";

const initialState = {
  loading: false,
  success: false,
  error: null,
  data: null,
};

const NewInviteArtistReducer = function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SEND_INVITE_ARTIST_LOADING:
    case DELETE_INVITE_ARTIST_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        data: null,
        error: null,
      };
    }
    case SEND_INVITE_ARTIST_SUCCESS:
    case DELETE_INVITE_ARTIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: payload,
        error: null,
      };
    }
    case SEND_INVITE_ARTIST_ERROR:
    case DELETE_INVITE_ARTIST_ERROR: {
      return {
        loading: false,
        success: false,
        data: null,
        error: payload ? payload : "Unknown Error Occured",
      };
    }
    default: {
      return state;
    }
  }
};

export default NewInviteArtistReducer;
