import * as React from "react";
import ClientProjects from "./ClientProjects";

const ProjectsHistory = (props) => {
  return (
    <ClientProjects
      selectedTab={props.selectedTab}
      setSelectedTab={props.setSelectedTab}
      data={props.projectsHistory}
      clientById={props.clientById}
      loginData={props.loginData}
      historyCount={
        props.projectsHistory && props.projectsHistory.project
          ? props.projectsHistory.project.length
          : 0
      }
      projectCount={
        props.clientsProjects && props.clientsProjects.project
          ? props.clientsProjects.project.length
          : 0
      }
      completedCount={
        props.projectsHistory && props.projectsHistory.project
          ? props.projectsHistory.project.filter(
              (item) => item.projectStatusId === 5
            ).length
          : 0
      }
      inCompleteCount={
        (props.projectsHistory && props.projectsHistory.project
          ? props.projectsHistory.project.filter(
              (item) => item.projectStatusId !== 5
            ).length
          : 0) +
        (props.clientsProjects && props.clientsProjects.project
          ? props.clientsProjects.project.length
          : 0)
      }
    />
  );
};

export default ProjectsHistory;
