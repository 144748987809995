import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field } from "formik";
import CustomSelect from "components/CustomSelect";
import { Dialog, Transition } from "@headlessui/react";
import { getTechniques } from "redux/actions/TechniqueAction";

const EditTechnique = (props) => {
  const techniqueDataObj = useSelector((state) => state.technique.data);
  const dispatch = useDispatch();
  const [techniqueOptionItems, setTechniqueOptionItems] = useState([]);

  useEffect(() => {
    dispatch(getTechniques()).then((res) => {
      populateTechniques(res.payload);
    });
  }, []);

  const handleClose = () => props.setOpen(false);

  const populateTechniques = (techniques) => {
    let techniqueOptionItemsTemp = [];
    if (techniques && techniques.length > 0) {
      techniques.forEach(function (value, index) {
        techniqueOptionItemsTemp.push({
          label: value.techniqueName,
          value: value.id,
        });
      });
    }
    setTechniqueOptionItems(techniqueOptionItemsTemp);
  };

  let intialFormValues = {
    multiTechniques: props?.techniqueId,
  };

  const handleFormSubmit = (values) => {
    let obj = {
      editTechnique: {
        techniqueId: values.multiTechniques,
        loggedInUserId: props.loggedInUserId,
      },
    };
    props.onSubmit(obj);
    props.setOpen(false);
  };

  return (
    <div>
      <Transition.Root show={props.open}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex items-start justify-center max-h-screen text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-top sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              className={"w-full"}
            >
              <div
                className="md:inline-block align-top bg-white rounded-md p-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-top 
                xl:max-w-6xl lg:max-w-4xl md:max-h-4/5 max-w-sm m-10"
              >
                <Formik
                  initialValues={intialFormValues}
                  onSubmit={(values) => {
                    handleFormSubmit(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className="grid grid-cols-1 md:gap-y-12 sm:grid-cols-2 sm:gap-x-8 items-center"
                    >
                      <div>
                        <Field
                          className="custom-select"
                          name="multiTechniques"
                          options={techniqueOptionItems}
                          component={CustomSelect}
                          placeholder="Select Technique"
                          isMulti={false}
                        />
                      </div>
                      <div className="sm:col-span-1 sm:flex-col sm:justify-start sm:px-4 mb-4 md:mb-0">
                        <button
                          type="submit"
                          className="mt-3 w-full inline-flex justify-center rounded-lg shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default EditTechnique;
