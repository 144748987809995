import * as React from "react";
import PriceComponent from "./PriceComponent";

const CounterOffer = (props) => {
  return (
    <div>
      <PriceComponent {...props} title="Counter Offer" isCounter={true} />
    </div>
  );
};

export default CounterOffer;
