import { ReactComponent as EyeIcon } from "../../assets/ic_eye.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/ic_eye_off.svg";

const Input = (props) => {
  const hasborder = props.hasborder ? props.hasborder === "true" : true;
  const showEye = props.showEye ? props.showEye : false;
  const rounded = props.rounded ? props.rounded : "2xl";
  const placeholder = props.placeholder ? props.placeholder : "";
  const textSize = props.textSize ? props.textSize : "base";
  return (
    <>
      <div
        className={`${
          hasborder === true
            ? `border border-primary rounded-${rounded} mt-4 py-2 px-3`
            : ""
        } shadow-sm focus-within:ring-0 focus-within:ring-primary focus-within:border-primary`}
      >
        <label
          htmlFor="name"
          className="block text-xs font-medium text-primary font-sans"
        >
          {props.label}
        </label>
        <div className={`flex flex-row`}>
          <input
            {...props}
            placeholder={placeholder}
            className={`text-${textSize} placeholder-opacity-25 font-sans w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ...`}
          />
          <div
            hidden={!showEye}
            onClick={() => props.setShowPassword(!props.showPassword)}
          >
            {props.showPassword ? <EyeIcon /> : <EyeOffIcon />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Input;
