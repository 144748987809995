import * as React from "react";
import AdminDateTimeOffer from "./AdminDateTimeOffer";
import { Dialog, Transition } from "@headlessui/react";

const AddEditAdminDateTimeOffer = (props) => {
  const handleClose = () => props.setOpen(false);
  return (
    <Transition.Root show={props.open}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="flex items-start justify-center max-h-screen text-center">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-top sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className={"w-full"}
          >
            <div
              className="inline-block align-top bg-white rounded-md text-left overflow-hidden shadow-xl transform transition-all 
              xl:max-w-2xl lg:max-w-xl max-w-xs m-4 px-4 pb-4 w-full"
            >
              <AdminDateTimeOffer
                page={props.page}
                {...props}
                title="Edit Dates"
                calledFromModel={true}
                setOpen={props.setOpen}
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddEditAdminDateTimeOffer;
