/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SaveArtistPortfolioView from "./SaveArtistPortfolioView";
import {
  resetArtistPortfolio,
  getArtistPortfolioById,
  createArtistPortfolio,
  updateArtistPortfolio,
  getTmpArtistPortfolioById,
} from "../../../redux/actions/ArtistPortfolioAction";
import { getStyles } from "../../../redux/actions/StyleAction";
import { VIEWS } from "../../../layouts/ArtistConstants";
import { getStudioArtistReviewsAvg } from "../../../redux/actions/StudioReviewsAction";
import { getUserByEmail } from "../../../redux/actions/UserActions";
import localStorageService from "services/localStorageService";

export default function SaveArtistPortfolioContainer(props) {
  const calledFrom = props.calledFromHome
    ? props.calledFromHome
    : props.match.params.calledFrom;
  const selectedArtistPortfolioId = props.match.params.id;
  const isTmpArtistPortfolioId = props.match.params.isTmp;
  let history = useHistory();

  const [editMode, setEditMode] = useState(selectedArtistPortfolioId > 0);
  const artistPortfolio = useSelector((state) => state.artistPortfolio.data);
  const artistPortfolioSuccess = useSelector(
    (state) => state.artistPortfolio.success
  );
  const style = useSelector((state) => state.style.stylesData);
  const artistReviewsAvg = useSelector(
    (state) => state.studioReviews.studioArtistReviewsAvg
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStyles());
    dispatch(getStudioArtistReviewsAvg(props.loginData.id));
    if (selectedArtistPortfolioId && isTmpArtistPortfolioId == 0) {
      dispatch(getArtistPortfolioById(selectedArtistPortfolioId));
      dispatch(resetArtistPortfolio());
    }
    if (selectedArtistPortfolioId && isTmpArtistPortfolioId == 1) {
      dispatch(getTmpArtistPortfolioById(selectedArtistPortfolioId));
      dispatch(resetArtistPortfolio());
    }
  }, [selectedArtistPortfolioId, isTmpArtistPortfolioId, dispatch]);

  useEffect(() => {
    if (artistPortfolioSuccess && !editMode) {
      dispatch(resetArtistPortfolio());
      if (
        calledFrom == VIEWS.ARTIST_PROFILE.id ||
        calledFrom == VIEWS.ARTIST_PORTFOLIO_LIST.id
      ) {
        history.push(`/artist/artistportfoliolist`);
      } else if (
        calledFrom == VIEWS.ARTIST_HOME.id ||
        calledFrom == VIEWS.PREVIEW_ARTIST_PORTFOLIO.id
      ) {
        history.replace(`/`);
      }
    }
  }, [artistPortfolioSuccess, dispatch]);

  const onSubmit = (obj) => {
    if (selectedArtistPortfolioId) {
      dispatch(updateArtistPortfolio(selectedArtistPortfolioId, obj));
    } else {
      dispatch(createArtistPortfolio(obj)).then((res) => {
        if (res.type === "CREATE_ARTISTPORTFOLIO_SUCCESS") {
          dispatch(getUserByEmail(props.loginData.email)).then((res1) => {
            if (res1.type === "GET_USER_BY_EMAIL_SUCCESS") {
              localStorageService.setItem("auth_user", res1.payload);
            }
          });
        }
      });
    }
    setEditMode(false);
  };

  return (
    <div>
      <SaveArtistPortfolioView
        onSubmit={onSubmit}
        artistPortfolio={artistPortfolio}
        style={style}
        loginData={props.loginData}
        selectedArtistPortfolioId={selectedArtistPortfolioId}
        calledFrom={calledFrom}
        artistReviewsAvg={artistReviewsAvg}
        isTmpArtistPortfolioId={isTmpArtistPortfolioId}
      />
    </div>
  );
}
