import ProjectService from "services/ProjectService";

export const PROJECT_LOADING = "PROJECT_LOADING";
export const PROJECT_SUCCESS = "PROJECT_SUCCESS";
export const PROJECT_ERROR = "PROJECT_ERROR";
export const PROJECT_VALIDATE_LOADING = "PROJECT_VALIDATE_LOADING";
export const PROJECT_VALIDATE_SUCCESS = "PROJECT_VALIDATE_SUCCESS";
export const PROJECT_VALIDATE_ERROR = "PROJECT_VALIDATE_ERROR";

export const TEMP_PROJECT_LOADING = "TEMP_PROJECT_LOADING";
export const TEMP_PROJECT_SUCCESS = "TEMP_PROJECT_SUCCESS";
export const TEMP_PROJECT_ERROR = "TEMP_PROJECT_ERROR";

export const PROJECT_GET_LOADING = "PROJECT_GET_LOADING";
export const PROJECT_GET_SUCCESS = "PROJECT_GET_SUCCESS";
export const PROJECT_GET_ERROR = "PROJECT_GET_ERROR";

export const SUPPORT_PROJECT_GET_LOADING = "SUPPORT_PROJECT_GET_LOADING";
export const SUPPORT_PROJECT_GET_SUCCESS = "SUPPORT_PROJECT_GET_SUCCESS";
export const SUPPORT_PROJECT_GET_ERROR = "SUPPORT_PROJECT_GET_ERROR";

export const createProject = (data) => async (dispatch) => {
  dispatch({
    type: PROJECT_LOADING,
  });
  try {
    let res = await ProjectService.createProject(data);
    return dispatch({
      type: PROJECT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: PROJECT_ERROR,
      payload: err.response,
    });
  }
};

export const getProjectDetails = (id) => async (dispatch) => {
  dispatch({
    type: PROJECT_GET_LOADING,
  });
  try {
    let res = await ProjectService.getProjectDetails(id);
    return dispatch({
      type: PROJECT_GET_SUCCESS,
      payload: res.data.project,
    });
  } catch (err) {
    return dispatch({
      type: PROJECT_GET_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const updateProject = (id, data) => async (dispatch) => {
  dispatch({
    type: PROJECT_LOADING,
  });
  try {
    let res = await ProjectService.updateProject(id, data);
    return dispatch({
      type: PROJECT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: PROJECT_ERROR,
      payload: err.response,
    });
  }
};

export const validateUserAndProject = (id, pid) => async (dispatch) => {
  dispatch({
    type: PROJECT_VALIDATE_LOADING,
  });
  try {
    let res = await ProjectService.validateUserAndProject(id, pid);
    return dispatch({
      type: PROJECT_VALIDATE_SUCCESS,
      payload: res.data.project,
    });
  } catch (err) {
    return dispatch({
      type: PROJECT_VALIDATE_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const updateTempProjectDetails = (id, data) => async (dispatch) => {
  dispatch({
    type: TEMP_PROJECT_LOADING,
  });
  try {
    let res = await ProjectService.updateTempProjectDetails(id, data);
    return dispatch({
      type: TEMP_PROJECT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: TEMP_PROJECT_ERROR,
      payload: err.response,
    });
  }
};

export const getProjectsWithSupportTickets = (id) => async (dispatch) => {
  dispatch({
    type: SUPPORT_PROJECT_GET_LOADING,
  });
  try {
    let res = await ProjectService.getProjectsWithSupportTickets(id);
    return dispatch({
      type: SUPPORT_PROJECT_GET_SUCCESS,
      payload: res.data.project,
    });
  } catch (err) {
    return dispatch({
      type: SUPPORT_PROJECT_GET_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};
