import { 
    GET_INVOICES_LOADING,
    GET_INVOICES,
    GET_INVOICES_ERROR, 
    GET_STATISTICS_DATA_LOADING,
    GET_STATISTICS_DATA_SUCCESS,
    GET_STATISTICS_DATA_FAILURE
} from "redux/actions/AccountingAction";

const initialState = {
    loading : false,
    success : false,
    error : null,
    invoices : null,
    statistics : null,
}

export const AccountingReducer = ( state=initialState, action ) => {
    switch(action.type){
        case GET_INVOICES_LOADING : {
            return {
                ...state,
                loading : true,
                success : false,
                error : null,
                invoices : null
            }
        }
        case GET_INVOICES : {
            return {
                ...state,
                loading : false,
                success : true,
                error : null,
                invoices : action.payload 
            }
        }
        case GET_INVOICES_ERROR : {
            return {
                ...state,
                loading : false,
                success : false,
                error : action.payload,
                invoices : null
            }
        }
        case GET_STATISTICS_DATA_LOADING : {
            return {
                ...state,
                loading : true,
                success : false,
                error : null,
                statistics : null
            }
        }
        case GET_STATISTICS_DATA_SUCCESS : {
            return {
                ...state,
                loading : false,
                success : true,
                error : null,
                statistics : action.payload 
            }
        }
        case GET_STATISTICS_DATA_FAILURE : {
            return {
                ...state,
                loading : false,
                success : false,
                error : action.payload,
                statistics : null
            }
        }
        default : { return {...state}}
    }
}   