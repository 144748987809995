import {
  PROJECTS_CURRENT_ERROR,
  PROJECTS_CURRENT_LOADING,
  PROJECTS_CURRENT_SUCCESS,
  PROJECTS_HISTORY_ERROR,
  PROJECTS_HISTORY_LOADING,
  PROJECTS_HISTORY_SUCCESS,
  PROJECTS_NEW_ERROR,
  PROJECTS_NEW_LOADING,
  PROJECTS_NEW_SUCCESS,
} from "../actions/ProjectListAction";

const initialState = {
  loadingNew: false,
  successNew: false,
  projectsNew: null,
  errorNew: null,
  loadingCurrent: false,
  successCurrent: false,
  projectsCurrent: null,
  errorCurrent: null,
  loadingHistory: false,
  successHistory: false,
  projectsHistory: null,
  errorHistory: null,
};

const ProjectListReducer = function (state = initialState, action) {
  switch (action.type) {
    case PROJECTS_NEW_LOADING: {
      return {
        ...state,
        loadingNew: true,
        successNew: false,
        projectsNew: null,
        errorNew: null,
      };
    }
    case PROJECTS_NEW_SUCCESS: {
      return {
        ...state,
        loadingNew: false,
        successNew: true,
        projectsNew: action.payload,
        errorNew: null,
      };
    }
    case PROJECTS_NEW_ERROR: {
      return {
        ...state,
        loadingNew: false,
        successNew: false,
        projectsNew: null,
        errorNew: action.payload ? action.payload : "Unknown Error Occured",
      };
    }

    case PROJECTS_CURRENT_LOADING: {
      return {
        ...state,
        loadingCurrent: true,
        successCurrent: false,
        projectsCurrent: null,
        errorCurrent: null,
      };
    }
    case PROJECTS_CURRENT_SUCCESS: {
      return {
        ...state,
        loadingCurrent: false,
        successCurrent: true,
        projectsCurrent: action.payload,
        errorCurrent: null,
      };
    }
    case PROJECTS_CURRENT_ERROR: {
      return {
        ...state,
        loadingCurrent: false,
        successCurrent: false,
        projectsCurrent: null,
        errorCurrent: action.payload ? action.payload : "Unknown Error Occured",
      };
    }

    case PROJECTS_HISTORY_LOADING: {
      return {
        ...state,
        loadingHistory: true,
        successHistory: false,
        projectsHistory: null,
        errorHistory: null,
      };
    }
    case PROJECTS_HISTORY_SUCCESS: {
      return {
        ...state,
        loadingHistory: false,
        successHistory: true,
        projectsHistory: action.payload,
        errorHistory: null,
      };
    }
    case PROJECTS_HISTORY_ERROR: {
      return {
        ...state,
        loadingHistory: false,
        successHistory: false,
        projectsHistory: null,
        errorHistory: action.payload ? action.payload : "Unknown Error Occured",
      };
    }

    default: {
      return state;
    }
  }
};

export default ProjectListReducer;
