import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PaymentView from "./PaymentView";
import { initializePayment } from "redux/actions/PaymentAction";
import { getProjectDetails } from "../../redux/actions/ProjectAction";
import localStorageService from "services/localStorageService";

const PaymentContainer = (props) => {
  const dispatch = useDispatch();
  const projectId = props.match.params.id;
  const projectDataObj = useSelector((state) => state.project);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onInitialize = (amount) => {
    let obj = {};
    obj.amount = amount;
    obj.projectId = projectId;
    obj.loginUserId = props.loginData.id;

    setLoading(true);
    dispatch(initializePayment(obj))
      .then((res) => {
        setLoading(false);
        if (res.payload.payment.status === "SUCCESS") {
          localStorageService.setItem("pId", res.payload.payment.id);
          if (res.payload.payment.url) {
            // history.replace({
            //   pathname: res.payload.payment.url,
            //   state: { url: res.payload.payment.url },
            // });
            // history.push(res.payload.payment.url)
           window.location.replace(res.payload.payment.url)
          }
        } else {
          // console.log("Error Occured! " + res.payload.payment.error);
        }
      })
      .catch((err) => {
        // console.log("Error Occured! " + err);
      });
  };

  useEffect(() => {
    dispatch(getProjectDetails(projectId));
  }, []);

  return (
    <PaymentView
      onInitialize={onInitialize}
      loginData={props.loginData}
      projectDataObj={projectDataObj.dataGet}
      loading={loading}
    />
  );
};

export default PaymentContainer;
