import React from "react";
import { ReactComponent as MaleIcon } from "../../../../assets/ic_male.svg";
import { ReactComponent as FemaleIcon } from "../../../../assets/ic_female.svg";
import { SERVER_URL } from "utils/constants";
import { useHistory } from "react-router-dom";
import { USER_ROLE_PROJECT_ADMIN, USER_ROLE_PROJECT_MYSTUDIO_ARTIST } from "../../CONSTANTS";

const ClientsInfo = (props) => {  
  let history = useHistory();
  const clientInfo = {
    name: props?.data?.firstName + " " + props?.data?.lastName,
    profile: props?.data?.photoUrl,
    gender: props?.gender,
    year: props?.data?.birthYear,
  };

  const showClientProfile = (id) => {
    if (props?.role === USER_ROLE_PROJECT_ADMIN) {
      history.push("/admin/client/project/" +id);
    }
    if (props?.role === USER_ROLE_PROJECT_MYSTUDIO_ARTIST) {
      history.push("/artist/client-profile/" +id);
    }
  };

  return (
    <>
      {props?.data && (
        <div className="flex flex-row gap-4 w-full mt-5">
          {/* <div className="w-12 h-12 overflow-hidden flex items-center"> */}
            <img onClick={() => showClientProfile(props.clientId)}
              src={
                clientInfo.profile
                  ? `${SERVER_URL}${clientInfo.profile}`
                  : `${SERVER_URL}uploads/default-images/DefaultProfile.jpeg`
              }
              alt="client_profile"
              className="w-12 h-12 rounded-full object-cover object-center"
            />
          {/* </div> */}
          <div className="flex flex-row items-center gap-4">
            <div className="flex flex-col">
              <div className="font-semibold text-base font-sans">{clientInfo.name}</div>
              <div className="text-xs">{clientInfo.year}</div>
            </div>
          </div>
          <div className="flex items-start mt-2">
            {clientInfo.gender === "female" ? <FemaleIcon /> : <MaleIcon />}
          </div>
        </div>
      )}
    </>
  );
};

export default ClientsInfo;
