import React, { useState } from "react";
import SideNavBar from "components/Navbars/SideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";
import { navigations, userNavigations, USER_ROLE_MANGER_ID } from "layouts/AdminConstants";

import { ARTIST_CLIENT_PROFILE_TABS as TABS } from "layouts/ArtistConstants";
import CurrentProjects from "components/CurrentProjects";

const ArtistClientProfileView = ({
  selectedTab,
  setSelectedTab,
  clientState,
  loginData,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };
  let grid;

  if (selectedTab === TABS.CURRENTPROJECTS.id) {
    grid = (
      <CurrentProjects
        setSelectedTab={setSelectedTab}
        clientById={clientState.client}
        clientsProjects={clientState.clientsProjects}
        projectsHistory={clientState.clientsProjectsHistory}
        loginData={loginData}
        selectedTab={selectedTab}
      />
    );
  }

  return (
    <div className="h-full flex">
      <div className="flex-1 flex flex-col overflow-hidden h-full bg-white">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={loginData}
        />

        <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
          <SideNavBar
            navigations={navigations(loginData.userRoleId !== USER_ROLE_MANGER_ID)}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            bg={"bgg"}
            loginID={loginData.id}
          />
          <div className="flex-1 flex items-stretch overflow-hidden">
            <main className="flex-1 overflow-y-auto">
              <div className="pt-2 px-3 md:px-2">
                <div className="my-5 lg:my-10">{grid}</div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistClientProfileView;
