import * as React from 'react'
import PriceComponent from "./PriceComponent"

const AcceptProject = (props) => {
    return (
       <div>
         <PriceComponent page='acceptProject' {...props} title="Accept Project" isAccept={true}/>
       </div>
    )
}

export default AcceptProject