import {
  PROJECT_LOADING,
  PROJECT_SUCCESS,
  PROJECT_ERROR,
  PROJECT_VALIDATE_LOADING,
  PROJECT_VALIDATE_SUCCESS,
  PROJECT_VALIDATE_ERROR,
  TEMP_PROJECT_LOADING,
  TEMP_PROJECT_SUCCESS,
  TEMP_PROJECT_ERROR,
  PROJECT_GET_LOADING,
  PROJECT_GET_SUCCESS,
  PROJECT_GET_ERROR,
  SUPPORT_PROJECT_GET_LOADING,
  SUPPORT_PROJECT_GET_SUCCESS,
  SUPPORT_PROJECT_GET_ERROR
} from "../actions/ProjectAction";

const initialState = {
  loading: false,
  success: false,
  data: null,
  error: null,
  canAccess: null,
  loadingGet: false,
  successGet: false,
  dataGet: null,
  errorGet: null,
  loadingGetSupport: false,
  successGetSupport: false,
  dataGetSupport: null,
  errorGetSupport: null,
};

const ProjectReducer = function (state = initialState, action) {
  switch (action.type) {
    case PROJECT_LOADING:
    case PROJECT_VALIDATE_LOADING:
    case TEMP_PROJECT_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        data: null,
        error: null,
        canAccess: null,
      };
    }
    case PROJECT_SUCCESS:
    case TEMP_PROJECT_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: null,
        canAccess: null,
      };
    }
    case PROJECT_VALIDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: null,
        error: null,
        canAccess: action.payload,
      };
    }
    case PROJECT_ERROR:
    case PROJECT_VALIDATE_ERROR:
    case TEMP_PROJECT_ERROR: {
      return {
        loading: false,
        success: false,
        data: null,
        error: action.payload ? action.payload : "Unknown Error Occured",
        canAccess: null,
      };
    }
    case PROJECT_GET_LOADING: {
      return {
        ...state,
        loadingGet: true,
        successGet: false,
        dataGet: null,
        errorGet: null,
        canAccess: null,
      };
    }

    case PROJECT_GET_SUCCESS: {
      return {
        ...state,
        loadingGet: false,
        successGet: true,
        dataGet: action.payload,
        errorGet: null,
        canAccess: null,
      };
    }

    case PROJECT_GET_ERROR: {
      return {
        ...state,
        loadingGet: false,
        successGet: false,
        dataGet: null,
        errorGet: action.payload ? action.payload : "Unknown Error Occured",
        canAccess: null,
      };
    }
    case SUPPORT_PROJECT_GET_LOADING: {
      return {
        ...state,
        loadingGetSupport: true,
        successGetSupport: false,
        dataGetSupport: null,
        errorGetSupport: null,
      };
    }

    case SUPPORT_PROJECT_GET_SUCCESS: {
      return {
        ...state,
        loadingGetSupport: false,
        successGetSupport: true,
        dataGetSupport: action.payload,
        errorGetSupport: null,
      };
    }

    case SUPPORT_PROJECT_GET_ERROR: {
      return {
        ...state,
        loadingGetSupport: false,
        successGetSupport: false,
        dataGetSupport: null,
        errorGetSupport: action.payload ? action.payload : "Unknown Error Occured",
      };
    }

    default: {
      return state;
    }
  }
};

export default ProjectReducer;
