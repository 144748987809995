import React from 'react'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const TopSection = () => {
    return (
        <div className=''>
            <div className='w-full flex flex-row gap-6'>
                <div className='rounded-lg shadow-lg bg-white w-full w-2/3 p-6 text-sm'>
                    <div className='flex flex-row justify-between py-4'>
                        <div className='flex flex-col font-bold'>
                            <div className='text-2xl'>365</div>
                            <div>Projects</div>
                        </div>
                        <div className='flex items-center justify-center rounded-lg overflow-hidden w-1/5 bg-secondaryLightGreen text-lightGreen text-sm font-bold my-auto h-16'>
                            <ArrowDropUpIcon /> 15%
                        </div>
                    </div>
                </div>
                <div className='rounded-lg overflow-hidden shadow-lg bg-white w-full w-2/3 p-6 text-sm'>
                    <div className='flex flex-row justify-between py-4'>
                        <div className='flex flex-col font-bold'>
                            <div className='text-gray'>CHF</div>
                            <div className='text-2xl'>35,426</div>
                            <div>Month Revenue</div>
                        </div>
                        <div className='flex items-center justify-center rounded-lg overflow-hidden w-1/5 bg-secondaryLightPink text-lightPink text-sm font-bold my-auto h-16'>
                            <ArrowDropDownIcon /> 15%
                        </div>
                    </div>
                </div>
            
                <div className='rounded-lg overflow-hidden shadow-lg bg-white w-full w-2/3 p-6 text-sm items-center flex'>
                    <div className='flex flex-col font-bold'>
                        <div className='text-2xl'>3655</div>
                        <div>Total Customers</div>
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default TopSection