import React, { useState } from "react";
import { navigations, userNavigations, USER_ROLE_MANGER_ID } from "../../../layouts/AdminConstants";
import SideNavBar from "components/Navbars/SideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";

const WithdrawnView = ({ loginData }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="h-full flex">
      <div className="flex-1 flex flex-col overflow-hidden h-full bg-bgg">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={loginData}
        />

        <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
          <SideNavBar
            navigations={navigations(loginData.userRoleId !== USER_ROLE_MANGER_ID)}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            bg={"bgg"}
            loginID={loginData.id}
          />
          <div className="flex-1 flex items-stretch overflow-hidden">
            {/* Main content */}
            <div className="flex flex-1 bg-bgg">
              <div className="w-full md:mx-0.1 py-2 md:py-5 md:px-4 bg-white shadow">
                <div className="mx-4 md:mx-4">
                  <div className="text-2xl font-normal font-sans text-primary mb-6">
                    Withdrawn
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawnView;
