import {
  STUDIO_LOADING,
  STUDIO_ERROR,
  GET_STUDIO_BY_TYPE_SUCCESS,
  GET_STUDIO_SUCCESS,
  CREATE_STUDIO_SUCCESS,
  UPDATE_STUDIO_SUCCESS,
  GET_ALL_STUDIOS_SUCCESS,
  STUDIO_CURRENT_PROJECT_LOADING,
  GET_STUDIO_CURRENT_PROJECT,
  GET_STUDIO_CURRENT_PROJECT_ERROR,  
  STUDIO_HISTORY_PROJECT_LOADING,
  GET_STUDIO_HISTORY_PROJECT,
  GET_STUDIO_HISTORY_PROJECT_ERROR,
  STUDIO_EARNINGS_LOADING,
  GET_STUDIO_EARNINGS,
  GET_STUDIO_EARNINGS_ERROR,
  GET_STUDIO_BY_ARTIST_SUCCESS
} from "../actions/StudioAction";

const initialState = {
  loading: null,
  success: null,
  getStudioByType: null,
  getStudioByArtistAndDay: null,
  getStudio: null,
  createStudio: null,
  updateStudio: null,
  getAllStudios: null,  
  studioCurrentProjects: null,
  studioProjectsHistory: null,
  studioEarnings: null,
  error: null,
};

const StudioReducer = function (state = initialState, action) {
  switch (action.type) {
    case STUDIO_CURRENT_PROJECT_LOADING:
    case STUDIO_HISTORY_PROJECT_LOADING:
    case STUDIO_EARNINGS_LOADING:
    case STUDIO_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        getStudioByType: null,
        getStudioByArtistAndDay: null,
        getStudio: null,
        createStudio: null,
        updateStudio: null,
        getAllStudios: null,
        error: null,
      };
    }
    case GET_STUDIO_CURRENT_PROJECT: {
      return {
        ...state,
        loading: false,
        success: true,
        studioCurrentProjects: action.payload,
        error: null,
      };
    }
    case GET_STUDIO_HISTORY_PROJECT: {
      return {
        ...state,
        loading: false,
        success: true,
        studioProjectsHistory: action.payload,
        error: null,
      };
    }
    case GET_STUDIO_BY_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        getStudioByType: action.payload,
        getStudio: null,
        createStudio: null,
        updateStudio: null,
        getAllStudios: null,
        error: null,
      };
    }
    case GET_STUDIO_BY_ARTIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        getStudioByType:null, 
        getStudioByArtistAndDay: action.payload,
        getStudio: null,
        createStudio: null,
        updateStudio: null,
        getAllStudios: null,
        error: null,
      };
    }
    case GET_STUDIO_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        getStudioByType: null,
        getStudioByArtistAndDay:null,
        getStudio: action.payload,
        createStudio: null,
        updateStudio: null,
        getAllStudios: null,
        error: null,
      };
    }
    case CREATE_STUDIO_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        getStudioByType: null,
        getStudioByArtistAndDay:null,
        getStudio: null,
        createStudio: action.payload,
        updateStudio: null,
        getAllStudios: null,
        error: null,
      };
    }
    case UPDATE_STUDIO_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        getStudioByType: null,
        getStudioByArtistAndDay:null,
        getStudio: null,
        createStudio: null,
        updateStudio: action.payload,
        getAllStudios: null,
        error: null,
      };
    }
    case GET_ALL_STUDIOS_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        getStudioByType: null,
        getStudioByArtistAndDay:null,
        getStudio: null,
        createStudio: null,
        updateStudio: null,
        getAllStudios: action.payload,
        error: null,
      };
    }
    case GET_STUDIO_EARNINGS: {
      return {
        ...state,
        loading: false,
        success: true,
        studioEarnings: action.payload,
        error: null,
      };
    }
    case GET_STUDIO_CURRENT_PROJECT_ERROR:
    case GET_STUDIO_HISTORY_PROJECT_ERROR:
    case GET_STUDIO_EARNINGS_ERROR:
    case STUDIO_ERROR: {
      return {
        loading: false,
        success: false,
        data: null,
        error: action.payload ? action.payload : "Unknown Error Occured",
      };
    }
    default: {
      return state;
    }
  }
};

export default StudioReducer;
