import * as React from "react";
import ClientProjects from "./ClientProjects";

const NewProjects = (props) => {
  return (
    <ClientProjects
      setSelectedTab={props.setSelectedTab}
      selectedTab={props.selectedTab}
      data={props.projectList}
      projectOnClick={props.projectOnClick}
    />
  );
};

export default NewProjects;
