import axios from "../http-config";

class StudioReviewsService {

    getStudioArtistReviews(id) {
        return axios({
            method: "GET",
            url: `/ratings/artist-reviews/${id}`
        });
    } 

    getStudioArtistReviewsAvg(id) {
        return axios({
            method: "GET",
            url: `/ratings/artist-reviews-average/${id}`
        });
    } 
}

export default new StudioReviewsService();
