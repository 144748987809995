import React, { useEffect, useState } from "react";
import { SERVER_URL } from "utils/constants";

const CustomThumbnail = ({ file, selectedItem, onClick, height, width="full", rounded=""}) => {
  const [loading, setLoading] = useState(false);
  const [thumb, setThumb] = useState(undefined);

  useEffect(() => {
    if (!file) {
      return;
    }

    if (file.imageUrl) {
      return;
    }

    setLoading(true);
    let reader = new FileReader();

    reader.onloadend = () => {
      setLoading(false);
      setThumb(reader.result);
    };

    reader.readAsDataURL(file);
  }, [file]);

  if (!file) {
    return null;
  }

  if (loading) {
    return <p>loading...</p>;
  }

  return (
    <img
      src={thumb ? thumb : SERVER_URL + file.imageUrl}
      alt={file.name}
      className={`flex h-full w-${width} object-cover ${rounded}`}
      onClick={() => {
        selectedItem(file);
        onClick && onClick();
      }}
    />
  );
};

export default CustomThumbnail;
