import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NewsletterView from "./NewsletterView";
import {
  getNewsletters,
  getNewsletter,
  createNewsletter,
  updateNewsletter,
  deleteNewsletter,
} from "redux/actions/NewsletterAction";

const NewsletterContainer = (props) => {
  const [editedData, setEditedData] = useState(null);
  const [resetForm, setResetForm] = useState(false);
  const newsletters = useSelector((state) => state.newsletter.getAll);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNewsletters());
  }, [dispatch]);

  const onSubmit = (data, id) => {
    if (id) {
      dispatch(updateNewsletter(id, data)).then((res) => {
        dispatch(getNewsletters());
        setEditedData(null)
        setResetForm(true);
      });
    } else {
      dispatch(createNewsletter(data)).then((res) => {
        dispatch(getNewsletters());
        setResetForm(true);
      });
    }
  };

  const deleteNewsletterItem = (data) => {
    dispatch(
      deleteNewsletter(data, {
        isDeleted: true,
      })
    );
  };
 

  const editNewsletter = (data) => {
    dispatch(getNewsletter(data)).then((res) => {
      setEditedData(res.payload);
    });
  };

  return (
    <NewsletterView
      newsletters={newsletters}
      onSubmit={onSubmit}
      loginData={props.loginData}
      deleteNewsletter={deleteNewsletterItem}
      editNewsletter={editNewsletter}
      editedData={editedData}
      resetForm={resetForm}
      setResetForm={setResetForm}
    />
  );
};
export default NewsletterContainer;
