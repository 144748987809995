/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createArtist } from "../../../redux/actions/UserActions";
import ArtistRegistrationView from "./ArtistRegistrationView";
import { getInvitation } from "../../../redux/actions/ArtistModuleAction";

export default function ArtistRegistrationContainer(props) {
  const verifyString = props.match.params.verifyString;
  let history = useHistory();

  const invitation = useSelector(
    (state) => state.artistModule.data?.invitation
  );
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvitation(verifyString));
  }, [verifyString]);

  const onSubmit = (obj) => {
    obj.firstName = invitation.name;
    obj.email = invitation.email;
    obj.lastName = invitation.lastName;
    dispatch(createArtist(obj));
  };

  if (user.success) {
    history.push("/auth/login");
    window.location.reload();
  }

  return (
    <div>
      <ArtistRegistrationView onSubmit={onSubmit} invitation={invitation} />
    </div>
  );
}
