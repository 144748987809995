/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Field, useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import Input from "components/UI/Input";
import CheckBox from "components/UI/CheckBox";
import Button from "components/UI/Button";
import { useHistory } from "react-router-dom";
import { ReactComponent as DefaultPic } from "assets/ico_default_pic.svg";
import PreviewImage from "components/PreviewImage";
import { HIDE_DISABLE_FLAG, SERVER_URL, USER_ROLES } from "utils/constants";
import Dropdown from "views/landing/support/components/Dropdown";
import Country from "./UI/Country";
import CustomSelect from "components/CustomSelect";
import Swal from "sweetalert2";
import SweetAlert2 from "react-sweetalert2";
import TextField from "components/CustomInput";

const profileValidationSchema = yup.object({
  firstName: yup
    .string("Enter your First Name")
    .required("First Name is required")
    .test("alphabets", "First Name must only contain alphabets", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  lastName: yup
    .string("Enter your Last Name")
    .required("Last Name is required")
    .test("alphabets", "Last Name must only contain alphabets", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string()
    .matches(/(\d){9}\b/, "Enter a valid mobile number")
    .max(10, "Enter a valid mobile number")
    .required(`Phone Number is required`),
});

export default function EditProfile(props) {
  const studioAdminScreen =
    props?.loginData?.userRoleId === 1 && props?.loginData?.userTypeId === 3;
  const [picEdited, setPicEdited] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    country: "Switzerland",
    city: "",
    bio: "",
    isWhatsappNotification: false,
    profilePic: SERVER_URL + "uploads/default-images/DefaultProfile.jpeg",
    artistTechniques: [],
  });
  const [optionItems, setOptionItems] = useState([]);
  const [errorTech, setErrorTech] = useState(false);
  const [approvedTech, setApprovedTech] = useState([]);

  const populateCountry = () => {
    let countries = props.countries;
    let countryOptionItems = [];
    if (countries && countries.length > 0) {
      countryOptionItems = countries.map((country) => (
        <option key={country.name} value={country.name}>
          {country.name}
        </option>
      ));
    }
    setCountryOptions(countryOptionItems);
  };

  const populatePhoneCode = () => {
    let countries = props.countries;
    let phoneCodeOptionItems = [];
    if (countries && countries.length > 0) {
      phoneCodeOptionItems = countries.map((country) => (
        <option key={country.phoneCode} value={country.phoneCode}>
          {country.phoneCode}
        </option>
      ));
    }
    setPhoneCodeOptions(phoneCodeOptionItems);
  };

  useEffect(() => {
    let optionItems1 = [];
    if (props.techniques && props.techniques.length > 0) {
      props.techniques.forEach(function (value, index) {
        optionItems1.push({
          label: value.techniqueName,
          value: value.id,
        });
      });
    }
    setOptionItems(optionItems1);
  }, [props.techniques]);

  useEffect(() => {
    populateCountry();
    populatePhoneCode();
  }, [props.countries]);

  useEffect(() => {
    let userData = props.userDataObj;
    if (userData) {
      if (userData?.artist_techniques) {
        let filteredTech = userData?.artist_techniques.filter((rec) => {
          return rec.statusId == 1;
        });
        const approvedData = filteredTech.map((obj) => obj.techniqueId);
        setApprovedTech(approvedData);
      }

      setInitialFormValues({
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        phone: userData.phone && userData.phone !== "null" ? userData.phone : "",
        phoneCode:
          userData.phoneCode && userData.phoneCode !== "null"
            ? userData.phoneCode
            : "+41",
        isWhatsappNotification: userData.isWhatsappNotification,
        city: userData.city && userData.city !== "null" ? userData.city : "",
        country: userData ? userData.country : "",
        bio: userData.bio && userData.bio !== "null" ? userData.bio : "",
        profilePic: userData.photoUrl
          ? SERVER_URL + userData.photoUrl
          : SERVER_URL + "uploads/default-images/DefaultProfile.jpeg",
        artistTechniques: userData?.artist_techniques
          ? userData?.artist_techniques?.map((item) => item.techniqueId)
          : [],
      });
      if (userData.photoUrl) {
        setPicEdited(false);
        formik.setFieldValue("profilePic", SERVER_URL + userData.photoUrl);
      }
    }
  }, [props.userDataObj]);

  const handleFormSubmit = (values) => {
    const obj = new FormData();
    obj.append("firstName", values.firstName);
    obj.append("lastName", values.lastName);
    obj.append("email", values.email);
    obj.append("phone", values.phone);
    obj.append("isWhatsappNotification", values.isWhatsappNotification);
    obj.append("profilePic", values.profilePic);
    obj.append("city", values.city);
    obj.append("country", values.country);
    obj.append("bio", values.bio);
    obj.append("phoneCode", values.phoneCode);

    if (props.userDataObj?.userRoleId === USER_ROLES.ARTIST) {
      if (values.artistTechniques.length < 1) {
        setErrorTech(true);
        setTimeout(() => {
          setErrorTech(false);
        }, 2000);
      }
      obj.append("artistTechniques", JSON.stringify(values.artistTechniques));
    }

    props.onSubmit(obj);

    if (studioAdminScreen) {
      props.handleClickCancelEditProfile();
    }
  };

  const updateTechniqeValue = (newValue) => {
    const allTechniques = newValue.map((obj) => obj.value);
    formik.setFieldValue("artistTechniques", allTechniques);
  };

  const history = useHistory();

  let btnLabel;
  if (props.type === "STUDIO") {
    btnLabel = "Save";
  } else {
    btnLabel = "Update";
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    validationSchema: profileValidationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  return (
    <div className="h-full flex">
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Main content */}
        <div className="flex-1 md:gap-2">
          <div className="sm:mb-12">
            <main className="overflow-hidden w-full">
              <section
                className="relative"
                aria-labelledby="manager-add-heading"
              >
                <div className="max-w-7xl mx-auto px-0 sm:px-0 lg:px-0">
                  <div className="relative bg-white">
                    {/*  form */}
                    <div className="">
                      <FormikProvider value={formik}>
                        <form
                          onSubmit={formik.handleSubmit}
                          className="md:grid md:grid-cols-6 md:gap-y-4 sm:gap-x-4"
                          encType="multipart/form-data"
                        >
                          {studioAdminScreen && (
                            <div className="text-sm mt-2 text-tertiary col-span-6">
                              Edit My Profile
                            </div>
                          )}
                          <div
                            className={`${studioAdminScreen ? "" : "mt-5"} ${
                              studioAdminScreen ? "" : "md:mt-10"
                            } col-span-6`}
                          >
                            {formik.values.profilePic && (
                              <div className="flex flex-row items-center">
                                {!picEdited && (
                                  <img
                                    src={formik.values.profilePic}
                                    className="h-24 w-24 rounded-full border border-tertiary object-cover object-center"
                                    alt="Profile"
                                  />
                                )}
                                {picEdited && (
                                  <PreviewImage
                                    file={formik.values.profilePic}
                                    rounded={"full"}
                                    height={"24"}
                                    width={"24"}
                                  />
                                )}
                                <label className="m-4 h-8 bg-primary text-sm font-normal font-sans text-white p-1.5 rounded-xl">
                                  <input
                                    id="profilePic"
                                    type="file"
                                    name="profilePic"
                                    onChange={(event) => {
                                      setPicEdited(true);
                                      formik.setFieldValue(
                                        "profilePic",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                    hidden
                                  />
                                  Change
                                </label>
                              </div>
                            )}
                            {!formik.values.profilePic && (
                              <div className="flex flex-row items-center">
                                <div className="w-24 h-24 bg-primary rounded-full flex justify-center items-center">
                                  <DefaultPic className="h-8 w-8" />
                                </div>
                                <label className="m-4 h-8 bg-primary text-sm font-normal font-sans text-white p-1.5 rounded-xl">
                                  <input
                                    id="profilePic"
                                    type="file"
                                    name="profilePic"
                                    onChange={(event) => {
                                      formik.setFieldValue(
                                        "profilePic",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                    hidden
                                  />
                                  Change
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2">
                            <Input
                              type="text"
                              name="firstName"
                              id="firstName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.firstName}
                              label="First Name"
                            />
                            <p className="text-red">
                              {formik.errors.firstName &&
                                formik.touched.firstName &&
                                formik.errors.firstName}
                            </p>
                          </div>
                          <div className="md:col-start-4 md:col-span-3 lg:col-start-3 lg:col-span-2">
                            <Input
                              type="text"
                              name="lastName"
                              id="lastName"
                              label="Last Name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.lastName}
                              autoComplete="username"
                              className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                            />

                            <p className="text-red">
                              {formik.errors.lastName &&
                                formik.touched.lastName &&
                                formik.errors.lastName}
                            </p>
                          </div>
                          <div className="md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2">
                            <Input
                              type="text"
                              name="email"
                              id="email"
                              label="Email"
                              autoComplete="username"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                              className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                              readOnly={true}
                            />
                            <p className="text-red">
                              {formik.errors.email &&
                                formik.touched.email &&
                                formik.errors.email}
                            </p>
                          </div>
                          <div className="md:col-start-4 md:col-span-3 lg:col-start-3 lg:col-span-2">
                            <Country
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              phoneCode={formik.values.phoneCode}
                              phoneCodeOptions={phoneCodeOptions}
                              phone={formik.values.phone}
                            />

                            <p className="text-red">
                              {formik.errors.phone &&
                                formik.touched.phone &&
                                formik.errors.phone}
                            </p>
                          </div>

                          <div className="md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2">
                            <Input
                              type="text"
                              name="city"
                              id="city"
                              label="City"
                              autoComplete="username"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.city}
                              className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                            />
                            <p className="text-red">
                              {formik.errors.city &&
                                formik.touched.city &&
                                formik.errors.city}
                            </p>
                          </div>
                          <div className="md:col-start-4 md:col-span-3 lg:col-start-3 lg:col-span-2">
                            <Dropdown label="Country">
                              <select
                                className="w-full bg-white appearance-none focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent"
                                id="country"
                                name="country"
                                value={formik.values.country}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              >
                                {countryOptions}
                              </select>
                            </Dropdown>
                            <p className="text-red font-sans">
                              {formik.errors.country &&
                                formik.touched.country &&
                                formik.errors.country}
                            </p>
                          </div>

                          {props.userDataObj?.userRoleId ===
                            USER_ROLES.ARTIST && (
                            <div className="md:col-span-6 lg:col-span-4">
                              <TextField
                                type="text"
                                name="bio"
                                id="bio"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.bio}
                                label="Bio"
                                multiline={true}
                                minRows={3}
                              />
                              {/* <Input
                                type="text"
                                name="bio"
                                id="bio"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.bio}
                                label="Bio"
                                minRows={3}                                
                              /> */}
                              <p className="text-red">
                                {formik.errors.bio &&
                                  formik.touched.bio &&
                                  formik.errors.bio}
                              </p>
                            </div>
                          )}

                          {props.userDataObj?.userRoleId ===
                            USER_ROLES.ARTIST && (
                            <div className="md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2">
                              <label className="text-primary font-sans, text-sm font-normal flex mb-2">
                                Techniques
                              </label>
                              <Field
                                style={{
                                  backgroundColor: "#C7A33F",
                                  borderColor: "#C7A33F",
                                }}
                                name="artistTechniques"
                                options={optionItems}
                                component={CustomSelect}
                                placeholder="Select Techniques"
                                isMulti={true}
                                value={formik.values.artistTechniques}
                                setTechniquValue={(newValue) =>
                                  updateTechniqeValue(newValue)
                                }
                                approvedTech={approvedTech}
                              />
                              <p className="text-red">
                                {errorTech &&
                                  Swal.fire({
                                    icon: "Error",
                                    title: "",
                                    text: "Technique is required",
                                    confirmButtonText: "Ok",
                                  })}
                                {formik.errors.artistTechniques &&
                                  formik.touched.artistTechniques &&
                                  formik.errors.artistTechniques}
                              </p>
                            </div>
                          )}

                          <div className="mt-5 md:mt-0 mb-5 md:mb-0 md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2">
                            {!HIDE_DISABLE_FLAG && (
                              <CheckBox
                                id="isWhatsappNotification"
                                name="isWhatsappNotification"
                                type="checkbox"
                                label="Receive Notification on Whatsapp"
                                onChange={formik.handleChange}
                                checked={formik.values.isWhatsappNotification}
                              />
                            )}
                          </div>
                          {props.error}
                          <div />
                          {studioAdminScreen && (
                            <div
                              className="md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-2 text-primary underline underline-offset-4 decoration-primary"
                              href="#"
                              onClick={props.handleClickChangePassword}
                            >
                              Change Password
                            </div>
                          )}
                          <div className="md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-1 xl:col-start-1 xl:col-span-1 xl:col-start-1 xl:col-span-1 sm:flex-col sm:justify-start mb-5 md:mb-0">
                            <Button
                              type="submit"
                              label={btnLabel}
                              textsize="base"
                            />
                          </div>
                          <div className="md:col-start-4 md:col-span-3 lg:col-start-2 lg:col-span-1 xl:col-start-2 xl:col-span-1  sm:flex-col sm:justify-start mb-5 md:mb-0">
                            <Button
                              type="button"
                              label="Cancel"
                              textsize="base"
                              background={"none"}
                              color={"black"}
                              bordercolor={"primary"}
                              onClick={
                                (formik.resetForm,
                                () => {
                                  if (studioAdminScreen) {
                                    props.handleClickCancelEditProfile();
                                  } else {
                                    history.goBack();
                                  }
                                })
                              }
                            />
                          </div>
                        </form>
                      </FormikProvider>
                    </div>
                    <SweetAlert2
                      icon="success"
                      show={props.showAlert}
                      text={props.alertMessage}
                      onConfirm={() => {
                        window.location.reload();
                        if (studioAdminScreen) {
                          props.handleClickCancelEditProfile();
                        } else {
                          history.goBack();
                        }
                      }}
                    />
                  </div>
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
