import * as React from "react";
import { useState } from "react";
import SideNavBar from "components/Navbars/SideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";
import TabNavBar from "components/Navbars/TabNavBar";
import Button from "components/UI/Button";

import PendingPortfolios from "./components/PendingPortfolios";
import MyArtist from "./components/MyArtist";
import SendInvite from "./components/SendInvite";
import AllArtists from "./components/AllArtists";
import NewArtists from "./components/NewArtists";
import InvitedList from "./components/InvitedList";
import {
  navigations,
  userNavigations,
  USER_ROLE_MANGER_ID,
} from "layouts/AdminConstants";
import { tabsNav } from "./CONSTANTS";
// import SessionExpiry from "components/SessionExpiry";

const ArtistAdminView = ({
  selectedTab,
  setSelectedTab,
  artistState,
  newArtistState,
  myArtistState,
  invitedArtistState,
  adminArtistPortfolioState,
  loginData,
  countries,
  techniques,
  styles,
  studios,
  open,
  setOpen,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const [tabs, setTabs] = useState(tabsNav);

  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  const setTab = (tabToSet) => {
    setSelectedTab(tabToSet.id);
    let updatedTabs = tabs.map((tab) =>
      tab.id === tabToSet.id
        ? { ...tab, current: true }
        : { ...tab, current: false }
    );
    setTabs(updatedTabs);
  };

  let grid;
  if (selectedTab === tabsNav[0].id) {
    grid = (
      <AllArtists
        allArtists={artistState.data}
        countries={countries}
        techniques={techniques}
        styles={styles}
        studios={studios}
      />
    );
  } else if (selectedTab === tabsNav[1].id) {
    let filteredUserData = newArtistState?.data?.user?.filter(
      (rec) =>
        rec.artist_work_places.length === 0 &&
        rec.studio_artists.length === 0 &&
        rec.artist_portfolios.length === 0
    );
    if (newArtistState?.data?.user) {
      newArtistState.data.user = filteredUserData;
    }

    grid = (
      <NewArtists
        newArtists={newArtistState.data}
        countries={countries}
        techniques={techniques}
        styles={styles}
        studios={studios}
      />
    );
  } else if (selectedTab === tabsNav[2].id) {
    grid = <InvitedList artists={invitedArtistState.data} />;
  } else if (selectedTab === tabsNav[3].id) {
    grid = (
      <MyArtist
        myArtists={myArtistState.myartist}
        techniques={techniques}
        styles={styles}
        studios={studios}
      />
    );
  } else if (selectedTab === tabsNav[4].id) {
    grid = (
      <PendingPortfolios pendingPortfolios={adminArtistPortfolioState.data} />
    );
  }

  return (
    <div className="h-full flex">
      <div className="flex-1 flex flex-col overflow-hidden h-full bg-artistBg">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={loginData}
        />

        <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
          <SideNavBar
            navigations={navigations(
              loginData.userRoleId !== USER_ROLE_MANGER_ID
            )}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            loginID={loginData.id}
          />
          <div className="flex-1 flex items-stretch overflow-hidden">
            <main className="flex-1 overflow-y-auto">
              <div className="pt-8 md:px-8 px-3 w-full">
                <div className="md:flex">
                  <TabNavBar tabs={tabs} handleChange={setTab} />
                  <div className="md:w-1/6 md:ml-10 flex items-center mt-5 md:mt-0">
                    <Button
                      label="Invite"
                      background={"none"}
                      color={"primary"}
                      bordercolor={"primary"}
                      onClick={handleOpen}
                      font={"normal"}
                      paddingv={2}
                    />
                    {open && (
                      <SendInvite
                        open={open}
                        setOpen={setOpen}
                        iniError={null}
                      />
                    )}
                  </div>
                </div>
                <div className="my-10 w-full -mt-4">{grid}</div>
              </div>
              {/* <SessionExpiry
                sessionError={
                  artistState.error
                    ? artistState.error
                    : invitedArtistState.error
                    ? invitedArtistState.error
                    : adminArtistPortfolioState.error
                    ? adminArtistPortfolioState.error
                    : null
                }
              /> */}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistAdminView;
