import StudioService from "services/StudioService";

export const STUDIO_LOADING = "STUDIO_LOADING";
export const GET_STUDIO_BY_TYPE_SUCCESS = "GET_STUDIO_BY_TYPE_SUCCESS";
export const GET_STUDIO_SUCCESS = "GET_STUDIO_SUCCESS";
export const CREATE_STUDIO_SUCCESS = "GET_STUDIO_SUCCESS";
export const UPDATE_STUDIO_SUCCESS = "GET_STUDIO_SUCCESS";
export const STUDIO_ERROR = "STUDIO_ERROR";
export const GET_ALL_STUDIOS_SUCCESS = "GET_ALL_STUDIOS_SUCCESS";

export const STUDIO_CURRENT_PROJECT_LOADING = "STUDIO_CURRENT_PROJECT_LOADING";
export const GET_STUDIO_CURRENT_PROJECT = "GET_STUDIO_CURRENT_PROJECT";
export const GET_STUDIO_CURRENT_PROJECT_ERROR = "GET_STUDIO_CURRENT_PROJECT_ERROR";

export const STUDIO_HISTORY_PROJECT_LOADING = "STUDIO_HISTORY_PROJECT_LOADING";
export const GET_STUDIO_HISTORY_PROJECT = "GET_STUDIO_HISTORY_PROJECT";
export const GET_STUDIO_HISTORY_PROJECT_ERROR = "GET_STUDIO_HISTORY_PROJECT_ERROR";

export const STUDIO_EARNINGS_LOADING = "STUDIO_EARNINGS_LOADING";
export const GET_STUDIO_EARNINGS = "GET_STUDIO_EARNINGS";
export const GET_STUDIO_EARNINGS_ERROR = "GET_STUDIO_EARNINGS_ERROR";

export const GET_STUDIO_BY_ARTIST_SUCCESS = "GET_STUDIO_BY_ARTIST_SUCCESS";


const tabledata =[
    {id:"1", project:"#354812", amount:"$1'200", payoutMethod:"artist@company.com", dateProcessed : "15 Jul 2021"},
    {id:"2", project:"#354812", amount:"$1'200", payoutMethod:"artist@company.com", dateProcessed : "15 Jul 2021"},
    {id:"3", project:"#354812", amount:"$1'200", payoutMethod:"artist@company.com", dateProcessed : "15 Jul 2021"},
    {id:"4", project:"#354812", amount:"$1'200", payoutMethod:"artist@company.com", dateProcessed : "15 Jul 2021"},
    {id:"5", project:"#354812", amount:"$1'200", payoutMethod:"artist@company.com", dateProcessed : "15 Jul 2021"},
    {id:"6", project:"#354812", amount:"$1'200", payoutMethod:"artist@company.com", dateProcessed : "15 Jul 2021"}
]

export const getStudiosByStudioType = (studioTypeId) => async (dispatch) => {
  dispatch({
    type: STUDIO_LOADING,
  });
  try {
    let res = await StudioService.getStudiosByStudioType(studioTypeId);

    return dispatch({
      type: GET_STUDIO_BY_TYPE_SUCCESS,
      payload: res.data.studio,
    });
  } catch (err) {
    return dispatch({
      type: STUDIO_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const getStudio = (id) => async (dispatch) => {
  dispatch({
    type: STUDIO_LOADING,
  });
  try {
    let res = await StudioService.getStudio(id);

    return dispatch({
      type: GET_STUDIO_SUCCESS,
      payload: res.data.studio,
    });
  } catch (err) {
    return dispatch({
      type: STUDIO_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const createStudio = (data) => async (dispatch) => {
  dispatch({
    type: STUDIO_LOADING,
  });
  try {
    let res = await StudioService.createStudio(data);
    return dispatch({
      type: CREATE_STUDIO_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: STUDIO_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const updateStudio = (id, data) => async (dispatch) => {
  dispatch({
    type: STUDIO_LOADING,
  });
  try {
    let res = await StudioService.updateStudio(id, data);
    return dispatch({
      type: UPDATE_STUDIO_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: STUDIO_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const getAllStudios = () => async (dispatch) => {
  dispatch({
    type: STUDIO_LOADING,
  });
  try {
    let res = await StudioService.getAllStudios();

    return dispatch({
      type: GET_ALL_STUDIOS_SUCCESS,
      payload: res.data.studio,
    });
  } catch (err) {
    return dispatch({
      type: STUDIO_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const getStudioCurrentProjects = (id) => async (dispatch) => {
  dispatch({
      type: STUDIO_CURRENT_PROJECT_LOADING,
  });
  try {
      const res = await StudioService.getStudioCurrentProjects(id);     
      return dispatch({
          type: GET_STUDIO_CURRENT_PROJECT,
          payload: res.data,
      });
  } catch (err) {
      return dispatch({
          type: GET_STUDIO_CURRENT_PROJECT_ERROR,
          payload: err.response,
      });
  }
};


export const getStudioProjectsHistory = (id) => async (dispatch) => {
  dispatch({
      type: STUDIO_HISTORY_PROJECT_LOADING,
  });
  try {
      const res = await StudioService.getStudioProjectsHistory(id);     
      return dispatch({
          type: GET_STUDIO_HISTORY_PROJECT,
          payload: res.data,
      });
  } catch (err) {
      return dispatch({
          type: GET_STUDIO_HISTORY_PROJECT_ERROR,
          payload: err.response,
      });
  }
};

export const getStudioEarnings = () => async (dispatch) => {
  dispatch({
      type: STUDIO_EARNINGS_LOADING,
  });
  try {
      // const res = await StudioService.getStudioEarnings();     
      return dispatch({
          type: GET_STUDIO_EARNINGS,
          // payload: res.data,
          payload: tabledata
      });
  } catch (err) {
      return dispatch({
          type: GET_STUDIO_EARNINGS_ERROR,
          payload: err.response,
      });
  }
};


export const getMyStudiosForArtistOnDay = (artistId, dayId, studioTypeId) => async (dispatch) => {
  dispatch({
    type: STUDIO_LOADING,
  });
  try {
    let res = await StudioService.getMyStudiosForArtistOnDay(artistId, dayId, studioTypeId);

    return dispatch({
      type: GET_STUDIO_BY_ARTIST_SUCCESS,
      payload: res.data.studio,
    });
  } catch (err) {
    return dispatch({
      type: STUDIO_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};
