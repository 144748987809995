import React from "react";
import { SERVER_URL } from "utils/constants";
import Pagination from "./Pagination";
import moment from "moment";
import { refundPayment } from "redux/actions/PaymentAction";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import Button from "components/UI/Button";

const Table = ({
  data,
  currentPage,
  setCurrentPage,
  pageSize,
  paginationRange,
  totalPageCount,
  disablePagination,
  setRefresh,
  loginDataId,
}) => {
  const dispatch = useDispatch();

  const showAlert = (text) => {
    return Swal.fire({
      icon: "Warning",
      title: "",
      text: text,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
    });
  };

  const onClickRefundAmount = async (val) => {
    showAlert("Are you sure, you want to refund this invoice?").then(
      (value) => {
        if (value.isConfirmed) {
          refundAmount(val);
        }
      }
    );
  };

  const refundAmount = (id) => {
    Swal.fire({
      title: "In Progress",
      text: "Processing Refund...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    dispatch(refundPayment(id, { loggedInUserId: loginDataId })).then((res) => {
      Swal.close();
      if (res && res?.payload && res?.payload?.payment) {
        Swal.fire({
          title: "Result",
          text: res?.payload?.payment?.Message,
        }).then(() => {
          setRefresh(true);
        });
      }
    });
  };

  return (
    <div className="flex w-full flex flex-col">
      <table className="w-full bg-white" id="more-tables">
        <thead>
          <tr className="text-tertiary text-left text-sm font-sans font-light border-b border-tableLine">
            <th className="pb-4">Order</th>
            <th className="pb-4">Client</th>
            <th className="pb-4">Project</th>
            <th className="pb-4">Date</th>
            {/* <th className="pb-4">Payment Method</th> */}
            <th className="pb-4">Cost</th>
            <th className="pb-4">Refund</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <tr key={item.id} className="border-b border-tableLine font-sans">
              <td data-title="Order">#{item?.id}</td>
              <td data-title="Client" className="flex flex-row ">
                <div className=" flex flex-row items-center gap-2">
                  <div className="">
                    <img
                      src={
                        item?.project?.user?.photoUrl
                          ? SERVER_URL + item?.project?.user?.photoUrl
                          : SERVER_URL +
                            "uploads/default-images/DefaultProfile.jpeg"
                      }
                      alt="profile"
                      className="w-10 h-10 rounded-full object-cover object-center"
                    />
                  </div>
                  {item?.project?.user?.firstName}{" "}
                  {item?.project?.user?.lastName}
                </div>
              </td>
              <td data-title="Project">{item?.projectId}</td>
              <td data-title="Date">
                {moment(item?.createdAt).format("D MMM, HH:mm")}
              </td>
              {/* <td data-title="Payment Method">
                {item?.paymentMethod ? item?.paymentMethod : "N/A"}
              </td> */}
              <td data-title="Cost">{item?.amount}</td>
              <td data-title="Refund">
                {item?.isRefunded ? (
                  <div className="flex bg-white border-0 text-primary rounded-lg w-full md:w-3/4 py-0 md:px-2 lg:px-0 text-red">
                    Refunded
                  </div>
                ) : (
                  <Button
                    onClick={() => onClickRefundAmount(item.id)}
                    label="Refund"
                    textsize="base"
                    border="none"
                    background="white"
                    color="primary"
                    shadow="shadow-none"
                    hoverbg="white"
                    hovertext="primary"
                    font={"normal"}
                    place={" "}
                    px={"0"}
                    paddingv={"0"}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!disablePagination && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          paginationRange={paginationRange}
          totalPageCount={totalPageCount}
        />
      )}
    </div>
  );
};

export default Table;
