import axios from "../http-config";

class StudioService {

    getStudiosByStudioType(studioTypeId) {
        return axios({
            method: "GET",
            url: `/studios/studiotype/${studioTypeId}`
        });
    }

    getStudio(id) {
        return axios({
            method: "GET",
            url: `/studios/${id}`
        });
    }

    createStudio(data) {
        return axios({
            method: "POST",
            url: `/studios`,
            data
        });
    }

    updateStudio(id, data) {
        return axios({
            method: "PATCH",
            url: `/studios/${id}`,
            data
        });
    }

    getAllStudios() {
        return axios({
            method: "GET",
            url: `/studios`
        });
    }

    getStudioCurrentProjects(id) {
        return axios({
            method: "GET",
            url: `/projects/projects-current-studio/${id}`
        });
      }
    
      getStudioProjectsHistory(id) {
        return axios({
            method: "GET",
            url: `/projects/projects-history-studio/${id}`
        });
      }

    getStudioEarnings() {
        return axios({
            method: "GET",
            url: `/projects/earnings/`
        });
    }


    getMyStudiosForArtistOnDay(artistId, dayId, studioTypeId) {
        return axios({
            method: "GET",
            url: `/studios/artistwise-daywise/${artistId}/${dayId}/${studioTypeId}`
        });
    }
}

export default new StudioService();
