/* eslint-disable eqeqeq */
import { useState } from "react";
import {
  DAYS,
  USER_ROLE_BOSS,
  USER_ROLE_ADMIN,
  USER_ROLE_MANAGER,
} from "../utils/constants";
import TabNavBar from "components/Navbars/TabNavBar";
import Swal from "sweetalert2";

export default function WorkPlaceSchedule({
  setSelectedTab,
  selectedTab,
  selectedTabData,
  openStudio,
  studioOptionItems,
  onSaveStudio,
  role,
  sShow,
}) {
  const [tabs, setTabs] = useState([
    {
      id: DAYS.MONDAY.id,
      name: DAYS.MONDAY.abbreviation,
      current: selectedTab == DAYS.MONDAY.id,
    },
    {
      id: DAYS.TUESDAY.id,
      name: DAYS.TUESDAY.abbreviation,
      current: selectedTab == DAYS.TUESDAY.id,
    },
    {
      id: DAYS.WEDNESDAY.id,
      name: DAYS.WEDNESDAY.abbreviation,
      current: selectedTab == DAYS.WEDNESDAY.id,
    },
    {
      id: DAYS.THURSDAY.id,
      name: DAYS.THURSDAY.abbreviation,
      current: selectedTab == DAYS.THURSDAY.id,
    },
    {
      id: DAYS.FRIDAY.id,
      name: DAYS.FRIDAY.abbreviation,
      current: selectedTab == DAYS.FRIDAY.id,
    },
    {
      id: DAYS.SATURDAY.id,
      name: DAYS.SATURDAY.abbreviation,
      current: selectedTab == DAYS.SATURDAY.id,
    },
    {
      id: DAYS.SUNDAY.id,
      name: DAYS.SUNDAY.abbreviation,
      current: selectedTab == DAYS.SUNDAY.id,
    },
  ]);

  const setTab = (tabToSet) => {
    setSelectedTab(tabToSet.id);
    let updatedTabs = tabs.map((tab) =>
      tab.id === tabToSet.id
        ? { ...tab, current: true }
        : { ...tab, current: false }
    );

    setTabs(updatedTabs);
  };

  const showAlert = (text) => {
    return Swal.fire({
      icon: "Warning",
      title: "",
      text: text,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
    });
  };

  const saveStudio = async (val) => {
    showAlert("Are you sure, you want to link this studio").then((value) => {
      if (value.isConfirmed) {
        onSaveStudio(val, selectedTab);
      }
    });
  };

  return (
    <div className="flex-1 flex items-stretch overflow-hidden">
      <main className="flex-1 overflow-y-auto">
        <div className="md:pt-0 max-w-7xl">
          <TabNavBar
            tabs={tabs}
            handleChange={setTab}
            tabSpace={4}
            tabFont={"text-sm"}
            py={1}
            px={0}
            mx={0}
            borderHeight={2}
            hideCount={true}
          />
          {selectedTabData}
          {openStudio &&
            role &&
            (role === USER_ROLE_BOSS ||
              role === USER_ROLE_ADMIN ||
              role === USER_ROLE_MANAGER) && (
              <div
                className="border border-tertiary mt-3 rounded-md px-2 flex-row items-center"
                hidden={sShow}
              >
                <select
                  className="w-full flex py-2 text-base focus:outline-none focus:ring-primary text-black"
                  name="studioId"
                  id="studioId"
                  onChange={(e) => saveStudio(e.target.value)}
                >
                  <option
                    className="shadow-none border-0"
                    value=""
                    label=""
                    key=""
                  >
                    Select Studio
                  </option>
                  {studioOptionItems}
                </select>
              </div>
            )}
        </div>
      </main>
    </div>
  );
}
