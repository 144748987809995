import axios from '../http-config'

class AccountingSerice {
    getInvoices(queryString){
        return axios({
            method : 'GET',
            url : `accounting/invoices?${queryString}`,
        })
    }

    getStatisticsData(){
        return axios({
            method : 'GET',
            url : `accounting/statistics`,
        })
    }

    getGraphsData() {
        return axios({
            method : 'GET',
            url : `accounting/graph`,
        })
    }
}

export default new AccountingSerice()