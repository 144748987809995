import axios from '../http-config'

class UserTypeService {

    getUserTypes() {
        return axios({
            method: "GET",
            url: `/usertype`
        });
    }

}

export default new UserTypeService()