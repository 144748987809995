import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getClientById,
} from "redux/actions/ClientAction";
import ArtistClientProfileView from "./ArtistClientProfileView";
import { ARTIST_CLIENT_PROFILE_TABS as TABS } from "layouts/ArtistConstants";

const ArtistClientProfileContainer = (props) => {
  const [selectedTab, setSelectedTab] = useState(TABS.CURRENTPROJECTS.id);
  const selectedClientId = props.match.params.id;

  const clientState = useSelector((state) => state.client);
  const dispatch = useDispatch();


  useEffect(() => {
    if (selectedTab === TABS.CURRENTPROJECTS.id) {
      dispatch(getClientById(selectedClientId));
    } 
  }, [selectedTab, dispatch]);

  return (
    <div>
      <ArtistClientProfileView
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        clientState={clientState}
        loginData={props.loginData}
      />
    </div>
  );
};

export default ArtistClientProfileContainer;
