export const DEFAULT_BUTTON_WIDTH = "full";
export const DEFAULT_BUTTON_TEXT_SIZE = "base";

export const DAYS = Object.freeze({
  MONDAY: { id: 1, name: "Monday", abbreviation: "Mon" },
  TUESDAY: { id: 2, name: "Tuesday", abbreviation: "Tue" },
  WEDNESDAY: { id: 3, name: "Wednesday", abbreviation: "Wed" },
  THURSDAY: { id: 4, name: "Thursday", abbreviation: "Thu" },
  FRIDAY: { id: 5, name: "Friday", abbreviation: "Fri" },
  SATURDAY: { id: 6, name: "Saturday", abbreviation: "Sat" },
  SUNDAY: { id: 7, name: "Sunday", abbreviation: "Sun" },
});

export const ORDINAL_NUMBERS = Object.freeze({
  FIRST: { id: 1, name: 'First', abbreviation: '1st' },
  SECOND: { id: 2, name: 'Second', abbreviation: '2nd' },
  THIRD: { id: 3, name: 'Third', abbreviation: '3rd' },
  FOURTH: { id: 4, name: 'Fourth', abbreviation: '4th' },
  FIFTH: { id: 5, name: 'Fifth', abbreviation: '5th' },
  SIXTH: { id: 6, name: 'Sixth', abbreviation: '6th' },
  SEVENTH: { id: 7, name: 'Seventh', abbreviation: '7th' },
  EIGHTH: { id: 8, name: 'Eighth', abbreviation: '8th' },
  NINTH: { id: 9, name: 'Ninth', abbreviation: '9th' },
  TENTH: { id: 10, name: 'Tenth', abbreviation: '10th' },
});

export const USER_TYPES = Object.freeze({
  CLIENT: { id: 1, name: "Client" },
  ARTIST: { id: 2, name: "Artist" },
  STUDIO: { id: 3, name: "Studio" },
  HISHEM: { id: 4, name: "Hishem" },
  GENERAL: { id: 5, name: "General" },
});

export const getDaysByInt = (dayId) => {
  switch (dayId) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 7:
      return "Sunday";
    default:
      return "";
  }
};

export const getDaysByIntShort = (dayId) => {
  switch (dayId) {
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
    case 7:
      return "Sun";
    default:
      return "";
  }
};

export const SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

export const IMAGE_SIZE = 11000000; //11000000 Bytes = 10.4904 Megabytes

export const USER_ROLE_BOSS = "Boss";
export const USER_ROLE_ADMIN = "Admin";
export const USER_ROLE_MANAGER = "Manager";
export const USER_ROLE_ARTIST = "Artist";
export const USER_ROLE_CLIENT = "Client";

export const SOCKET_CONNECTION_URL_PORT = SERVER_URL;

export const USER_ROLES = Object.freeze({
  ADMIN: 1,
  BOSS: 2,
  ARTIST: 3,
  CLIENT: 4,
  MANAGER: 5,
  PARTNER_STUDIO_ADMIN: 6,
  PARTNER_STUDIO_MANAGER: 7,
});

export const WEB_SERVER_URL = process.env.REACT_APP_WEB_SERVER_URL;

export const getUserType = (userTypeId) => {
  switch (userTypeId) {
    case 1:
      return "Client";
    case 2:
      return "Artist";
    case 3:
      return "Studio";
    case 4:
      return "Hichem";
    default:
      return "Client";
  }
};

export const BOSS_USER_NAME = "Ink Foundation";
export const BOSS_USER_ID = 1;
export const HIDE_DISABLE_FLAG = true;
export const SCERETPASS = process.env.REACT_APP_SECRETPASS;

export const dayNumberArray = [1, 2, 3, 4, 5, 6, 7]; 

export const DEFAULT_COUNTRY = "Switzerland";