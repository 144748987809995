/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import ArtistSideNavBar from "../components/ArtistSideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";
import TabNavBar from "components/Navbars/TabNavBar";
import SideNavBar from "components/Navbars/SideNavBar";
import { userNavigations } from "layouts/ArtistConstants";
import {
  TABS,
  VIEWS,
  BOSS_TABS,
  getUserRole,
  USER_ROLE_BOSS,
  USER_ROLE_ARTIST,
  USER_ROLE_ADMIN,
  USER_ROLE_MANAGER,
} from "../../../layouts/ArtistConstants";
import ArtistPortfolioListCell from "../components/ArtistPortfolioListCell";
import TempArtistPortfolioListCell from "../components/TempArtistPortfolioListCell";
import CurrentProjects from "../ArtistProjects/CurrentProjects";
import ProjectsHistory from "../ArtistProjects/ProjectsHistory";
import { ReactComponent as AddIcon } from "../../../assets/ic_plus.svg";
import AdminArtistProjects from "../ArtistProjects/AdminArtistProjects";
import {
  USER_ROLE_MANGER_ID,
  USER_TYPE_ARTIST,
  USER_TYPE_CLIENT,
  navigations,
} from "layouts/AdminConstants";
import AdminArtistRatings from "../ArtistRatings/AdminArtistRatings";
import { ReactComponent as RatingIcon } from "../../../assets/ico_rating.svg";
import { ReactComponent as RatingEmptyIcon } from "../../../assets/ico_rating_empty.svg";
import { styled } from '@mui/material/styles';
import Rating from "@mui/material/Rating";

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#C7A33F',
  },
  '& .MuiRating-iconHover': {
    color: '#AFAFAF',
  },
  '& .MuiRating-iconEmpty': {
    color: '#AFAFAF',
  },
});

export default function ArtistHomeView({
  selectedTab,
  setSelectedTab,
  loginData,
  artistState,
  currentCount,
  historyCount,
  artistReviewsAvg,
  role,
  onSaveStudio,
  onUpdateStatus,
  onDeletePortfolio,
  addPortfolio,
  artistError,
  actualLoginData,
  projectCount,
  onUpdateTmpStatus,
  onDeleteTmpPortfolio,
  refreshArtist,
  showRating,
  handleShowRating,
  artistRatings,
  changeArtistRatingStatus
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [tabs, setTabs] = useState([]);

  const user_role = getUserRole(actualLoginData?.userRoleId);

  let avg = 0;
  let cnt = 0;
  if (artistReviewsAvg && artistReviewsAvg.length > 0) {
    avg = Math.round(artistReviewsAvg[0].avgRating);
    cnt = Math.round(artistReviewsAvg[0].count);
  }

  if (user_role === USER_ROLE_ARTIST) {
    userNavigations[0].href = "/artist/profile";
    userNavigations[1].href = "/artist/signout";
  } else if (
    user_role === USER_ROLE_BOSS ||
    user_role === USER_ROLE_ADMIN ||
    user_role === USER_ROLE_MANAGER
  ) {
    userNavigations[0].href = "/admin/profile";
    userNavigations[1].href = "/admin/signout";
  }

  useEffect(() => {
    if (
      role === USER_ROLE_BOSS ||
      role === USER_ROLE_ADMIN ||
      role === USER_ROLE_MANAGER
    ) {
      setTabs([
        {
          id: BOSS_TABS.PORTFOLIO.id,
          name: BOSS_TABS.PORTFOLIO.name,
          href: "#",
          current: selectedTab == BOSS_TABS.PORTFOLIO.id,
        },
        {
          id: BOSS_TABS.PROJECTS.id,
          name: BOSS_TABS.PROJECTS.name,
          href: "#",
          current: selectedTab == BOSS_TABS.PROJECTS.id,
          count: projectCount,
        },
      ]);
    } else {
      setTabs([
        currentCount == 0
          ? {
              id: TABS.PORTFOLIO.id,
              name: TABS.PORTFOLIO.name,
              href: "#",
              current: selectedTab == TABS.PORTFOLIO.id,
            }
          : {
              id: TABS.CURRENTPROJECTS.id,
              name: TABS.CURRENTPROJECTS.name,
              href: "#",
              current: selectedTab == TABS.CURRENTPROJECTS.id,
              count: currentCount,
            },
        currentCount == 0
          ? {
              id: TABS.CURRENTPROJECTS.id,
              name: TABS.CURRENTPROJECTS.name,
              href: "#",
              current: selectedTab == TABS.CURRENTPROJECTS.id,
              count: currentCount,
            }
          : {
              id: TABS.PORTFOLIO.id,
              name: TABS.PORTFOLIO.name,
              href: "#",
              current: selectedTab == TABS.PORTFOLIO.id,
            },
        {
          id: TABS.PROJECTHISTORY.id,
          name: TABS.PROJECTHISTORY.name,
          href: "#",
          current: selectedTab == TABS.PROJECTHISTORY.id,
          count: historyCount,
        },
        // {
        //   id: TABS.MARKETPLACE.id,
        //   name: TABS.MARKETPLACE.name,
        //   href: "#",
        //   current: selectedTab == TABS.MARKETPLACE.id,
        // },
        // {
        //   id: TABS.WORKPLACES.id,
        //   name: TABS.WORKPLACES.name,
        //   href: "#",
        //   current: selectedTab == TABS.WORKPLACES.id,
        // },
      ]);
    }
  }, [projectCount, historyCount, currentCount, role, selectedTab]);

  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  const setTab = (tabToSet) => {
    setSelectedTab(tabToSet.id);
    let updatedTabs = tabs.map((tab) =>
      tab.id === tabToSet.id
        ? { ...tab, current: true }
        : { ...tab, current: false }
    );
    setTabs(updatedTabs);
  };

  let grid;
  let showAdd = true;
  if (selectedTab === TABS.PORTFOLIO.id) {
    grid = (
      <div>
        <section className="mt-8 pb-12" aria-labelledby="gallery-heading">
          <ul className="">
            {loginData?.artist_portfolios?.map((artistPortfolio, index) => (
              <div key={index + 400000}>
                <li key={index} className="col-span-1 bg-white p-2 my-2">
                  <ArtistPortfolioListCell
                    artistPortfolio={artistPortfolio}
                    calledFrom={VIEWS.ARTIST_HOME.id}
                    showStatus={true}
                    role={role}
                    onUpdateStatus={onUpdateStatus}
                    onDeletePortfolio={onDeletePortfolio}
                    actualLoginData={actualLoginData}
                  />
                </li>
                {artistPortfolio?.tmp_artist_portfolios?.length > 0 && (
                  <li
                    key={index + 1000}
                    className="col-span-1 bg-white p-2 my-2"
                  >
                    <TempArtistPortfolioListCell
                      artistPortfolio={artistPortfolio.tmp_artist_portfolios[0]}
                      calledFrom={VIEWS.ARTIST_HOME.id}
                      showStatus={true}
                      role={role}
                      onUpdateTmpStatus={onUpdateTmpStatus}
                      onDeleteTmpPortfolio={onDeleteTmpPortfolio}
                      actualLoginData={actualLoginData}
                    />
                  </li>
                )}
              </div>
            ))}
          </ul>
        </section>
      </div>
    );
    showAdd = true;
  } else if (selectedTab === TABS.CURRENTPROJECTS.id) {
    grid = (
      <CurrentProjects
        selectedTab={selectedTab}
        artistsProjects={artistState.artistCurrentProjects}
      />
    );
    showAdd = false;
  } else if (selectedTab === TABS.PROJECTHISTORY.id) {
    grid = (
      <ProjectsHistory
        selectedTab={selectedTab}
        artistsProjects={artistState.artistProjectsHistory}
      />
    );
    showAdd = false;
  } else if (selectedTab === TABS.MARKETPLACE.id) {
    showAdd = false;
    //TODO
  } else if (selectedTab === TABS.WORKPLACES.id) {
    showAdd = true;
    //TODO
  } else if (selectedTab === BOSS_TABS.PROJECTS.id) {
    showAdd = false;
    grid = (
      <AdminArtistProjects
        selectedTab={selectedTab}
        artistsProjects={artistState.artistProjects}
        role={role}
      />
    );
  }

  const addNewEntity = () => {
    if (selectedTab === TABS.PORTFOLIO.id) {
      addNewPortfolio();
    } else {
      addNewWorkplace();
    }
  };

  const addNewPortfolio = () => {
    addPortfolio();
  };

  const addNewWorkplace = () => {};
  return (
    <>
      <div className="h-full flex">
        <div className="flex-1 flex flex-col overflow-hidden h-full ">
          <TopNavBar
            userNavigations={userNavigations}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            loginData={actualLoginData}
          />
          {/* Content area */}
          <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
            {actualLoginData.userTypeId != USER_TYPE_CLIENT &&
              actualLoginData.userTypeId != USER_TYPE_ARTIST && (
                <SideNavBar
                  navigations={navigations(
                    actualLoginData.userRoleId !== USER_ROLE_MANGER_ID
                  )}
                  mobileMenuOpen={mobileMenuOpen}
                  handleMenu={handleMenuOpen}
                  bg={"white"}
                  loginID={actualLoginData.id}
                />
              )}
            <div className="w-full md:w-2/5 lg:w-1/3 xl:w-1/4 2xl:w-1/4 4xl:w-1/4 md:h-full bg-white">
              <ArtistSideNavBar
                loginData={loginData}
                avgRating={artistReviewsAvg}
                role={role}
                onSaveStudio={onSaveStudio}
                refreshArtist={refreshArtist}
                actualLoginData={actualLoginData}
                handleShowRating={handleShowRating}
              />
            </div>
            {/* Main content */}
            <div className="flex-1 flex items-stretch overflow-hidden bg-gradient-to-r from-bgg to-white">
              <main className="flex-1 overflow-y-auto">
                {!showRating && 
                  <div className="md:pt-4 px-2 md:px-8 ">
                    <div className="pl-0 flex flex-row items-center">
                      <TabNavBar tabs={tabs} handleChange={setTab} tabSpace={4} selectedTab={selectedTab}/>
                      {role === USER_ROLE_ARTIST && showAdd && (
                        <AddIcon
                          onClick={() => addNewEntity()}
                          className="ml-2"
                        />
                      )}
                    </div>
                    {grid}
                  </div>
                }
                {showRating && 
                <div className="flex flex-1 bg-white h-full">
                  <div className="w-full md:mx-0.1 py-2 md:py-4 md:px-4 bg-white shadow">
                    <div className="mr-4">
                      <div className="flex flex-col mx-2 w-full ">
                        <div>
                          <p className="text-primary text-2xl font-normal font-sans">
                            {" "}
                            Reviews
                          </p>       
                          <div className="flex mt-2 items-center gap-x-2">
                            <StyledRating
                              size="large"
                              name="simple-controlled"
                              getLabelText={(value) =>
                                `${value} Heart${value !== 1 ? "s" : ""}`
                              }
                              value={avg}
                              icon={<RatingIcon />}
                              emptyIcon={<RatingEmptyIcon />}
                            /> {cnt > 0 ? `(${cnt})` : ''}
                          </div> 
                        </div>
                        <p className="text-primary text-sm font-semibold mt-2" onClick={() => handleShowRating(false)}>
                          {"  "}
                          Back
                        </p>            
                        <div className="flex my-4 w-full">
                          <AdminArtistRatings artistRatings={artistRatings} role={role} changeArtistRatingStatus={changeArtistRatingStatus}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>                  
                }
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
