const TextArea = (props) => {
  return (
    <>
      <div className="border border-primary rounded-2xl px-3 py-2 mt-4 shadow-sm focus-within:ring-0 focus-within:ring-primary focus-within:border-primary overflow-hidden">
        <label
          htmlFor="name"
          className="block text-xs font-medium text-primary font-sans"
        >
          {props.label}
        </label>
        <textarea
          {...props}
          className="font-sans border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
        />
      </div>
    </>
  );
};

export default TextArea;
