import ReviewsService from "services/ReviewsService";

export const GET_ARTISTRATINGS_LOADING = "GET_ARTISTRATINGS_LOADING";
export const GET_ARTISTRATINGS_SUCCESS = "GET_ARTISTRATINGS_SUCCESS";
export const GET_ARTISTRATINGS_ERROR = "GET_ARTISTRATINGS_ERROR";

export const UPDATE_ARTISTRATING_AS_VIEWED_LOADING = "UPDATE_ARTISTRATING_AS_VIEWED_LOADING";
export const UPDATE_ARTISTRATING_AS_VIEWED_SUCCESS = "UPDATE_ARTISTRATING_AS_VIEWED_SUCCESS";
export const UPDATE_ARTISTRATING_AS_VIEWED_ERROR = "UPDATE_ARTISTRATING_AS_VIEWED_ERROR";

export const UPDATE_ARTISTRATING_STATUS_LOADING = "UPDATE_ARTISTRATING_STATUS_LOADING";
export const UPDATE_ARTISTRATING_STATUS_SUCCESS = "UPDATE_ARTISTRATING_STATUS_SUCCESS";
export const UPDATE_ARTISTRATING_STATUS_ERROR = "UPDATE_ARTISTRATING_STATUS_ERROR";

export const GET_ARTISTPUBLISHEDRATINGS_LOADING = "GET_ARTISTPUBLISHEDRATINGS_LOADING";
export const GET_ARTISTPUBLISHEDRATINGS_SUCCESS = "GET_ARTISTPUBLISHEDRATINGS_SUCCESS";
export const GET_ARTISTPUBLISHEDRATINGS_ERROR = "GET_ARTISTPUBLISHEDRATINGS_ERROR";

export const GET_ALLRATINGSARTISTID_LOADING = "GET_ALLRATINGSARTISTID_LOADING";
export const GET_ALLRATINGSARTISTID_SUCCESS = "GET_ALLRATINGSARTISTID_SUCCESS";
export const GET_ALLRATINGSARTISTID_ERROR = "GET_ALLRATINGSARTISTID_ERROR";

export const getArtistRatings = () => async (dispatch) => {
  dispatch({
    type: GET_ARTISTRATINGS_LOADING,
  });
  try {
    let res = await ReviewsService.getArtistRatings();

    return dispatch({
      type: GET_ARTISTRATINGS_SUCCESS,
      payload: res.data.artistPortfolio
    });
  } catch (err) {
    return dispatch({
      type: GET_ARTISTRATINGS_ERROR,
      payload: err.response,
    });
  }
};

export const updateArtistRatingAsViewed = () => async (dispatch) => {
  dispatch({
    type: UPDATE_ARTISTRATING_AS_VIEWED_LOADING,
  });
  try {
    let res = await ReviewsService.updateArtistRatingAsViewed();

    return dispatch({
      type: UPDATE_ARTISTRATING_AS_VIEWED_SUCCESS,
      payload: res.data.artistrating,
    });
  } catch (err) {
    return dispatch({
      type: UPDATE_ARTISTRATING_AS_VIEWED_ERROR,
      payload: err.response,
    });
  }
};

export const updateArtistRatingStatus = (id, statusId) => async (dispatch) => {
  dispatch({
    type: UPDATE_ARTISTRATING_STATUS_LOADING,
  });
  try {
    let res = await ReviewsService.updateArtistRatingStatus(id, statusId);

    return dispatch({
      type: UPDATE_ARTISTRATING_STATUS_SUCCESS,
      payload: res.data.artistrating,
    });
  } catch (err) {
    return dispatch({
      type: UPDATE_ARTISTRATING_STATUS_ERROR,
      payload: err.response,
    });
  }
};

export const getArtistPublishedRatings = (id) => async (dispatch) => {
  dispatch({
    type: GET_ARTISTPUBLISHEDRATINGS_LOADING,
  });
  try {
    let res = await ReviewsService.getArtistPublishedRatings(id);

    return dispatch({
      type: GET_ARTISTPUBLISHEDRATINGS_SUCCESS,
      payload: res.data.artistPortfolio
    });
  } catch (err) {
    return dispatch({
      type: GET_ARTISTPUBLISHEDRATINGS_ERROR,
      payload: err.response,
    });
  }
};

export const getAllRatingsByArtistId = (id) => async (dispatch) => {
  dispatch({
    type: GET_ALLRATINGSARTISTID_LOADING,
  });
  try {
    let res = await ReviewsService.getAllRatingsByArtistId(id);

    return dispatch({
      type: GET_ALLRATINGSARTISTID_SUCCESS,
      payload: res.data.artistPortfolio
    });
  } catch (err) {
    return dispatch({
      type: GET_ALLRATINGSARTISTID_ERROR,
      payload: err.response,
    });
  }
};
