import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import ResetPassword from "views/auth/ResetPassword/ResetPasswordContainer.js";
import Profile from "views/profile/ProfileContainer.js";
import ChangePassword from "views/auth/ChangePassword/ChangePasswordContainer.js";
import VerifyEmail from "views/auth/VerifyEmail/VerifyEmailContainer.js";
import ArtistRegistrationContainer from "views/artist/ArtistRegistration/ArtistRegistrationContainer.js"
import StudioVerificationContainer from "views/studio/StudioVerification/StudioVerificationContainer.js"


export default function AuthLayout() {
  return (
    <>
      <main>
        <Switch>
          <Route path="/auth/profile" exact component={Profile} />
          <Route path="/auth/login" exact component={Login} />
          <Route path="/auth/register" exact component={Register} />
          <Route path="/auth/resetPassword" exact component={ResetPassword} />
          <Route path="/auth/changePassword/:id" exact component={ChangePassword} />
          <Route path="/auth/verifyEmail/:id" exact component={VerifyEmail} />
          <Route path="/auth/verify-invitation/:verifyString" exact component={ArtistRegistrationContainer} />
          <Route path="/auth/verify-studio/:verifyString" exact component={StudioVerificationContainer} />
          <Redirect from="/auth" to="/auth/login" />
          <Redirect from="/" to="/landing" />
        </Switch>
      </main>
    </>
  );
}