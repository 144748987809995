import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPasswordById,
  getUserByResetString,
} from "../../../redux/actions/UserActions";
import ChangePasswordView from "./ChangePasswordView";

const ChangePasswordContainer = (props) => {
  const resetString = props.match.params.id;
  const error = useSelector((state) => state.user.error);
  const data = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    dispatch(getUserByResetString(resetString));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (obj) => {
    obj.id = data.id;
    dispatch(resetPasswordById(obj)).then((res) => {
      if (res.error == null) {
        setShowSuccess(true);
      }
    });
  };

  return (
    <div>
      <ChangePasswordView
        onSubmit={onSubmit}
        error={error}
        data={data}
        showSuccess={showSuccess}
      />
    </div>
  );
};

export default ChangePasswordContainer;
