import {
    ARTISTPORTFOLIO_RESET,
    GET_ARTISTPORTFOLIO_BY_ID_LOADING,
    GET_ARTISTPORTFOLIO_BY_ID_SUCCESS,
    GET_ARTISTPORTFOLIO_BY_ID_ERROR,
    GET_ARTISTPORTFOLIOS_BY_ARTISTID_LOADING,
    GET_ARTISTPORTFOLIOS_BY_ARTISTID_SUCCESS,
    GET_ARTISTPORTFOLIOS_BY_ARTISTID_ERROR,
    CREATE_ARTISTPORTFOLIO_LOADING,
    CREATE_ARTISTPORTFOLIO_SUCCESS,
    CREATE_ARTISTPORTFOLIO_ERROR,
    UPDATE_ARTISTPORTFOLIO_LOADING,
    UPDATE_ARTISTPORTFOLIO_SUCCESS,
    UPDATE_ARTISTPORTFOLIO_ERROR,
    GET_TMP_ARTISTPORTFOLIO_BY_ID_LOADING,
    GET_TMP_ARTISTPORTFOLIO_BY_ID_SUCCESS,
    GET_TMP_ARTISTPORTFOLIO_BY_ID_ERROR,
} from "../actions/ArtistPortfolioAction";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null
};

const ArtistPortfolioReducer = function (state = initialState, action) {
    switch (action.type) {
        case ARTISTPORTFOLIO_RESET: {
            return {
                ...state,
                loading: false,
                success: false,
                data: null,
                error: null,
            };
        }
        case GET_ARTISTPORTFOLIO_BY_ID_LOADING:
        case GET_TMP_ARTISTPORTFOLIO_BY_ID_LOADING:
        case GET_ARTISTPORTFOLIOS_BY_ARTISTID_LOADING:
        case CREATE_ARTISTPORTFOLIO_LOADING:
        case UPDATE_ARTISTPORTFOLIO_LOADING: {
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null
            };
        }
        case GET_ARTISTPORTFOLIO_BY_ID_SUCCESS:
        case GET_TMP_ARTISTPORTFOLIO_BY_ID_SUCCESS:
        case GET_ARTISTPORTFOLIOS_BY_ARTISTID_SUCCESS:
        case CREATE_ARTISTPORTFOLIO_SUCCESS:
        case UPDATE_ARTISTPORTFOLIO_SUCCESS: {
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };
        }
        case GET_ARTISTPORTFOLIO_BY_ID_ERROR:
        case GET_TMP_ARTISTPORTFOLIO_BY_ID_ERROR:
        case GET_ARTISTPORTFOLIOS_BY_ARTISTID_ERROR:
        case CREATE_ARTISTPORTFOLIO_ERROR:
        case UPDATE_ARTISTPORTFOLIO_ERROR: {
            return {
                loading: false,
                success: false,
                data: null,
                error: action.payload ? action.payload.data.error.message : "Error Occured",
            };
        }
        default: {
            return state;
        }
    }
};

export default ArtistPortfolioReducer;