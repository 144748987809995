import axios from "../../http-config";

export const SKINTONE_LOADING = "SKINTONE_LOADING";
export const GET_SKINTONE_SUCCESS = "GET_SKINTONE_SUCCESS";
export const GET_SKINTONE_ERROR = "GET_SKINTONE_ERROR";

export const getSkinTones = () => async (dispatch) => {
  dispatch({
    type: SKINTONE_LOADING,
  });
  try {
    let res = await axios({
      method: "GET",
      url: `/skintones`,
    });

    return dispatch({
      type: GET_SKINTONE_SUCCESS,
      payload: res.data.skintones,
    });
  } catch (err) {
    return dispatch({
      type: GET_SKINTONE_ERROR,
      payload: err.response,
    });
  }
};
