import {
  GET_SUPPORTTICKETSUBJECTS_LOADING,
  GET_SUPPORTTICKETSUBJECTS_SUCCESS,
  GET_SUPPORTTICKETSUBJECTS_ERROR,
  GET_SUPPORTTICKETS_BY_USERTYPEID_LOADING,
  GET_SUPPORTTICKETS_BY_USERTYPEID_SUCCESS,
  GET_SUPPORTTICKETS_BY_USERTYPEID_ERROR,
  CREATE_SUPPORTTICKET_LOADING,
  CREATE_SUPPORTTICKET_SUCCESS,
  CREATE_SUPPORTTICKET_ERROR,
  CREATE_SUPPORTTICKETCOMMENT_LOADING,
  CREATE_SUPPORTTICKETCOMMENT_SUCCESS,
  CREATE_SUPPORTTICKETCOMMENT_ERROR,
  CREATE_SUPPORT_CHAT_LOADING,
  CREATE_SUPPORT_CHAT_SUCCESS,
  CREATE_SUPPORT_CHAT_ERROR,
  CLOSE_SUPPORT_CHAT_LOADING,
  CLOSE_SUPPORT_CHAT_SUCCESS,
  CLOSE_SUPPORT_CHAT_ERROR,
  GET_CLOSE_CASE_STATUS_LOADING,
  GET_CLOSE_CASE_STATUS_SUCCESS,
  GET_CLOSE_CASE_STATUS_ERROR,
  GET_USER_CHAT_STATUS_LOADING,
  GET_USER_CHAT_STATUS_SUCCESS,
  GET_USER_CHAT_STATUS_ERROR,
  GET_USER_UNREAD_SUPPORT_CHATS_LOADING,
  GET_USER_UNREAD_SUPPORT_CHATS_SUCCESS,
  GET_USER_UNREAD_SUPPORT_CHATS_ERROR,
  GET_USER_UNREAD_COMMENT_CHATS_LOADING,
  GET_USER_UNREAD_COMMENT_CHATS_SUCCESS,
  GET_USER_UNREAD_COMMENT_CHATS_ERROR,
  GET_USER_UNREAD_COMMENT_CHATS_ADMIN_LOADING ,
  GET_USER_UNREAD_COMMENT_CHATS_ADMIN_SUCCESS,
  GET_USER_UNREAD_COMMENT_CHATS_ADMIN__ERROR,
  SET_STATUS_COMMENT_CHATS_ADMIN_LOADING,
  SET_STATUS_COMMENT_CHATS_ADMIN_SUCCESS,
  SET_STATUS_COMMENT_CHATS_ADMIN_ERROR,
} from "../actions/SupportAction";

const initialState = {
  loading: false,
  success: false,
  data: null,
  error: null,
  loadingSubjects: false,
  successSubjects: false,
  dataSubjects: null,
  errorSubjects: null,
};

const SupportReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_SUPPORTTICKETSUBJECTS_LOADING: {
      return {
        ...state,
        loadingSubjects: true,
        successSubjects: false,
        dataSubjects: null,
        errorSubjects: null,
      };
    }

    case GET_SUPPORTTICKETSUBJECTS_SUCCESS: {
      return {
        ...state,
        loadingSubjects: false,
        successSubjects: true,
        dataSubjects: action.payload,
        errorSubjects: null,
      };
    }

    case GET_SUPPORTTICKETSUBJECTS_ERROR: {
      return {
        loadingSubjects: false,
        successSubjects: false,
        dataSubjects: null,
        errorSubjects: action.payload
          ? action?.payload?.data?.error?.message
          : "Error Occured",
      };
    }

    case CREATE_SUPPORTTICKET_LOADING:
    case CREATE_SUPPORTTICKETCOMMENT_LOADING:
    case GET_SUPPORTTICKETS_BY_USERTYPEID_LOADING:
    case CREATE_SUPPORT_CHAT_LOADING:
    case CLOSE_SUPPORT_CHAT_LOADING:
    case GET_CLOSE_CASE_STATUS_LOADING:
    case GET_USER_CHAT_STATUS_LOADING:
    case GET_USER_UNREAD_SUPPORT_CHATS_LOADING:
    case GET_USER_UNREAD_COMMENT_CHATS_LOADING:
    case GET_USER_UNREAD_COMMENT_CHATS_ADMIN_LOADING: 
    case SET_STATUS_COMMENT_CHATS_ADMIN_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        data: null,
        error: null,
      };
    }
    case CREATE_SUPPORTTICKET_SUCCESS:
    case CREATE_SUPPORTTICKETCOMMENT_SUCCESS:
    case GET_SUPPORTTICKETS_BY_USERTYPEID_SUCCESS:
    case CREATE_SUPPORT_CHAT_SUCCESS:
    case CLOSE_SUPPORT_CHAT_SUCCESS:
    case GET_CLOSE_CASE_STATUS_SUCCESS:
    case GET_USER_CHAT_STATUS_SUCCESS:
    case GET_USER_UNREAD_SUPPORT_CHATS_SUCCESS:
    case GET_USER_UNREAD_COMMENT_CHATS_SUCCESS:
    case GET_USER_UNREAD_COMMENT_CHATS_ADMIN_SUCCESS:
    case SET_STATUS_COMMENT_CHATS_ADMIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: null,
      };
    }
    case CREATE_SUPPORTTICKET_ERROR:
    case CREATE_SUPPORTTICKETCOMMENT_ERROR:
    case GET_SUPPORTTICKETS_BY_USERTYPEID_ERROR:
    case CREATE_SUPPORT_CHAT_ERROR:
    case CLOSE_SUPPORT_CHAT_ERROR:
    case GET_CLOSE_CASE_STATUS_ERROR:
    case GET_USER_CHAT_STATUS_ERROR:
    case GET_USER_UNREAD_SUPPORT_CHATS_ERROR:
    case GET_USER_UNREAD_COMMENT_CHATS_ERROR:
    case GET_USER_UNREAD_COMMENT_CHATS_ADMIN__ERROR:
    case SET_STATUS_COMMENT_CHATS_ADMIN_ERROR: {
      return {
        loading: false,
        success: false,
        data: null,
        error: action.payload
          ? action?.payload?.data?.error?.message
          : "Error Occured",
      };
    }
    default: {
      return state;
    }
  }
};

export default SupportReducer;
