import "fontsource-roboto";
import "fontsource-damion";
import "fontsource-lato";
import "fontsource-leckerli-one";

import React from "react";
import { Suspense, useEffect } from "react"; 
import { Provider } from "react-redux";
import Store from "redux/store";
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "navigation/RouterConfig";
import axiosInterceptor from "./utils/axiosInterceptor";
import { socket } from "./utils/socketContext";
import localStorageService from "services/localStorageService";

axiosInterceptor(Store);

function App() {
  const user = localStorageService.getItem("auth_user");

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);

  useEffect(() => {
    if (user?.id && socket) {
      let username = user.id;
      socket?.emit("registerNotificationUser", username);
    }
  }, [socket, user?.id]);

  return (
    <>
      <div>
        <Provider store={Store}>
          <BrowserRouter
            basename="/"
            getUserConfirmation={() => {
              /* Empty callback to block the default browser prompt */
            }}
          >
            <RouterConfig />
          </BrowserRouter>
        </Provider>
      </div>
    </>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...is loading">
      <App />
    </Suspense>
  );
}
