import SupportService from "services/SupportService";

export const GET_SUPPORTTICKETSUBJECTS_LOADING =
  "GET_SUPPORTTICKETSUBJECTS_LOADING";
export const GET_SUPPORTTICKETSUBJECTS_SUCCESS =
  "GET_SUPPORTTICKETSUBJECTS_SUCCESS";
export const GET_SUPPORTTICKETSUBJECTS_ERROR =
  "GET_SUPPORTTICKETSUBJECTS_ERROR";

export const GET_SUPPORTTICKETS_BY_USERTYPEID_LOADING =
  "GET_SUPPORTTICKETS_BY_USERTYPEID_LOADING";
export const GET_SUPPORTTICKETS_BY_USERTYPEID_SUCCESS =
  "GET_SUPPORTTICKETS_BY_USERTYPEID_SUCCESS";
export const GET_SUPPORTTICKETS_BY_USERTYPEID_ERROR =
  "GET_SUPPORTTICKETS_BY_USERTYPEID_ERROR";

export const CREATE_SUPPORTTICKET_LOADING = "CREATE_SUPPORTTICKET_LOADING";
export const CREATE_SUPPORTTICKET_SUCCESS = "CREATE_SUPPORTTICKET_SUCCESS";
export const CREATE_SUPPORTTICKET_ERROR = "CREATE_SUPPORTTICKET_ERROR";

export const CREATE_SUPPORTTICKETCOMMENT_LOADING =
  "CREATE_SUPPORTTICKETCOMMENT_LOADING";
export const CREATE_SUPPORTTICKETCOMMENT_SUCCESS =
  "CREATE_SUPPORTTICKETCOMMENT_SUCCESS";
export const CREATE_SUPPORTTICKETCOMMENT_ERROR =
  "CREATE_SUPPORTTICKETCOMMENT_ERROR";

export const CREATE_SUPPORT_CHAT_LOADING = "CREATE_SUPPORT_CHAT_LOADING";
export const CREATE_SUPPORT_CHAT_SUCCESS = "CREATE_SUPPORT_CHAT_SUCCESS";
export const CREATE_SUPPORT_CHAT_ERROR = "CREATE_SUPPORT_CHAT_ERROR";

export const CLOSE_SUPPORT_CHAT_LOADING = "CLOSE_SUPPORT_CHAT_LOADING";
export const CLOSE_SUPPORT_CHAT_SUCCESS = "CLOSE_SUPPORT_CHAT_SUCCESS";
export const CLOSE_SUPPORT_CHAT_ERROR = "CLOSE_SUPPORT_CHAT_ERROR";

export const GET_CLOSE_CASE_STATUS_LOADING = "GET_CLOSE_CASE_STATUS_LOADING";
export const GET_CLOSE_CASE_STATUS_SUCCESS = "GET_CLOSE_CASE_STATUS_SUCCESS";
export const GET_CLOSE_CASE_STATUS_ERROR = "GET_CLOSE_CASE_STATUS_ERROR";

export const GET_USER_CHAT_STATUS_LOADING = "GET_USER_CHAT_STATUS_LOADING";
export const GET_USER_CHAT_STATUS_SUCCESS = "GET_USER_CHAT_STATUS_SUCCESS";
export const GET_USER_CHAT_STATUS_ERROR = "GET_USER_CHAT_STATUS_ERROR";

export const GET_USER_UNREAD_SUPPORT_CHATS_LOADING ="GET_USER_UNREAD_SUPPORT_CHATS_LOADING";
export const GET_USER_UNREAD_SUPPORT_CHATS_SUCCESS ="GET_USER_UNREAD_SUPPORT_CHATS_SUCCESS";
export const GET_USER_UNREAD_SUPPORT_CHATS_ERROR  ="GET_USER_UNREAD_SUPPORT_CHATS_ERROR";

export const GET_USER_UNREAD_COMMENT_CHATS_LOADING ="GET_USER_UNREAD_COMMENT_CHATS_LOADING";
export const GET_USER_UNREAD_COMMENT_CHATS_SUCCESS ="GET_USER_UNREAD_COMMENT_CHATS_SUCCESS";
export const GET_USER_UNREAD_COMMENT_CHATS_ERROR  = "GET_USER_UNREAD_COMMENT_CHATS_ERROR";

export const GET_USER_UNREAD_COMMENT_CHATS_ADMIN_LOADING ="GET_USER_UNREAD_COMMENT_CHATS_ADMIN_LOADING";
export const GET_USER_UNREAD_COMMENT_CHATS_ADMIN_SUCCESS ="GET_USER_UNREAD_COMMENT_CHATS_ADMIN_SUCCESS";
export const GET_USER_UNREAD_COMMENT_CHATS_ADMIN__ERROR  = "GET_USER_UNREAD_COMMENT_CHATS_ADMIN__ERROR";

export const SET_STATUS_COMMENT_CHATS_ADMIN_LOADING = "SET_STATUS_COMMENT_CHATS_ADMIN_LOADING";
export const SET_STATUS_COMMENT_CHATS_ADMIN_SUCCESS = "SET_STATUS_COMMENT_CHATS_ADMIN_SUCCESS";
export const SET_STATUS_COMMENT_CHATS_ADMIN_ERROR = "SET_STATUS_COMMENT_CHATS_ADMIN_ERROR";

export const getSupportTicketSubjects = () => async (dispatch) => {
  dispatch({
    type: GET_SUPPORTTICKETSUBJECTS_LOADING,
  });
  try {
    let res = await SupportService.getSupportTicketSubjects();

    return dispatch({
      type: GET_SUPPORTTICKETSUBJECTS_SUCCESS,
      payload: res.data.supportTicket,
    });
  } catch (err) {
    return dispatch({
      type: GET_SUPPORTTICKETSUBJECTS_ERROR,
      payload: err.response,
    });
  }
};

export const getSupportTicketsByUserTypeId =
  (userTypeId) => async (dispatch) => {
    dispatch({
      type: GET_SUPPORTTICKETS_BY_USERTYPEID_LOADING,
    });
    try {
      let res = await SupportService.getSupportTicketsByUserTypeId(userTypeId);

      return dispatch({
        type: GET_SUPPORTTICKETS_BY_USERTYPEID_SUCCESS,
        payload: res.data.supportTicket,
      });
    } catch (err) {
      return dispatch({
        type: GET_SUPPORTTICKETS_BY_USERTYPEID_ERROR,
        payload: err.response,
      });
    }
  };

export const createSupportTicket = (data) => async (dispatch) => {
  dispatch({
    type: CREATE_SUPPORTTICKET_LOADING,
  });
  try {
    let res = await SupportService.createSupportTicket(data);
    return dispatch({
      type: CREATE_SUPPORTTICKET_SUCCESS,
      payload: res.data.supportTicket,
    });
  } catch (err) {
    return dispatch({
      type: CREATE_SUPPORTTICKET_ERROR,
      payload: err.response,
    });
  }
};

export const createSupportTicketComment = (data) => async (dispatch) => {
  dispatch({
    type: CREATE_SUPPORTTICKETCOMMENT_LOADING,
  });
  try {
    let res = await SupportService.createSupportTicketComment(data);
    return dispatch({
      type: CREATE_SUPPORTTICKETCOMMENT_SUCCESS,
      payload: res.data.supportTicket,
    });
  } catch (err) {
    return dispatch({
      type: CREATE_SUPPORTTICKETCOMMENT_ERROR,
      payload: err.response,
    });
  }
};

export const createSupportChat = (data) => async (dispatch) => {
  dispatch({
    type: CREATE_SUPPORT_CHAT_LOADING,
  });
  try {
    let res = await SupportService.createSupportChat(data);
    return dispatch({
      type: CREATE_SUPPORT_CHAT_SUCCESS,
      payload: res.data.supportTicket,
    });
  } catch (err) {
    return dispatch({
      type: CREATE_SUPPORT_CHAT_ERROR,
      payload: err.response,
    });
  }
};

export const closeSupportChat = (data) => async (dispatch) => {
  dispatch({
    type: CLOSE_SUPPORT_CHAT_LOADING,
  });
  try {
    let res = await SupportService.closeSupportChat(data);
    return dispatch({
      type: CLOSE_SUPPORT_CHAT_SUCCESS,
      payload: res.data.supportTicket,
    });
  } catch (err) {
    return dispatch({
      type: CLOSE_SUPPORT_CHAT_ERROR,
      payload: err.response,
    });
  }
};

export const getCloseCaseStatus = (id, fId, tId) => async (dispatch) => {
  dispatch({
    type: GET_CLOSE_CASE_STATUS_LOADING,
  });
  try {
    let res = await SupportService.getCloseCaseStatus(id, fId, tId);

    return dispatch({
      type: GET_CLOSE_CASE_STATUS_SUCCESS,
      payload: res.data.supportTicket,
    });
  } catch (err) {
    return dispatch({
      type: GET_CLOSE_CASE_STATUS_ERROR,
      payload: err.response,
    });
  }
};

export const getUserChat = (pid, fId, tId) => async (dispatch) => {
  dispatch({
    type: GET_USER_CHAT_STATUS_LOADING,
  });
  try {
    let res = await SupportService.getUserChat(pid, fId, tId);

    return dispatch({
      type: GET_USER_CHAT_STATUS_SUCCESS,
      payload: res.data.supportTicket,
    });
  } catch (err) {
    return dispatch({
      type: GET_USER_CHAT_STATUS_ERROR,
      payload: err.response,
    });
  }
};

export const getUnreadSupportChatsForUser = (pid, uId) => async (dispatch) => {
  dispatch({
    type: GET_USER_UNREAD_SUPPORT_CHATS_LOADING,
  });
  try {
    let res = await SupportService.getUnreadSupportChatsForUser(pid, uId);

    return dispatch({
      type: GET_USER_UNREAD_SUPPORT_CHATS_SUCCESS,
      payload: res.data.supportTicket,
    });
  } catch (err) {
    return dispatch({
      type: GET_USER_UNREAD_SUPPORT_CHATS_ERROR,
      payload: err.response,
    });
  }
};

export const getUnreadCommentChatsForUser = (pid, uId) => async (dispatch) => {
  dispatch({
    type: GET_USER_UNREAD_COMMENT_CHATS_LOADING,
  });
  try {
    let res = await SupportService.getUnreadCommentChatsForUser(pid, uId);

    return dispatch({
      type: GET_USER_UNREAD_COMMENT_CHATS_SUCCESS,
      payload: res.data.supportTicket,
    });
  } catch (err) {
    return dispatch({
      type: GET_USER_UNREAD_COMMENT_CHATS_ERROR,
      payload: err.response,
    });
  }
};


export const getUnreadCountClientArtistChatsForAdmin = (pid, msgToSocket, msgFromSocket ) => async (dispatch) => {
  dispatch({
    type: GET_USER_UNREAD_COMMENT_CHATS_ADMIN_LOADING,
  });
  try {
    let res = await SupportService.getUnreadCountClientArtistChatsForAdmin(pid, msgToSocket, msgFromSocket );

    return dispatch({
      type: GET_USER_UNREAD_COMMENT_CHATS_ADMIN_SUCCESS,
      payload: res.data.supportTicket,
    });
  } catch (err) {
    return dispatch({
      type: GET_USER_UNREAD_COMMENT_CHATS_ADMIN__ERROR,
      payload: err.response,
    });
  }
};


export const setReadStatusforAdminChat = (data) => async (dispatch) => {
  dispatch({
    type: SET_STATUS_COMMENT_CHATS_ADMIN_LOADING,
  });
  try {
    let res = await SupportService.setReadStatusforAdminChat(data);

    return dispatch({
      type:  SET_STATUS_COMMENT_CHATS_ADMIN_SUCCESS,
      payload: res.data.supportTicket,
    });
  } catch (err) {
    return dispatch({
      type:  SET_STATUS_COMMENT_CHATS_ADMIN_ERROR,
      payload: err.response,
    });
  }
};