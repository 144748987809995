import React, { useState } from "react";
import ArtistPortfolioListCell from "../components/ArtistPortfolioListCell";
import TopNavBar from "components/Navbars/TopNavBar";
import { userNavigations } from "layouts/ArtistConstants";
import { VIEWS } from "../../../layouts/ArtistConstants";

export default function ArtistPortFolioListView(props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  return (
    <>
      <div className="h-full flex">
        <div className="flex-1 flex flex-col overflow-hidden h-full bg-bgg">
          <TopNavBar
            userNavigations={userNavigations}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            loginData={props.loginData}
            hideMenuOptions={true}
          />
          {/* Content area */}
          <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
            {/* Main content */}
            <div className="lg:grid grid-cols bg-white w-full">
              <main className="overflow-hidden">
                <section
                  className="mt-0 pb-5 md:grid md:grid-cols-3 lg:grid-cols-9 "
                  aria-labelledby="gallery-heading"
                >
                  <div className="md:col-start-1 md:col-span-3 bg-warm-gray-50 lg:col-start-3 lg:col-span-5">
                    <div className="py-6 lg:py-5">
                      <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-4 lg:px-4">
                        <h1 className="mt-2 pt-2 text-3.5xl font-bold text-primary font-sans">
                          {props.title}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <ul className="md:col-start-1 md:col-span-3 lg:col-start-3 lg:col-span-5 ">
                    {props.artistPortfoliosList &&
                      props.artistPortfoliosList.length !== 0 &&
                      props.artistPortfoliosList.map((artistPortfolio) => (
                        <li
                          key={artistPortfolio.id}
                          className="bg-white rounded-lg p-4"
                        >
                          <ArtistPortfolioListCell
                            artistPortfolio={artistPortfolio}
                            calledFrom={VIEWS.ARTIST_PORTFOLIO_LIST.id}
                          />
                        </li>
                      ))}
                  </ul>
                  <div className="md:col-start-1  md:col-span-1 lg:col-start-3 lg:col-span-2 mx-2">
                    <button
                      label="Add Portfolio"
                      className="p-3 bg-primary w-full text-white font-sans font-semibold mt-2 rounded-3xl"
                      textsize="base"
                      onClick={() => props.addPortfolio()}
                    >
                      Add Portfolio
                    </button>
                  </div>
                  <div className="md:col-span-1 lg:col-span-2 mx-2">
                    <button
                      label="Proceed"
                      className="p-3 bg-primary w-full text-white font-sans font-semibold mt-2 rounded-3xl"
                      textsize="base"
                      onClick={() => props.showArtistHomeView()}
                    >
                      Proceed
                    </button>
                  </div>
                </section>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
