import {
  SKINTONE_LOADING,
  GET_SKINTONE_SUCCESS,
  GET_SKINTONE_ERROR,
} from "../actions/SkinToneAction";

const initialState = {
  loading: false,
  success: false,
  data: null,
  error: null,
};

const SkinToneReducer = function (state = initialState, action) {
  switch (action.type) {
    case SKINTONE_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        data: null,
        error: null,
      };
    }
    case GET_SKINTONE_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: null,
      };
    }
    case GET_SKINTONE_ERROR: {
      return {
        loading: false,
        success: false,
        data: null,
        error: action.payload
          ? action.payload.data.error.message
          : "Error Occured",
      };
    }
    default: {
      return state;
    }
  }
};

export default SkinToneReducer;
