import React, { useState } from "react";
import { useFormik } from "formik";
import { createSupportChat } from "redux/actions/SupportAction";
import { useDispatch } from "react-redux";
import AlertMessage from "components/AlertMessage";
import { ReactComponent as FileIcon } from "../../../../assets/ic_file_upload.svg";

const RoomMessageInput = ({
  socket,
  loggedInUserName,
  loggedInUserPhotoUrl,
  loggedInUserId,
  projectId,
  setIsFileUploaded,
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertId, setAlertId] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [fileSelected, setFileSelected] = useState(null);
  const dispatch = useDispatch();

  const handleFormSubmit = (values) => {
    let obj = {
      comment: values.comment,
      loggedInUserName: loggedInUserName,
      loggedInUserPhotoUrl: loggedInUserPhotoUrl,
      loggedInUserId: loggedInUserId,
      projectId: projectId,
      isFile: false
    };

    const roomChatObj = {
      fromUserId: loggedInUserId,
      comment: values.comment,
      projectId: projectId,
      isFile: false
    };
    socket.emit("messageRc", obj);
    onSubmit(roomChatObj, false);
    formik.resetForm();
  };

  const onSubmit = (supportObj, isFileSent) => {
    dispatch(createSupportChat(supportObj)).then((res) => {
      if (isFileSent) {
        setIsFileUploaded(true);
      }
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { comment: "" },
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const handleFileUpload = (e, file) => {
    setIsFileUploaded(false);
    setAlertId("filesend");
    setAlertMessage("Are you sure, you want to send this file?");
    setShowAlert(true);
    setFileSelected(file);
    e.target.value = null;
  };

  const onPressYes = (alertId) => {
    if (alertId === "filesend") {
      socket.emit(
        "upload",
        { file: fileSelected, name: fileSelected.name },
        (status) => {
          if (status && status.message !== "false") {
            const supportObj = {
              fromUserId: loggedInUserId,
              comment: status.message,
              projectId: projectId,
              isFile: true,
            };
            onSubmit(supportObj, true);
            let obj = {
              comment: status.message,
              loggedInUserName: loggedInUserName,
              loggedInUserPhotoUrl: loggedInUserPhotoUrl,
              loggedInUserId: loggedInUserId,
              projectId: projectId,
              isFile: true
            };
            socket.emit("messageFileRc", obj);
            setIsFileUploaded(true);
          }
        }
      );
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex grid grid-cols-6 gap-4 mt-5">
        <textarea
          className="flex col-start-1 col-span-6 font-sans border border-tertiary rounded focus:outline-none focus:ring-0 focus:ring-transparent p-2 text-base"
          label="Comment"
          type="text"
          name="comment"
          id="comment"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.comment}
          rows={3}
        />
        <label
          htmlFor={"file-upload"}
          className="col-span-6  md:col-span-2 bg-primary text-white rounded-full  text-base font-normal flex w-full px-3 justify-center items-center py-3"
        >
          {" "}
          Choose File
          <FileIcon className="ml-2 w-5 h-5" />
          <input
            type="file"
            id="file-upload"
            onChange={(event) => {
              handleFileUpload(event, event.target.files[0]);
            }}
            className="w-max"
            style={{ display: "none" }}
            accept=".pdf,.png,.jpeg,.jpg,.docx"
          />
        </label>
        <div className="self-end col-start-1 col-span-6 md:col-start-5 md:col-span-2">
          <button
            label="Submit"
            type="submit"
            disabled={formik.values.comment.length === 0}
            className="bg-primary disabled:bg-tertiary text-white text-base font-normal py-3 px-4 flex w-full justify-center rounded-full"
          >
            Submit
          </button>
        </div>
      </div>
      <div>
        <AlertMessage
          id={alertId}
          message={alertMessage}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          confirmable={true}
          onPressYes={onPressYes}
        />
      </div>
    </form>
  );
};

export default RoomMessageInput;
