/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PreviewArtistPortfolioView from "./PreviewArtistPortfolioView";
import {
  getArtistPortfolioById,
  getTmpArtistPortfolioById,
} from "../../../redux/actions/ArtistPortfolioAction";
import { getStudioArtistReviewsAvg } from "../../../redux/actions/StudioReviewsAction";

export default function PreviewArtistPortfolioContainer(props) {
  const selectedArtistPortfolioId = props.match.params.id;
  const isTmpArtistPortfolioId = props.match.params.isTmp;

  const artistPortfolio = useSelector((state) => state.artistPortfolio.data);
  const tmpArtistPortfolio = useSelector((state) => state.artistPortfolio.data);
  const artistReviewsAvg = useSelector(
    (state) => state.studioReviews.studioArtistReviewsAvg
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedArtistPortfolioId && isTmpArtistPortfolioId == 0) {
      dispatch(getArtistPortfolioById(selectedArtistPortfolioId));
      dispatch(getStudioArtistReviewsAvg(props.loginData.id));
    } else if (selectedArtistPortfolioId && isTmpArtistPortfolioId == 1) {
      dispatch(getTmpArtistPortfolioById(selectedArtistPortfolioId));
      dispatch(getStudioArtistReviewsAvg(props.loginData.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArtistPortfolioId, isTmpArtistPortfolioId]);

  return (
    <div>
      <PreviewArtistPortfolioView
        loginData={props.loginData}
        artistPortfolio={
          isTmpArtistPortfolioId ? tmpArtistPortfolio : artistPortfolio
        }
        artistReviewsAvg={artistReviewsAvg}
        isTmpArtistPortfolioId={isTmpArtistPortfolioId}
      />
    </div>
  );
}
