import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Doughnut } from "react-chartjs-2";

import TabNavBar from "components/Navbars/TabNavBar";
import { tabsNav, getUserRole } from "layouts/ClientConstants";
import { CLIENT_PROJECT_TABS as TABS } from "layouts/ClientConstants";
import { updateProfilePic } from "redux/actions/UserActions";

import moment from "moment";
import Rating from "@mui/material/Rating";

// import { ReactComponent as MaleIcon } from "assets/ic_male.svg";
// import { ReactComponent as FemaleIcon } from "assets/ic_female.svg";
// import { ReactComponent as GenderIcon } from "assets/ic_gender.svg";
import { ReactComponent as MoneyIcon } from "assets/ic_money.svg";
import { ReactComponent as BudgetIcon } from "assets/ic_budget.svg";
import { ReactComponent as ChatIcon } from "assets/ic_chat.svg";
import { ReactComponent as EmailIcon } from "assets/ico_email.svg";
import { ReactComponent as LocationIcon } from "assets/ico_location.svg";
import { ReactComponent as PhoneIcon } from "assets/ico_phone.svg";
import { ReactComponent as EditPicture } from "assets/ico_edit_picture.svg";
import {
  SERVER_URL,
  USER_ROLE_CLIENT,
  USER_ROLE_BOSS,
  USER_ROLE_ARTIST,
} from "utils/constants";
import localStorageService from "services/localStorageService";
import { styled } from "@mui/material/styles";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#C7A33F",
  },
  "& .MuiRating-iconHover": {
    color: "#AFAFAF",
  },
  "& .MuiRating-iconEmpty": {
    color: "#AFAFAF",
  },
});

const ClientProjects = (props) => {
  tabsNav[0]["count"] = props.projectCount;
  tabsNav[1]["count"] = props.historyCount;
  const [tabs, setTabs] = useState(tabsNav);
  let history = useHistory();
  const dispatch = useDispatch();

  const role = getUserRole(props?.loginData?.userRoleId);

  const getProjectStatusColor = (projectStatus) => {
    switch (projectStatus) {
      case 1:
        return "new";
      case 2:
        return "waiting";
      case 3:
        return "withdrawn";
      case 4:
        return "declined";
      case 5:
        return "done";
      case 6:
        return "waiting";
      case 7:
        return "progress";
      case 8:
        return "waiting";
      case 13:
        return "refunded";
      default:
        return "new";
    }
  };

  useEffect(() => {
    setTabs([
      {
        id: "1",
        name: "Current Projects",
        href: "#",
        current: props.selectedTab == "1",
        count: props.projectCount,
      },
      {
        id: "2",
        name: "Project History",
        href: "#",
        current: props.selectedTab == "2",
        count: props.historyCount,
      },
    ]);
  }, [props.projectCount, props.historyCount]);

  const setTab = (tabToSet) => {
    props.setSelectedTab(tabToSet.id);
    let updatedTabs = tabs.map((tab) =>
      tab.id === tabToSet.id
        ? { ...tab, current: true }
        : { ...tab, current: false }
    );
    setTabs(updatedTabs);
  };

  const projectOnClick = (id) => {
    if (role === USER_ROLE_BOSS) {
      history.push("/admin/project-details/" + id);
    } else if (role === USER_ROLE_CLIENT) {
      history.push("/client/project-details/" + id);
    }
  };

  const handleProfilePicEdit = () => {
    const input = document.getElementById("profile-pic-upload");
    if (input) {
      input.click();
    }
  };

  const handleProfilePicUpload = (values) => {
    const obj = new FormData();
    obj.id = props.loginData.id;
    obj.append("profilePic", values);

    dispatch(updateProfilePic(obj)).then((res) => {
      if (res.type === "UPDATE_PROFILE_PIC_SUCCESS") {
        localStorageService.setItem("auth_user", res.payload);
        window.location.reload();
      }
    });
  };

  const showArtistProfile = (id) => {
    if (role === USER_ROLE_CLIENT) {
      history.push("/client/artist-profile/" + id);
    }
  };

  const projectCard = (items) => {
    return (
      <div
        key={items.id}
        className="flex grid grid-col-8 gap-1 rounded-2xl shadow-xl py-6 h-56 auto-cols-fr shadow-cardDropShadowColor bg-bcard border-tableLine border"
        onClick={() => projectOnClick(items.id)}
      >
        <div className="flex flex-1 col-start-1 h-full items-start mr-2 flex-row ">
          <div
            className={`w-2 bg-${getProjectStatusColor(
              items.projectStatusId
            )} h-1/3`}
          />
          <div className="flex flex-1 col-start-1 h-full items-end mr-2 flex-col">
            <div className="text-tertiary text-base font-sans">
              <br></br>{" "}
            </div>
          </div>
        </div>
        <div className="flex flex-1 col-start-2 col-span-5 h-full flex-col">
          <span className="text-tertiary text-base font-sans">#{items.id}</span>

          <div className="flex flex-row mt-2 items-start">
            <div
              className={`border-3 rounded-full w-4 h-4 mt-1 border-${getProjectStatusColor(
                items.projectStatusId
              )} mr-2`}
            />
            <div className="font-bold text-secondary flex-1 font-sans text-base overflow-hidden">
              {role === USER_ROLE_BOSS && items?.project_status?.bossStatusName}
              {role === USER_ROLE_CLIENT &&
                items?.project_status?.clientStatusName}
            </div>
          </div>
          <div className="text-sm mt-2 mb-2 text-tertiary">
            {moment(items?.createdAt).format("D MMM, HH:mm")}
          </div>

          <div className="flex flex-1" />

          <div className="text-base font-sans flex flex-row items-center -mr-10 md:mr-0">
            {items?.offerPrice && !items.isPriceNegotiated ? (
              <MoneyIcon className="mr-2" />
            ) : items?.counter_offers &&
              items?.counter_offers[0]?.offerPrice &&
              items.projectStatusId !== 1 ? (
              <MoneyIcon className="mr-2" />
            ) : (
              <BudgetIcon className="mr-2" />
            )}
            <div className="font-thin">
              {items?.offerPrice && !items.isPriceNegotiated
                ? "Price: "
                : items?.counter_offers &&
                  items?.counter_offers[0]?.offerPrice &&
                  items.projectStatusId !== 1
                ? "Price: "
                : `Budget:`}
            </div>

            <div className="font-bold ml-1 font-sans">
              {" "}
              {items?.offerPrice && !items.isPriceNegotiated
                ? " " + items?.offerPrice
                : items?.counter_offers &&
                  items?.counter_offers[0]?.offerPrice &&
                  items.projectStatusId !== 1
                ? " " + items?.counter_offers[0]?.offerPrice
                : ` ${items?.budgetPriceFrom} - ${items?.budgetPriceTo}`}
            </div>
          </div>
          {items.counter_offers &&
            props.selectedTab !== TABS.PROJECTHISTORY.id &&
            items?.projectStatusId == 2 && (
              <div className="flex flex-row gap-2">
                {items?.counter_offers?.map((item, index) => {
                  return (
                    <li className="flex flex-row gap-2" key={index}>
                      <img
                        className="flex flex-1 w-6 h-6 rounded-full border border-primary object-cover object-center"
                        src={
                          item?.user?.photoUrl
                            ? SERVER_URL + item?.user?.photoUrl
                            : SERVER_URL +
                              "uploads/default-images/DefaultProfile.jpeg"
                        }
                        alt="Profile"
                      />
                    </li>
                  );
                })}
              </div>
            )}
          {((props.selectedTab === TABS.PROJECTHISTORY.id &&
            items?.projectStatusId == 5) ||
            (props.selectedTab !== TABS.PROJECTHISTORY.id &&
              items?.projectStatusId != 2 &&
              items?.projectStatusId != 1)) && (
            <div className="mt-3 flex xl:flex-row flex-col">
              <div className="flex flex-row mb-2 xl:mb-0">
                <img
                  onClick={() => showArtistProfile(items?.finalArtistId)}
                  className="w-6 h-6 rounded-full"
                  src={
                    items?.finalArtist?.photoUrl
                      ? SERVER_URL + items?.finalArtist?.photoUrl
                      : SERVER_URL +
                        "uploads/default-images/DefaultProfile.jpeg"
                  }
                  alt="Profile"
                />
                <div className="ml-2 font-sans">
                  {items?.finalArtist
                    ? items?.finalArtist?.firstName +
                      " " +
                      items?.finalArtist?.lastName
                    : "N/A"}{" "}
                </div>
              </div>
              <div className="flex flex-1" />
              {props.selectedTab === TABS.PROJECTHISTORY.id && (
                <div>
                  {items?.artist_ratings?.length > 0 ? (
                    <StyledRating
                      name="simple-controlled"
                      value={
                        items?.artist_ratings
                          ? items?.artist_ratings[0]?.rating
                          : "Unrated"
                      }
                      color="primary"
                    />
                  ) : (
                    <div className="text-primary">Rate Artist</div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-1 col-start-7 h-full">
          <div className="flex flex-row items-start">
            <div className="flex items-center">
              <div className="mr-2 font-bold text-base">
                {(items?.user_comment_chats_statuses
                  ? items?.user_comment_chats_statuses?.length
                  : 0) +
                  (items?.user_support_chats_statuses
                    ? items?.user_support_chats_statuses?.length
                    : 0)}
              </div>
              <ChatIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const data = {
    labels: ["Completed Projects", "Incomplete Projects"],
    datasets: [
      {
        label: "# of Projects",
        data: [props.completedCount, props.inCompleteCount],
        backgroundColor: ["#0BC851", "#0BC85120"],
        borderColor: ["#0BC851", "#0BC85120"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "95%",
  };

  return (
    <div className="flex flex-col md:grid grid-cols-12 gap-2 w-full">
      <div className="col-start-1 col-end-6 lg:col-span-3">
        <div className="flex flex-col ">
          <div className="flex flex-col items-center w-full">
            {(role === USER_ROLE_BOSS || role === USER_ROLE_ARTIST) && (
              <div className={`flex justify-center items-center`}>
                <Doughnut
                  data={data}
                  options={options}
                  width={140}
                  height={140}
                  className={"z-5"}
                />
                <div className="absolute">
                  <img
                    className="w-28 h-28 rounded-full object-cover object-center"
                    src={
                      props?.clientById?.user?.photoUrl
                        ? SERVER_URL + props?.clientById?.user?.photoUrl
                        : SERVER_URL +
                          "uploads/default-images/DefaultProfile.jpeg"
                    }
                    alt="Profile"
                  />
                </div>
              </div>
            )}
            {role === USER_ROLE_CLIENT && (
              <div className="relative">
                <img
                  className="h-28 w-28 bg-gray-300 rounded-full flex-shrink-0 border-primary border-2 object-cover object-center"
                  src={
                    props?.clientById?.user?.photoUrl
                      ? SERVER_URL + props?.clientById?.user?.photoUrl
                      : SERVER_URL +
                        "uploads/default-images/DefaultProfile.jpeg"
                  }
                  alt=""
                />
                <button
                  className="absolute top-20 right-0"
                  onClick={handleProfilePicEdit}
                >
                  <EditPicture />
                </button>
                <input
                  type="file"
                  id="profile-pic-upload"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    handleProfilePicUpload(event.target.files[0]);
                  }}
                />
              </div>
            )}
            <div className="flex justify-center items-center text-center text-base font-sans font-semibold mt-5 mb-1 w-full flex flex-row">
              {props?.clientById?.user?.firstName}{" "}
              {props?.clientById?.user?.lastName}
              {/* {props?.clientById?.user?.gender === "Female" ? (
                <FemaleIcon className="ml-4" />
              ) : props?.clientById?.user?.gender === "male" ? (
                <MaleIcon className="ml-4" />
              ) : (
                <GenderIcon className="ml-4" />
              )} */}
            </div>
            <div className="text-center text-base font-sans font-semibold mt-2">
              {props?.clientById?.user?.birthYear}
            </div>
          </div>
          <div className="align-left mt-10 flex grid grid-cols-12">
            <div className="text-primary text-sm font-semibold mb-3 font-sans col-start-1 col-span-12 md:col-start-2 md:col-span-11">
              My Info:
            </div>
            <EmailIcon className="col-start-1 md:col-start-2 h-5 w-5" />
            <div className="mb-2 ml-2.5 text-base flex flex-row col-start-2 md:col-start-3 col-span-10 items-top">
              {props?.clientById?.user?.email}
            </div>
            <LocationIcon className="col-start-1 md:col-start-2 h-5 w-5" />
            <div className="mb-2 ml-2.5 text-base flex flex-row col-start-2 md:col-start-3 col-span-10">
              {props?.clientById?.user?.street}
              {props?.clientById?.user?.street && props?.clientById?.user?.city
                ? ","
                : ""}{" "}
              {props?.clientById?.user?.city}
              {props?.clientById?.user?.zipcode && props?.clientById?.user?.city
                ? ","
                : ""}{" "}
              {props?.clientById?.user?.zipcode}
              {props?.clientById?.user?.country &&
              props?.clientById?.user?.zipcode
                ? ","
                : ""}{" "}
              {props?.clientById?.user?.country}
            </div>
            <PhoneIcon className="col-start-1 md:col-start-2 h-5 w-5" />
            <div className="mb-2 ml-2.5 text-base flex flex-row col-start-2 md:col-start-3 col-span-10">
              ({props?.clientById?.user?.phoneCode}){" "}
              {props?.clientById?.user?.phone}
            </div>
          </div>
        </div>
      </div>

      <div className="col-start-7 lg:col-start-4 col-span-9 bg-white w-full flex-col">
        <div className="flex mb-5">
          <TabNavBar tabs={tabs} handleChange={setTab} showInMobileMode = {true} tabSpace={2}/>
        </div>
        <section className="mt-0 pb-0" aria-labelledby="gallery-heading">
          <ul className="grid grid-cols-1 gap-3 md:grid-cols-1 md:gap-3 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 3xl:gap-3 4xl:grid-cols-3 5xl:grid-cols-4">
            {props?.data?.project?.map((items) => projectCard(items))}
            {role === USER_ROLE_CLIENT && (
              // props.selectedTab !== TABS.PROJECTHISTORY.id &&
              <li className="flex h-56 col-span-1 bg-white rounded-lg shadow border-dashed border-2 justify-center shadow-lg border-primary ...">
                <Link
                  to="/client/project"
                  className={`md:flex inline-flex items-center justify-center mr-${
                    props.hideRegister ? 0 : 4
                  } px-8 py-2 my-2 border-primary rounded-3xl text-primaryno-underline border-solid text-base font-sans font-normal`}
                >
                  Create New Project
                </Link>
              </li>
            )}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default ClientProjects;
