/* eslint-disable eqeqeq */
import React, { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import { BellIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { SERVER_URL } from "utils/constants";
import localStorageService from "services/localStorageService";
import { USER_ROLE_CLIENT } from "layouts/AdminConstants";
import { useHistory, useLocation } from "react-router-dom";
import NotificationPopUpView from "../../views/notification/NotificationPopUpView";
import { getUnReadNotications } from "redux/actions/NotificationAction";
import { useDispatch } from "react-redux";
import { socket } from "utils/socketContext";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const homeUrl = process.env.REACT_APP_WEB_SERVER_URL;

export default function TopNavBar(props) {
  const [loginData, setloginData] = useState(props.loginData);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(localStorageService.getItem("notification") && localStorageService.getItem("notification").length > 0  ? localStorageService.getItem("notification").length : null);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
     // if (notificationSocket && loginData?.id) {
        socket.on("updateNoficationData", (isTrue) => {
          if (isTrue) {
            dispatch(getUnReadNotications(loginData.id)).then((res1) => {
              if (res1 && res1.type === "NOTIFICATION_SUCCESS") {
                localStorageService.setItem("notification", res1?.payload?.notification);
                setNotificationCount(localStorageService.getItem("notification").length);
              }
            });  
          }
        });
      // } 
    } catch (error) {
      console.log(error)
    }    
  }, [socket]);

  const handleNotificationOpen = () => {
    setNotificationOpen(true);
  };

  let history = useHistory();
  const location = useLocation();

  const getUserRole = (roleId) => {
    switch (roleId) {
      case 1:
        return "Admin";
      case 2:
        return "Boss";
      case 3:
        return "Artist";
      case 4:
        return "";
      case 5:
        return "Manager";
      default:
        return "";
    }
  };

  const role = getUserRole(loginData.userRoleId);

  useEffect(() => {    
    setNotificationCount(localStorageService.getItem("notification").length);
  }, [localStorageService.getItem("notification").length]);

  return (
    <>
      <header className="w-full">
        <div className="relative z-10 flex-shrink-0 h-14 bg-white  border-gray-200 shadow-sm flex">
          {loginData.userTypeId == 4 && (
            <button
              type="button"
              className="border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary lg:hidden"
              onClick={() => props.handleMenu(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          )}
          {loginData.userTypeId != 4 && window.location.href != homeUrl && window.location.href != homeUrl+"#" && (
            <button
              type="button"
              className="border-gray-200 pl-2 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary lg:hidden text-primary font-bold"
              onClick={() =>
                location.key ? history.goBack() : history.replace("/")
              }
            >
              <object
                type="image/svg+xml"
                data="/ic_back.svg"
                className="h-6 w-auto pointer-events-none"
              >
                2ndSkin
              </object>
            </button>
          )}

          <div className="flex-1 flex justify-between px-2">
            <div className="lg:flex items-center justify-center h-14 border-gray-200 mt-0.5 lg:mt-0 ml-0 lg:ml-1">
              <Link
                to="/"
                // className="text-secondary hover:text-primary no-underline text-base font-sans font-normal"
                onClick={() => localStorageService.setItem("side_item", "1")}
              >
                <object
                  type="image/svg+xml"
                  data="/2ndSkin.svg"
                  className="h-12 w-auto pointer-events-none"
                >
                  2ndSkin
                </object>
              </Link>
            </div>
            <div className="flex-1 flex"></div>
            <div className={`${!props.clientview ? "hidden" : ""}`}>
              <Link
                to="/client/project"
                className={`hidden md:flex inline-flex items-center mr-${
                  props.hideRegister ? 0 : 4
                } px-8 py-2 my-2 border-primary rounded-3xl text-primary bg-bgg border no-underline border-solid text-base font-sans font-normal`}
              >
                New Project
              </Link>
            </div>
            {props.hideMenuOptions != true && (
              <div className="ml-0 flex items-center space-x-0 sm:ml-6 sm:space-x-2">
                <span
                  className="inline-block relative m-3 md:m-4"
                  onClick={() => handleNotificationOpen()}
                >
                  <BellIcon
                    className="h-8 w-8 rounded-full text-primary"
                    aria-hidden="true"
                  />
                  {notificationCount > 0 && (
                    <p className="absolute -top-2 -right-2 block h-6 w-6 rounded-full ring-2 ring-white bg-red text-white font-sans font-semibold text-xs flex items-center justify-center text-ellipsis overflow-hidden">
                      {notificationCount}
                    </p>
                  )}
                </span>
                {/* Profile dropdown */}
                <Menu as="div" className="relative flex-shrink-0">
                  <div>
                    <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-primary flex items-center">
                      <span className="sr-only">Open user menu</span>

                      <div
                        className={`mr-1 md:mr-6 ${
                          loginData.userRoleId != 3
                            ? `grid grid-rows-${
                                loginData.userRoleId == USER_ROLE_CLIENT ? 1 : 2
                              } mt-0`
                            : ""
                        }`}
                      >
                        <p className="text-base font-light font-sans whitespace-pre-wrap">
                          {(loginData.firstName ? loginData.firstName : "") +
                            " " +
                            (loginData.lastName ? loginData.lastName : "")}
                        </p>
                        <p
                          className={`text-xs font-bold text-primary font-sans flex justify-end ${
                            loginData.userRoleId == USER_ROLE_CLIENT
                              ? "hidden"
                              : ""
                          }`}
                        >
                          {role}
                        </p>
                      </div>
                      <img
                        className="h-10 w-10 rounded-full mt-0 flex object-cover object-center"
                        src={
                          loginData.photoUrl
                            ? SERVER_URL + loginData.photoUrl
                            : SERVER_URL +
                              "uploads/default-images/DefaultProfile.jpeg"
                        }
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {props.userNavigations.length !== 0 &&
                        props.userNavigations.map((item) => (
                          <Menu.Item key={item.id}>
                            {({ active }) => (
                              <>
                                {/* <a
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </a> */}
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </Link>
                              </>
                            )}
                          </Menu.Item>
                        ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            )}
          </div>
        </div>
      </header>
      {notificationOpen && notificationCount >= 0 && (
        <NotificationPopUpView
          open={notificationOpen}
          setOpen={setNotificationOpen}
          data={localStorageService.getItem("notification")}
          role={role}
          loginData={loginData}
        />
      )}
    </>
  );
}
