import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import SaveArtistPortfolioContainer from "views/artist/ArtistPortfolio/SaveArtistPortfolioContainer";
import ArtistPortfolioListContainer from "views/artist/ArtistPortfolioList/ArtistPortfolioListContainer";
import SaveArtistProfileContainer from "views/artist/ArtistProfile/SaveArtistProfileContainer";
import Profile from "views/profile/ProfileContainer.js";
import SignOut from "views/SignOut";
import ProjectDetailsContainer from "views/project/projectDetails/ProjectDetailsContainer";
import ArtistHomeContainer from "views/artist/ArtistHome/ArtistHomeContainer";
import PreviewArtistPortfolioContainer from "views/artist/ArtistPortfolio/PreviewArtistPortfolioContainer";
import { VIEWS } from "../layouts/ArtistConstants";
import NotificationContainer from "views/notification/NotificationContainer";
import ArtistClientProfileContainer from "views/hichem/client/ArtistClientProfileContainer";

export default function ArtistLayout(props) {
  // eslint-disable-next-line no-unused-vars
  const [loginData, setloginData] = useState(props.loginData);
  return (
    <>
      <main>
        <Switch>
          <Route
            path="/"
            exact
            render={(props) =>
              loginData?.artist_portfolios.length > 0 ? (
                <ArtistHomeContainer {...props} loginData={loginData} />
              ) : loginData?.updatedAt === null ? (
                <SaveArtistProfileContainer {...props} loginData={loginData} />
              ) : (
                <SaveArtistPortfolioContainer
                  {...props}
                  loginData={loginData}
                  calledFromHome={VIEWS.ARTIST_PROFILE.id}
                />
              )
            }
          />
          <Route
            path="/artist/home/:id"
            exact
            render={(props) => (
              <ArtistHomeContainer {...props} loginData={loginData} />
            )}
          />
          {/* Artist Profile Routes */}
          <Route
            path="/artist/artistprofile"
            exact
            render={(props) => (
              <SaveArtistProfileContainer {...props} loginData={loginData} />
            )}
          />
          {/* Artist Portfolio Routes */}
          <Route
            path="/artist/artistportfolio/:calledFrom"
            exact
            render={(props) => (
              <SaveArtistPortfolioContainer {...props} loginData={loginData} />
            )}
          />
          <Route
            path="/artist/artistportfolio/:calledFrom/:id/:isTmp"
            exact
            render={(props) => (
              <SaveArtistPortfolioContainer {...props} loginData={loginData} />
            )}
          />
          {/* Artist Portfolio List Routes */}
          <Route
            path="/artist/artistportfoliolist"
            exact
            render={(props) => (
              <ArtistPortfolioListContainer {...props} loginData={loginData} />
            )}
          />
          {/* Preview Artist Portfolio Routes */}
          <Route
            path="/artist/previewartistportfolio/:id/:isTmp"
            exact
            render={(props) => (
              <PreviewArtistPortfolioContainer
                {...props}
                loginData={loginData}
              />
            )}
          />

          <Route
            path="/artist/profile"
            exact
            render={(props) => <Profile {...props} loginData={loginData} />}
          />
          <Route path="/artist/signout" component={SignOut} />
          <Route
            path="/artist/project-details/:id"
            exact
            render={(props) => (
              <ProjectDetailsContainer {...props} loginData={loginData} />
            )}
          />
          <Route
            path="/artist/notification"
            exact
            render={(props) => (
              <NotificationContainer {...props} loginData={loginData} />
            )}
          />
          <Route path="/artist/client-profile/:id" exact render={(props) => <ArtistClientProfileContainer {...props} loginData={loginData}/>} />

        </Switch>
      </main>
    </>
  );
}
