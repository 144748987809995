import axios from "../http-config";

class ManagerService {
  getManagersByStudioType(studioTypeId) {
    return axios({
      method: "GET",
      url: `/users/studio-type/${studioTypeId}`,
    });
  }

  getManager(id) {
    return axios({
      method: "GET",
      url: `/users/${id}`,
    });
  }

  createManager(data) {
    return axios({
      method: "POST",
      url: `/users/manager`,
      data,
    });
  }

  updateManager(id, data) {
    return axios({
      method: "PATCH",
      url: `/users/manager/${id}`,
      data,
    });
  }
  deleteManager(id, data) {
    return axios({
      method: "PATCH",
      url: `/users/delete-manager/${id}`,
    });
  }
}

export default new ManagerService();
