import CountryService from "services/CountryService";

export const GET_COUNTRIES_LOADING = "GET_COUNTRIES_LOADING";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR";

export const GET_COUNTRY_LOADING = "GET_COUNTRY_LOADING";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_ERROR = "GET_COUNTRY_ERROR";

export const getCountries = () => async (dispatch) => {
    dispatch({
        type: GET_COUNTRIES_LOADING,
    });
    try {
        let res = await CountryService.getCountries();

        return dispatch({
            type: GET_COUNTRIES_SUCCESS,
            payload: res.data.country,
        });
    } catch (err) {
        return dispatch({
            type: GET_COUNTRIES_ERROR,
            payload: err.response,
        });
    }
};

export const getCountry = (id) => async (dispatch) => {
    dispatch({
        type: GET_COUNTRY_LOADING,
    });
    try {
        let res = await CountryService.getCountry(id);

        return dispatch({
            type: GET_COUNTRY_SUCCESS,
            payload: res.data.country,
        });
    } catch (err) {
        return dispatch({
            type: GET_COUNTRY_ERROR,
            payload: err.response,
        });
    }
};

