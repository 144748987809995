import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AboutUsView from "./AboutUsView";
import { createSubscriber } from "redux/actions/SubscriberAction";

const AboutUsContainer = () => {
  const dispatch = useDispatch();
  const subscriptionResponse = useSelector((state) => state.subscriber);
  const subscribe = (obj) => {
    dispatch(createSubscriber(obj));
  };

  return (
    <AboutUsView
      subscribe={subscribe}
      subscriptionResponse={subscriptionResponse}
    />
  );
};

export default AboutUsContainer;
