/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Field, useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import CustomSelect from "components/CustomSelect";
import TextField from "components/CustomInput";
import PreviewImage from "components/PreviewImage";
import TopNavBar from "components/Navbars/TopNavBar";
import { userNavigations } from "layouts/ArtistConstants";
import { ReactComponent as DefaultPic } from "../../../assets/ico_default_profile_pic.svg";
import Dropdown from "views/landing/support/components/Dropdown";
import { Alert } from "@mui/material";
import Country from "components/UI/Country";

const saveArtistProfileValidationSchema = yup.object({
  firstName: yup
    .string("Enter your First Name")
    .required("First Name is required")
    .nullable(true)
    .test("alphabets", "First Name must only contain alphabets", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  lastName: yup
    .string("Enter your Last Name")
    .required("Last Name is required")
    .nullable(true)
    .test("alphabets", "Last Name must only contain alphabets", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  nationality: yup
    .string("Enter your Country")
    .required("Country is required")
    .test("alphabets", "Country must only contain alphabets", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  city: yup.string().required(`City is required`).nullable(true),
  phone: yup
    .string()
    .matches(/(\d){9}\b/, "Enter a valid mobile number")
    .max(10, "Enter a valid mobile number")
    .required(`Phone Number is required`),
  bio: yup.string().nullable(true),
  artistTechniques: yup
    .array()
    .min(1, "At least one option is required")
    .required(),
});

export default function SaveArtistProfileView(props) {
  const [picEdited, setPicEdited] = useState(false);
  const [state, setState] = useState({
    open: false,
  });

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [errorTech, setErrorTech] = useState(false);
  const [optionItems, setOptionItems] = useState([]);
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  const [countryOptions, setCountryOptions] = useState([]);
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  const [initiatlFormValues, setInitiatlFormValues] = useState({
    profilePic: undefined,
    firstName: "",
    lastName: "",
    nationality: props.countries ? props.countries[0].name : "Switzerland",
    phone: "",
    bio: "",
    city: "",
    artistTechniques: [],
  });

  const handleFormSubmit = (values) => {
    const obj = new FormData();
    obj.append("profilePic", values.profilePic);
    obj.append("firstName", values.firstName);
    obj.append("lastName", values.lastName);
    obj.append("country", values.nationality);
    obj.append("phone", values.phone);
    obj.append("city", values.city);
    obj.append("bio", values.bio);
    obj.append("artistTechniques", JSON.stringify(values.artistTechniques));
    obj.append("phoneCode", values.phoneCode);

    if (values.artistTechniques.length) {
      props.onSubmit(obj);

      setState((prevState) => ({
        ...prevState,
        open: true,
      }));
    } else {
      setErrorTech(true);
      setTimeout(() => {
        setErrorTech(false);
      }, 2000);
    }
  };

  const populateCountry = () => {
    let countries = props.countries;
    let countryOptionItems = [];
    if (countries && countries.length > 0) {
      countryOptionItems = countries.map((country) => (
        <option key={country.name} value={country.name}>
          {country.name}
        </option>
      ));
    }
    setCountryOptions(countryOptionItems);
  };

  const populatePhoneCode = () => {
    let countries = props.countries;
    let phoneCodeOptionItems = [];
    if (countries && countries.length > 0) {
      phoneCodeOptionItems = countries.map((country) => (
        <option key={country.phoneCode} value={country.phoneCode}>
          {country.phoneCode}
        </option>
      ));
    }
    setPhoneCodeOptions(phoneCodeOptionItems);
  };

  useEffect(() => {
    populateCountry();
    populatePhoneCode();

    let artist = props.loginData;
    setInitiatlFormValues({
      profilePic: artist.photoUrl ? artist.photoUrl : undefined,
      firstName: artist ? artist.firstName : "",
      lastName: artist && artist.lastName ? artist.lastName : "",
      nationality:
        artist && artist.country
          ? artist.country
          : props.countries
          ? props.countries[0].name
          : "",
      phone: artist && artist.phone ? artist.phone : "",
      phoneCode: artist?.phoneCode ?? "+41",
      bio: artist && artist.bio != null ? artist.bio : "",
      city: artist && artist.city ? artist.city : "",
      artistTechniques: artist
        ? artist?.artist_techniques?.map((item) => item.techniqueId)
        : [],
    });
  }, [props.countries]);

  useEffect(() => {
    let optionItems1 = [];
    if (props.techniques && props.techniques.length > 0) {
      props.techniques.forEach(function (value, index) {
        optionItems1.push({
          label: value.techniqueName,
          value: value.id,
        });
      });
    }
    setOptionItems(optionItems1);
  }, [props.techniques]);

  useEffect(() => {
    if (props?.loginData?.photoUrl) {
      setPicEdited(true);
    }
  }, [props?.loginData]);

  const updateTechniqeValue = (newValue) => {
    const allTechniques = newValue.map((obj) => obj.value);
    formik.setFieldValue("artistTechniques", allTechniques);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initiatlFormValues,
    validationSchema: saveArtistProfileValidationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  return (
    <div className="h-full flex">
      {/* Content area */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={props.loginData}
          hideMenuOptions={true}
        />
        {/* Main content */}
        <div className="bg-white sm:m-12">
          <main className="overflow-hidden">
            <section
              className="relative bg-white"
              aria-labelledby="register-heading"
            >
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative bg-white">
                  {/*  form */}
                  <div className="">
                    <FormikProvider value={formik}>
                      <form
                        encType="multipart/form"
                        onSubmit={formik.handleSubmit}
                        className="md:grid md:grid-cols-2 xl:grid-cols-8 lg:gap-y-8 md:gap-x-6 xl:gap-x-8"
                      >
                        <div className="bg-warm-gray-50 xl:col-start-3 xl:col-span-2">
                          <div className="py-5 lg:py-5">
                            <div className="relative z-10 max-w-7xl mx-auto pl-0 pr-8 sm:px-0 lg:px-0">
                              <h1 className="mt-0 pt-0 text-3.5xl font-bold text-primary font-sans">
                                Profile
                              </h1>
                            </div>
                          </div>
                        </div>
                        <div />
                        <div className="xl:col-start-3 xl:col-span-2">
                          {formik.values.profilePic && (
                            <div className="flex flex-row items-center">
                              {picEdited && (
                                <img
                                  src={formik.values.profilePic}
                                  className="h-24 w-24 rounded-full border border-tertiary object-cover object-center"
                                  alt="Artist"
                                />
                              )}
                              {!picEdited && (
                                <PreviewImage
                                  file={formik.values.profilePic}
                                  rounded={"full"}
                                  height={"24"}
                                  width={"24"}
                                />
                              )}
                              <label className="m-4 h-8 bg-primary text-sm font-normal font-sans text-white p-1.5 rounded-xl">
                                <input
                                  id="profilePic"
                                  type="file"
                                  accept="image/png, image/jpeg"
                                  name=""
                                  onChange={(event) => {
                                    setPicEdited(false);
                                    formik.setFieldValue(
                                      "profilePic",
                                      event.currentTarget.files[0]
                                    );
                                  }}
                                  hidden
                                />
                                Change Logo
                              </label>
                            </div>
                          )}
                          {!formik.values.profilePic && (
                            <div className="flex flex-row items-center">
                              <div className="w-24 h-24 bg-primary rounded-full flex justify-center items-center">
                                <DefaultPic className="h-20 w-20" />
                              </div>
                              <label className="m-4 h-8 bg-primary text-sm font-normal font-sans text-white p-1.5 px-3 rounded-2xl">
                                <input
                                  id="profilePic"
                                  type="file"
                                  accept="image/png, image/jpeg"
                                  name=""
                                  onChange={(event) => {
                                    formik.setFieldValue(
                                      "profilePic",
                                      event.currentTarget.files[0]
                                    );
                                  }}
                                  hidden
                                />
                                Upload Logo
                              </label>
                            </div>
                          )}
                        </div>
                        <div className="xl:col-span-2" />
                        <div className="xl:col-start-3 xl:col-span-2">
                          <Input
                            type="text"
                            name="firstName"
                            id="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            label="First Name"
                          />
                          <p className="text-red">
                            {formik.errors.firstName &&
                              formik.touched.firstName &&
                              formik.errors.firstName}
                          </p>
                        </div>
                        <div className="xl:col-span-2">
                          <Input
                            type="text"
                            name="lastName"
                            id="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            label="Last Name"
                          />
                          <p className="text-red">
                            {formik.errors.lastName &&
                              formik.touched.lastName &&
                              formik.errors.lastName}
                          </p>
                        </div>
                        <div className="xl:col-start-3 xl:col-span-2">
                          <Dropdown label="Country">
                            <select
                              className="w-full bg-white border-transparent focus:outline-none"
                              id="nationality"
                              name="nationality"
                              value={formik.values.nationality}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              {countryOptions}
                            </select>
                          </Dropdown>
                          <p className="text-red font-sans">
                            {formik.errors.nationality &&
                              formik.touched.nationality &&
                              formik.errors.nationality}
                          </p>
                        </div>
                        <div className="xl:col-span-2">
                          <Country
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            phoneCode={formik.values.phoneCode}
                            phoneCodeOptions={phoneCodeOptions}
                            phone={formik.values.phone}
                          />
                          <p className="text-red">
                            {formik.errors.phone &&
                              formik.touched.phone &&
                              formik.errors.phone}
                          </p>
                        </div>
                        <div className="xl:col-start-3 xl:col-span-2">
                          <Input
                            type="text"
                            name="city"
                            id="city"
                            label="City"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                            className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                          />
                          <p className="text-red">
                            {formik.errors.city &&
                              formik.touched.city &&
                              formik.errors.city}
                          </p>
                        </div>

                        <div className="md:col-span-2 xl:col-span-4 py-4 xl:col-start-3">
                          <TextField
                            type="text"
                            name="bio"
                            id="bio"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bio}
                            label="Bio"
                            multiline={true}
                            minRows={4}
                          />
                          <p className="text-red">
                            {formik.errors.bio &&
                              formik.touched.bio &&
                              formik.errors.bio}
                          </p>
                        </div>
                        <div className="xl:col-start-3 xl:col-span-2">
                          <label className="text-primary font-sans, text-sm font-normal flex mb-2">
                            Techniques
                          </label>
                          <Field
                            style={{
                              backgroundColor: "#C7A33F",
                              borderColor: "#C7A33F",
                            }}
                            name="artistTechniques"
                            options={optionItems}
                            component={CustomSelect}
                            placeholder="Select Techniques"
                            isMulti={true}
                            value={formik.values.artistTechniques}
                            setTechniquValue={(newValue) =>
                              updateTechniqeValue(newValue)
                            }
                          />
                          <p className="text-red">
                            {errorTech && (
                              <Alert severity="error">
                                Technique is required
                              </Alert>
                            )}
                            {formik.errors.artistTechniques &&
                              formik.touched.artistTechniques &&
                              formik.errors.artistTechniques}
                          </p>
                        </div>
                        <div className="xl:col-start-3 xl:col-span-2"> </div>
                        {props.error}
                        <div className="sm:col-span-1 sm:flex-col sm:justify-start lg:px-0 mb-10 md:mb-0 mt-6 xl:mt-0 xl:col-start-3 xl:col-span-2">
                          <Button
                            type="submit"
                            label="Save Profile"
                            textsize="base"
                          />
                        </div>
                      </form>
                    </FormikProvider>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
  );
}
