import React from "react";
import { ReactComponent as AddIcon } from "../../../assets/ico_plus.svg";
import { ReactComponent as PencilIcon } from "../../../assets/ico_pencil.svg";
import { SERVER_URL } from "utils/constants";

export default function StudioCard(props) {
  return (
    <>
      {/* Gallery */}
      <section className="mt-8 pb-12" aria-labelledby="gallery-heading">
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {props.studios &&
            props.studios.length !== 0 &&
            props.studios.map((studio) => (
              <li
                key={studio.id}
                className="col-span-1 bg-white rounded-3xl shadow"
              >
                <div className="max-w-sm rounded-3xl overflow-hidden shadow-lg h-96 w-full justify-center place-items-center">
                  <img
                    className="w-full object-cover h-48"
                    src={
                      studio.imageUrl
                        ? SERVER_URL + studio.imageUrl
                        : SERVER_URL +
                          "uploads/default-images/DefaultStudio.svg"
                    }
                    alt=" Studio"
                  />
                  <div className="px-4 py-2 h-48">
                    <div className="font-bold text-xl mb-2">
                      {studio.placeName}
                    </div>
                    <a href={`/admin/studio/${props.selectedTab}/${studio.id}`}>
                      <PencilIcon className="h-6 w-6" />
                    </a>
                    <p className="text-tertiary text-base">
                      ({studio.phoneCode}) {studio.phoneNumber}
                    </p>
                    <p className="text-tertiary text-base">
                      {studio.street}, {studio.city}, {studio.country}{" "}
                      {studio.zipcode}
                    </p>
                    {studio.studio_work_day && (
                      <p className="text-tertiary text-base">
                        {studio.studio_work_day.isMonday ||
                        studio.studio_work_day.isTuesday ||
                        studio.studio_work_day.isWednesday ||
                        studio.studio_work_day.isThursday ||
                        studio.studio_work_day.isFriday ||
                        studio.studio_work_day.isSaturday ||
                        studio.studio_work_day.isSunday
                          ? "Work Days: "
                          : ""}
                        {studio.studio_work_day.isMonday ? "Mon" : ""}
                        {studio.studio_work_day.isTuesday
                          ? studio.studio_work_day.isMonday
                            ? ", Tue"
                            : "Tue"
                          : ""}
                        {studio.studio_work_day.isWednesday
                          ? studio.studio_work_day.isMonday ||
                            studio.studio_work_day.isTuesday
                            ? ", Wed"
                            : "Wed"
                          : ""}
                        {studio.studio_work_day.isThursday
                          ? studio.studio_work_day.isMonday ||
                            studio.studio_work_day.isTuesday ||
                            studio.studio_work_day.isWednesday
                            ? ", Thu"
                            : "Thu"
                          : ""}
                        {studio.studio_work_day.isFriday
                          ? studio.studio_work_day.isMonday ||
                            studio.studio_work_day.isTuesday ||
                            studio.studio_work_day.isWednesday ||
                            studio.studio_work_day.isThursday
                            ? ", Fri"
                            : "Fri"
                          : ""}
                        {studio.studio_work_day.isSaturday
                          ? studio.studio_work_day.isMonday ||
                            studio.studio_work_day.isTuesday ||
                            studio.studio_work_day.isWednesday ||
                            studio.studio_work_day.isThursday ||
                            studio.studio_work_day.isFriday
                            ? ", Sat"
                            : "Sat"
                          : ""}
                        {studio.studio_work_day.isSunday
                          ? studio.studio_work_day.isMonday ||
                            studio.studio_work_day.isTuesday ||
                            studio.studio_work_day.isWednesday ||
                            studio.studio_work_day.isThursday ||
                            studio.studio_work_day.isFriday ||
                            studio.studio_work_day.isSaturday
                            ? ", Sun"
                            : "Sun"
                          : ""}

                        {/* {$({(studio.studio_work_day.isMonday ? "Mon, " : "") }
                        { (studio.studio_work_day.isTuesday ? "Tue, " : "")}
                        { (studio.studio_work_day.isWednesday ? "Wed, ": "")}
                        {(studio.studio_work_day.isThursday ? "Thu, ": "")}                        
                        {(studio.studio_work_day.isFriday ? "Fri, ": "")}
                        {(studio.studio_work_day.isSaturday ? "Sat, " : "")}
                        {(studio.studio_work_day.isSunday ? "Sun, " : "")}
                        )}.replace() */}
                      </p>
                    )}
                  </div>
                </div>
              </li>
            ))}
          <li
            key={"add_studio_card"}
            className="flex cursor-pointer h-96 col-span-1 bg-white rounded-3xl shadow p-0 border-dashed  border-2 justify-center border-primary ..."
          >
            {/* <div
              className="lg:bg-none lg:px-0 flex items-center content-center justify-center "
              onClick={`location.href='/admin/studio/${props.selectedTab}'`}
            > */}
              <a
                href={`/admin/studio/${props.selectedTab}`}
                className="w-full flex content-center items-center justify-center"
              >
                <div className="flex flex-col content-center items-center justify-center w-full">
                  <AddIcon className="h-6 w-6" />
                  <h2 className="text-primary ml-2 mt-2 text-base font-sans font-semibold justify-center flex">
                    Add Studio
                  </h2>
                </div>
              </a>
            {/* </div> */}
          </li>
        </ul>
      </section>
    </>
  );
}
