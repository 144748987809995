/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { Box } from "@mui/material";

const style = {
  position: "relative",
  maxWidth: 905,
  marginLeft: "auto",
  marginRight: "auto",
};

const PaymentIframeView = (props) => {
  return (
    <div>
      <Box sx={style}>
        <iframe
          src={props?.location?.state?.url}
          allow="payment *"
          className="h-screen w-screen"
        />
      </Box>
    </div>
  );
};

export default PaymentIframeView;
