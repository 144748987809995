import Button from "components/UI/Button";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import SideScrollBar from "./SideScrollBar";
import moment from "moment";
import { socket } from "utils/socketContext";
import localStorageService from "../../../../services/localStorageService";
import * as yup from "yup";

const supportResponseSchema = yup.object({
  response: yup.string().required("Response note is required"),
});

const General = ({ loginData, items, onSubmit, type }) => {
  const [selectedItem, setSelectedItem] = useState(
    localStorageService.getItem("general_support")
      ? localStorageService.getItem("general_support")
      : items[0]
  );

  // This is to ensure first item is selected when use goes to the general tab
  useEffect(() => {
    localStorageService.setItem("general_support", items[0]);
    setSelectedItem(localStorageService.getItem("general_support")
    ? localStorageService.getItem("general_support")
    : items[0]);
  }, [type]);

  useEffect(() => {
    socket.on("GENERAL_SUPPORT_TICKET_MESSAGE_RECEIVED", (data) => {});
  });

  const handleFormSubmit = (values) => {
    const requestObj = {
      supportTicketId: selectedItem.id,
      userId: loginData.id,
      typeId: selectedItem.userTypeId,
      comment: values.response,
    };

    socket.emit("SUPPORT_TICKET", selectedItem.id);

    socket.emit("GENERAL_SUPPORT_TICKET_MESSAGE", requestObj);

    onSubmit(requestObj);
  };

  return (
    <div className="flex w-full grid grid-cols-12">
      <div className="col-start-1 col-span-12 md:col-start-1 md:col-span-4 lg:col-span-3 w-full">
        <SideScrollBar
          items={items}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </div>
      <div className="col-start-1 col-span-12 md:col-start-6 lg:col-start-5 md:col-span-6">
        <div className="text-tertiary">
          <span className="mr-1">{selectedItem.fullName}</span>({type})
        </div>
        <ul className="">
          {selectedItem.support_ticket_comments.map(
            (supportTicketComment, index) => (
              <li key={index}>{supportTicketComment.comment}</li>
            )
          )}
        </ul>
        <div className="text-sm text-tertiary">
          {moment(selectedItem.createdAt).format("DD-MM-yyyy hh:mm")}
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={{ response: "" }}
          validationSchema={supportResponseSchema}
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <form
              encType="multipart/form-data"
              className="flex flex-1 grid grid-cols-6 w-full"
              onSubmit={handleSubmit}
            >
              <div className="border border-primary rounded-2xl px-3 py-2 shadow-sm focus-within:ring-0 focus-within:ring-primary focus-within:border-primary col-start-1 col-span-6 mt-6 mb-2">
                <label
                  htmlFor="name"
                  className="block text-xs font-medium text-primary font-sans"
                >
                  Respond
                </label>
                <textarea
                  id="response"
                  onChange={handleChange}
                  value={values.response}
                  className="w-full font-sans border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                />
              </div>
              <p className="text-red font-sans pb-2 col-span-6">
                  {errors.response && touched.response && errors.response}
                </p>
              <div className="col-start-5 col-span-2">
                <Button label="Submit" type="submit" paddingv={"2"} px={"2"} />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default General;
