import axios from '../http-config'

class CountryService {

    getCountries() {
        return axios({
            method: "GET",
            url: `/country`
        });
    }

    getCountry(id) {
        return axios({
            method: "GET",
            url: `/country/${id}`
        });
    }
}

export default new CountryService();