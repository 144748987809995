import axios from "../../http-config";

export const SERVICE_LOADING = "SERVICE_LOADING";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_ERROR = "GET_SERVICE_ERROR";

export const getServices = () => async (dispatch) => {
    dispatch({
        type: SERVICE_LOADING,
    });
    try {
        let res = await axios({
            method: "GET",
            url: `/services`
        });

        return dispatch({
            type: GET_SERVICE_SUCCESS,
            payload: res.data.services,
        });
    } catch (err) {
        return dispatch({
            type: GET_SERVICE_ERROR,
            payload: err.response,
        });
    }
};

export const getService = (id) => async (dispatch) => {
    dispatch({
        type: SERVICE_LOADING,
    });
    try {
        let res = await axios({
            method: "GET",
            url: `/services/${id}`
        });

        return dispatch({
            type: GET_SERVICE_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        return dispatch({
            type: GET_SERVICE_ERROR,
            payload: err.response,
        });
    }
};