import moment from "moment";
import * as React from "react";
import { USER_ROLE_PROJECT_ADMIN, USER_ROLE_PROJECT_MYSTUDIO_ARTIST, USER_ROLE_CLIENT } from "../../CONSTANTS";

const ProjectInfo = (props) => {

  const getProjectStatusColor = (projectStatus) => {
    switch (projectStatus) {
      case 1:
        return "new";
      case 2:
        return "waiting";
      case 3:
        return "withdrawn";
      case 4:
        return "declined";
      case 5:
        return "done";
      case 6:
        return "waiting";
      case 7:
        return "progress";
      case 8:
        return "waiting";  
      case 13:
        return "refunded";
      default:
        return "new";
    }
  };

  return (
    <div className="flex grid grid-cols-2">
      <div className="col-span-2 grid grid-cols-4">
        <div
          className={`flex h-3 col-span-3 flex-col mb-6 bg-${getProjectStatusColor(
            props?.projectStatusId
          )}`}
        />
      </div>
      <div className="col-start-1 col-span-2 mb-2 flex items-center grid grid-cols-12">
        <div
          className={`w-5 h-5 border-3 border-${getProjectStatusColor(
            props?.projectStatusId
          )} rounded-full lg:mr-0`}
        />
        <span className="text-secondary text-base col-span-10 lg:ml-2 font-bold">
          {props.role === USER_ROLE_PROJECT_ADMIN || props.role === USER_ROLE_PROJECT_MYSTUDIO_ARTIST
            ? `${props?.data?.statusData?.bossStatusName}`
            : props.role === USER_ROLE_CLIENT
            ? props?.data?.statusData?.clientStatusName
            : props.role === "PSArtist"
            ? props?.data?.statusData?.artistStatusName
            : props.role === "Admin"
            ? props?.data?.statusData?.studioStatusName
            : props?.data?.statusData?.statusName}
        </span>
      </div>
      <div className="mb-2 text-tertiary text-base col-start-1 col-span-2 font-sans">
        #{props?.data?.id}
      </div>
      <div className="text-tertiary text-base mb-6 col-start-1 col-span-2 font-sans">
        Created: {moment(props?.data?.created).format("D MMM, HH:mm")}
      </div>
    </div>
  );
};

export default ProjectInfo;
