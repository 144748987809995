import * as React from "react";
import EditBudget from "components/EditBudget";
import { Dialog, Transition } from "@headlessui/react";

//slider
const marks = [
  {
    value: 50,
    label: "50",
  },
  {
    value: 3600,
    label: "3500",
  },
];

const BudgetPage = (props) => {
  const handleClose = () => props.setOpen(false);

  if (props.budget) {
    props.data.fromPrice = props.budget.fromPrice;
    props.data.toPrice = props.offerPriceVal
      ? props.offerPriceVal
      : props.budget.toPrice;
  }

  return (
    <Transition.Root show={props.open}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="flex items-start justify-center max-h-screen text-center px-2">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-top sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block bg-white rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all 
                 sm:max-h-4/5 md:max-w-md max-w-xs mt-20"
            >
              <EditBudget
                marks={marks}
                page="editBudget"
                {...props}
                title="Edit Bugdet"
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default BudgetPage;
