import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StudioReviewView from "./StudioReviewView";
// import { getArtistPortfolioById } from "../../../redux/actions/ArtistPortfolioAction";
import { getStudioArtistReviews, getStudioArtistReviewsAvg } from "../../../redux/actions/StudioReviewsAction"
import { getUserByEmail, getUserById } from "redux/actions/UserActions";


export default function StudioReviewContainer(props) {
   // const selectedArtistPortfolioId = props.match.params.id;
    const selectedArtistId = props.match.params.id;

    // const artistPortfolio = useSelector((state) => state.artistPortfolio.data);
    const studioReviews = useSelector((state) => state.studioReviews.studioArtistReviews);
    const studioReviewsAvg = useSelector((state) => state.studioReviews.studioArtistReviewsAvg);
    const userDataByEmail = useSelector((state) => state.user.userByEmail);
    const userDataObj = useSelector((state) => state.user.data);
    
    const dispatch = useDispatch();
    // useEffect(() => {
    //     if (selectedArtistPortfolioId) {
    //         dispatch(getArtistPortfolioById(selectedArtistPortfolioId));
    //     }
    // }, [selectedArtistPortfolioId]);

    useEffect (() => {
        dispatch(getStudioArtistReviews(selectedArtistId));
        dispatch(getStudioArtistReviewsAvg(selectedArtistId));
        dispatch(getUserById(selectedArtistId));
    },[])

    useEffect (() => {
        if (userDataObj?.email) {
            dispatch(getUserByEmail(userDataObj.email));
        }        
    },[userDataObj])

    return (
        <div>
            <StudioReviewView
                loginData={props.loginData}
                studioReviews={studioReviews}
                // artistPortfolio={artistPortfolio}
                studioReviewsAvg={studioReviewsAvg}
                userDataByEmail={userDataByEmail}
            />
        </div>
    );
}