import axios from "../../http-config";

export const COLOR_LOADING = "COLOR_LOADING";
export const GET_COLOR_SUCCESS = "GET_COLOR_SUCCESS";
export const GET_COLOR_ERROR = "GET_COLOR_ERROR";

export const getColors = () => async (dispatch) => {
    dispatch({
        type: COLOR_LOADING,
    });
    try {
        let res = await axios({
            method: "GET",
            url: `/colors`
        });

        return dispatch({
            type: GET_COLOR_SUCCESS,
            payload: res.data.colors,
        });
    } catch (err) {
        return dispatch({
            type: GET_COLOR_ERROR,
            payload: err.response,
        });
    }
};

export const getColor = (id) => async (dispatch) => {
    dispatch({
        type: COLOR_LOADING,
    });
    try {
        let res = await axios({
            method: "GET",
            url: `/colors/${id}`
        });

        return dispatch({
            type: GET_COLOR_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        return dispatch({
            type: GET_COLOR_ERROR,
            payload: err.response,
        });
    }
};