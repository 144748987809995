import React from "react";
import StudioProjects from "./StudioProjects";

const ProjectsHistory = (props) => {
  return (
    <StudioProjects
      selectedTab={props.selectedTab}
      data={props.studioProjects.project}
    />
  );
};

export default ProjectsHistory;
