import MarketPlaceDecisionService from "services/MarketPlaceDecisionService";

export const MARKETPLACEDECISION_LOADING = "MARKETPLACEDECISION_LOADING";
export const MARKETPLACEDECISION_SUCCESS = "MARKETPLACEDECISION_SUCCESS";
export const MARKETPLACEDECISION_ERROR = "MARKETPLACEDECISION_ERROR";

export const getMarketPlaceDecisions = () => async (dispatch) => {
    dispatch({
        type: MARKETPLACEDECISION_LOADING,
    });
    try {
        let res = await MarketPlaceDecisionService.getMarketPlaceDecisions();

        return dispatch({
            type: MARKETPLACEDECISION_SUCCESS,
            payload: res.data.marketPlaceDecision,
        });
    } catch (err) {
        return dispatch({
            type: MARKETPLACEDECISION_ERROR,
            payload: err.response,
        });
    }
};