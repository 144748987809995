import AccountingService from "services/AccountingService"

export const GET_INVOICES_LOADING = 'GET_INVOICES_LOADING'
export const GET_INVOICES = 'GET_INVOICES'
export const GET_INVOICES_ERROR = 'GET_INVOICES_ERROR'
export const GET_GRAPHS_DATA_LOADING = 'GET_GRAPHS_DATA_LOADING'
export const GET_GRAPHS_DATA_SUCCESS = 'GET_GRAPHS_DATA_SUCCESS'
export const GET_GRAPHS_DATA_FAILURE = 'GET_GRAPHS_DATA_FAILURE'

export const GET_STATISTICS_DATA_LOADING = 'GET_STATISTICS_DATA_LOADING'
export const GET_STATISTICS_DATA_SUCCESS = 'GET_STATISTICS_DATA_SUCCESS'
export const GET_STATISTICS_DATA_FAILURE = 'GET_STATISTICS_DATA_FAILURE'

export const getInvoices = ( queryString ) => async (dispatch) => {
    dispatch({
        type : GET_INVOICES_LOADING
    })
    try{
        const res = await AccountingService.getInvoices(queryString)
        return dispatch({
            type : GET_INVOICES,
            payload : res.data
        })
    }
    catch(err){
        return dispatch({
            type : GET_INVOICES_ERROR,
            payload : err.response
        })
    }
}

export const getGraphsData = () => async (dispatch) => {
    dispatch({
        type : GET_GRAPHS_DATA_LOADING
    })
    try{
        const res = await AccountingService.getGraphsData()
        return dispatch({
            type : GET_GRAPHS_DATA_SUCCESS,
            payload : res.data
        })
    }
    catch(err){
        return({
            type : GET_GRAPHS_DATA_FAILURE,
            payload : err.response
        })
    }
}

export const getStatisticsData = () => async (dispatch) => {
    dispatch({
        type : GET_STATISTICS_DATA_LOADING
    })
    try{
        const res = await AccountingService.getStatisticsData()
        return dispatch({
            type : GET_STATISTICS_DATA_SUCCESS,
            payload : res.data
        })
    }
    catch(err) {
        return({
            type : GET_STATISTICS_DATA_FAILURE,
            payload : err.response
        })
    }
}