import React, { useState, useEffect } from "react";
import ClientProjects from "./ClientProjects";

const ProjectsHistory = (props) => {
  const [checkedDone, setCheckedDone] = useState(false);
  const [checkedDeclined, setCheckedDeclined] = useState(false);
  const [checkedRefunded, setCheckedRefunded] = useState(false);

  const [projectListData, setProjectListData] = useState(
    props?.projectList
  );
  let doneCount = 0;
  let declinedCount = 0;
  let refundedCount = 0;

  useEffect(() => {
    setProjectListData(props?.projectList);
  }, [props]);

  let doneData =
    props?.projectList
      ?  props?.projectList.filter(
          (item) => item.projectStatusId === 5
        )
      : [];
    doneCount = doneData.length;

  let declinedData =
    props?.projectList
      ? props?.projectList.filter(
          (item) => item.projectStatusId === 4
        )
      : [];
    declinedCount = declinedData.length;

  let refundedData =
      props?.projectList
        ? props?.projectList.filter(
            (item) => item.projectStatusId === 13
          )
        : [];
    refundedCount = refundedData.length;

  const handleChangeDone = (isDoneChecked) => {
    setCheckedDone(isDoneChecked);
    refreshSearch(isDoneChecked, checkedDeclined, checkedRefunded);
  };

  const handleChangeDeclined= (isDeclinedChecked) => {
    setCheckedDeclined(isDeclinedChecked);
    refreshSearch(checkedDone, isDeclinedChecked, checkedRefunded);
  };

  const handleChangeRefunded= (isRefundedChecked) => {
    setCheckedRefunded(isRefundedChecked);
    refreshSearch(checkedDone, checkedDeclined, isRefundedChecked);
  };

  const refreshSearch = (bCheckedDone, bCheckedDeclined, bCheckedRefunded) => {
    if (bCheckedDone  && !bCheckedDeclined && !bCheckedRefunded) {
      setProjectListData(doneData);
    } else if (bCheckedDone && !bCheckedDeclined && bCheckedRefunded) {
      let data =
      props?.projectList
        ? props?.projectList.filter(
            (item) => item.projectStatusId === 13 || item.projectStatusId === 5
          ) : [];
      setProjectListData(data);
    } else if (bCheckedDone && bCheckedDeclined && !bCheckedRefunded) {
      let data =
      props?.projectList
        ? props?.projectList.filter(
            (item) => item.projectStatusId === 5 || item.projectStatusId === 4
          ) : [];
      setProjectListData(data);
    } else if ((bCheckedDone && bCheckedDeclined && bCheckedRefunded) || (!bCheckedDone  && !bCheckedDeclined && !bCheckedRefunded)) {
      setProjectListData(props?.projectList);
    } else if (!bCheckedDone && !bCheckedDeclined && bCheckedRefunded) {
      setProjectListData(refundedData);
    } else if (!bCheckedDone && bCheckedDeclined && !bCheckedRefunded) {
      setProjectListData(declinedData);
    } else if (!bCheckedDone && bCheckedDeclined && bCheckedRefunded) {
      let data =
      props?.projectList
        ? props?.projectList.filter(
            (item) => item.projectStatusId === 13 || item.projectStatusId === 4 
          ) : [];
      setProjectListData(data);    
    }
  }

  return (
    <div>
      <div className="flex flex-col lg:flex-row mt-2">
        <div className="flex mb-2 lg:mb-0 mr-4 items-center">
          <input
            className={`form-checkbox h-5 w-5 border border-gray rounded text-primary  align-top float-left mr-2 focus:ring-0`}
            type="checkbox"
            name="isDone"
            id="isDone"
            checked={checkedDone}
            onChange={(e) => handleChangeDone(e.target.checked)}
          />
          <label className="flex text-base" htmlFor="isDone">
            Done {"  "} {doneCount}
          </label>
        </div>
        <div className="flex mr-4 items-center">
          <input
            className="form-checkbox h-5 w-5 border border-gray rounded text-primary align-top float-left mr-2 focus:ring-0"
            type="checkbox"
            name="isDeclined"
            id="isDeclined"
            checked={checkedDeclined}
            onChange={(e) => handleChangeDeclined(e.target.checked)}
          />
          <label className="flex text-base" htmlFor="isDeclined">
          Declined {"  "} {declinedCount}
          </label>
        </div>
        <div className="flex mr-4 items-center">
          <input
            className="form-checkbox h-5 w-5 border border-gray rounded text-primary align-top float-left mr-2 focus:ring-0"
            type="checkbox"
            name="isRefunded"
            id="isRefunded"
            checked={checkedRefunded}
            onChange={(e) => handleChangeRefunded(e.target.checked)}
          />
          <label className="flex text-base" htmlFor="isDeclined">
          Refunded {"  "} {refundedCount}
          </label>
        </div>
      </div>
      <ClientProjects
        setSelectedTab={props.setSelectedTab}
        data={projectListData}
        clientById={props.clientById}
        projectOnClick={props.projectOnClick}
      />
    </div>
  );
};

export default ProjectsHistory;
