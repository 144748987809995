import {
  NOTIFICATION_LOADING,
  NOTIFICATION_ERROR,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_USERTYPE_LOADING,
  NOTIFICATION_USERTYPE_ERROR,
  NOTIFICATION_USERTYPE_SUCCESS,
} from "../actions/NotificationAction";

const initialState = {
  loading: false,
  success: false,
  create: null,
  update: null,
  delete: null,
  error: null,
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_LOADING:
    case NOTIFICATION_USERTYPE_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        create: null,
        update: null,
        delete: null,
        error: null,
      };
    }
   
    case NOTIFICATION_SUCCESS:
    case NOTIFICATION_USERTYPE_ERROR: {
      return {
        ...state,
        loading: false,
        success: true,
        create: null,
        update: action.payload,
        delete: null,
        error: null,
      };
    }
    case NOTIFICATION_ERROR:
    case NOTIFICATION_USERTYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: false,
        create: null,
        update: null,
        delete: null,
        error: action.payload ? action.payload : "Unknown Error Occured",
      };
    }
    default:
      return { ...state };
  }
};

export default NotificationReducer;
