import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { USER_ROLE_CLIENT } from "../../CONSTANTS";
import EditUploadPictures from "./EditUploadPictures";
import { SERVER_URL } from "utils/constants";

const TattooImages = (props) => {
  const [editOpen, setEditOpen] = useState(false);
  const [bName, setBName] = useState("Add");
  const [editData, setEditData] = useState(null);

  const handleEditOpen = (btnName, fileDetails, ind) => {
    setEditOpen(true);
    setBName(btnName);
    // if (props.data.length > 1 && ind) {
      setEditData("");
    // } else {
    //   setEditData(fileDetails);
    // }
  };

  const onUploadSubmit = (obj) => {
    props.onSubmit(obj);
  };

  return (
    <div className="w-full">
      <div className="flex w-full grid grid-cols-3 gap-4">
        <div className="flex col-span-3 w-full" id="tattoo-project-details">
          {props.modelPicture && (
            <img
              className="rounded-md object-fill w-full"
              src={SERVER_URL + props.modelPicture}
              alt="Model"
            />
          )}
        </div>

        {props.data && props.data[0] && (
          <div className="flex col-span-1 w-full">
            <img
              className="rounded border-0 border-gray object-cover object-center bg-verylightGray h-24 w-full"
              src={SERVER_URL + props.data[0].tatooImageUrl}
              alt="Profile"
              onClick={() => handleEditOpen("Update", props.data[0], 1)}
            />
          </div>
        )}
        {props?.role === USER_ROLE_CLIENT &&
          props?.projectStatus === "New Project" && (
            <div className="flex col-start-2 col-span-2 grid grid-cols-2 gap-4">
              {props.data && props.data[1] && (
                <div className="relative w-full h-full">
                  <img
                    className={`rounded border-0 border-gray object-cover object-center bg-lightGray w-full h-24 absolute ${
                      props.data && props.data.length === 3 ? "opacity-20" : ""
                    }`}
                    src={SERVER_URL + props.data[1].tatooImageUrl}
                    alt="Profile"
                    onClick={() => handleEditOpen("Update", props.data[1], 1)}
                  />
                  {props.data && props.data.length === 3 && (
                    <div className="text-white text-3xl flex h-full w-full items-center justify-center bg-black z-10 rounded border border-gray">
                      <AddIcon fontSize="medium" /> {props.data.length - 2}
                    </div>
                  )}
                </div>
              )}

              {props.data && props.data[2] && props.data.length === 4 && (
                <div className="relative w-full h-full">
                  <img
                    className={`rounded-md border-0 border-gray object-cover object-center bg-lightGray w-full h-24 absolute ${
                      props.data && props.data.length === 4 ? "opacity-20" : ""
                    }`}
                    src={SERVER_URL + props.data[1].tatooImageUrl}
                    alt="Profile"
                    onClick={() => handleEditOpen("Update", props.data[2], 1)}
                  />
                  {props.data && props.data.length === 4 && (
                    <div className="text-white text-3xl flex h-full w-full items-center justify-center bg-black z-10 rounded-md border border-gray">
                      <AddIcon fontSize="medium" /> {props.data.length - 2}
                    </div>
                  )}
                </div>
              )}

              {!props.data ||
                (props.data && props.data.length < 4 && (
                  <div className="flex col-start-2 w-full h-24">
                    <div
                      className="flex border-dashed border-2 border-gray rounded-md w-full"
                      onClick={() => handleEditOpen("Add")}
                    >
                      <div className="`dropzone  h-full w-full flex items-center justify-center">
                        <span>
                          <AddIcon fontSize="large" />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        {!(
          props?.role === USER_ROLE_CLIENT &&
          props?.projectStatus === "New Project"
        ) && (
          <div className="flex grid grid-cols-2 gap-4 col-span-2">
            {props.data && props.data[1] && (
              <img
                className="rounded-md border-0 border-gray object-cover object-center bg-lightGray h-24 w-full "
                src={SERVER_URL + props.data[1].tatooImageUrl}
                alt="Profile"
                onClick={() => handleEditOpen("Update", props.data[1], 1)}
              />
            )}
            {props.data && props.data[2] && (
              <div className="relative w-full h-full">
                <img
                  className={`rounded-md border-0 border-gray object-cover object-center bg-lightGray w-full h-24 absolute ${
                    props.data && props.data.length > 3 ? "opacity-20" : ""
                  }`}
                  src={SERVER_URL + props.data[2].tatooImageUrl}
                  alt="Profile"
                  onClick={() => handleEditOpen("Update", props.data[1], 1)}
                />
                {props.data && props.data.length > 3 && (
                  <div className="text-white text-3xl flex h-full w-full items-center justify-center bg-black z-10 rounded-md border border-gray">
                    <AddIcon fontSize="medium" /> {props.data.length - 2}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {editOpen && (
        <EditUploadPictures
          open={editOpen}
          setOpen={setEditOpen}
          loggedInUserId={props.loggedInUserId}
          data={props?.data}
          buttonText={bName}
          onSubmit={onUploadSubmit}
          editData={editData}
          role={props?.role}
          projectStatus={props?.projectStatus}
        />
      )}
    </div>
  );
};

export default TattooImages;
