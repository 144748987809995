import React, { useEffect, useState } from "react";
import OfferPriceComponent from "./OfferPriceComponent";
import BudgetPage from "./BudgetPage";
import { USER_ROLE_PROJECT_ADMIN } from "../../CONSTANTS";
import { ReactComponent as CheckDone } from "assets/ic_check_gray.svg";
import { ReactComponent as AccountBalanceWalletIcon } from "assets/ic_money.svg";
import { ReactComponent as DetailsIcon } from "assets/ico_details.svg";
import Swal from "sweetalert2";

const OfferPrice = (props) => {
  let show = false,
    cShow = false,
    dcntShow = false;

  let label;
  if (
    props?.projectStatus === "Accepted Project by Artist" ||
    props?.projectStatus === "Accepted Project by Admin"
  ) {
    label = "Offer Price";
    if (
      props?.role === "PSArtist" ||
      props?.role === USER_ROLE_PROJECT_ADMIN ||
      props?.role === "Admin"
    ) {
      show = true;
    }
  } else if (
    props?.projectStatus === "Counter Offer by Artist" ||
    props?.projectStatus === "Counter Offer by Admin"
  ) {
    label = "Counter Offer Price";
    if (
      props?.role === "PSArtist" ||
      props?.role === USER_ROLE_PROJECT_ADMIN ||
      props?.role === "Admin"
    ) {
      show = true;
    }
    if (props?.role === "Client") {
      cShow = true;
    }
  } else {
    label = "Price";
    if (props?.role === "Client" && props.addDiscount) {
      dcntShow = true;
    }
  }
  const [editOpen, setEditOpen] = useState(false);

  const [editNewPriceOpen, setEditNewPriceOpen] = useState(false);
  const handleEditNewPriceOpen = () => setEditNewPriceOpen(true);

  const getAmountAfterDiscount = () => {
    return Math.round(
      props?.offerPriceVal - (props.addDiscount / 100) * props?.offerPriceVal
    );
  };

  const getAmountPaidOnline = () => {
    var amountAfterDiscount = props.addDiscount
      ? getAmountAfterDiscount()
      : props?.offerPriceVal;
    return Math.round(0.2 * amountAfterDiscount);
  };

  const showPriceDetails = () => {
    var priceDetails = "";

    priceDetails =
      priceDetails +
      `<p align='left'>Original Amount: ${props?.offerPriceVal} <br/></p>`;

    priceDetails =
      priceDetails +
      (props.addDiscount
        ? `<p align='left'>Discount: ${props.addDiscount}% <br/></p>`
        : "");

    priceDetails =
      priceDetails +
      (props.addDiscount
        ? `<p align='left'>Amount After Discount: ${getAmountAfterDiscount()} <br/></p>`
        : "");

    priceDetails =
      priceDetails +
      `<p align='left'>Pre-payment (Online) : ${getAmountPaidOnline()} <br/></p>`;

    priceDetails =
      priceDetails +
      `<p align='left'>Balance Payment (At Studio): ${
        getAmountAfterDiscount() - getAmountPaidOnline()
      } <br/></p>`;

    if (props?.projectStatus === "Refunded") {
      priceDetails =
        priceDetails +
        `<p align='left'>Refunded Amount: ${getAmountPaidOnline()}
       <br/></p>`;
    }

    Swal.fire({
      title: "Price Details",
      html: priceDetails,
      allowEscapeKey: true,
    });
  };

  useEffect(() => {
    return () => {
      Swal.close();
    };
  }, []);

  return (
    <div className="w-full mt-5 bg-bgg">
      <div className="flex flex-row justify-items-center rounded-2xl border border-dashed border-primary px-4 py-3 gap-4">
        <div className="my-auto">
          <DetailsIcon
            fontSize="medium"
            className="mb-2"
            onClick={() => showPriceDetails()}
          />
          <AccountBalanceWalletIcon fontSize="medium" />
        </div>
        <div className="flex flex-col text-sm">
          <div className="flex flex-row">
            {label}
            {dcntShow && (
              <span className="bg-primary text-white text-xs font-bold px-1 rounded ml-2">
                {props?.addDiscount + "% Off"}
              </span>
            )}
          </div>
          <div className="font-bold">
            CHF{" "}
            {props.addDiscount
              ? props?.offerPriceVal -
                props?.offerPriceVal * (props.addDiscount / 100)
              : props?.offerPriceVal}
          </div>
        </div>
        <div className="flex flex-1" />
        {props?.isPaymentDone && props?.projectStatus !== "Refunded" && (
          <div className="flex flex-row items-center">
            <CheckDone className="mr-2" fill="#32CD32" />
            <span className="text-base text-progress">{"Paid"}</span>
          </div>
        )}
        {props?.projectStatus === "Refunded" && (
          <div className="flex flex-row items-center">
            <CheckDone className="mr-2" fill="#BF2600" />
            <span className="text-delete">{"Refund"}</span>
          </div>
        )}
        {show && (
          <div
            className="text-primary my-auto ml-11 text-sm"
            onClick={(e) => setEditOpen(true)}
          >
            Edit
            {editOpen && (
              <OfferPriceComponent
                open={editOpen}
                setOpen={setEditOpen}
                loggedInUserId={props.loggedInUserId}
                onSubmit={props.onSubmit}
                data={props.data}
                budget={props.budget}
                offerPriceVal={props.offerPriceVal}
                title={"Update Price"}
              />
            )}
          </div>
        )}
      </div>
      {cShow && (
        <div className="text-primary my-auto ml-0 mt-5 text-sm ">
          <button
            className="bg-primary text-white w-full p-3 rounded-3xl font-semibold text-base"
            onClick={(e) => handleEditNewPriceOpen()}
          >
            {"Negotiate New Price"}
          </button>
          {editNewPriceOpen && (
            <BudgetPage
              open={editNewPriceOpen}
              setOpen={setEditNewPriceOpen}
              loggedInUserId={props.loggedInUserId}
              onSubmit={props.onSubmit}
              data={props.data}
              budget={props.budget}
              offerPriceVal={props.offerPriceVal}
              isNegotiate={true}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OfferPrice;
