import { createTheme } from "@mui/material/styles";

export const skinTheme = createTheme({
  palette: {
    primary: {
      main: "#C7A33F",
    },
    secondary: {
      main: "#C7A33F",
    },
    typography: {},
    // Comomn Styles
  },
});
