export const tabs = [
	{id : 1, name: 'DAY', value : 'day'},
	{id : 2, name: 'WEEK', value : 'week'},
	{id : 3, name: 'MONTH', value : 'month'},
	{id : 4, name: 'YEAR', value : 'year'},
	{id : 5, name: 'ALL', value : 'all'},
]

export const graphOptions = {
	responsive: true,
	plugins: {
	  legend: {
		display: false
	  },
	},
	scales: {
		yAxes: [{
			ticks: {
				beginAtZero:true
			}
		}]
	}
};

export const defaultGraphCongig = {
	borderColor: '#C7A33F',
	backgroundColor: 'rgba(255, 99, 132, 0.5)',
}