import Header from "components/Navbars/AuthNavbar";
import TopNavBar from "components/Navbars/TopNavBar";
import { userNavigations } from "layouts/ClientConstants";
import { useState } from "react";
// import SessionExpiry from "components/SessionExpiry";

const ThankyouView = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  const goHome = () => {
    props.history.replace({
      pathname: "/",
    });
    window.location.reload(true);
  };

  return (
    <>
      <div className="bg-white">
        {/* Header section */}
        {!props.loginData && (
          <header>
            <Header className="" />
          </header>
        )}
        {props.loginData && (
          <TopNavBar
            userNavigations={userNavigations}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            loginData={props.loginData}
            clientview={true}
          />
        )}
        <main className="overflow-hidden">
          <div className="flex flex-col items-center justify-items-center mx-2">
            <div className="aspect-w-3 aspect-h-2 overflow-hidden">
              <img
                src="/Thankyou.png"
                alt=""
                className="w-50 h-50 object-center object-cover"
              />
            </div>
            {!props?.loginData?.id && (
              <p className="text-center">Please verify your email address to send the proposal.</p>
            )}
            {props.loginData && (
              <button
                className="bg-primary text-white py-2 px-4 rounded-xl"
                onClick={() => goHome()}
              >
                {" "}
                Go to Home
              </button>
            )}
          </div>
          {/* <SessionExpiry sessionError={clientState.error} /> */}
        </main>
      </div>
    </>
  );
};

export default ThankyouView;
