import {
  STUDIO_ARTIST_REVIEWS_LOADING,
  GET_STUDIO_ARTIST_REVIEWS_SUCCESS,
  STUDIO_ARTIST_REVIEWS_ERROR,
  STUDIO_ARTIST_REVIEWS_AVG_LOADING,
  GET_STUDIO_ARTIST_REVIEWS_AVG_SUCCESS,
  STUDIO_ARTIST_REVIEWS_AVG_ERROR
} from "../actions/StudioReviewsAction";

const initialState = {
  loading: null,
  success: null,
  studioArtistReviews: null,
  studioArtistReviewsAvg: null,
  error: null,
};

const StudioReviewsReducer = function (state = initialState, action) {
  switch (action.type) {
    case STUDIO_ARTIST_REVIEWS_LOADING:
    case GET_STUDIO_ARTIST_REVIEWS_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        studioArtistReviews: action.payload,
        error: null,
      };
    }
    case STUDIO_ARTIST_REVIEWS_AVG_LOADING:
    case GET_STUDIO_ARTIST_REVIEWS_AVG_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        studioArtistReviewsAvg: action.payload,
        error: null,
      };
    }
    case STUDIO_ARTIST_REVIEWS_ERROR:
    case STUDIO_ARTIST_REVIEWS_AVG_ERROR: {
      return {
        loading: false,
        success: false,
        data: null,
        error: action.payload ? action.payload : "Unknown Error Occured",
      };
    }
    default: {
      return state;
    }
  }
};

export default StudioReviewsReducer;
