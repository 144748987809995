import React, { useState } from "react";
import { ReactComponent as AccountBalanceWalletIcon } from "../../../../assets/ic_budget.svg";
import { USER_ROLE_CLIENT } from "../../CONSTANTS";

import BudgetPage from "./BudgetPage";

const Budget = (props) => {
  let show = false;
  if (
    props?.role === USER_ROLE_CLIENT &&
    props?.projectStatus === "New Project"
  ) {
    show = true;
  }
  const [editOpen, setEditOpen] = useState(false);
  const handleEditOpen = () => setEditOpen(true);
  return (
    <div className="w-full mt-5 md:mt-0">
      <div className="flex items-center rounded-2xl border-2 border-dashed border-primary  py-3 flex grid grid-cols-6">
        <div className="my-auto justify-self-center">
          <AccountBalanceWalletIcon />
        </div>
        <div className="col-start-2 col-span-4 flex flex-row gap-4 px-0">
          <div className="flex flex-col">
            <div className="font-sans text-base font-light">Budget:</div>
            <div className="font-sans text-base font-bold">
              CHF {props?.data?.fromPrice} - {props?.data?.toPrice}
            </div>
          </div>
        </div>
        {show && (
          <div
            className="text-primary my-auto text-sm flex  px-4 flex justify-end"
            onClick={handleEditOpen}
          >
            Edit
            {editOpen && (
              <BudgetPage
                open={editOpen}
                setOpen={setEditOpen}
                loggedInUserId={props.loggedInUserId}
                onSubmit={props.onSubmit}
                data={props.data}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Budget;
