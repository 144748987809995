import * as React from "react";
import { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CustomInput from "components/CustomInput";
import ButtonSolid from "components/ButtonSolid";
import ButtonHollow from "components/ButtonHollow";
import { Formik } from "formik";
import { getProjectStatusByName } from "../../CONSTANTS";

export default function DeclineProject({
  open,
  setOpen,
  loggedInUserId,
  onSubmit,
}) {
  const handleClose = () => setOpen(false);

  const handleFormSubmit = (values) => {
    let obj = {
      comments: {
        comment: values.comment,
        loggedInUserId: loggedInUserId,
      },
      status: {
        statusId: getProjectStatusByName.Declined_Project,
        loggedInUserId: loggedInUserId,
      },
    };
    onSubmit(obj);
    setOpen(false);
  };

  return (
    <div>
      <Transition.Root show={open}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto px-2"
          onClose={handleClose}
        >
          <div className="flex items-center justify-center min-h-screen text-center w-full">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              className={"w-full"}
            >
              <div
                className="inline-flex align-top bg-white rounded-md text-left overflow-hidden shadow-xl transform transition-all 
                xl:max-w-6xl lg:max-w-4xl md:max-h-4/5 max-w-sm my-4 pb-8 w-full"
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-full">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
                      <Dialog.Title className="text-2xl text-red leading-6 font-semibold">
                        Decline Project
                      </Dialog.Title>
                      <Formik
                        initialValues={{ comment: "" }}
                        onSubmit={(values) => {
                          handleFormSubmit(values);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="mt-2">
                              <div className="mt-8">
                                <CustomInput
                                  label="Comment"
                                  type="text"
                                  name="comment"
                                  id="comment"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.comment}
                                  multiline
                                  rows={2}
                                />
                              </div>
                              <div className="flex flex-row gap-4 mt-8 w-full">
                                <ButtonSolid
                                  style={{
                                    borderRadius: 25,
                                    fontSize: 15,
                                    backgroundColor: "#C7A33F",
                                    flex: 1,
                                  }}
                                  type="submit"
                                >
                                  Send
                                </ButtonSolid>
                                <ButtonHollow
                                  style={{
                                    borderRadius: 25,
                                    fontSize: 15,
                                    color: "#C7A33F",
                                    borderColor: "#C7A33F",
                                    borderWidth: 1,
                                    flex: 1,
                                  }}
                                  type="reset"
                                  onClick={() => handleClose()}
                                >
                                  Cancel
                                </ButtonHollow>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
