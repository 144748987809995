import ArtistService from "services/ArtistService";

export const INVITE_STRING_ARTIST_LOADING = "INVITE_STRING_ARTIST_LOADING";
export const INVITE_STRING_ARTIST_SUCCESS = "INVITE_STRING_ARTIST_SUCCESS";
export const INVITE_STRING_ARTIST_ERROR = "INVITE_STRING_ARTIST_ERROR";

export const ARTIST_CURRENT_PROJECT_LOADING = "ARTIST_CURRENT_PROJECT_LOADING";
export const GET_ARTIST_CURRENT_PROJECT = "GET_ARTIST_CURRENT_PROJECT";
export const GET_ARTIST_CURRENT_PROJECT_ERROR = "GET_ARTIST_CURRENT_PROJECT_ERROR";

export const ARTIST_HISTORY_PROJECT_LOADING = "ARTIST_HISTORY_PROJECT_LOADING";
export const GET_ARTIST_HISTORY_PROJECT = "GET_ARTIST_HISTORY_PROJECT";
export const GET_ARTIST_HISTORY_PROJECT_ERROR = "GET_ARTIST_HISTORY_PROJECT_ERROR";

export const ARTIST_PROJECTS_LOADING = "ARTIST_PROJECTS_LOADING";
export const GET_ARTIST_PROJECTS = "GET_ARTIST_PROJECTS";
export const GET_ARTIST_PROJECTS_ERROR = "GET_ARTIST_PROJECTS_ERROR";

//Artist module
export const getInvitation = (verifyString) => async (dispatch) => {
  dispatch({
    type: INVITE_STRING_ARTIST_LOADING,
  });
  try {
    const res = await ArtistService.getInivitations(verifyString);

    return dispatch({
      type: INVITE_STRING_ARTIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: INVITE_STRING_ARTIST_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const getArtistCurrentProjects = (id) => async (dispatch) => {
  dispatch({
      type: ARTIST_CURRENT_PROJECT_LOADING,
  });
  try {
      const res = await ArtistService.getArtistCurrentProjects(id);     
      return dispatch({
          type: GET_ARTIST_CURRENT_PROJECT,
          payload: res.data,
      });
  } catch (err) {
      return dispatch({
          type: GET_ARTIST_CURRENT_PROJECT_ERROR,
          payload: err.response,
      });
  }
};


export const getArtistProjectsHistory = (id) => async (dispatch) => {
  dispatch({
      type: ARTIST_HISTORY_PROJECT_LOADING,
  });
  try {
      const res = await ArtistService.getArtistProjectsHistory(id);     
      return dispatch({
          type: GET_ARTIST_HISTORY_PROJECT,
          payload: res.data,
      });
  } catch (err) {
      return dispatch({
          type: GET_ARTIST_HISTORY_PROJECT_ERROR,
          payload: err.response,
      });
  }
};

export const getAdminArtistProjects = (id) => async (dispatch) => {
  dispatch({
      type: ARTIST_PROJECTS_LOADING,
  });
  try {
      const res = await ArtistService.getAdminArtistProjects(id);     
      return dispatch({
          type: GET_ARTIST_PROJECTS,
          payload: res.data,
      });
  } catch (err) {
      return dispatch({
          type: GET_ARTIST_PROJECTS_ERROR,
          payload: err.response,
      });
  }
};