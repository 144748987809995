import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ImageCarouselSlider from "components/ImageCarouselSlider";


export default function ShowPicturePreview(props) {
  const [imageComment, setImageComment] = useState(
    props?.comments[0]
  ); 
  
  const handleClose = () => props.setOpen(false);
  return (
    <div className="h-screen w-screen">
      <Transition.Root show={props.open} className="h-full w-full">
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto h-full w-full"
          onClose={handleClose}
        >
          <div className="items-start justify-center h-full w-full px-2 py-4 flex">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-top sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              className={"h-full"}
            >
              <div className="inline-block bg-white rounded-xl p-4 text-left shadow-xl transform transition-all m-0 flex border border-primary sm:max-h-4/5 sm:max-w-xs md:max-w-2xl lg:max-w-2xl xl:max-w-4xl">
  
                <div className="h-full w-full">
                   <div className="flex">
                      <div className="flex justify-center align-center">
                        <ImageCarouselSlider
                          images={props.pictures}
                          setImageComment={setImageComment}
                          artistPortfolioId={""}
                          openLink={false}
                          comments={props?.comments}
                        />
                      </div>
                    </div> 
                      <p className="p-4 border border-primaryLight rounded-md mt-2">
                        {imageComment}
                      </p>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
