/* eslint-disable react-hooks/exhaustive-deps */
import { projectTabs } from "layouts/AdminConstants";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getCurrentProjects,
  getHistoryProjects,
  getNewProjects,
} from "redux/actions/ProjectListAction";
import { getStyles } from "redux/actions/StyleAction";
import ProjectsView from "./ProjectsView";

const ProjectsContainer = (props) => {
  const TabState = useSelector((state) => state.TabState);
  const [selectedTab, setSelectedTab] = useState(TabState.HICHEM_PROJECT_TAB);
  const dispatch = useDispatch();
  const projectList = useSelector((state) => state.projectList);
  const stylesList = useSelector((state) => state.style);

  useEffect(() => {
    dispatch(getNewProjects(props.loginData.id));
    dispatch(getCurrentProjects(props.loginData.id));
    dispatch(getHistoryProjects(props.loginData.id));
  }, []);

  useEffect(() => {
    if (selectedTab === projectTabs["New Projects"]) {
      dispatch(getNewProjects(props.loginData.id));
    } else if (selectedTab === projectTabs["Current Projects"]) {
      dispatch(getCurrentProjects(props.loginData.id));
    } else if (selectedTab === projectTabs["Project History"]) {
      dispatch(getHistoryProjects(props.loginData.id));
    } else if (selectedTab === projectTabs["Market Place"]) {
      // dispatch(getHistoryProjects(props.loginData.id));
    }
  }, [selectedTab, dispatch]);

  useEffect(() => {
    dispatch(getStyles());
  }, [dispatch]);

  const projectOnClick = (projectId) => {
    props.history.push({
      pathname: "/admin/project-details/" + projectId,
    });
  };

  return (
    <ProjectsView
      loginData={props.loginData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      projectList={projectList}
      stylesData={stylesList.stylesData}
      projectOnClick={projectOnClick}
      projectError={
        projectList.errorNew ||
        projectList.errorCurrent ||
        projectList.errorHistory
      }
    />
  );
};
export default ProjectsContainer;
