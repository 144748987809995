import { Link } from "react-router-dom";

export default function AuthNavBar(props) {
  return (
    <div className="mt-4 w-full flex-1 grid grid-cols-3">
      <div className={`flex ${props.fromCreateProject ? 'col-start-1 mb-4' : 'col-start-2'} justify-${props.fromCreateProject ? 'center' : 'center'}`}>
        <div className="">
          <Link
            to="/"
            className="text-secondary hover:text-primary no-underline text-base font-sans font-normal"
          >
            <object
              type="image/svg+xml"
              data="/2ndSkin.svg"
              className={`pointer-events-none h-${props.fromCreateProject ? '16' : '20'}`}
            >
              2ndSkin
            </object>
          </Link>
        </div>
      </div>
      <div className="flex justify-end items-center col-start-3">
        <div className="flex items-center">
          <Link
            to="/auth/login"
            className="text-secondary hover:text-primary no-underline text-base font-sans font-normal flex items-center"
          >
            <object
              type="image/svg+xml"
              data="/ic_login.svg"
              className={`pointer-events-none h-${props.fromCreateProject ? '16' : '20'}`}
            >
              2ndSkin
            </object>
          </Link>
        </div>
      </div>
      {/* <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
          <Popover.Group
            as="nav"
            className="hidden md:flex space-x-10 items-center"
          >
            <Link
              to="/"
              className="text-secondary hover:text-primary no-underline text-base font-sans font-normal"
            >
              {t("landing.home")}
            </Link>
            <Link
              to={SUPPORT}
              className="text-secondary hover:text-primary no-underline text-base font-sans font-normal"
            >
              {t("landing.support")}
            </Link>
            <Link
              // to={ABOUTUS}
              className="text-secondary hover:text-primary no-underline text-base font-sans font-normal"
            >
              {t("landing.aboutUs")}
            </Link>
            <Link
              to={FAQ}
              className="text-secondary hover:text-primary no-underline text-base font-sans font-normal pr-5"
            >
              {t("landing.faq")}
            </Link>
            <div
              className="text-2xl font-medium text-gray-500 hover:text-gray-900 hidden"
              onClick={() => {
                i18n.changeLanguage("de");
              }}
            >
              <ReactCountryFlag countryCode="DE" svg />
            </div>
            <div
              className="text-2xl font-medium text-gray-500 hover:text-gray-900 px-5 hidden"
              style={{ marginLeft: "-9px" }}
            >
              <ReactCountryFlag
                countryCode="US"
                svg
                onClick={() => {
                  i18n.changeLanguage("en");
                }}
              />
            </div>
          </Popover.Group>
          
          <div hidden={props.hideRegister}>
            <Link
              to="/auth/register"
              className="hidden md:flex inline-flex items-center px-4 py-2 border border-transparent rounded-xl shadow-sm text-white bg-primary no-underline text-base font-sans font-normal"
            >
              {t("landing.register")}
            </Link>
          </div>
        </div> */}
    </div>
  );
}
