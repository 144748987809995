import React, { useState } from "react";
import TopNavBar from "components/Navbars/TopNavBar";
import { userNavigations } from "layouts/ClientConstants";

import CurrentProjects from "components/CurrentProjects";
import ProjectsHistory from "components/ProjectsHistory";

import { CLIENT_PROJECT_TABS as TABS } from "layouts/AdminConstants";
// import SessionExpiry from "components/SessionExpiry";

const ClientHomeView = ({
  selectedTab,
  setSelectedTab,
  clientState,
  loginData,
  clientCurrentProjects,
  clientHistoryProjects,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  let grid;

  if (selectedTab === TABS.CURRENTPROJECTS.id) {
    grid = (
      <CurrentProjects
        setSelectedTab={setSelectedTab}
        clientById={clientState.client}
        clientsProjects={clientCurrentProjects}
        projectsHistory={clientHistoryProjects}
        loginData={loginData}
        selectedTab={selectedTab}
      />
    );
  } else if (selectedTab === TABS.PROJECTHISTORY.id) {
    grid = (
      <ProjectsHistory
        setSelectedTab={setSelectedTab}
        clientById={clientState.client}
        projectsHistory={clientHistoryProjects}
        clientsProjects={clientCurrentProjects}
        loginData={loginData}
        selectedTab={selectedTab}
      />
    );
  }

  return (
    <div className="h-full flex">
      <div className="flex-1 flex flex-col overflow-hidden h-full bg-white">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={loginData}
          clientview={true}
        />
        <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
          <div className="flex-1 flex items-stretch overflow-hidden">
            <main className="flex-1 overflow-y-auto">
              <div className="pt-2 px-4 md:px-12">
                <div className="my-5 lg:my-10">{grid}</div>
              </div>
            </main>
            {/* <SessionExpiry sessionError={clientState.error} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientHomeView;
