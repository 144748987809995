import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  sendInvite,
  deleteInvite,
} from "../../../redux/actions/AdminArtistAction";
import { Alert } from "@mui/material";
import { ReactComponent as SearchIcon } from "../../../assets/ic_search.svg";
import Button from "../../../components/UI/Button";
import Pagination from "../../hichem/accounting/components/Pagination";

const totalCount = 11;
const limit = 10;

const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

const InvitedList = ({ artists }) => {
  const dispatch = useDispatch();
  const [msg, setMsg] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [projectfilteredData, setProjectfilteredData] = useState([]);
  const [currentTableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationRange, setRange] = useState([]);
  const totalPageCount = Math.ceil(
    projectfilteredData?.length > 0 ? projectfilteredData?.length / limit : 1
  );

  useEffect(() => {
    const start = (currentPage - 1) * limit;
    const end = start + limit;
    setTableData(projectfilteredData?.slice(start, end));
  }, [projectfilteredData, currentPage]);

  useEffect(() => {
    if (totalPageCount <= 5) {
      setRange(range(1, totalPageCount));
    } else if (currentPage === 1 && totalPageCount > 5) {
      setRange(range(1, 5));
    } else if (currentPage !== 1 && (currentPage - 1) % 5 === 0) {
      if (totalPageCount < currentPage + 4) {
        setRange(range(currentPage, totalPageCount));
      } else {
        setRange(range(currentPage, currentPage + 4));
      }
    }
  }, [currentPage, totalPageCount]);

  useEffect(() => {
    if (searchText && searchText !== "") {
      let finalData = artists?.invitation?.filter((rec) => {
        return (
          rec.name &&
          rec.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        );
      });

      setProjectfilteredData(finalData);
    } else {
      setProjectfilteredData(artists?.invitation);
    }
  }, [artists?.invitation, searchText]);

  const handleResendInvitation = (id, name, email, comment) => {
    const data = {
      name,
      email,
      comment,
      id,
    };
    dispatch(sendInvite(data));
    setMsg(<Alert>Mail Resent.</Alert>);
    setTimeout(() => {
      setMsg(null);
    }, 3000);
  };

  const handleCancelInvitation = (id) => {
    dispatch(deleteInvite(id));
    setMsg(<Alert>Invitation Cancelled.</Alert>);
    setTimeout(() => {
      setMsg(null);
    }, 3000);
  };

  const calculateDuration = (date_future) => {
    var dateExpiry = new Date(date_future);

    var delta = Math.abs(dateExpiry.getTime() - new Date().getTime()) / 1000;

    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    return days + " Days " + hours + " Hours";
  };

  const showTable = () => {
    return (
      <div className="flex w-full flex flex-col col-start-1 col-span-12 md:mt-4">
        <table className="w-full bg-white" id="more-tables">
          <thead>
            <tr className="text-tertiary text-left text-sm font-sans font-light border-b border-tableLine">
              <th className="pb-4">Name</th>
              <th className="pb-4 md:pl-2">Email</th>
              <th className="pb-4 md:pl-2">Expiry</th>
              <th className="pb-4"></th>
              <th className="pb-4"></th>
            </tr>
          </thead>
          <tbody>
            {/* {artists?.invitation?.map((row) => ( */}
            {currentTableData?.map((row) => (
              <tr
                key={row.id}
                className="border-b border-tableLine font-sans mt-2"
              >
                <td data-title="Name">{row?.name ? row?.name : "N/A"}</td>
                <td data-title="Email" className="md:pl-2">
                  {row?.email}
                </td>
                <td data-title="Expiry" className="md:pl-2 md:py-2">
                  {row?.expiry ? calculateDuration(row?.expiry) : "N/A"}
                </td>
                {!row?.expiry && (
                  <td data-title=" ">
                    <Button
                      onClick={() =>
                        handleResendInvitation(
                          row?.id,
                          row?.name,
                          row?.email,
                          row?.comment
                        )
                      }
                      label="Resend"
                      textsize="base"
                      border="none"
                      background="white"
                      color="primary"
                      shadow="shadow-none"
                      hoverbg="white"
                      hovertext="primary"
                      font={"normal"}
                      place={" "}
                      px={"1"}
                      paddingv={"0"}
                    />
                  </td>
                )}
                {/* {row?.expiry && ( */}
                <td data-title=" ">
                  <Button
                    onClick={() => handleCancelInvitation(row?.id)}
                    label="Cancel"
                    textsize="base"
                    border="none"
                    background="white"
                    color="cancelInvite"
                    shadow="shadow-none"
                    hoverbg="white"
                    hovertext="primary"
                    font={"normal"}
                    place={" "}
                    px={"1"}
                    paddingv={"0"}
                  />
                </td>
                {/* )} */}
              </tr>
            ))}
          </tbody>
        </table>
        {
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalCount={totalCount}
            pageSize={limit}
            paginationRange={paginationRange}
            totalPageCount={totalPageCount}
          />
        }
      </div>
    );
  };

  return (
    <div className="h-full flex flex-col grid grid-cols-12 mt-10">
      <div className="col-start-1 col-span-12 md:col-span-3">
        <div className="pl-3 rounded-xl py-2 mb-0 bg-tertiary bg-opacity-10 flex flex-row items-center">
          <SearchIcon className="h-3 w-3" />
          <input
            className="text-black text-sm mr-2 pl-2 pr-35 bg-opacity-0 flex bg-tertiary outline-none font-normal"
            placeholder="Search"
            onChange={(e) => setSearchText(e.target.value)}
          ></input>
        </div>
      </div>
      {showTable()}
      <div className="col-start-1 col-span-12 md:col-span-3">{msg}</div>
    </div>
  );
};

export default InvitedList;
