import {
  CLIENT_LOADING,
  GET_CLIENT_SUCCESS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_PROJECTS_HISTORY,
  GET_CLIENT_ERROR,
  CLIENT_PROJECTS_HISTORY_LOADING,
  CLIENT_PROJECTS_HISTORY_ERROR,
  CLIENT_PROJECTS_CURRENT_LOADING,
  CLIENT_PROJECTS_CURRENT_SUCCESS,
  CLIENT_PROJECTS_CURRENT_ERROR
} from "../actions/ClientAction";

const initialState = {
  loading: false,
  success: false,
  clients: null,
  client: null,
  clientsProjects: null,
  clientsProjectsHistory: null,
  error: null,
};

const ClientReducer = function (state = initialState, action) {
  switch (action.type) {
    case CLIENT_PROJECTS_HISTORY_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        clients: null,
        client: null,
        clientsProjectsHistory: null,
        error: null,
      };
    }
    case CLIENT_PROJECTS_CURRENT_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        clients: null,
        client: null,
        clientsProjects: null,
        error: null,
      };
    }
    case CLIENT_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        clients: null,
        client: null,
        error: null,
      };
    }
    case GET_CLIENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        clients: action.payload,
        error: null,
      };
    }
    case GET_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        client: action.payload,
        error: null,
      };
    }
    case CLIENT_PROJECTS_CURRENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        clientsProjects: action.payload,
        error: null,
      };
    }
    case GET_CLIENTS_PROJECTS_HISTORY: {
      return {
        ...state,
        loading: false,
        success: true,
        clientsProjectsHistory: action.payload,
        error: null,
      };
    }
    case GET_CLIENT_ERROR:
    case CLIENT_PROJECTS_HISTORY_ERROR: 
    case CLIENT_PROJECTS_CURRENT_ERROR: {
      return {
        loading: false,
        success: false,
        clients: null,
        client: null,
        error: action.payload
          ? action.payload
          : "Error Occured",
      };
    }
    default: {
      return state;
    }
  }
};

export default ClientReducer;
