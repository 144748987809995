import axios from "../http-config";

class FaqService {
  getAll() {
    return axios({
      method: "GET",
      url: `/faq`,
    });
  }

  get(id) {
    return axios({
      method: "GET",
      url: `/faq/${id}`,
    });
  }

  create(data) {
    return axios({
      method: "POST",
      url: `/faq`,
      data,
    });
  }

  update(id, data) {
    return axios({
      method: "PUT",
      url: `/faq/${id}`,
      data,
    });
  }

  patch(id, data) {
    return axios({
      method: "PATCH",
      url: `/faq/${id}`,
      data,
    });
  }
}

export default new FaqService();
