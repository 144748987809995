import {
  SET_USER_DATA,
  ADD_USER,
  REMOVE_USER_DATA,
  USER_CREATE_ERROR,
  LOADING,
  MANAGER_LOADING,
  GET_MANAGER_SUCCESS,
  GET_MANAGER_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_PROFILE_PIC_LOADING,
  UPDATE_PROFILE_PIC_SUCCESS,
  UPDATE_PROFILE_PIC_ERROR,
  USER_BY_EMAIL_LOADING,
  GET_USER_BY_EMAIL_ERROR,
  GET_USER_BY_EMAIL_SUCCESS,
  USERS_BY_ROLE_LOADING,
GET_USERS_BY_ROLE_ERROR,
GET_USERS_BY_ROLE_SUCCESS
} from "../actions/UserActions";

const initialState = {
  success: false,
  loading: false,
  error: null,
  data: null,
  userByEmail: null,
  usersByRole: null
};

const UserReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
        data: null,
      };
    }
    case SET_USER_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }
    case ADD_USER: {
      return {
        ...state,
        success: true,
        data: action.payload,
        loading: false,
        error: null,
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...state,
        success: true,
        loading: false,
        data: null,
      };
    }
    case USER_CREATE_ERROR: {
      return {
        success: false,
        loading: false,
        data: null,
        error: action.payload ? action.payload : "Unknown Error Occured",
      };
    }
    case UPDATE_USER_LOADING:
    case UPDATE_PROFILE_PIC_LOADING:
    case MANAGER_LOADING: 
    case USER_BY_EMAIL_LOADING:
    case USERS_BY_ROLE_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        data: null,
        error: null,
      };
    }
    case UPDATE_USER_SUCCESS:
    case UPDATE_PROFILE_PIC_SUCCESS:
    case GET_MANAGER_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: null,
      };
    }
    case GET_USER_BY_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        userByEmail: action.payload,
        data: null,
        error: null,
      };
    }
    case GET_USERS_BY_ROLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        userByEmail: null,
        usersByRole: action.payload,
        data: null,
        error: null,
      };
    }
    case UPDATE_USER_ERROR:
    case UPDATE_PROFILE_PIC_ERROR:
    case GET_MANAGER_ERROR: 
    case GET_USER_BY_EMAIL_ERROR: 
    case GET_USERS_BY_ROLE_ERROR: {
      return {
        ...state,
        loading: false,
        success: false,
        data: null,
        error: action.payload ? action.payload : "Unknown Error Occured",
      };
    }

    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: null,
      };
    }
    case RESET_PASSWORD_ERROR: {
      return {
        loading: false,
        success: false,
        data: null,
        error: action.payload ? action.payload : "Unknown Error Occured",
      };
    }

    default: {
      return state;
    }
  }
};

export default UserReducer;
