import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";

// views
import Profile from "views/profile/ProfileContainer.js";
import ProjectCreateContainer from "views/project/ProjectCreate/ProjectCreateContainer";
import ThankyouView from "views/project/ProjectCreate/ThankyouView";
import SignOut from "views/SignOut";
import ProjectDetailsContainer from "views/project/projectDetails/ProjectDetailsContainer";
import ClientHomeContainer from "views/client/ClientHomeContainer";

import PaymentContainer from "views/payrexxPayment/PaymentContainer";
import PaymentSuccessView from "views/payrexxPayment/PaymentSuccessView";
import PaymentFailureView from "views/payrexxPayment/PaymentFailureView";
import PaymentAbortView from "views/payrexxPayment/PaymentAbortView";
import PaymentIframeView from "views/payrexxPayment/PaymentIframeView";

import { ArtistProfileContainer } from "views/client/ArtistProfile/ArtistProfileContainer";
import NotificationContainer from "views/notification/NotificationContainer";

export default function ClientLayout(props) {
  const [loginData, setloginData] = useState(props.loginData)
  return (
    <>
      <main>
        <Switch>
          {/* <Route path="/" exact render={(props) => <Profile {...props} loginData={loginData}/>} /> */}
          <Route path="/" exact render={(props) => <ClientHomeContainer {...props} loginData={loginData}/>} />

          <Route path="/client/project" exact render={(props) => <ProjectCreateContainer {...props} loginData={loginData}/>} />
          <Route path="/client/thankyou" exact render={(props) => <ThankyouView {...props} loginData={loginData}/>} />
 
          <Route path="/client/profile" exact render={(props) => <Profile {...props} loginData={loginData}/>} />
          <Route path="/client/signout" component={SignOut} />
          <Route path="/client/project-details/:id" exact render={(props) => <ProjectDetailsContainer {...props} loginData={loginData}/>}/>
          <Route path="/client/payment/:id" exact render={(props) => <PaymentContainer {...props} loginData={loginData}/>} />
          <Route path="/client/payment-success/:id" exact render={(props) => <PaymentSuccessView {...props} loginData={loginData}/>} />
          <Route path="/client/payment-failure" exact render={(props) => <PaymentFailureView {...props} loginData={loginData}/>} />
          <Route path="/client/payment-abort" exact render={(props) => <PaymentAbortView {...props} loginData={loginData}/>} />
          <Route path="/client/payment-options" exact render={(props) => <PaymentIframeView {...props} loginData={loginData}/>} />
          <Route path="/client/artist-profile/:id" exact render={(props) => <ArtistProfileContainer {...props} loginData={loginData}/>} />
          <Route path="/client/notification" exact render={(props) => <NotificationContainer {...props} loginData={loginData}/>}/>
        
        </Switch>
      </main>
    </>
  );
}
