import * as React from "react";
import { useState } from "react";
import SideNavBar from "components/Navbars/SideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";
import {
  navigations,
  userNavigations,
  USER_ROLE_MANGER_ID,
  USER_TYPE_CLIENT,
  USER_TYPE_ARTIST,
} from "layouts/AdminConstants";
import {
  USER_ROLE_BOSS,
  USER_ROLE_ARTIST,
  USER_ROLE_ADMIN,
  USER_ROLE_MANAGER,
} from "../../layouts/ArtistConstants";
import { SERVER_URL, BOSS_USER_NAME, BOSS_USER_ID } from "utils/constants";
import moment from "moment";

const NotificationView = ({ data, loginData, onMarkRead, role, onMarkRowRead }) => {

  let clientview = false;
  let redirectUrl = '';
  if (role === USER_ROLE_ARTIST) {
    userNavigations[0].href = "/artist/profile";
    userNavigations[1].href = "/artist/signout";
    redirectUrl = "/artist/";
  } else if (
    role === USER_ROLE_BOSS ||
    role === USER_ROLE_ADMIN ||
    role === USER_ROLE_MANAGER
  ) {
    userNavigations[0].href = "/admin/profile";
    userNavigations[1].href = "/admin/signout";
    redirectUrl = "/admin/";
  } else {
    userNavigations[0].href = "/client/profile";
    userNavigations[1].href = "/client/signout";
    redirectUrl = "/client/";
  }
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  const onClickMarkRead = () => {
    onMarkRead();
  };

  const rowOnClick = (url, id) => {    
      onMarkRowRead(id, redirectUrl + url);      
  };

  return (
    <div className="h-screen flex">
      <div className="flex-1 flex flex-col overflow-hidden h-full bg-white">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={loginData}
          clientview={clientview}
        />

        <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
          {loginData.userTypeId !== USER_TYPE_CLIENT &&
            loginData.userTypeId !== USER_TYPE_ARTIST && (
              <SideNavBar
                navigations={navigations(
                  loginData.userRoleId !== USER_ROLE_MANGER_ID
                )}
                mobileMenuOpen={mobileMenuOpen}
                handleMenu={handleMenuOpen}
                bg={"white"}
                loginID={loginData.id}
              />
            )}
          {/*  */}
          <div className="flex-1 flex items-stretch overflow-hidden bg-primaryLightest h-full">
            <main className="flex-1 overflow-y-auto">
              <div className="pt-2 px-4 md:px-12">
                <div className="h-full flex flex-1 flex-col mb-8">
                  <div className="text-3.5xl font-bold font-sans text-primary mb-2">
                    Notifications
                  </div>
                  <div className="flex flex-col">
                    <div className="overflow-x-auto">
                      <div className="py-2 align-middle inline-block min-w-full">
                        <div className="overflow-hidden">
                          <table className="min-w-full md:table-fixed flex-col md:flex-row flex-1">
                            <thead className="flex flex-1 mb-4">
                              <tr className="lg:flex lg:flex-1">
                                <th
                                  scope="col"
                                  className="font-sans text-tiny font-normal text-primary lg:flex lg:flex-1 text-left whitespace-nowrap"
                                >
                                  {data && data.length > 0 && (
                                    <div onClick={() => onClickMarkRead()}>
                                      Mark all as read
                                    </div>
                                  )}
                                </th>
                                <th
                                  scope="col"
                                  className="font-sans text-tiny font-normal text-tertiary lg:flex lg:flex-1 text-left px-6"
                                ></th>
                                <th
                                  scope="col"
                                  className="font-sans text-tiny font-normal text-tertiary lg:flex lg:flex-1 text-left px-6 whitespace-nowrap"
                                ></th>
                              </tr>
                            </thead>
                            <tbody className="">
                              {data && data?.map((row) => (
                                <tr
                                  key={row.id}
                                  className="flex rounded-xl bg-white my-2 p-2"
                                  onClick={() => rowOnClick(row?.redirectUrl, row.id)}
                                  style={row?.isRead ? {} : { backgroundColor: "#FFF3E0" }}
                                >
                                  <td className="flex whitespace-nowrap pr-6 py-1 ">
                                    {" "}
                                    <div className="flex flex-row items-top" >
                                      <img
                                        className="mr-4 h-12 w-12 bg-gray-300 rounded-full flex-shrink-0 border border-tertiary object-cover object-center"
                                        src={
                                          row?.createdBy?.photoUrl
                                            ? SERVER_URL + row?.createdBy?.photoUrl
                                            : SERVER_URL +
                                              "uploads/default-images/DefaultProfile.jpeg"
                                        }
                                        alt=""
                                      />
                                      <div className="flex flex-col font-semibold font-sans">
                                      {(row?.createdBy?.id === BOSS_USER_ID) ? <span> {BOSS_USER_NAME} </span> : 
                                        <span>{row?.createdBy?.firstName}{ " "}
                                        {row?.createdBy?.lastName}{" "}</span>
                                        }
                                        <span className="text-tertiary lg:pl-1 font-sans">
                                          {row?.subject}
                                        </span>
                                        <div className="lg:flex font-sans text-tiny font-normal text-tertiary lg:hidden">
                                            {moment(row?.createdAt).from(
                                              moment(new Date())
                                            )}
                                          </div>
                                      </div>
                                    </div>
                                  </td>
                                  <div className="hidden flex flex-1"></div>
                                  <td className="hidden lg:flex lg:flex-1 px-6 py-2 text-tertiary justify-end text-tiny">
                                    {moment(row?.createdAt).from(
                                      moment(new Date())
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationView;
