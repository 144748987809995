import React, { useEffect } from "react";
import ClientView from "./ClientView";
import { useSelector, useDispatch } from "react-redux";
import { getClientList } from "redux/actions/ClientAction";

const ClientContainer = (props) => {
  const clientState = useSelector((state) => state.client);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientList());
  }, [dispatch]);

  const onClientSelect = (id) => {
    props.history.push("/admin/client/project/" + id);
  };

  return (
    <div>
      <ClientView
        clientState={clientState}
        loginData={props.loginData}
        onClientSelect={onClientSelect}
      />
    </div>
  );
};

export default ClientContainer;
