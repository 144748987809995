import { ReactComponent as home } from "../assets/SideBar/ico_side_home.svg";
import { ReactComponent as account } from "../assets/SideBar/ico_side_account.svg";
import { ReactComponent as artist } from "../assets/SideBar/ico_side_artist.svg";
import { ReactComponent as client } from "../assets/SideBar/ico_side_client.svg";
import { ReactComponent as news } from "../assets/SideBar/ico_side_newsletter.svg";
import { ReactComponent as project } from "../assets/SideBar/ico_side_project.svg";
import { ReactComponent as support } from "../assets/SideBar/ico_side_support.svg";
import { ReactComponent as chat } from "../assets/SideBar/ico_side_chat.svg";
import { ReactComponent as withdrawn } from "../assets/SideBar/ico_side_withdrawn.svg";
import { ReactComponent as rating } from "../assets/SideBar/ico_side_rating.svg";
import { ReactComponent as faq } from "../assets/SideBar/ico_side_faq.svg";

export const USER_TYPE_MANAGER = 3;

export const navigations = (isAdmin) => {
  return clientNavigations
}

export const clientNavigations = [
  { id: "1", name: "Home", href: "/", icon: home, current: true },
  { id: "2", name: "Accounting", href: "#", icon: account, current: false },
  {
    id: "3",
    name: "Artists",
    href: "/admin/artist",
    icon: artist,
    current: false,
  },
  { id: "4", name: "Clients", href: "#", icon: client, current: false },
  { id: "5", name: "Newsletter", href: "#", icon: news, current: false },
  { id: "6", name: "Projects", href: "#", icon: project, current: false },
  { id: "7", name: "Support", href: "#", icon: support, current: false },
  { id: "8", name: "Chat", href: "#", icon: chat, current: false },
  { id: "9", name: "Withdrawn", href: "#", icon: withdrawn, current: false },
  { id: "10", name: "Rating", href: "#", icon: rating, current: false },
  { id: "11", name: "FAQ", href: "#", icon: faq, current: false },
];

export const userNavigations = [
  { id: "1", name: "Your profile", href: "/client/profile" },
  { id: "2", name: "Sign out", href: "/client/signout" },
];

export const tabsNav = [
  { id: "1", name: "Current Projects", href: "#", current: true },
  { id: "2", name: "Project History", href: "#", current: false },
];

export const CLIENT_PROJECT_TABS = Object.freeze({
  CURRENTPROJECTS: { id: "1", name: "Current Projects" },
  PROJECTHISTORY: { id: "2", name: "Project History" },
});

export const getUserRole = (roleId) => {
  switch (roleId) {
    case 1:
      return "Admin";
    case 2:
      return "Boss";
    case 3:
      return "Artist";
    case 4:
      return "Client";
    case 5:
      return "Manager";
    default:
      return "";
  }
};

export const VIEWS = Object.freeze({
  CLIENT_PEOJECT_DETAILS: { id: 5, name: "PreviewArtistPortfolio" },
});


export const TABS = Object.freeze({
  PORTFOLIO: { id: 1, name: 'Portfolio' },
  REVIEWS: { id: 2, name: 'Reviews' }
});
