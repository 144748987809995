import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
// import { Route, Routes, Switch } from "react-router-dom";
import {
  ROOT,
  FAQ,
  AUTH,
  ABOUTUS,
  SUPPORT,
  MAKE_TATOO,
  MAKE_TATOO_THANK,
  TERMS,
} from "./CONSTANTS";
import NotFound from "./NotFound";
import Faq from "../pages/Faq";

import Admin from "layouts/Admin.js";
import Artist from "layouts/Artist";
import Client from "layouts/Client";
import Studio from "layouts/Studio";

import Auth from "layouts/Auth.js";
import Landing from "views/landing/Landing.js";
import { useDispatch, useSelector } from "react-redux";
import { getLoginStatus } from "redux/actions/LoginActions";
import FaqContainer from "views/landing/faq/FaqContainer";
import AboutUsContainer from "views/aboutUs/AboutUsContainer";
import SupportContainer from "views/landing/support/SupportContainer";
import ProjectCreateContainer from "views/project/ProjectCreate/ProjectCreateContainer";
import ThankyouView from "views/project/ProjectCreate/ThankyouView";
import TermsOfServiceContainer from "views/TermsOfService/TermsOfServiceContainer";
import { getUserType } from "utils/constants";

export const RouterConfig = () => {
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLoginStatus());
  }, [dispatch]);

  return (
    <div>
      {/* <Routes> */}
      <Switch>
        <Route path={AUTH} component={Auth} />
        <Route
          path={FAQ}
          exact
          render={(props) => <FaqContainer {...props} />}
        />
        <Route
          path={ABOUTUS}
          exact
          render={(props) => <AboutUsContainer {...props} />}
        />
        <Route
          path={SUPPORT}
          exact
          render={(props) => <SupportContainer {...props} />}
        />
        <Route
          path={MAKE_TATOO}
          exact
          render={(props) => <ProjectCreateContainer {...props} />}
        />
        <Route
          path={MAKE_TATOO_THANK}
          exact
          render={(props) => <ThankyouView {...props} />}
        />
        <Route
          path={TERMS}
          exact
          render={(props) => <TermsOfServiceContainer {...props} />}
        />
        <Route
          path={ROOT}
          render={(props) =>
            login.loginData != null &&
            getUserType(login.loginData.userTypeId) == "Hichem" ? (
              <Admin {...props} loginData={login.loginData} />
            ) : login.loginData != null &&
              getUserType(login.loginData.userTypeId) == "Artist" ? (
              <Artist {...props} loginData={login.loginData} />
            ) : login.loginData != null &&
              getUserType(login.loginData.userTypeId) == "Client" ? (
              <Client {...props} loginData={login.loginData} />
            ) : login.loginData != null &&
              getUserType(login.loginData.userTypeId) == "Studio" ? (
              <Studio {...props} loginData={login.loginData} />
            ) : (
              <Landing />
            )
          }
        />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
      {/* </Routes> */}
    </div>
  );
};
