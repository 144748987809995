import React from 'react'

const Table = (props) => {
    return (
        <div className="rounded-lg shadow-lg bg-white pb-6 pt-8 px-10">
        <div className="text-black font-bold mb-6">Payout History</div>
        <table className="table-fixed w-full">
            <thead>
                <tr className='text-gray text-left mb-6'>
                    <th>Project</th>
                    <th>Amount</th>
                    <th>Payout Method</th>
                    <th>Date Processed</th>
                </tr>
            </thead>
            <tbody>
            {
                props?.tableData?.map(item => (
                    <tr key={item.id} className='border-b border-gray'>
                        <td className='py-4'>{item?.project}</td>
                        <td className='py-4'>{item?.amount}</td>
                        <td className='py-4'>{item?.payoutMethod}</td>
                        <td className='py-4'>{item?.dateProcessed}</td>
                    </tr>
                ))
            }
            </tbody>
        </table>    
        </div>
    )
}

export default Table