import * as React from "react";
import { useState } from "react";
import SideNavBar from "components/Navbars/SideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";

import ClientList from "./components/ClientList";
import { navigations, userNavigations, USER_ROLE_MANGER_ID } from "layouts/AdminConstants";

const ClientView = ({ clientState, loginData, onClientSelect }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  return (
    <div className="h-full flex">
      <div className="flex-1 flex flex-col overflow-hidden h-full bg-white">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={loginData}
        />

        <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
          <SideNavBar
            navigations={navigations(loginData.userRoleId !== USER_ROLE_MANGER_ID)}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            bg={"bgg"}
            loginID={loginData.id}
          />
          <div className="flex-1 flex items-stretch overflow-hidden">
            <main className="flex-1 overflow-y-auto">
              <div className="pt-2 px-4 md:px-12">
                <ClientList
                  clientData={clientState.clients}
                  onClientSelect={onClientSelect}
                />
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientView;
