/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ImageCarouselSlider from "components/ImageCarouselSlider";

export default function ShowPortfolioImages(props) {
  const [imageComment, setImageComment] = useState(
    props.isTmpPortfolio == 1
      ? props.artistPortfolio?.tmpImg[0].comment
      : props?.artistPortfolio?.artist_portfolio_images[0].comment
  );

  const handleClose = () => props.setOpen(false);
  return (
    <div className="h-screen w-screen">
      <Transition.Root show={props.open} className="h-full w-full">
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex items-start justify-center max-h-screen text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-top sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              className={"h-full w-full"}
            >
              <div
                className="inline-block align-top bg-white rounded-md text-left overflow-hidden shadow-xl transform transition-all 
              xl:max-w-2xl lg:max-w-xl md:max-w-xl max-w-xs m-4 px-4 pb-4 w-full"
              >
                {" "}
                <div className="h-full w-full">
                  <div className="flex">
                    <div className="flex justify-center align-center mt-4">
                      <ImageCarouselSlider
                        images={
                          props.isTmpPortfolio == 1
                            ? props.artistPortfolio?.tmpImg
                            : props.artistPortfolio.artist_portfolio_images
                        }
                        setImageComment={setImageComment}
                        artistPortfolioId={props.artistPortfolio.id}
                        openLink={false}
                      />
                    </div>
                  </div>
                  <p className="p-4 border border-primaryLight rounded-xl mt-4">
                    {imageComment}
                  </p>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
