import axios from "axios";
import localStorageService from "./services/localStorageService";

export const url = `${process.env.REACT_APP_API_SERVER_URL}api/`;
const instance = axios.create({
  baseURL: url
});

// Add a request interceptor to add token in request header.
instance.interceptors.request.use((requestConfig) => {
  // Get token from localStorage
 const token =
  localStorageService.getItem("auth_user") !== undefined &&
  localStorageService.getItem("auth_user") !== null
    ? localStorageService.getItem("jwt_token")
    : null;

  if (requestConfig.headers) {
      requestConfig.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  
  return requestConfig;
});

export default instance;