import React from 'react';

const Dropdown = ({children, label}) => {
	return (
		<div className="border border-primary rounded-2xl px-3 py-2 mt-4 shadow-sm focus-within:ring-0 focus-within:ring-primary focus-within:border-primary">
			<label htmlFor="name" className="block text-xs font-medium text-primary font-sans">{label}</label>
			{children}
		</div>
	)
};

export default Dropdown;
