import React, { useState } from "react";
import TopNavBar from "components/Navbars/TopNavBar";
import { userNavigations, VIEWS } from "layouts/StudioConstants";
import ArtistSideNavBar from "../../artist/components/ArtistSideNavBar";
import Reviews from "./components/Reviews";

export default function StudioReviewView(props) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
 
    const handleMenuOpen = (isOpen) => {
        setMobileMenuOpen(isOpen);
    };

    return (
        <>
            <div className="h-full flex">
                <div className="flex-1 flex flex-col overflow-hidden h-full bg-bgg">
                    <TopNavBar
                        userNavigations={userNavigations}
                        mobileMenuOpen={mobileMenuOpen}
                        handleMenu={handleMenuOpen}
                        loginData={props.loginData}
                    />
                    {/* Content area */}
                    <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full gap-6">
                        {/* {props.artistPortfolio && */}
                            <div className="w-full md:w-1/4 md:h-full bg-white">
                                <ArtistSideNavBar loginData={props?.userDataByEmail} avgRating={props?.studioReviewsAvg} />
                            </div>
                        {/* } */}
                        {/* Main content */}
                        <div className="bg-white">
                            <main className="overflow-hidden">
                                <Reviews reviewsData={props?.studioReviews} avgRating={props?.studioReviewsAvg}/>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}