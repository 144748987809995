import React, { useEffect, useState } from "react";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import Button from "components/UI/Button";
import { Dialog, Transition } from "@headlessui/react";
import Dropzone from "react-dropzone";
import CustomThumbnail from "components/CustomThumbnail";
import "../../styles/image.css";
import { ReactComponent as ChatIcon } from "../../../../assets/ic_chat.svg";
import { ReactComponent as RemoveIcon } from "../../../../assets/ic_remove.svg";
import { Alert } from "@mui/material";
import ImageCarouselSlider from "./ImageCarouselSlider";
import { USER_ROLE_CLIENT } from "../../CONSTANTS";
import { SERVER_URL } from "utils/constants";

const uploadPictureValidationSchema = yup.object({});

export default function EditUploadPictures(props) {
  const [file, setFile] = useState([]);
  const [comments, setComments] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showImageError, setShowImageError] = useState(false);
  const [imageError, setImageError] = useState("");
  const handleClose = () => props.setOpen(false);
  const [imageComment, setImageComment] = useState();
  const [tatooImageId, setTatooImageId] = useState();
  const [commentId, setCommentId] = useState();
  const [maxFiles, setMaxFiles] = useState();
  const [imagesData, setImagesData] = useState([]);

  useEffect(() => {
    let initMaxFiles = 4;
    if (props && props.data && props.data.length > 0) {
      setImageComment(props?.data[0]?.project_comment?.comment ?? "");
      setTatooImageId(props?.data[0]?.id);
      setCommentId(props?.data[0]?.projectCommentId ?? "");
      initMaxFiles = initMaxFiles - props?.data?.length;
      setImagesData(props?.data);
    }
    setMaxFiles(initMaxFiles);
  }, [props.data]);

  let intialFormValues = {
    editComment: imageComment,
  };

  let intialImageForm = {
    tattoPics: [],
    comment: "",
    images: [],
  };

  const formikImage = useFormik({
    initialValues: intialImageForm,
    onSubmit: (values, actions) => {
      handleImageForm(values, actions);
    },
  });

  const handleImageForm = (values, actions) => {
    const newComments = [...comments];
    for (let i = 0; i < file.length; i++) {
      if (file[i].path == selectedItem.path) {
        newComments[i] = values.comment;
        break;
      }
    }
    setComments(newComments);
  };

  const handleFormSubmit = (values) => {
    let obj = new FormData();
    if (props.buttonText === "Add") {
      obj.append("imagecomments", comments ? JSON.stringify(comments) : null);
      obj.append("loggedInUserId", props.loggedInUserId);
      file.map((file) => obj.append("files", file));
    } else {
      obj.append("loggedInUserId", props.loggedInUserId);
      obj.append("editedComment", values.editComment ?? "");
      obj.append("tatooImageId", tatooImageId);
      obj.append("commentId", commentId);
    }
    //props.setUploadedImages(file);
    props.onSubmit(obj);
    handleClose();
  };

  const onDelete = (values) => {
    let obj = new FormData();
    obj.append("deleteImageId", tatooImageId);
    obj.append("commentId", commentId);
    obj.append("loggedInUserId", props.loggedInUserId);
    props.onSubmit(obj);
  };

  const fillcomments = (i) => {
    const cValue = comments[i];
    formikImage.setFieldValue("comment", cValue ? cValue : "");
  };

  return (
    <div>
      <Transition.Root show={props.open}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto "
          onClose={handleClose}
        >
          <div className="flex items-start justify-center max-h-screen text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-top sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              // className={"h-full"}
            >
              <div className="bg-white rounded-md shadow-xl transform transition-all border border-primary xl:max-w-4xl lg:max-w-2xl md:max-h-4/5 md:max-w-xl max-w-sm m-2 p-4">
                <div className="h-full w-full">
                  {props.buttonText === "Add" && (
                    <form
                      onSubmit={formikImage.handleSubmit} // was commented
                      className="md:gap-y-12 sm:grid-cols-2 sm:gap-x-8"
                      encType="multipart/form-data"
                    >
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          for (var i = 0; i < file.length; i++) {
                            for (var j = 0; j < acceptedFiles.length; j++) {
                              if (file[i].path == acceptedFiles[j].path) {
                                setShowImageError(true);
                                setImageError("Duplicates Found");
                                return;
                              }
                            }
                          }
                          setShowImageError(false);
                          formikImage.setFieldValue(
                            "tattoPics",
                            formikImage.values.tattoPics.concat(acceptedFiles)
                          );
                          setFile((prev) => [...prev, ...acceptedFiles]);
                          formikImage.setFieldValue("images", acceptedFiles);
                        }}
                        onDragEnter={() => setShowImageError(false)}
                        onFileDialogCancel={() => setShowImageError(false)}
                        accept="image/*"
                        minSize={1024}
                        maxSize={5242880}
                        maxFiles={maxFiles}
                      >
                        {({
                          getRootProps,
                          getInputProps,
                          isDragAccept,
                          isDragReject,
                        }) => {
                          const additionalClass = isDragAccept
                            ? "accept"
                            : isDragReject
                            ? "reject"
                            : "";

                          return (
                            <div
                              {...getRootProps({
                                className: `dropzone ${additionalClass}`,
                              })}
                              className="flex flex-col border-primary border border-dashed p-10 rounded-2xl items-center stroke-2 mb-5"
                            >
                              <p className="font-sans text-primary text-xs mb-2">
                                Drag & Drop images here
                              </p>
                              <p className="font-sans text-primary text-xs  mb-2">
                                {" "}
                                Or
                              </p>

                              <input {...getInputProps()} />
                              <span>
                                <button
                                  type="button"
                                  className="bg-primary px-20 py-2 rounded-3xl text-white font-sans font-bold text-xs"
                                >
                                  {" "}
                                  Add Images
                                </button>
                              </span>
                              <p className="font-sans text-tertiary text-xs mt-2 mb-2">
                                {" "}
                                Max {maxFiles} files
                              </p>
                            </div>
                          );
                        }}
                      </Dropzone>
                      <div className={!showImageError ? "hidden" : undefined}>
                        <Alert
                          action={
                            <RemoveIcon
                              onClick={() => setShowImageError(false)}
                            />
                          }
                          severity="error"
                        >
                          {imageError}
                        </Alert>
                      </div>
                      <div className="mt-5">
                        <textarea
                          name="comment"
                          onChange={formikImage.handleChange}
                          onBlur={formikImage.handleBlur}
                          className="border border-tertiary rounded-xl w-full flex p-2 text-tertiary text-sm font-sans"
                          placeholder="Comment"
                          rows={3}
                          value={formikImage.values.comment}
                          autoComplete="Comment"
                          disabled={!selectedItem}
                        />

                        <p className="text-red">
                          {formikImage.errors.comment &&
                            formikImage.touched.comment &&
                            formikImage.errors.comment}
                        </p>
                      </div>
                      <button
                        className="bg-primary disabled:bg-tertiary rounded-xl px-7 py-1 mt-5 text-white font-sans text-xs"
                        type="submit"
                        disabled={!selectedItem}
                      >
                        {" "}
                        Save Comment{" "}
                      </button>
                      <div
                        className={`${
                          formikImage.values.tattoPics.length ? "h-20" : "h-0"
                        } w-full`}
                      >
                        <ul className="flex flex-row">
                          {" "}
                          {formikImage.values.tattoPics.map((file, i) => (
                            <div
                              className={`flex m-2 ${
                                selectedItem && file.path == selectedItem.path
                                  ? "border-2"
                                  : ""
                              } border-primary w-12 h-12`}
                              key={i}
                            >
                              <CustomThumbnail
                                file={file}
                                height={90}
                                width={90}
                                selectedItem={setSelectedItem}
                                onClick={() => fillcomments(i)}
                              />
                              <div className="flex mt-4 -ml-5">
                                <ChatIcon
                                  hidden={comments[i] ? false : true}
                                  onClick={() => fillcomments(i)}
                                />
                              </div>
                            </div>
                          ))}
                        </ul>
                      </div>
                    </form>
                  )}

                  {props.buttonText === "Update" && props?.editData && (
                    <div className="w-full h-4/5 justify-center">
                      <img
                        className="rounded-2xl object-cover object-center w-full h-full float-right"
                        src={SERVER_URL + props?.editData?.tatooImageUrl}
                        alt="Profile"
                      />
                    </div>
                  )}

                  {props.buttonText === "Update" && !props?.editData && (
                    <div className="flex">
                      <div className="flex justify-center align-center">
                        <ImageCarouselSlider
                          images={imagesData}
                          setImageComment={setImageComment}
                          setTatooImageId={setTatooImageId}
                          setCommentId={setCommentId}
                        />
                      </div>
                    </div>
                  )}

                  {props?.role === USER_ROLE_CLIENT &&
                    props?.projectStatus === "New Project" && (
                      <Formik
                        initialValues={intialFormValues}
                        validationSchema={uploadPictureValidationSchema}
                        onSubmit={(values) => {
                          handleFormSubmit(values);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                        }) => (
                          <form
                            onSubmit={handleSubmit}
                            encType="multipart/form-data"
                          >
                            {props.buttonText === "Update" && (
                              <div className="mt-0">
                                <textarea
                                  name="editComment"
                                  className="border border-tertiary rounded-xl w-full flex p-2 text-tertiary text-sm font-sans"
                                  placeholder="Comment"
                                  onChange={(e) => {
                                    setImageComment(e.target.value);
                                    values.editComment = e.target.value;
                                  }}
                                  onBlur={handleBlur}
                                  rows={4}
                                  value={imageComment}
                                  autoComplete="Comment"
                                />
                              </div>
                            )}
                            {/* Buttons */}
                            <div className="grid md:grid-cols-7">
                              <div
                                className={`col-start-1 ${
                                  props.buttonText === "Add"
                                    ? "xl:col-span-5 md:col-span-5"
                                    : "xl:col-span-2 md:col-span-3 "
                                } mt-5`}
                              >
                                <Button
                                  type="submit"
                                  label={props.buttonText}
                                  textsize="base"
                                />
                              </div>
                              {props.buttonText === "Update" && (
                                <div className="col-start-1 xl:col-span-2 md:col-span-3 mt-5 md:ml-5">
                                  <button
                                    className="bg-red text-white flex justify-center py-3 px-4 rounded-full shadow-sm text-base w-full"
                                    onClick={onDelete}
                                  >
                                    Delete
                                  </button>
                                </div>
                              )}
                            </div>
                          </form>
                        )}
                      </Formik>
                    )}

                  {props.buttonText === "Update" &&
                    props?.editData &&
                    ((props?.role === USER_ROLE_CLIENT &&
                      props?.projectStatus !== "New Project") ||
                      props?.role !== USER_ROLE_CLIENT) && (
                      <p>{props?.editData?.project_comment?.comment}</p>
                    )}

                  {props.buttonText === "Update" &&
                    !props?.editData &&
                    ((props?.role === USER_ROLE_CLIENT &&
                      props?.projectStatus !== "New Project") ||
                      props?.role !== USER_ROLE_CLIENT) && (
                      <p className="p-4 border border-primaryLight rounded-xl">
                        {imageComment}
                      </p>
                    )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
