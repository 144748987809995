import Header from "components/Navbars/AuthNavbar";
import React from "react";
import SupportForm from "./components/SupportForm";
import Footer from "components/Footers/Footer";

const SupportView = ({
  userTypes,
  countries,
  subjects,
  onSubmit,
  subscribe,
  setShowAlert,
  alertMessage,
  showAlert,
  subscriptionResponse,
}) => {
  return (
    <>
      <div className="bg-white">
        {/* Header section */}
        <header>
          <Header className="" />
        </header>
        <div className="h-full flex px-6">
          {/* Content area */}
          <div className="flex-1 flex flex-col overflow-hidden">
            {/* Main content */}
            <div className="flex flex-1">
              <div className="w-full md:mx-0.1 py-2 md:py-5 md:px-0 lg-gradient-to-r from-bgg via-white to-bgg">
                <div className="">
                  <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-6 md:gap-10 lg:mx-20 4xl:grid-cols-8">
                    <div className="w-full xl:col-span-2 xl:col-start-3 4xl:col-span-2 4xl:col-start-2">
                      <div className="text-2xl font-normal font-sans text-primary mb-6">
                        Support
                      </div>
                      <SupportForm
                        userTypes={userTypes}
                        subjects={subjects}
                        countries={countries}
                        onSubmit={onSubmit}
                        setShowAlert={setShowAlert}
                        alertMessage={alertMessage}
                        showAlert={showAlert}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer
          subscribe={subscribe}
          subscriptionResponse={subscriptionResponse}
        />
      </div>
    </>
  );
};

export default SupportView;
