/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import {
  navigations,
  userNavigations,
  USER_ROLE_MANGER_ID,
} from "../../../layouts/AdminConstants";
import SideNavBar from "components/Navbars/SideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";
import TabNavBar from "components/Navbars/TabNavBar";
import CardLayout from "./components/CardLayout";
import { SUPPORT_TABS } from "../../../layouts/AdminConstants";
import General from "./components/General";

const SupportView = ({
  loginData,
  selectedTab,
  setSelectedTab,
  supportTickets,
  onSubmit,
  projects,
  projectOnClick,
  generalDataCount,
  clientDataCount,
  artistDataCount
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [projectData, setProjectData] = useState(null);
 
  useEffect(() => {
    if (projects?.length > 0) {
      var resArr = [];
      projects.filter(function(item){
        var i = resArr.findIndex(x => (x.projectId == item.projectId && x.fromUserId == item.fromUserId));
        if(i <= -1){
              resArr.push(item);
        }
        return null;
      });
      setProjectData(resArr);
    }
  }, [projects])

  const getProjectCount = (tabNumber) => {
    switch (tabNumber) {
      case 1:
        return projectData ? projectData?.length : 0;
      case 2:
        return 0;
      case 3:
        return 0;
      case 4:
        return generalDataCount;
      case 5:
        return clientDataCount;
      case 6:
        return artistDataCount;
      default:
        return undefined;
    }
  };

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setTabs([
      {
        id: SUPPORT_TABS.OUR_PROJECTS.id,
        name: SUPPORT_TABS.OUR_PROJECTS.name,
        href: "#",
        current: selectedTab == SUPPORT_TABS.OUR_PROJECTS.id,
        count: getProjectCount(1),
      },
      {
        id: SUPPORT_TABS.MARKET_PLACE.id,
        name: SUPPORT_TABS.MARKET_PLACE.name,
        href: "#",
        current: selectedTab == SUPPORT_TABS.MARKET_PLACE.id,
        count: getProjectCount(2),
        isDisabled: SUPPORT_TABS.MARKET_PLACE.isDisabled
      },
      {
        id: SUPPORT_TABS.MARKED_AS_FINISHED_REPORT.id,
        name: SUPPORT_TABS.MARKED_AS_FINISHED_REPORT.name,
        href: "#",
        current: selectedTab == SUPPORT_TABS.MARKED_AS_FINISHED_REPORT.id,
        count: getProjectCount(3),
        isDisabled: SUPPORT_TABS.MARKED_AS_FINISHED_REPORT.isDisabled
      },
      {
        id: SUPPORT_TABS.GENERAL.id,
        name: SUPPORT_TABS.GENERAL.name,
        href: "#",
        current: selectedTab == SUPPORT_TABS.GENERAL.id,
        count: generalDataCount,
      },
      {
        id: SUPPORT_TABS.CLIENT.id,
        name: SUPPORT_TABS.CLIENT.name,
        href: "#",
        current: selectedTab == SUPPORT_TABS.CLIENT.id,
        count: getProjectCount(5),
      },
      {
        id: SUPPORT_TABS.ARTIST.id,
        name: SUPPORT_TABS.ARTIST.name,
        href: "#",
        current: selectedTab == SUPPORT_TABS.ARTIST.id,
        count: getProjectCount(6),
      },
    ]);
  }, [projectData, supportTickets]);

  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const setTab = (tabToSet) => {
    setSelectedTab(tabToSet.id);
    let updatedTabs = tabs.map((tab) =>
      tab.id === tabToSet.id
        ? { ...tab, current: true }
        : { ...tab, current: false }
    );
    setTabs(updatedTabs);
  };

  let grid;
  if (selectedTab == SUPPORT_TABS.OUR_PROJECTS.id) {
    grid = (
      <CardLayout
        data={projectData}
        selectedTab={selectedTab}
        projectOnClick={projectOnClick}
      />
      // <div>OUR_PROJECTS</div>
    );
  }
  if (selectedTab === SUPPORT_TABS.MARKET_PLACE.id) {
    grid = (
      // <CardLayout
      //   data={supportState?.marketProjects}
      //   selectedTab={selectedTab}
      //   getProjectById={getProjectById}
      // />
      <div></div>
    );
  }
  if (selectedTab === SUPPORT_TABS.MARKED_AS_FINISHED_REPORT.id) {
    grid = (
      // <FinishedReport
      //   data={supportState?.finishedProjects}
      //   selectedTab={selectedTab}
      // />
      <div></div>
    );
  }
  if (selectedTab == SUPPORT_TABS.GENERAL.id && supportTickets?.length > 0) {
    grid = (
      <General
        loginData={loginData}
        items={supportTickets}
        onSubmit={onSubmit}
        type='GENERAL'
      />
    );
  }
  if (selectedTab == SUPPORT_TABS.CLIENT.id && supportTickets?.length > 0) {
    grid = (
      <General
        loginData={loginData}
        items={supportTickets}
        onSubmit={onSubmit}
        type='CLIENT'
      />
    );
  }
  if (selectedTab == SUPPORT_TABS.ARTIST.id && supportTickets?.length > 0) {
    grid = (
      <General
        loginData={loginData}
        items={supportTickets}
        onSubmit={onSubmit}
        type='ARTIST'
      />
    );
  }

  return (
    <div className="h-full flex">
      <div className="flex-1 flex flex-col overflow-hidden h-full bg-bgg">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={loginData}
        />
        {/* Content area */}
        <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
          <SideNavBar
            navigations={navigations(
              loginData.userRoleId !== USER_ROLE_MANGER_ID
            )}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            bg={"bgg"}
            loginID={loginData.id}
          />
          {/* Main content */}
          <div className="flex-1 flex items-stretch overflow-hidden">
            {/* Main content */}
            <div className="flex flex-1 bg-bgg">
              <div className="w-full md:mx-0.1 py-2 md:py-5 md:px-4 bg-white shadow">
                <div className="mx-2 md:mx-4">
                  <div className="text-2xl font-normal font-sans text-primary mb-6">
                    <TabNavBar tabs={tabs} handleChange={setTab} />
                  </div>
                  {grid}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportView;
