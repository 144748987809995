import React from "react";

const PreviewImage = ({ file, filePath, rounded, height, width }) => {
  const [preview, setPreview] = React.useState(null);

  if (file) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      setPreview(fileReader.result);
    };
  }

  return (
    <div className={``}>
      <img
        src={preview ? preview : filePath}
        alt="Preview"
        className={`rounded-${rounded} w-${width ? width : "52"} h-${
          height ? height : "52"
        } border-primary border object-cover object-center`}
      />
    </div>
  );
};

export default PreviewImage;
