import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "components/Navbars/AuthNavbar";
import TopNavBar from "components/Navbars/TopNavBar";
import { userNavigations } from "layouts/ClientConstants";
import { updatePaymentDetails } from "redux/actions/PaymentAction";
import localStorageService from "services/localStorageService";
import { PAYMENT_STATUS_ABORT } from "./CONSTANTS";

const PaymentAbortView = (props) => {
  const dispatch = useDispatch();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  const goHome = () => {
    props.history.replace({
      pathname: "/",
    });
    window.location.reload(true);
  };

  useEffect(() => {
    let pId =
      localStorageService.getItem("pId") != undefined &&
      localStorageService.getItem("pId") != null
        ? localStorageService.getItem("pId")
        : null;
    if (pId) {
      localStorageService.removeItem("pId");
      let data = {
        loggedInUserId: props.loginData.id,
        status: PAYMENT_STATUS_ABORT,
      };
      dispatch(updatePaymentDetails(data, pId));
    }
    if (window.top.location !== window.location) {
      window.top.location.href = window.location.href;
    }
  }, []);

  return (
    <>
      <div className="bg-white">
        {/* Header section */}
        {!props.loginData && (
          <header>
            <Header className="" />
          </header>
        )}
        {props.loginData && (
          <TopNavBar
            userNavigations={userNavigations}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            loginData={props.loginData}
            clientview={true}
          />
        )}
        <main className="overflow-hidden">
          <div className="flex flex-col items-center justify-items-center">
            <div className="aspect-w-3 aspect-h-2 overflow-hidden">
              <object
                type="image/svg+xml"
                data="/2ndSkin.svg"
                className="h-20 w-auto content-center mx-auto object-cover pointer-events-none m-4"
              >
                2ndSkin
              </object>
              <img
                src="/payment_failed.jpg"
                alt=""
                className="w-40 h-40 object-center object-cover"
              />
            </div>
            {props?.loginData?.id && <p className="m-4">Payment Aborted.</p>}
            {props.loginData && (
              <button
                className="bg-primary text-white py-2 px-4 rounded-xl"
                onClick={() => goHome()}
              >
                {" "}
                Go to Home
              </button>
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default PaymentAbortView;
