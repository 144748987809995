import {
  CREATE_MANAGER_SUCCESS,
  DELETE_MANAGER_SUCCESS,
  GET_MANAGER_BY_TYPE_SUCCESS,
  GET_MANAGER_SUCCESS,
  MANAGER_ERROR,
  MANAGER_LOADING,
  UPDATE_MANAGER_SUCCESS,
} from "redux/actions/ManagerAction";

const initialState = {
  loading: null,
  success: null,
  getManagerByType: null,
  getManager: null,
  createManager: null,
  updateManager: null,
  deleteManager: null,
  error: null,
};

const ManagerReducer = function (state = initialState, action) {
  switch (action.type) {
    case MANAGER_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        // getManagerByType: null,
        // getManager: null,
        // createManager: null,
        // updateManager: null,
        // deleteManager: null,
        error: null,
      };
    }
    case GET_MANAGER_BY_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        getManagerByType: action.payload,
        // getManager: null,
        // createManager: null,
        // updateManager: null,
        // deleteManager: null,
        error: null,
      };
    }
    case GET_MANAGER_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        // getManagerByType: null,
        getManager: action.payload,
        // createManager: null,
        // updateManager: null,
        // deleteManager: null,
        error: null,
      };
    }
    case CREATE_MANAGER_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        // getManagerByType: null,
        // getManager: null,
        createManager: action.payload,
        // updateManager: null,
        // deleteManager: null,
        error: null,
      };
    }
    case UPDATE_MANAGER_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        // getManagerByType: null,
        // getManager: null,
        // createManager: null,
        updateManager: action.payload,
        // deleteManager: null,
        error: null,
      };
    }
    case DELETE_MANAGER_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        // getManagerByType: null,
        // getManager: null,
        // createManager: null,
        // updateManager: null,
        deleteManager: action.payload,
        error: null,
      };
    }
    case MANAGER_ERROR: {
      return {
        loading: false,
        success: false,
        getManagerByType: null,
        getManager: null,
        createManager: null,
        updateManager: null,
        deleteManager: null,
        error: action.payload ? action.payload : "Unknown Error Occured",
      };
    }
    default: {
      return state;
    }
  }
};

export default ManagerReducer;
