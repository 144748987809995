import React, { useState } from "react";
import { getDaysByInt, SERVER_URL } from "utils/constants";
import AdminNextAppointmentDateModal from "./AdminNextAppointmentDateModal";
import moment from "moment";

const ConfirmedAppointmentDates = (props) => {
  const [nextAppOpen, setNextAppOpen] = useState(false);
  const handleNextAppOpen = () => setNextAppOpen(true);

  let apptDate = props?.userdata?.counter_offer_dates.filter(
    (counterOffDt) => counterOffDt.statusId === 1
  );

  return (
    <div>
      <div className="flex flex-row gap-52">
        <div className="text-base text-gray mb-4">Appointment:</div>
      </div>
      <div>
        {getDaysByInt(parseInt(apptDate[0].day)) +
          ", " +
          moment(apptDate[0].offerDate).format("D-MMM-yyyy") +
          " | " +
          // apptDate[0].fromTime}
          moment(apptDate[0].fromTime, "HH:mm:ss").format("hh:mm") }
      </div>
      {props.role === "ProjectAdmin" &&
        props?.projectStatus === "Appointment Date Confirmed" && (
          <div className="text-primary mt-2" onClick={handleNextAppOpen}>
            {" "}
            Add Next Appointment{" "}
            {nextAppOpen && (
              <AdminNextAppointmentDateModal
                open={nextAppOpen}
                setOpen={setNextAppOpen}
                loggedInUserId={props.loggedInUserId}
                onSubmit={props.onSubmit}
                data={props.data}
                role={props.role}
                userdata={props?.userdata?.studio?.studio_work_day}
                dates={props?.userdata?.counter_offer_dates}
                artistId={props?.userdata?.userId}
                studioId={props?.userdata?.studioId}
                artistData={props?.userdata.user?.studio_artists}
                page=""
                clientProposedDates={props.clientProposedDates}
                addNewDate={false}
              />
            )}
          </div>
        )}
      {props?.userdata?.studio && 
      <div className="mt-2 w-full">
        <div className="font-bold flex flex-row items-center mt-4">
          <img
            className="h-12 w-12 rounded-lg mt-2 mr-4 object-cover"
            src={
              props?.userdata?.studio?.imageUrl
                ? SERVER_URL + props.userdata.studio.imageUrl
                : `${SERVER_URL}uploads/default-images/DefaultStudio.svg`
            }
            alt="profile"
          />
          <div className="w-1/2">{props?.userdata?.studio?.placeName}</div>
        </div>
        <div className="flex flex-col ml-16 gap-2 mt-0 mb-4 text-gray">
          <div>
            {(props?.userdata?.studio?.phoneCode) ? "(" + (props?.userdata?.studio?.phoneCode) + ")" : "" }{" "}
            {(props?.userdata?.studio?.phoneNumber) ? props?.userdata?.studio?.phoneNumber : ""}
          </div>
          <div>
            
            {(props?.userdata?.studio?.street) ? props?.userdata?.studio?.street : "" +
              " " +
             (props?.userdata?.studio?.city) ? props?.userdata?.studio?.city : "" +
              " " +
             (props?.userdata?.studio?.zipcode) ? props?.userdata?.studio?.zipcode : ""}
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default ConfirmedAppointmentDates;
