import {
  FAQ_LOADING,
  FAQ_GET_ALL_DATA,
  FAQ_CREATE_DATA,
  FAQ_GET_DATA,
  FAQ_UPDATE_DATA,
  FAQ_PATCH_DATA,
  FAQ_ERROR,
} from "redux/actions/faqAction";

const initialState = {
  loading: false,
  success: false,
  error: null,
  getAll: null,
  get: null,
  create: null,
  update: null,
  patch: null,
};

const FaqReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAQ_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        getAll: null,
        get: null,
        create: null,
        update: null,
        patch: null,
        error: null,
      };
    }
    case FAQ_GET_ALL_DATA: {
      return {
        ...state,
        loading: false,
        success: true,
        getAll: action.payload,
        get: null,
        create: null,
        update: null,
        patch: null,
        error: null,
      };
    }
    case FAQ_GET_DATA: {
      return {
        ...state,
        loading: false,
        success: true,
        getAll: null,
        get: action.payload,
        create: null,
        update: null,
        patch: null,
        error: null,
      };
    }
    case FAQ_CREATE_DATA: {
      return {
        ...state,
        loading: false,
        success: true,
        getAll: null,
        get: null,
        create: action.payload,
        update: null,
        patch: null,
        error: null,
      };
    }
    case FAQ_UPDATE_DATA: {
      return {
        ...state,
        loading: false,
        success: true,
        getAll: null,
        get: null,
        create: null,
        update: action.payload,
        patch: null,
        error: null,
      };
    }
    case FAQ_PATCH_DATA: {
      return {
        ...state,
        loading: false,
        success: true,
        getAll: null,
        get: null,
        create: null,
        update: null,
        patch: action.payload,
        error: null,
      };
    }
    case FAQ_ERROR: {
      return {
        ...state,
        loading: false,
        success: false,
        getAll: null,
        get: null,
        create: null,
        update: null,
        patch: null,
        error: action.payload ? action.payload : "Unknown Error Occured",
      };
    }
    default:
      return { ...state };
  }
};

export default FaqReducer;
