import React from "react";
import SetPassword from "../../../components/SetPassword";

export default function StudioVerificationView({ onSubmit, data, error }) {
  let view = data ? (
    <div className="min-h-screen flex lg:grid grid-cols-12">
      <div className="flex-1 flex flex-col justify-start m-10 col-start-1 col-span-5 xl:col-start-2 xl:col-span-3">
        <div className="mx-auto w-full max-w-lg">
          <div>
            <object
              type="image/svg+xml"
              data="/2ndSkin.svg"
              className="h-20 w-auto content-center mx-auto object-cover"
            >
              2ndSkin
            </object>
            <h2 className="mt-10 md:mt-8 lg:mt-32 text-3.5xl font-bold text-primary font-sans">
              Thank you!
            </h2>
            <p className="mt-0 text-base text-primary font-sans">
              E-Mail has been successfully verified.
            </p>
          </div>
          <SetPassword onSubmit={onSubmit} from={"studio"} />
        </div>
      </div>
      <div className="lg:flex-1 col-start-6 col-span-7 bg-primary" />
    </div>
  ) : (
    <p className="mt-6 text-xl text-primary font-sans">
      Invalid verification string
    </p>
  );

  return <div>{view}</div>;
}
