import UserTypeService from "services/UserTypeService";

export const GET_USER_TYPES_LOADING = "GET_USER_TYPES_LOADING";
export const GET_USER_TYPES_SUCCESS = "GET_USER_TYPES_SUCCESS";
export const GET_USER_TYPES_ERROR = "GET_USER_TYPES_ERROR";

export const getUserTypes = () => async (dispatch) => {
    dispatch({
        type: GET_USER_TYPES_LOADING,
    });
    try {
        let res = await UserTypeService.getUserTypes();

        return dispatch({
            type: GET_USER_TYPES_SUCCESS,
            payload: res.data.userType,
        });
    } catch (err) {
        return dispatch({
            type: GET_USER_TYPES_ERROR,
            payload: err.response,
        });
    }
};