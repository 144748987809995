import FaqService from "services/FaqService";

export const FAQ_LOADING = "FAQ_LOADING";
export const FAQ_ERROR = "FAQ_ERROR";
export const FAQ_GET_ALL_DATA = "FAQ_GET_ALL_DATA";
export const FAQ_GET_DATA = "FAQ_GET_DATA";
export const FAQ_CREATE_DATA = "FAQ_CREATE_DATA";
export const FAQ_UPDATE_DATA = "FAQ_UPDATE_DATA";
export const FAQ_PATCH_DATA = "FAQ_PATCH_DATA";

export const getData = () => async (dispatch) => {
  dispatch({
    type: FAQ_LOADING,
  });
  try {
    let res = await FaqService.getAll();
    return dispatch({
      type: FAQ_GET_ALL_DATA,
      payload: res.data.faq,
    });
  } catch (err) {
    return dispatch({
      type: FAQ_ERROR,
      payload: err.response,
    });
  }
};

export const addFaq = (data) => async (dispatch) => {
  dispatch({
    type: FAQ_LOADING,
  });
  try {
    let res = await FaqService.create(data);

    return dispatch({
      type: FAQ_CREATE_DATA,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: FAQ_ERROR,
      payload: err.response,
    });
  }
};

export const updateFaq = (id, data) => async (dispatch) => {
  dispatch({
    type: FAQ_LOADING,
  });
  try {
    let res = await FaqService.update(id, data);

    return dispatch({
      type: FAQ_UPDATE_DATA,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: FAQ_ERROR,
      payload: err.response,
    });
  }
};

export const deleteFaq = (id, data) => async (dispatch) => {
  dispatch({
    type: FAQ_LOADING,
  });
  try {
    let res = await FaqService.patch(id, data);

    return dispatch({
      type: FAQ_PATCH_DATA,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: FAQ_ERROR,
      payload: err.response,
    });
  }
};
