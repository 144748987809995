import {
    PAYMENT_LOADING,
    PAYMENT_SUCCESS,
    PAYMENT_ERROR,
    SAVE_PAYMENT_LOADING,
    SAVE_PAYMENT_SUCCESS,
    SAVE_PAYMENT_ERROR,    
    UPDATE_PAYMENT_LOADING,
    UPDATE_PAYMENT_SUCCESS,
    UPDATE_PAYMENT_ERROR,
    PAYMENT_REFUND_LOADING,
    PAYMENT_REFUND_SUCCESS,
    PAYMENT_REFUND_ERROR,
} from "../actions/PaymentAction";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null
};

const PaymentReducer = function (state = initialState, action) {
    switch (action.type) {
        case PAYMENT_LOADING: 
        case SAVE_PAYMENT_LOADING:
        case UPDATE_PAYMENT_LOADING:
        case PAYMENT_REFUND_LOADING: {
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null
            };
        }
        case PAYMENT_SUCCESS: 
        case SAVE_PAYMENT_SUCCESS: 
        case UPDATE_PAYMENT_SUCCESS:
        case PAYMENT_REFUND_SUCCESS: {
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };
        }
        case PAYMENT_ERROR: 
        case SAVE_PAYMENT_ERROR:
        case UPDATE_PAYMENT_ERROR:
        case PAYMENT_REFUND_ERROR: {
            return {
                loading: false,
                success: false,
                data: null,
                error: action.payload ? action.payload.data.error.message : "Error Occured",
            };
        }
        default: {
            return state;
        }
    }
};

export default PaymentReducer;