import axios from "../http-config";

class ReviewsService {

  getArtistRatings() {
    return axios({
      method: "GET",
      url: `/artistportfolios/ratings/all`
    });
  }

  updateArtistRatingAsViewed() {
    return axios({
      method: "PATCH",
      url: `/artistportfolios/updateArtistRatingAsViewed/all`,
    });
  }

  updateArtistRatingStatus(id, statusId) {
    return axios({
      method: "PATCH",
      url: `/artistportfolios/updateartistratingstatus/${id}/${statusId}`,
    });
  }
  
  getArtistPublishedRatings(id) {
    return axios({
      method: "GET",
      url: `/artistportfolios/ratings/${id}`
    });
  }

  getAllRatingsByArtistId(id) {
    return axios({
      method: "GET",
      url: `/artistportfolios/ratings/artist/${id}`
    });
  }

}

export default new ReviewsService();
