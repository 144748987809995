/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArtistHomeView from "./ArtistHomeView";
import { getUserById } from "../../../redux/actions/UserActions";
import {
  TABS,
  getUserRole,
  VIEWS,
  BOSS_TABS,
  USER_ROLE_BOSS,
  USER_ROLE_ADMIN,
  USER_ROLE_MANAGER,
  USER_ROLE_ARTIST
} from "../../../layouts/ArtistConstants";
import {
  getArtistCurrentProjects,
  getArtistProjectsHistory,
  getAdminArtistProjects,
} from "redux/actions/ArtistModuleAction";
import { getStudioArtistReviewsAvg } from "../../../redux/actions/StudioReviewsAction";
import {
  linkArtistStudio,
  updateArtistPortfolioStatus,
  deleteArtistPortfolio,
  updateTmpArtistPortfolioStatus,
  deleteTmpArtistPortfolio,
} from "../../../redux/actions/AdminArtistAction";
import { useHistory } from "react-router-dom";
import {
  getAllRatingsByArtistId, 
  updateArtistRatingStatus, getArtistPublishedRatings
} from "redux/actions/ReviewsAction";

export default function ArtistHomeContainer(props) {
  const selectedArtistId = props.match.params.id;
  const [selectedTab, setSelectedTab] = useState(TABS.PORTFOLIO.id);

  const artist = useSelector((state) => state.user);
  const artistState = useSelector((state) => state.artistModule);
  const artistReviewsAvg = useSelector(
    (state) => state.studioReviews.studioArtistReviewsAvg
  );
  const reviewsArtistData = useSelector((state) => state.artistrating.reviewsData);
  const dispatch = useDispatch();
  const [currentCount, setCurrentCount] = useState(0);
  const [historyCount, setHistoryCount] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const [showRating, setShowRating] = useState(false);

  let artistRatings = reviewsArtistData ? Object.assign([], reviewsArtistData) : [];

  let history = useHistory();

  const role = getUserRole(props?.loginData?.userRoleId);

  useEffect(() => {
    if (
      role === USER_ROLE_BOSS ||
      role === USER_ROLE_ADMIN ||
      role === USER_ROLE_MANAGER
    ) {
      dispatch(getAdminArtistProjects(selectedArtistId));
    }
    setProjectCount(artistState?.artistProjects?.project?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedArtistId) {
      dispatch(getUserById(selectedArtistId));
      dispatch(getAllRatingsByArtistId(selectedArtistId));
    } else {
      dispatch(getUserById(props.loginData.id));
      if (role === USER_ROLE_ARTIST) {
        dispatch(getArtistPublishedRatings(props.loginData.id));
      }
    }
    if (
      role === USER_ROLE_BOSS ||
      role === USER_ROLE_ADMIN ||
      role === USER_ROLE_MANAGER
    ) {
      dispatch(getStudioArtistReviewsAvg(selectedArtistId));
      if (selectedTab === BOSS_TABS.PROJECTS.id) {
        dispatch(getAdminArtistProjects(selectedArtistId));
      }
    } else {
      dispatch(getStudioArtistReviewsAvg(props.loginData.id));
      if (selectedTab === TABS.CURRENTPROJECTS.id) {
        dispatch(getArtistCurrentProjects(props.loginData.id));
      } else if (selectedTab === TABS.PROJECTHISTORY.id) {
        dispatch(getArtistProjectsHistory(props.loginData.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArtistId, selectedTab]);

  useEffect(() => {
    dispatch(getArtistCurrentProjects(props.loginData.id));
    dispatch(getArtistProjectsHistory(props.loginData.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentCount(artistState?.artistCurrentProjects?.project?.length);
    setHistoryCount(artistState?.artistProjectsHistory?.project?.length);
    setProjectCount(artistState?.artistProjects?.project?.length);
  }, [artistState]);

  useEffect(() => {
    if (artistState?.success) {
      setSelectedTab(
        artistState?.artistCurrentProjects?.project?.length > 0
          ? TABS.CURRENTPROJECTS.id
          : TABS.PORTFOLIO.id
      );
    }
  }, [artistState?.artistCurrentProjects?.project?.length]);

  const onSaveStudio = (studioId, day) => {
    let dataObj = {
      artistId: selectedArtistId,
      studioId: studioId,
      day: day,
    };
    dispatch(linkArtistStudio(dataObj)).then((item) => {
      dispatch(getUserById(selectedArtistId));
    });
  };

  const onUpdateStatus = (obj, id) => {
    dispatch(updateArtistPortfolioStatus(obj, id)).then((item) => {
      dispatch(getUserById(selectedArtistId));
    });
  };

  const onDeletePortfolio = (id) => {
    dispatch(deleteArtistPortfolio(id)).then((item) => {
      dispatch(getUserById(selectedArtistId));
    });
  };

  const onUpdateTmpStatus = (obj, id) => {
    dispatch(updateTmpArtistPortfolioStatus(obj, id)).then((item) => {
      dispatch(getUserById(selectedArtistId));
    });
  };

  const onDeleteTmpPortfolio = (id) => {
    dispatch(deleteTmpArtistPortfolio(id)).then((item) => {
      dispatch(getUserById(selectedArtistId));
    });
  };

  const addPortfolio = () => {
    history.push(`/artist/artistportfolio/${VIEWS.ARTIST_HOME.id}`);
  };

  const refreshArtist = () => {
    dispatch(getUserById(selectedArtistId));
  };

  const handleShowRating = (b) => {
    setShowRating(b);
  }

  const changeArtistRatingStatus = (artistRatingId, artistRatingStatusId) => {
    dispatch(
      updateArtistRatingStatus(artistRatingId, artistRatingStatusId)
    ).then(() => {
      dispatch(getAllRatingsByArtistId(selectedArtistId));
    });
  };

  return (
    <div>
      <ArtistHomeView
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        loginData={artist.data ?? props.loginData}
        artistState={artistState}
        currentCount={currentCount}
        historyCount={historyCount}
        artistReviewsAvg={artistReviewsAvg}
        role={role}
        onSaveStudio={onSaveStudio}
        onUpdateStatus={onUpdateStatus}
        onDeletePortfolio={onDeletePortfolio}
        addPortfolio={addPortfolio}
        artistError={artist.error}
        actualLoginData={props.loginData}
        projectCount={projectCount}
        onUpdateTmpStatus={onUpdateTmpStatus}
        onDeleteTmpPortfolio={onDeleteTmpPortfolio}
        refreshArtist={refreshArtist}
        showRating={showRating}
        handleShowRating={handleShowRating}
        artistRatings={artistRatings}
        changeArtistRatingStatus={changeArtistRatingStatus}
      />
    </div>
  );
}
