import React, { useState, useEffect } from "react";
import Table from "./Table";
import { ReactComponent as SearchIcon } from "../../../../assets/ic_search.svg";
import DatePicker from "react-date-picker";
import moment from "moment";

// const pageSize = 10;
const maxPagesDisplayed = 5;
const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

const Invoices = ({
  data,
  applyFilters,
  setInvoices,
  setRefresh,
  loginDataId,
}) => {
  const [pageSize, setPageSize] = useState(10);
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();

  const [currentTableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationRange, setRange] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [filterQuery, setFilterQuery] = useState("");
  const [searchText, setSearchText] = useState("");
  const [newQueryString, setNewQueryString] = useState("");

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (width < 768) {
      setPageSize(5);
    }
  }, []);

  const handleFilter = () => {
    let ddateFrom = dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : "";
    let ddateTo = dateTo ? moment(dateTo).format("YYYY-MM-DD") : "";

    const queryString = `start=0${priceFrom ? '&priceFrom='+priceFrom : ''}${priceTo ? '&priceTo='+priceTo : ''}${ddateFrom ? '&dateFrom='+ddateFrom : ''}${ddateTo ? '&dateTo='+ddateTo : ''}${searchText ? '&searchBy='+searchText : ''}`;
    setFilterQuery(queryString);
    setCurrentPage(1);
  };

  useEffect(() => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;

    setTableData(data?.slice(start, end));
  }, [currentPage, data]);

  useEffect(() => {
    if (filterQuery) {
      setNewQueryString(filterQuery);
    } else {
      setNewQueryString("start=0");
    }
  }, [filterQuery]);

  useEffect(() => {
    applyFilters(newQueryString);
  }, [newQueryString]);

  useEffect(() => {
    if (data && data.length > 0) {
      setTotalPageCount(Math.ceil(data.length / pageSize));
    }

    if (totalPageCount <= maxPagesDisplayed) {
      // Number of pages are within the limit so : use limt as range
      setRange(range(1, totalPageCount));
    } else if (
      currentPage <= Math.floor(maxPagesDisplayed / 2) &&
      totalPageCount > maxPagesDisplayed
    ) {
      setRange(range(1, maxPagesDisplayed));
    } else if (
      totalPageCount - currentPage ===
      Math.floor(maxPagesDisplayed / 2)
    ) {
      setRange(
        range(currentPage - Math.floor(maxPagesDisplayed / 2), totalPageCount)
      );
    } else if (
      totalPageCount - currentPage <
      Math.floor(maxPagesDisplayed / 2)
    ) {
      setRange(range(currentPage - maxPagesDisplayed + 1, totalPageCount));
    } else {
      setRange(
        range(
          currentPage - Math.floor(maxPagesDisplayed / 2),
          currentPage + Math.floor(maxPagesDisplayed / 2)
        )
      );
    }
  }, [currentPage, data, totalPageCount, dateFrom]);

  const handleReset = () => {
    setDateFrom("");
    setDateTo("");
    setPriceFrom("");
    setPriceTo("");
    setSearchText("");

    const queryString = "";
    setFilterQuery(queryString);
    setCurrentPage(1);
  };

  return (
    <div className="flex md:grid grid-cols-12 gap-2 flex-col mx-2 md:mx-0">
      <div className="md:col-start-1 md:col-span-12 text-2xl font-semibold font-sans text-primary mb-0">
        Invoices
      </div>

      <div className="md:col-start-1 md:col-span-3 py-2 rounded-lg pl-3 flex gap-4 items-end bg-grayUnderLine items-center">
        <label htmlFor="search">
          <SearchIcon className="h-4 w-4" />
        </label>
        <input
          placeholder="Search"
          id="search"
          className="w-full focus:outline-none py-1 mr-4"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
        />
      </div>

      {/* FILTER BY  */}
      <form className="hidden md:block w-full py-3 rounded md:flex gap-2 justify-between text-gray md:col-start-1 md:col-span-12 md:grid md:grid-cols-12 px-4 mt-4 items-center bg-grayUnderLine flex-col">
        <div className="flex col-start-1 col-span-2 gap-1 font-sans text-sm font-normal text-tertiary">
          Date
          <DatePicker onChange={setDateFrom} value={dateFrom} />
        </div>

        <div className="flex col-start-3 col-span-2 gap-1 font-sans text-sm font-normal text-tertiary">
          To
          <DatePicker onChange={setDateTo} value={dateTo} />
        </div>

        <div className="flex col-start-5 col-span-3 gap-1 font-sans text-sm font-normal text-tertiary">
          Price
          <input
            className="ml-1 text-center hover:outline-none focus:outline-none hover:border-b border-b border-slate-200 w-1/3"
            onChange={(e) => {
              (e.target.value.match(/^\d+$/) || e.target.value === "") &&
                setPriceFrom(e.target.value);
            }}
            value={priceFrom}
            id="priceFrom"
            name="priceFrom"
          />
        </div>

        <div className="flex col-start-8 col-span-3 gap-1 font-sans text-sm font-normal text-tertiary">
          To
          <input
            className="ml-1 text-center hover:outline-none focus:outline-none hover:border-b border-b border-slate-200 w-1/3"
            onChange={(e) => {
              (e.target.value.match(/^\d+$/) || e.target.value === "") &&
                setPriceTo(e.target.value);
            }}
            value={priceTo}
            id="priceTo"
            name="priceTo"
          />
        </div>

        <div
          onClick={() => handleFilter()}
          className="col-start-11 mt-4 md:mt-0 text-sm text-primary"
        >
          Apply
        </div>
        <div
          onClick={() => handleReset()}
          className="col-start-12 mt-4 md:mt-0 text-sm"
        >
          Clear
        </div>
      </form>

      <form className="md:hidden w-full py-2 rounded text-gray flex grid grid-cols-6 px-2 mt-4 items-center bg-grayUnderLine">
        <div className="flex col-start-1 col-span-2 font-sans text-sm font-normal text-tertiary w-full">
          Date From
        </div>
        <DatePicker
          onChange={setDateFrom}
          value={dateFrom}
          className="flex col-start-3 col-span-3"
        />

        <div className="flex col-start-1 col-span-2 gap-1 font-sans text-sm font-normal text-tertiary mt-2">
          Date To
        </div>
        <DatePicker
          onChange={setDateTo}
          value={dateTo}
          className={"flex col-start-3 col-span-3"}
        />

        <div className="flex col-start-1 col-span-2 gap-1 font-sans text-sm font-normal text-tertiary mt-2">
          Price From
        </div>
        <input
          className="ml-1 text-center hover:outline-none focus:outline-none hover:border-b border-b border-slate-200 w-full col-start-3 col-span-3"
          onChange={(e) => {
            (e.target.value.match(/^\d+$/) || e.target.value === "") &&
              setPriceFrom(e.target.value);
          }}
          value={priceFrom}
          id="priceFrom"
          name="priceFrom"
        />

        <div className="flex col-start-1 col-span-2 gap-1 font-sans text-sm font-normal text-tertiary mt-2">
          Price To
        </div>
        <input
          className="ml-1 text-center hover:outline-none focus:outline-none hover:border-b border-b border-slate-200 w-full col-start-3 col-span-3"
          onChange={(e) => {
            (e.target.value.match(/^\d+$/) || e.target.value === "") &&
              setPriceTo(e.target.value);
          }}
          value={priceTo}
          id="priceTo"
          name="priceTo"
        />
        <div
          onClick={() => handleFilter()}
          className="col-start-1 col-span-3 mt-3 md:mt-0 text-sm text-primary flex justify-self-start"
        >
          Apply
        </div>
        <div
          onClick={() => handleReset()}
          className="col-start-4 col-span-3 mt-3 mb-2 md:mt-0 text-sm"
        >
          Clear
        </div>
      </form>

      <div className="md:col-start-1 md:col-span-12 mt-4">
        <Table
          data={currentTableData}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageSize={pageSize}
          paginationRange={paginationRange}
          totalPageCount={totalPageCount}
          setRefresh={setRefresh}
          loginDataId={loginDataId}
        />
      </div>
    </div>
  );
};

export default Invoices;
