/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import {
  navigations,
  userNavigations,
  USER_ROLE_MANGER_ID,
  ARTIST_RATING_STATUS,
} from "../../../layouts/AdminConstants";
import SideNavBar from "components/Navbars/SideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";
import TabNavBar from "components/Navbars/TabNavBar";
import { tabsNav } from "./CONSTANTS";
import { REVIEWS_TABS } from "../../../layouts/AdminConstants";
import AllReviews from "./components/AllReviews";
import NewReviews from "./components/NewReviews";
import CheckBox from "../../../components/UI/CheckBox";

const ReviewsView = ({
  selectedTab,
  setSelectedTab,
  loginData,
  artistRatings,
  changeArtistRatingStatus,
  projectOnClick,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const [publishedChecked, setPublishedChecked] = useState(false);
  const [disclosedChecked, setDisclosedChecked] = useState(false);

  const getReviewCount = (tabNumber) => {
    switch (tabNumber) {
      case 1:
        return artistRatings?.length;
      case 2:
        let newReviews = artistRatings?.filter(
          (artistRating) => artistRating.isViewed == false
        );
        return newReviews?.length;
      default:
        return 0;
    }
  };

  const [tabs, setTabs] = useState(tabsNav);
  const setTab = (tabToSet) => {
    setSelectedTab(tabToSet.id);
    let updatedTabs = tabs.map((tab) =>
      tab.id === tabToSet.id
        ? { ...tab, current: true, count: getReviewCount(tab.id) }
        : { ...tab, current: false }
    );
    setTabs(updatedTabs);
  };

  useEffect(() => {
    setTabs([
      {
        id: "1",
        name: "All",
        href: "#",
        current: selectedTab == "1",
        count: getReviewCount(1),
      },
      {
        id: "2",
        name: "New",
        href: "#",
        current: selectedTab == "2",
        count: getReviewCount(2),
      },
    ]);
  }, [artistRatings]);

  const filteredReviews = (isNew) => {
    if (publishedChecked && !disclosedChecked) {
      return artistRatings.filter(
        (artistRating) =>
          artistRating.isViewed == !isNew &&
          artistRating.artistRatingStatusId === ARTIST_RATING_STATUS.PUBLISH.id
      );
    } else if (!publishedChecked && disclosedChecked) {
      return artistRatings.filter(
        (artistRating) =>
          artistRating.isViewed == !isNew &&
          artistRating.artistRatingStatusId ==
            ARTIST_RATING_STATUS.UNDISCLOSE.id
      );
    } else {
      return artistRatings.filter(
        (artistRating) => artistRating.isViewed == !isNew
      );
    }
  };

  let grid;
  if (selectedTab == REVIEWS_TABS.ALL.id) {
    grid = (
      <AllReviews
        artistRatings={filteredReviews(false)}
        changeArtistRatingStatus={changeArtistRatingStatus}
        projectOnClick={projectOnClick}
      />
    );
  } else if (selectedTab == REVIEWS_TABS.NEW.id) {
    grid = (
      <NewReviews
        artistRatings={filteredReviews(true)}
        changeArtistRatingStatus={changeArtistRatingStatus}
        projectOnClick={projectOnClick}
      />
    );
  }

  return (
    <div className="h-full flex">
      <div className="flex-1 flex flex-col overflow-hidden h-full bg-white">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={loginData}
        />
        <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
          <SideNavBar
            navigations={navigations(
              loginData.userRoleId !== USER_ROLE_MANGER_ID
            )}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            bg={"bgg"}
            loginID={loginData.id}
          />
          <div className="flex-1 flex items-stretch overflow-hidden">
            {/* Main content */}
            <div className="flex flex-1 bg-white">
              <div className="w-full md:mx-0.1 py-2 md:py-4 md:px-4 bg-white shadow">
                <div className="mr-4">
                  <div className="flex flex-col mx-2 w-full ">
                    <p className="text-primary text-2xl font-normal font-sans">
                      {" "}
                      Reviews
                    </p>
                    <TabNavBar tabs={tabs} handleChange={setTab} />
                    <div className="text-base flex flex-row gap-16 mt-4">
                      <div className="flex">
                        <CheckBox
                          checked={publishedChecked && true}
                          onChange={() =>
                            setPublishedChecked(!publishedChecked)
                          }
                          rounded={"sm"}
                        />
                        <div className="flex gap-2">Published</div>
                      </div>
                      <div className="flex">
                        <CheckBox
                          checked={disclosedChecked && true}
                          onChange={() =>
                            setDisclosedChecked(!disclosedChecked)
                          }
                          rounded={"sm"}
                        />
                        <div className="flex gap-2">Disclosed</div>
                      </div>
                    </div>
                    <div className="flex my-4 w-full">{grid}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsView;
