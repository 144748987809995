import { ReactComponent as home } from "../assets/SideBar/ico_side_home.svg";
import { ReactComponent as account } from "../assets/SideBar/ico_side_account.svg";
import { ReactComponent as artist } from "../assets/SideBar/ico_side_artist.svg";
import { ReactComponent as client } from "../assets/SideBar/ico_side_client.svg";
import { ReactComponent as news } from "../assets/SideBar/ico_side_newsletter.svg";
import { ReactComponent as project } from "../assets/SideBar/ico_side_project.svg";
import { ReactComponent as support } from "../assets/SideBar/ico_side_support.svg";
import { ReactComponent as chat } from "../assets/SideBar/ico_side_chat.svg";
import { ReactComponent as withdrawn } from "../assets/SideBar/ico_side_withdrawn.svg";
import { ReactComponent as rating } from "../assets/SideBar/ico_side_rating.svg";
import { ReactComponent as faq } from "../assets/SideBar/ico_side_faq.svg";

export const USER_TYPE_MANAGER = 3;

export const navigations = (isAdmin) => {
  return ArtistNavigations;
};

export const ArtistNavigations = [
  { id: "1", name: "Home", href: "/", icon: home, current: true },
  { id: "2", name: "Accounting", href: "#", icon: account, current: false },
  {
    id: "3",
    name: "Artists",
    href: "/admin/artist",
    icon: artist,
    current: false,
  },
  { id: "4", name: "Clients", href: "#", icon: client, current: false },
  { id: "5", name: "Newsletter", href: "#", icon: news, current: false },
  { id: "6", name: "Projects", href: "#", icon: project, current: false },
  { id: "7", name: "Support", href: "#", icon: support, current: false },
  { id: "8", name: "Chat", href: "#", icon: chat, current: false },
  { id: "9", name: "Withdrawn", href: "#", icon: withdrawn, current: false },
  { id: "10", name: "Rating", href: "#", icon: rating, current: false },
  { id: "11", name: "FAQ", href: "#", icon: faq, current: false },
];

export const userNavigations = [
  { id: "1", name: "Your profile", href: "/artist/profile" },
  { id: "2", name: "Sign out", href: "/artist/signout" },
];

export const PORTFOLIO_STATUS = Object.freeze({
  PENDING: 1,
  COMPLETE: 2,
  CANCELLED: 3,
});

export const TABS = Object.freeze({
  PORTFOLIO: { id: "1", name: "Portfolio" },
  CURRENTPROJECTS: { id: "2", name: "Current Projects" },
  PROJECTHISTORY: { id: "3", name: "Project History" },
  MARKETPLACE: { id: "4", name: "Market Place" },
  WORKPLACES: { id: "5", name: "Work Places" },
});

export const VIEWS = Object.freeze({
  ARTIST_REGISTRATION: { id: 1, name: "ArtistRegistration" },
  ARTIST_PROFILE: { id: 2, name: "ArtistProfile" },
  ARTIST_PORTFOLIO_LIST: { id: 3, name: "ArtistPortfolioList" },
  ARTIST_HOME: { id: 4, name: "ArtistHome" },
  PREVIEW_ARTIST_PORTFOLIO: { id: 5, name: "PreviewArtistPortfolio" },
});

export const ISTMPPORTFOLIO = Object.freeze({
  YES: { id: 1 },
  NO: { id: 0 },
});

export const getUserRole = (roleId) => {
  switch (roleId) {
    case 1:
      return "Admin";
    case 2:
      return "Boss";
    case 3:
      return "Artist";
    case 4:
      return "Client";
    case 5:
      return "Manager";
    default:
      return "";
  }
};

export const BOSS_TABS = Object.freeze({
  PORTFOLIO: { id: "1", name: "Portfolio" },
  PROJECTS: { id: "10", name: "Projects" },
});

export const studioTypes = {
  "My Studio": "1",
  "Partner Studio": "2",
};

export const getstudioTypesById = (studioTypeId) => {
  switch (studioTypeId) {
    case 1:
      return "My Studio";
    case 2:
      return "Partner Studio";
    default:
      return "My Studio";
  }
};

export const USER_ROLE_BOSS = "Boss";
export const USER_ROLE_ADMIN = "Admin";
export const USER_ROLE_MANAGER = "Manager";
export const USER_ROLE_ARTIST = "Artist";
export const USER_ROLE_CLIENT = "Client";

export const ARTIST_CLIENT_PROFILE_TABS = Object.freeze({
  CURRENTPROJECTS: { id: "1", name: "Current Projects" },
  //REVIEWS: { id: "2", name: "Reviews" },
});
