import React from "react";
import Reviews from "./Reviews"

const NewReviews = (props) => {
  
  return (
     <Reviews {...props} />
  );
};

export default NewReviews;
