import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Input from "components/UI/Input";
import Button from "components/UI/Button";

const formValidations = yup.object({
  questEng: yup.string().required("required"),
  questGer: yup.string().required("required"),
  ansEng: yup.string().required("required"),
  ansGer: yup.string().required("required"),
});

const FaqForm = ({ onSubmit, setForm, editedData }) => {
  const formValues = {
    questEng: editedData ? editedData.questionEN : "",
    questGer: editedData ? editedData.questionDE : "",
    ansEng: editedData ? editedData.answerEN : "",
    ansGer: editedData ? editedData.answerDE : "",
  };

  const handleFormSubmit = (values) => {
    onSubmit(values, editedData ? editedData.id : undefined);
  };

  return (
    <div className="text-sm">
      <div className="text-primary">Add Question & Answer</div>
      <Formik
        initialValues={formValues}
        validationSchema={formValidations}
        validateOnMount={true}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
        }) => (
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <div className="mt-8">
              {/* <label htmlFor='questEng'>Question in Englsih</label> */}
              <Input
                type="text"
                name="questEng"
                id="questEng"
                label="Question in English"
                value={values.questEng}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-red font-sans">
                {errors.questEng && touched.questEng && errors.questEng}
              </p>
            </div>
            <div className="mt-8">
              {/* <label htmlFor='questGer'>Question in German</label> */}
              <Input
                type="text"
                name="questGer"
                id="questGer"
                label="Question in German"
                value={values.questGer}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-red font-sans">
                {errors.questGer && touched.questGer && errors.questGer}
              </p>
            </div>
            <div className="mt-8">
              {/* <label htmlFor='ansEng'>Answer in Englsih</label> */}
              <Input
                type="text"
                name="ansEng"
                id="ansEng"
                label="Answer in English"
                value={values.ansEng}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-red font-sans">
                {errors.ansEng && touched.ansEng && errors.ansEng}
              </p>
            </div>
            <div className="mt-8">
              {/* <label htmlFor='ansGer'>Answer in German</label> */}
              <Input
                type="text"
                name="ansGer"
                id="ansGer"
                label="Answer in German"
                value={values.ansGer}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-red font-sans">
                {errors.ansGer && touched.ansGer && errors.ansGer}
              </p>
            </div>
            <div className="flex flex-row gap-4 md:w-1/2 mt-8">
              <Button type="submit" label="Save" />
              <Button
                type="reset"
                label="Cancel"
                textsize="base"
                background={"none"}
                color={"black"}
                bordercolor={"primary"}
                onClick={() => setForm(false)}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default FaqForm;
