import axios from "../http-config";

class ArtistPortfolioService {

    getArtistPortfolioById(id) {
        return axios({
            method: "GET",
            url: `/artistportfolios/${id}`
        });
    }

    getTmpArtistPortfolioById(id) {
        return axios({
            method: "GET",
            url: `/artistportfolios/tmp-details/${id}`
        });
    }

    getArtistPortfoliosByArtistId(artistId) {
        return axios({
            method: "GET",
            url: `/artistportfolios/artistId/${artistId}`
        });
    }

    createArtistPortfolio(data) {
        return axios({
            method: "POST",
            url: `/artistportfolios`,
            data
        });
    }

    updateArtistPortfolio(id, data) {
        return axios({
            method: "PUT",
            url: `/artistportfolios/${id}`,
            data
        });
    }
}

export default new ArtistPortfolioService();
