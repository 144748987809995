import * as React from "react";
import EditBudget from "components/EditBudget";
import { useEffect } from "react";

//slider
const marks = [
  {
    value: 50,
    label: "50",
  },
  {
    value: 3600,
    label: "3500",
  },
];

const BudgetPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <EditBudget marks={marks} {...props} />;
};

export default BudgetPage;
