import { getUserRole } from "layouts/ClientConstants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStudioArtistReviewsAvg } from "redux/actions/StudioReviewsAction";
import { getUserById } from "redux/actions/UserActions";
import { ArtistProfileView } from "./ArtistProfileView";
import { TABS } from "layouts/ClientConstants";
import { getArtistPublishedRatings} from "redux/actions/ReviewsAction";

export const ArtistProfileContainer = (props) => {
  const artistId = props.match.params.id;
  const role = getUserRole(props?.loginData?.userRoleId);
  const [selectedTab, setSelectedTab] = useState(TABS.PORTFOLIO.id);
  const reviewsData = useSelector((state) => state.artistrating.reviewsData);

  let artistRatings = reviewsData ? Object.assign([], reviewsData) : [];


  const dispatch = useDispatch();
  const artist = useSelector((state) => state.user);
  const artistReviewsAvg = useSelector(
    (state) => state.studioReviews.studioArtistReviewsAvg
  );

  
   useEffect(() => {
    dispatch(getUserById(artistId));
    dispatch(getStudioArtistReviewsAvg(artistId));
    dispatch(getArtistPublishedRatings(artistId));
  }, [artistId, dispatch]);

  return (
    <ArtistProfileView
      loginData={artist.data ?? props.loginData}
      role={role}
      artistError={artist.error}
      actualLoginData={props.loginData}
      artistReviewsAvg={artistReviewsAvg}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}      
      artistRatings={artistRatings}
    />
  );
};
