/* eslint-disable eqeqeq */
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Card, CardContent, Rating } from "@mui/material";
import { useEffect, useState } from "react";
import { SERVER_URL } from "utils/constants";
import { ReactComponent as Workplace } from "../../../assets/ic_workplace.svg";
import { styled } from '@mui/material/styles';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#C7A33F',
  },
  '& .MuiRating-iconHover': {
    color: '#AFAFAF',
  },
  '& .MuiRating-iconEmpty': {
    color: '#AFAFAF',
  },
});

const PendingPortfolios = ({ pendingPortfolios }) => {
  const [artistPortfolio, setArtistPortfolio] = useState([]);

  useEffect(() => {
    const combinedItems = (arr = []) => {
      const res = arr.reduce((acc, obj) => {
        let found = false;
        for (let i = 0; i < acc.length; i++) {
          if (acc[i].id === obj.id) {
            found = true;
            acc[i].artist_portfolios = acc[i].artist_portfolios.concat(
              obj.tmp_artist_portfolios
            );
            acc[i].count++;
          }
        }
        if (!found) {
          obj.count = 1;
          acc.push(obj);
        }
        return acc;
      }, []);
      return res;
    };
    setArtistPortfolio(combinedItems(pendingPortfolios?.artistPortfolio));
  }, [pendingPortfolios]);

  let history = useHistory();

  const artistonClick = (artistId) => {
    history.push({
      pathname: "/admin/artist/" + artistId,
    });
  };

  return (
    <div className="flex grid lg:grid-cols-3 md:grid-cols-2 gap-4 flex-wrap text-sm w-full mt-10">
      {artistPortfolio &&
        artistPortfolio.map((item) => {
          return (
            <Card
              style={{ borderRadius: "20px", paddingRight: "10px" }}
              className="rounded-xl"
              key={item.id}
            >
              <CardContent>
                <div
                  className="flex flex-row gap-4"
                  onClick={() => artistonClick(item.id)}
                >
                  <img
                    className="h-12 w-12 rounded-full object-cover object-center"
                    src={
                      item?.photoUrl
                        ? SERVER_URL + item?.photoUrl
                        : SERVER_URL +
                          "uploads/default-images/DefaultProfile.jpeg"
                    }
                    alt="profile"
                  />
                  <div className="flex flex-col gap-1 flex-1">
                    <div className="flex flex-row align-right">
                      <div className="flex flex-col gap-0">
                        <div className="flex flex-row gap-0 item-end">
                          <div className="font-bold">
                            {item.firstName} {item.lastName}
                          </div>
                          {item.artist_work_places.length > 0 && (
                            <div
                              className="text-sm"
                              style={{ color: "#b5b1a7" }}
                            >
                              Public
                            </div>
                          )}
                        </div>
                        <div className="flex items-center mt-1">
                          {/* Average of rating */}
                          <StyledRating
                            name="simple-controlled"
                            value={item.artist_ratings.reduce(
                              (acc, v, i, a) => acc + v.rating / a.length,
                              0
                            )}
                            size="small"
                          />
                          {/* Number of ratings */}
                          <span className="ml-1 text-sm text-tertiary">
                            ({item.artist_ratings.length})
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="text-tertiary text-sm mb-0 mt-0">
                      {item.country ? item.country : "Country Not Selected"}
                    </div>
                    <div className="flex flex-row items-center mb-1">
                      <Workplace className="mr-2 w-4 h-4" />
                      {item.artist_work_places.length == 0 && (
                        <div className="text-sm" style={{ color: "#b5b1a7" }}>
                          No Place
                        </div>
                      )}
                    </div>
                    <div className="text-primary text-wrap break-words">
                      {item?.artist_portfolios?.map((item, index) => (
                        <div key={index}>{item.style.styleName}</div>
                      ))}
                    </div>
                    <div className="text-primary text-wrap break-words">
                      {item?.tmp_artist_portfolios?.map((item, index) => (
                        <div>{item.style.styleName}</div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <div className="bg-primary rounded-md py-0 px-2 text-white font-normal">
                      {item?.artist_portfolios
                        ? item?.artist_portfolios.length
                        : 0}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          );
        })}
    </div>
  );
};

export default PendingPortfolios;
