import { Formik } from "formik";
import * as yup from "yup";
import Input from "../../../../components/UI/Input";
import Button from "../../../../components/UI/Button";
import AlertMessage from "../../../../components/AlertMessage";
import { sections } from "views/project/CONSTANTS";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { resetPwd } from "redux/actions/UserActions";

const managerValidationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const ForgotPasswordPage = (props) => {
  const dispatch = useDispatch();
  const [forgotPasswordAlert, setShowForgotPasswordAlert] = useState(false);
  const forgotPasswordAlertMessage = "An email is sent to your email address";
  const forgotPasswordData = useSelector((state) => state.user);

  const onForgotPassword = (values) => {
    let obj = {
      email: values.email,
    };
    dispatch(resetPwd(obj)).then(() => {
      setShowForgotPasswordAlert(true);
    });
  };

  return (
    <>
      <div className="flex md:grid grid-cols-4">
        <div className="flex flex-1 justify-start col-start-1 col-span-2 xl:col-span-2">
          <div className="w-full">
            <div>
              <p className="mt-4 text-base text-tertiary font-sans">
                Please enter your email we will send you a link to your inbox to
                reset your password
              </p>
            </div>

            <div className="mt-0">
              <div className="mt-4">
                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={managerValidationSchema}
                  validateOnMount={true}
                  onSubmit={(values) => {
                    onForgotPassword(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className="overflow-hidden">
                        <Input
                          type="text"
                          name="email"
                          id="email"
                          label="Email"
                          autoComplete="username"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        <p className="text-red font-sans">
                          {errors.email && touched.email && errors.email}
                        </p>
                      </div>

                      <div className="flex items-center mt-5 space-x-2">
                        <Button
                          label="Back"
                          textsize="base"
                          onClick={() =>
                            props.setSelectedSection(sections.Login)
                          }
                          background="white"
                          bordercolor="primary"
                          color="primary"
                        />
                        <Button
                          type="submit"
                          width="full"
                          textsize="base"
                          label="Reset"
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
              {forgotPasswordData.error}
            </div>
          </div>
        </div>
        <AlertMessage
          message={forgotPasswordAlertMessage}
          showAlert={forgotPasswordAlert}
          setShowAlert={setShowForgotPasswordAlert}
          onAlertClose={() => props.setSelectedSection(sections.Login)}
        />
      </div>
    </>
  );
};

export default ForgotPasswordPage;
