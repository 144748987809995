import {
  NEWSLETTER_RESET,
  NEWSLETTER_LOADING,
  NEWSLETTER_ERROR,
  NEWSLETTER_GET_ALL_DATA,
  NEWSLETTER_GET_DATA,
  NEWSLETTER_CREATE_DATA,
  NEWSLETTER_UPDATE_DATA,
  NEWSLETTER_DELETE_DATA,
} from "../actions/NewsletterAction";

const initialState = {
  loading: false,
  success: false,
  getAll: null,
  get: null,
  create: null,
  update: null,
  delete: null,
  error: null,
};

const NewsletterReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEWSLETTER_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        // getAll: null,
        get: null,
        create: null,
        update: null,
        delete: null,
        error: null,
      };
    }
    case NEWSLETTER_GET_ALL_DATA: {
      return {
        ...state,
        loading: false,
        success: true,
        getAll: action.payload,
        get: null,
        create: null,
        update: null,
        delete: null,
        error: null,
      };
    }
    case NEWSLETTER_GET_DATA: {
      return {
        ...state,
        loading: false,
        success: true,
        get: action.payload,
        create: null,
        update: null,
        delete: null,
        error: null,
      };
    }
    case NEWSLETTER_CREATE_DATA: {
      return {
        ...state,
        loading: false,
        success: true,
        getAll: null,
        get: null,
        create: action.payload,
        update: null,
        delete: null,
        error: null,
      };
    }
    case NEWSLETTER_UPDATE_DATA: {
      return {
        ...state,
        loading: false,
        success: true,
        getAll: null,
        get: null,
        create: null,
        update: action.payload,
        delete: null,
        error: null,
      };
    }
    case NEWSLETTER_DELETE_DATA: {
      return {
        ...state,
        loading: false,
        success: true,
        getAll: null,
        get: null,
        create: null,
        update: null,
        delete: action.payload,
        error: null,
      };
    }
    case NEWSLETTER_ERROR: {
      return {
        ...state,
        loading: false,
        success: false,
        getAll: null,
        get: null,
        create: null,
        update: null,
        delete: null,
        error: action.payload ? action.payload : "Unknown Error Occured",
      };
    }
    default:
      return { ...state };
  }
};

export default NewsletterReducer;
