export const navigations = [
  {
    id: "1",
    name: "Upload Pictures",
    href: "#",
    current: true,
  },
  {
    id: "2",
    name: "Place your Tattoo",
    href: "#",
    current: false,
  },
  {
    id: "3",
    name: "Budget",
    href: "#",
    current: false,
  },
  {
    id: "4",
    name: "Date Proposal",
    href: "#",
    current: false,
  },
  {
    id: "5",
    name: "Finish",
    href: "#",
    current: false,
  },
];

export const sections = {
  UploadPictures: "1",
  PlaceYourTatto: "2",
  Budget: "3",
  DateProposal: "4",
  Location: "5",
  Finish: "6",
  Submit: "7",
  Login:"8",
  ForgotPassword:"9"
};

export const getUserRole = (roleId) => {
  switch (roleId) {
    case 1:
      return "Admin";
    case 2:
      return "Boss";
    case 3:
      return "Artist";
    case 4:
      return "Client";
    case 5:
      return "Manager";
    default:
      return "";
  }
};

export const getProjectStatus = (statusId) => {
  switch (statusId) {
    case 1:
      return "New Project";
    case 2:
      return "Accepted Project by Admin"; //Admin refers to studio manager or boss
    case 3:
      return "Withdrawn Project";
    case 4:
      return "Declined Project";
    case 5:
      return "Project Done";
    case 6:
      return "Counter Offer by Admin"; //Admin refers to studio manager or boss
    case 7:
      return "Accepted Project by Artist";
    case 8:
      return "Counter Offer by Artist";
    case 9:
      return "Artist Confrimed";
    case 10:
      return "Appointment Date Confirmation";
    case 11:
      return "Appointment Date Confirmed";
    case 12:
      return "Studio Done";
    case 13:
      return "Refunded"
    default:
      return "";
  }
};

export const getProjectStatusByName = {
  New_Project: 1,
  Accepted_Project_by_Admin: 2,
  Withdrawn_Project: 3,
  Declined_Project: 4,
  Project_Done: 5,
  Counter_Offer_by_Admin: 6,
  Accepted_Project_by_Artist: 7,
  Counter_Offer_by_Artist: 8,
  Artist_Confrimed: 9,
  Appointment_Date_Confirmation: 10,
  Appointment_Date_Confirmed: 11,
  Studio_Done: 12,
  Refunded: 13,
};

export const getUserType = (userTypeId) => {
  switch (userTypeId) {
    case 1:
      return "Client";
    case 2:
      return "Artist";
    case 3:
      return "Studio";
    case 4:
      return "Hichem";
    default:
      return "Client";
  }
};

export const USER_ROLE_BOSS = "Boss";
export const USER_ROLE_ADMIN = "Admin";
export const USER_ROLE_MANAGER = "Manager";
export const USER_ROLE_ARTIST = "Artist";
export const USER_ROLE_CLIENT = "Client";

export const USER_ROLE_PROJECT_ADMIN = "ProjectAdmin";
export const USER_ROLE_PROJECT_MYSTUDIO_ARTIST = "MSArtist";

// export const HISHEM = 3;

export const TIME_RANGE_ERROR_MESSAGE = "Selected time range is incorrect";
export const DUPLICATE_TIME_RANGE_ERROR_MESSAGE =
  "Duplicate time range selected";

export const projectRequestKeys = ["comments", "color","styles","techniques","files", "height","width","gender", "skinToneId","modelPicture", "isTatooAround", "fromPrice","toPrice", "proposedDays","latitude", "longitude"];
