import NewsletterService from "services/NewsletterService";

export const NEWSLETTER_RESET = "NEWSLETTER_RESET";
export const NEWSLETTER_LOADING = "NEWSLETTER_LOADING";
export const NEWSLETTER_ERROR = "NEWSLETTER_ERROR";
export const NEWSLETTER_GET_ALL_DATA = "NEWSLETTER_GET_ALL_DATA";
export const NEWSLETTER_GET_DATA = "NEWSLETTER_GET_DATA";
export const NEWSLETTER_CREATE_DATA = "NEWSLETTER_CREATE_DATA";
export const NEWSLETTER_UPDATE_DATA = "NEWSLETTER_UPDATE_DATA";
export const NEWSLETTER_DELETE_DATA = "NEWSLETTER_DELETE_DATA";

export const resetNewsletter = () => async (dispatch) => {
  dispatch({
    type: NEWSLETTER_RESET,
  });
};

export const getNewsletters = () => async (dispatch) => {
  dispatch({
    type: NEWSLETTER_LOADING,
  });
  try {
    let res = await NewsletterService.getNewsletters();

    return dispatch({
      type: NEWSLETTER_GET_ALL_DATA,
      payload: res.data.newsletter,
    });
  } catch (err) {
    return dispatch({
      type: NEWSLETTER_ERROR,
      payload: err.response,
    });
  }
};

export const getNewsletter = (id) => async (dispatch) => {
  dispatch({
    type: NEWSLETTER_LOADING,
  });
  try {
    let res = await NewsletterService.getNewsletter(id);

    return dispatch({
      type: NEWSLETTER_GET_DATA,
      payload: res.data.newsletter,
    });
  } catch (err) {
    return dispatch({
      type: NEWSLETTER_ERROR,
      payload: err.response,
    });
  }
};

export const createNewsletter = (data) => async (dispatch) => {
  dispatch({
    type: NEWSLETTER_LOADING,
  });
  try {
    let res = await NewsletterService.createNewsletter(data);
    return dispatch({
      type: NEWSLETTER_CREATE_DATA,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: NEWSLETTER_ERROR,
      payload: err.response,
    });
  }
};

export const updateNewsletter = (id, data) => async (dispatch) => {
  dispatch({
    type: NEWSLETTER_LOADING,
  });
  try {
    let res = await NewsletterService.updateNewsletter(id, data);
    return dispatch({
      type: NEWSLETTER_UPDATE_DATA,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: NEWSLETTER_ERROR,
      payload: err.response,
    });
  }
};

export const deleteNewsletter = (id, data) => async (dispatch) => {
  dispatch({
    type: NEWSLETTER_LOADING,
  });
  try {
    let res = await NewsletterService.deleteNewsletter(id, data);
    return dispatch({
      type: NEWSLETTER_DELETE_DATA,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: NEWSLETTER_ERROR,
      payload: err.response,
    });
  }
};
