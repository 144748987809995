import SubscriberService from "services/SubscriberService";

export const CREATE_SUBSCRIBER_LOADING = "CREATE_SUBSCRIBER_LOADING";
export const CREATE_SUBSCRIBER_SUCCESS = "CREATE_SUBSCRIBER_SUCCESS";
export const CREATE_SUBSCRIBER_ERROR = "CREATE_SUBSCRIBER_ERROR";
export const CREATE_SUBSCRIBER_RESET = "CREATE_SUBSCRIBER_RESET";

export const createSubscriber = (data) => async (dispatch) => {
  dispatch({
    type: CREATE_SUBSCRIBER_LOADING,
  });
  try {
    let res = await SubscriberService.createSubscriber(data);
    return dispatch({
      type: CREATE_SUBSCRIBER_SUCCESS,
      payload: res.data.subscriber,
    });
  } catch (err) {
    return dispatch({
      type: CREATE_SUBSCRIBER_ERROR,
      payload: err.response,
    });
  }
};

export const resetSubscriber = () => async (dispatch) => {
  dispatch({
    type: CREATE_SUBSCRIBER_RESET,
  });
};
