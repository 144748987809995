import React, { useState, useEffect } from "react";
import StudioProjects from "./StudioProjects";
import { useDispatch, useSelector } from "react-redux";

const CurrentProjects = (props) => {
  const [checkedProcess, setCheckedProcess] = useState(false);
  const [checkedAppointment, setCheckedAppointment] = useState(false);
  const [checkedWaiting, setCheckedWaiting] = useState(false);

  const [projectListData, setProjectListData] = useState(
    props?.studioProjects?.project
  );
  let inProcessCount = 0;
  let inAppointmentCount = 0;
  let inWaitingCount = 0;

  useEffect(() => {
    setProjectListData(props?.studioProjects?.project);
  }, [props]);

  let inProcessData =
    props.studioProjects && props.studioProjects.project
      ? props.studioProjects.project.filter(
          (item) => item.projectStatusId === 1
        )
      : [];
  inProcessCount = inProcessData.length;

  let inWaitingData =
    props.studioProjects && props.studioProjects.project
      ? props.studioProjects.project.filter(
          (item) => item.projectStatusId === 7
        )
      : [];
  inWaitingCount = inWaitingData.length;

  let inAppointmentData =
    props.studioProjects && props.studioProjects.project
      ? props.studioProjects.project.filter(
          (item) => item.projectStatusId === 10
        )
      : [];
  inAppointmentCount = inAppointmentData.length;

  const handleChangeInProcess = () => {
    setCheckedProcess(!checkedProcess);
    if (!checkedProcess) {
      setProjectListData(inProcessData);
    } else {
      setProjectListData(props?.studioProjects?.project);
    }
  };

  const handleChangeInWaiting = () => {
    setCheckedWaiting(!checkedWaiting);
    if (!checkedWaiting) {
      setProjectListData(inWaitingData);
    } else {
      setProjectListData(props?.studioProjects?.project);
    }
  };

  const handleChangeInAppointment = () => {
    setCheckedAppointment(!checkedAppointment);
    if (!checkedAppointment) {
      setProjectListData(inAppointmentData);
    } else {
      setProjectListData(props?.studioProjects?.project);
    }
  };

  return (
    <div>
      <div className="flex space-x-6 items-center">
        <div className="flex items-center">
          <input
            className={`h-5 w-5 border border-gray rounded bg-white mt-0 align-top float-left mr-2 form-checkbox text-primary focus:ring-0`}
            type="checkbox"
            name="isInProcess"
            id="isInProcess"
            checked={checkedProcess}
            onChange={handleChangeInProcess}
          />
          <label className="inline-block items-center" htmlFor="isInProcess">
            In Process {inProcessCount}
          </label>
        </div>
        <div className="flex items-center">
          <input
            className="h-5 w-5 border border-gray rounded bg-white mt-0 align-top float-left mr-2 form-checkbox text-primary focus:ring-0"
            type="checkbox"
            name="isInAppointment"
            id="isInAppointment"
            checked={checkedAppointment}
            onChange={handleChangeInAppointment}
          />
          <label className="inline-block" htmlFor="isInAppointment">
            Appointment Date {inAppointmentCount}
          </label>
        </div>
        <div className="flex items-center">
          <input
            className="h-5 w-5 border border-gray rounded bg-white mt-0 align-top float-left mr-2 form-checkbox text-primary focus:ring-0"
            type="checkbox"
            name="isInWaiting"
            id="isInWaiting"
            checked={checkedWaiting}
            onChange={handleChangeInWaiting}
          />
          <label className="inline-block" htmlFor="isInWaiting">
            Waiting for Confirmation {inWaitingCount}
          </label>
        </div>
      </div>
      <StudioProjects selectedTab={props.selectedTab} data={projectListData} />
    </div>
  );
};

export default CurrentProjects;
