import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import CryptoJS from "crypto-js";
import { SCERETPASS } from "../utils/constants";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const setPasswordValidationSchema = yup.object({
  password: yup.string().required("This field is required"),
  confirmPassword: yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: yup
      .string()
      .oneOf(
        [yup.ref("password")],
        "Password and Confirmed Password should match."
      ),
  }),
});

export default function SetPassword(props) {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation();

  const handleFormSubmit = (values) => {
    var encrypted = CryptoJS.AES.encrypt(
      values.password,
      SCERETPASS
    ).toString();
    let obj = {
      password: encrypted,
    };
    props.onSubmit(obj);
  };

  let buttonLabel = props.from === "studio" ? "Save" : "Register";

  return (
    <div className="md:mt-10 lg:mt-18">
      <div className="mt-4 md:mt-10 lg:mt-16">
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          validationSchema={setPasswordValidationSchema}
          onSubmit={(values) => {
            handleFormSubmit(values);
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="">
              <div className="overflow-hidden">
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  label={t("login.password")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  showEye={true}
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                />
                <p className="text-red font-sans">{errors.password}</p>
              </div>
              <div className="overflow-hidden">
                <Input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  id="confirmPassword"
                  label="Confirm Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  showEye={true}
                  setShowPassword={setShowConfirmPassword}
                  showPassword={showConfirmPassword}
                />
                <p className="text-red font-sans">{errors.confirmPassword}</p>
              </div>
              <div className="flex items-center mt-6">
                <Button
                  type="submit"
                  width="full"
                  textsize="base"
                  label={buttonLabel}
                />
              </div>
              <div className="sm:col-span-1 flex flex-row sm:px-4 mb-10 md:mb-0 items-center mt-3">
                <a
                  className="font-normal text-base text-primary hover:text-primary font-sans no-underline"
                  onClick={() => {
                    history.push({
                      pathname: "/terms",
                    });
                  }}
                >
                  Terms of Service
                </a>
              </div>
            </form>
          )}
        </Formik>
      </div>
      {props.error}
    </div>
  );
}
