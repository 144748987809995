import axios from "../http-config";

class ProjectService {
  createProject(data) {
    return axios({
      method: "POST",
      url: `/projects`,
      headers: { "Content-Type": "multipart/form-data" },
      data,
    });
  }

  getProjectDetails(id) {
    return axios({
      method: "GET",
      url: `/projects/${id}`,
    });
  }

  updateProject(id, data) {
    return axios({
      method: "PATCH",
      url: `/projects/${id}`,
      data,
    });
  }

  getNewProjects(id) {
    return axios({
      method: "GET",
      url: `/projects/projects-new/${id}`,
    });
  }

  getCurrentProjects(id) {
    return axios({
      method: "GET",
      url: `/projects/projects-current/${id}`,
    });
  }

  getHistoryProjects(id) {
    return axios({
      method: "GET",
      url: `/projects/projects-history/${id}`,
    });
  }

  validateUserAndProject(id, pid) {
    return axios({
      method: "GET",
      url: `/projects/validate-user-project/${id}/${pid}`,
    });
  }

  updateTempProjectDetails(id, data) {
    return axios({
      method: "PATCH",
      url: `/projects/temp/${id}`,
      data,
    });
  }

  getProjectsWithSupportTickets(id) {
    return axios({
      method: "GET",
      url: `/projects/project-support/${id}`,
    });
  }

}

export default new ProjectService();
