import PaymentService from "services/PaymentService";
import localStorageService from "../../services/localStorageService";


export const PAYMENT_LOADING = "PAYMENT_LOADING";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_ERROR = "PAYMENT_ERROR";

export const SAVE_PAYMENT_LOADING = "SAVE_PAYMENT_LOADING";
export const SAVE_PAYMENT_SUCCESS = "SAVE_PAYMENT_SUCCESS";
export const SAVE_PAYMENT_ERROR = "SAVE_PAYMENT_ERROR";

export const UPDATE_PAYMENT_LOADING = "UPDATE_PAYMENT_LOADING";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT_ERROR = "UPDATE_PAYMENT_ERROR";

export const PAYMENT_REFUND_LOADING = "PAYMENT_REFUND_LOADING";
export const PAYMENT_REFUND_SUCCESS = "PAYMENT_REFUND_SUCCESS";
export const PAYMENT_REFUND_ERROR = "PAYMENT_REFUND_ERROR";


export const initializePayment = (data) => async (dispatch) => {    
    dispatch({
        type: PAYMENT_LOADING,
    });
    try {
        let res = await PaymentService.initializePayment(data);
        
        return dispatch({
            type: PAYMENT_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        return dispatch({
            type: PAYMENT_ERROR,
            payload: err.response,
        });
    }
};

export const savePaymentDetails = (data) => async (dispatch) => {    
    dispatch({
        type: SAVE_PAYMENT_LOADING,
    });
    try {
        let res = await PaymentService.savePaymentDetails(data);
        let result = res.data;
        if (result.payment.id) {
            localStorageService.setItem("pId", result.payment.id);
        }
        return dispatch({
            type: SAVE_PAYMENT_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        return dispatch({
            type: SAVE_PAYMENT_ERROR,
            payload: err.response,
        });
    }
};

export const updatePaymentDetails = (data, pid) => async (dispatch) => {    
    dispatch({
        type: UPDATE_PAYMENT_LOADING,
    });
    try {
        let res = await PaymentService.updatePaymentDetails(pid, data);
        
        return dispatch({
            type: UPDATE_PAYMENT_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        return dispatch({
            type: UPDATE_PAYMENT_ERROR,
            payload: err.response,
        });
    }
};

//
export const refundPayment = (id, data) => async (dispatch) => {    
    dispatch({
        type: PAYMENT_REFUND_LOADING,
    });
    try {
        let res = await PaymentService.refundPayment(id, data);
        
        return dispatch({
            type: PAYMENT_REFUND_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        return dispatch({
            type: PAYMENT_REFUND_ERROR,
            payload: err.response,
        });
    }
};

