/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import Dropzone from "react-dropzone";
import CustomThumbnail from "components/CustomThumbnail";
import TopNavBar from "components/Navbars/TopNavBar";
import {
  userNavigations,
  PORTFOLIO_STATUS,
  VIEWS,
} from "layouts/ArtistConstants";
import { ReactComponent as ChatIcon } from "../../../assets/ic_chat.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/ic_remove.svg";
import { ReactComponent as MaximiseIcon } from "../../../assets/ic_maximise.svg";
import ShowPicturePreview from "../components/ShowPicturePreview";

const saveArtistPortfolioValidationSchema = yup.object({
  styleTitle: yup
    .string()
    .max(20, ({ max }) => "Title can be of maximum 20 characters long")
    .required("Title is required"),
  styleDesc: yup.string(),
  styleId: yup.string().required("Style is required."),
  artistPortfolioImages: yup.array().min(1, "Atleast One Image Required"),
});

export default function SaveArtistPortfolioView(props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [comments, setComments] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const [styleOptions, setStyleOptions] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState({
    styleTitle: "",
    styleDesc: "",
    styleId: "1",
    artistPortfolioImages: [],
  });
  const [title, setTitle] = useState("");
  const [submitButtonLabel, setSubmitButtonLabel] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const [allcomments, setAllcomments] = useState("");

  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  const handleShowPicture = () => {
    let allCommentArr = comments.filter((item) => !item.isDeleted);
    var allcommentArray = allCommentArr.map(function (i) {
      return i.comment;
    });
    setAllcomments(allcommentArray);
    setEditOpen(true);
  };

  const saveComment = (comment) => {
    // change the formik comment array : this is neede for zoom picture view
    if (
      selectedItem.path !== undefined &&
      formik.values.artistPortfolioImages &&
      formik.values.artistPortfolioImages.length > 0
    ) {
      formik.values.artistPortfolioImages.forEach(function (v, j) {
        if (v.imageName === selectedItem.path) {
          v.comment = comment;
        }
      });
    }

    //Adding comment for newly added artist portfolio image which is NOT saved in the DB.
    if (selectedItem.path !== undefined && selectedItem.id === undefined) {
      //Check if comment already added. If Yes, remove the comment and add newly added comment.
      if (comments.some((d) => d.imageName === selectedItem.path)) {
        for (let i = 0; i < comments.length; i++) {
          if (comments[i].imageName === selectedItem.path) {
            comments.splice(i, 1);
            break;
          }
        }
      }

      let portfolioImageComments = [...comments];
      portfolioImageComments.push({
        artistPortfolioImageId: 0,
        imageName: selectedItem.path,
        comment: comment,
      });
      setComments(portfolioImageComments);
    }

    //Adding comment for existing artist portfolio image which is saved in the DB.
    if (selectedItem.id !== undefined) {
      //Check if comment already added. If Yes, remove the comment and add newly added comment.
      if (comments.some((d) => d.artistPortfolioImageId === selectedItem.id)) {
        for (let i = 0; i < comments.length; i++) {
          if (comments[i].artistPortfolioImageId === selectedItem.id) {
            comments.splice(i, 1);
            break;
          }
        }
      }

      let portfolioImageComments = [...comments];
      portfolioImageComments.push({
        artistPortfolioImageId: selectedItem.id,
        imageName: selectedItem.imageName,
        comment: comment,
      });
      setComments(portfolioImageComments);
    }
  };

  const handleFormSubmit = (values) => {
    const obj = new FormData();
    obj.append("styleTitle", values.styleTitle);
    obj.append("styleId", values.styleId);
    obj.append("artistId", props.loginData.id);
    obj.append("statusId", PORTFOLIO_STATUS.PENDING);
    obj.append("isTmpArtistPortfolio", props.isTmpArtistPortfolioId);
    file.map((file) => obj.append("artistPortfolioImages", file));
    obj.append("artistPortfolioImageComments", JSON.stringify(comments));
    props.onSubmit(obj);
  };

  const populatStyles = () => {
    let styles = props.style;
    let styleOptionItems = [];
    if (styles && styles.length > 0) {
      styleOptionItems = styles.map((style) => (
        <option key={style.id} value={style.id}>
          {style.styleName}
        </option>
      ));
    }
    setStyleOptions(styleOptionItems);
  };

  const loadPortfolio = () => {
    let artistPortfolio = props.artistPortfolio;
    let artistPortfolioImages;
    if (
      props.isTmpArtistPortfolioId == 0 &&
      artistPortfolio?.artist_portfolio_images
    ) {
      let tempComments = artistPortfolio.artist_portfolio_images.map((obj) => {
        return {
          comment: obj.comment,
          imageName: obj.imageUrl,
          artistPortfolioImageId: obj.id,
        };
      });
      setComments(tempComments);

      artistPortfolioImages = artistPortfolio.artist_portfolio_images.map(
        (obj) => {
          return {
            comment: obj.comment,
            imageName: obj.imageUrl,
            artistPortfolioImageId: obj.id,
            path: obj.imageUrl,
            imageUrl: obj.imageUrl,
            id: obj.id,
          };
        }
      );
    }

    if (props.isTmpArtistPortfolioId == 1 && artistPortfolio?.tmpImg) {
      let tempComments = artistPortfolio.tmpImg.map((obj) => {
        return {
          comment: obj.comment,
          imageName: obj.imageUrl,
          artistPortfolioImageId: obj.id,
        };
      });
      setComments(tempComments);

      artistPortfolioImages = artistPortfolio.tmpImg.map((obj) => {
        return {
          comment: obj.comment,
          imageName: obj.imageUrl,
          artistPortfolioImageId: obj.id,
          path: obj.imageUrl,
          imageUrl: obj.imageUrl,
          id: obj.id,
        };
      });
    }

    let initialFormValuesTemp;
    if (artistPortfolio) {
      initialFormValuesTemp = {
        styleTitle: artistPortfolio.styleTitle,
        styleDesc:
          selectedItem?.comment !== undefined ? selectedItem.comment : "",
        styleId: artistPortfolio.styleId,
        artistPortfolioImages: artistPortfolioImages
          ? [...artistPortfolioImages, ...file]
          : [],
      };
    } else {
      initialFormValuesTemp = {
        styleTitle: "",
        styleDesc: "",
        styleId: "1",
        artistPortfolioImages: [],
      };
    }

    setInitialFormValues(initialFormValuesTemp);

    let tempTitle = props.selectedArtistPortfolioId
      ? "Edit Portfolio"
      : props.calledFrom == VIEWS.ARTIST_PROFILE.id
      ? "Create your Portfolio!"
      : "Add Portfolio";
    let tempSubmitButtonLabel = props.selectedArtistPortfolioId
      ? "Save"
      : "Create Portfolio";

    setTitle(tempTitle);
    setSubmitButtonLabel(tempSubmitButtonLabel);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    validationSchema: saveArtistPortfolioValidationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const fillcomments = (filePath) => {
    let cValue = "";
    for (let i = 0; i < comments.length; i++) {
      if (comments[i].imageName === filePath) {
        cValue = comments[i].comment;
      }
    }

    formik.setFieldValue("styleDesc", cValue ? cValue : "");
  };

  useEffect(() => {
    populatStyles();
    loadPortfolio();
  }, [props.style, props.selectedArtistPortfolioId, props.artistPortfolio]);

  return (
    <>
      <div className="h-full flex">
        <div className="flex-1 flex flex-col overflow-hidden h-full bg-bgg">
          <TopNavBar
            userNavigations={userNavigations}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            loginData={props.loginData}
            hideMenuOptions={
              props.calledFrom == VIEWS.ARTIST_PROFILE.id ||
              props.calledFrom == VIEWS.ARTIST_PORTFOLIO_LIST.id
                ? true
                : false
            }
          />
          {/* Content area */}
          <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
            {/* {(props.calledFrom != VIEWS.ARTIST_PROFILE.id && props.calledFrom != VIEWS.ARTIST_PORTFOLIO_LIST.id) && (
              <div className="w-full md:w-1/4 md:h-full bg-white">
                <ArtistSideNavBar loginData={props.loginData} avgRating={props?.studioReviewsAvg}/>
              </div>
            )} */}
            {/* {props.calledFrom != VIEWS.ARTIST_PROFILE.id &&
              props.calledFrom != VIEWS.ARTIST_PORTFOLIO_LIST.id && (
                <div className="w-full md:w-1/4 md:h-full bg-white">
                  <ArtistSideNavBar loginData={props.loginData} />
                </div>
              )} */}
            {/* Main content */}
            <div className="bg-white  w-full">
              <main className="overflow-hidden">
                <section
                  className="relative bg-white"
                  aria-labelledby="register-heading"
                >
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="relative bg-white">
                      {/*  form */}
                      <FormikProvider value={formik}>
                        <div className="">
                          <form
                            encType="multipart/form-data"
                            onSubmit={formik.handleSubmit}
                            className="md:grid md:grid-cols-2 lg:grid-cols-6 xl:grid-cols-8 md:gap-x-6 xl:gap-x-8"
                          >
                            <div className="md:col-start-1 md:col-span-2 lg:col-start-2 lg:col-span-3 xl:col-start-3 xl:col-span-4 ">
                              <div className="py-5 lg:py-5">
                                <div className="relative z-0 max-w-7xl mx-auto pl-0 pr-8 sm:px-0 lg:px-0">
                                  <h1 className="mt-0 pt-0 text-3.5xl font-bold text-primary font-sans">
                                    {title}
                                  </h1>
                                </div>
                              </div>
                            </div>
                            <div className="md:mb-4 md:col-start-1 lg:col-start-2 lg:col-span-2 xl:col-start-3 xl:col-span-2">
                              <Input
                                type="text"
                                name="styleTitle"
                                id="styleTitle"
                                className="bg-white"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.styleTitle}
                                label="Title"
                              />
                              <p className="text-red">
                                {formik.errors.styleTitle &&
                                  formik.touched.styleTitle &&
                                  formik.errors.styleTitle}
                              </p>
                            </div>
                            <div className="mb-5 md:mb-0 lg:col-span-2 xl:col-span-2">
                              <div className="border border-primary rounded-2xl px-3 py-2 mt-4 shadow-sm focus-within:ring-0 focus-within:ring-primary focus-within:border-primary">
                                <label className="block text-xs font-medium text-primary font-sans">
                                  Select Style
                                </label>
                                <select
                                  label="Select Style"
                                  className="w-full appearance-none font-sans text-base focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent"
                                  name="styleId"
                                  id="styleId"
                                  value={formik.values.styleId}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                >
                                  {styleOptions}
                                </select>
                                <p className="text-red">
                                  {formik.errors.styleId &&
                                    formik.touched.styleId &&
                                    formik.errors.styleId}
                                </p>
                              </div>
                            </div>
                            <div
                              className={`md:col-start-1 md:col-span-2 lg:col-start-2 lg:col-span-4 xl:col-start-3 col-span-4 ${
                                formik.values.artistPortfolioImages.length > 0
                                  ? "mb-0"
                                  : "mb-5"
                              }`}
                            >
                              <div>
                                <Dropzone
                                  className=""
                                  onDrop={(acceptedFiles) => {
                                    formik.setFieldValue(
                                      "artistPortfolioImages",
                                      formik.values.artistPortfolioImages.concat(
                                        acceptedFiles
                                      )
                                    );
                                    setFile((prev) => [
                                      ...prev,
                                      ...acceptedFiles,
                                    ]);
                                  }}
                                  accept="image/*"
                                  minSize={1024}
                                  maxSize={3072000}
                                >
                                  {({
                                    getRootProps,
                                    getInputProps,
                                    isDragActive,
                                    isDragAccept,
                                    isDragReject,
                                  }) => {
                                    const additionalClass = isDragAccept
                                      ? "accept"
                                      : isDragReject
                                      ? "reject"
                                      : "";

                                    return (
                                      <div
                                        {...getRootProps({
                                          className: `dropzone ${additionalClass}`,
                                        })}
                                        className="flex flex-col border-primary border border-dashed p-10 rounded-2xl items-center stroke-2"
                                      >
                                        <p className="font-sans text-primary text-xs mb-2">
                                          Drag & Drop images here
                                        </p>
                                        <p className="font-sans text-primary text-xs  mb-2">
                                          {" "}
                                          Or
                                        </p>
                                        <input {...getInputProps()} />
                                        <span>
                                          {/* {isDragActive ? "📂" : "📁"} */}
                                          <div className="bg-primary px-10 py-2 rounded-3xl text-white font-sans font-bold text-xs">
                                            {" "}
                                            Add Images
                                          </div>
                                        </span>
                                      </div>
                                    );
                                  }}
                                </Dropzone>
                                <p className="text-red">
                                  {formik.errors.artistPortfolioImages &&
                                    formik.touched.artistPortfolioImages &&
                                    formik.errors.artistPortfolioImages}
                                </p>
                              </div>
                              {/* <div
                                className={`${
                                  formik.values.artistPortfolioImages.length
                                    ? "h-28 mb-1"
                                    : "h-0"
                                } w-full`}
                              >
                                <ul className="flex flex-row">
                                  {formik.values.artistPortfolioImages &&
                                    formik.values.artistPortfolioImages.map(
                                      (file, i) => (
                                        <div
                                          className={`flex m-2 ${
                                            selectedItem &&
                                            file.path == selectedItem.path
                                              ? "border-2"
                                              : ""
                                          } border-primary w-24 h-24 rounded-xl overflow-hidden`}
                                          key={i}
                                        >
                                          <CustomThumbnail
                                            key={i}
                                            file={file}
                                            height={90}
                                            width={90}
                                            selectedItem={setSelectedItem}
                                            onClick={() =>
                                              fillcomments(file.path)
                                            }
                                          />
                                          <div className="flex mt-2 -ml-6">
                                            <ChatIcon
                                              hidden={
                                                !(
                                                  selectedItem &&
                                                  file.path == selectedItem.path
                                                ) && comments[i]?.comment
                                                  ? false
                                                  : true
                                              }
                                              onClick={() =>
                                                fillcomments(file.path)
                                              }
                                            />
                                            <DeleteIcon
                                              hidden={
                                                selectedItem &&
                                                file.path == selectedItem.path
                                                  ? false
                                                  : true
                                              }
                                              onClick={() => {                                                
                                                var temp =
                                                  formik.values.artistPortfolioImages.filter(
                                                    (item) =>
                                                      item.path != file.path
                                                  );
                                                setFile(temp);
                                                formik.setFieldValue(
                                                  "artistPortfolioImages",
                                                  temp
                                                );
                                                comments.map((item) => {
                                                  if (item == comments[i]) {
                                                    item.isDeleted = true;
                                                  }
                                                });

                                                setComments(comments);
                                              }}
                                            />
                                          </div>
                                          <div className="absolute flex" style={{marginLeft: '4rem', marginTop: '4.4rem'}}>
                                            <MaximiseIcon onClick={() => handleShowPicture()} />
                                          </div>
                                        </div>
                                      )
                                    )}
                                </ul>
                              </div> */}
                              <div
                                className={`${
                                  formik.values.artistPortfolioImages.length
                                    ? "h-42 mb-1"
                                    : "h-0"
                                } w-screen -mx-5 md:mx-0 md:w-full`}
                              >
                                <ul className="whitespace-nowrap overflow-x-scroll mx-5 md:mx-0 my-2">
                                  {" "}
                                  {formik.values.artistPortfolioImages.map(
                                    (file, i) => (
                                      <div
                                        className={`inline-flex relative  ${
                                          selectedItem &&
                                          file.path == selectedItem.path
                                            ? "border-primary"
                                            : "border-white"
                                        }  border-2 h-36 w-36 space-x-3 rounded-2xl`}
                                        key={i}
                                      >
                                        <CustomThumbnail
                                          file={file}
                                          selectedItem={setSelectedItem}
                                          onClick={() => {
                                              fillcomments(file.path);
                                          }}
                                          rounded = "rounded-2xl"
                                        />
                                        <div className="absolute mt-2">
                                          <ChatIcon
                                            hidden={
                                              (formik.values.artistPortfolioImages[i]?.comment) || ((
                                                selectedItem &&
                                                file.path == selectedItem.path
                                              ) && comments[i]?.comment) || (comments[i]?.comment)
                                                ? false
                                                : true
                                            }
                                            onClick={() =>
                                              fillcomments(file.path)
                                            }
                                          />
                                        </div>
                                        <div className="absolute top-1 right-1">
                                          <DeleteIcon
                                            hidden={
                                              selectedItem &&
                                              file.path == selectedItem.path
                                                ? false
                                                : true
                                            }
                                            onClick={() => {
                                              var temp =
                                                formik.values.artistPortfolioImages.filter(
                                                  (item) =>
                                                    item.path !== file.path
                                                );
                                              setFile(temp);
                                              formik.setFieldValue(
                                                "artistPortfolioImages",
                                                temp
                                              );
                                              comments.map((item) => {
                                                if (item == comments[i]) {
                                                  item.isDeleted = true;
                                                }
                                              });

                                              setComments(comments);
                                            }}
                                          />
                                        </div>
                                        <div className="absolute right-1 bottom-1">
                                          <MaximiseIcon
                                            onClick={() => handleShowPicture()}
                                          />
                                        </div>
                                      </div>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div className="md:col-start-1 md:col-span-2 lg:col-start-2 lg:col-span-4 xl:col-start-3 col-span-4">
                              <textarea
                                name="styleDesc"
                                id="styleDesc"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="border border-tertiary rounded-xl w-full flex p-2 text-tertiary text-sm font-sans"
                                placeholder="Comment"
                                rows={3}
                                value={formik.values.styleDesc}
                                autoComplete="Comment"
                                disabled={!selectedItem}
                              />
                              <button
                                type="button"
                                className="bg-primary disabled:bg-tertiary rounded-xl px-4 py-1 mt-5 text-white font-sans text-xs mb-4"
                                onClick={() => {
                                  saveComment(formik.values.styleDesc);
                                  formik.setFieldValue(
                                    "styleDesc",
                                    formik.values.styleDesc
                                  );
                                }}
                                disabled={!selectedItem}
                              >
                                Save Comment
                              </button>
                              <p className="text-red">
                                {formik.errors.styleDesc &&
                                  formik.touched.styleDesc &&
                                  formik.errors.styleDesc}
                              </p>
                            </div>
                            <div className=""> </div>
                            {props.error}
                            <div className="md:col-start-1 lg:col-start-2 lg:col-span-2 lg:pb-6 sm:col-span-1 sm:flex-col sm:justify-start lg:px-0 mb-10 md:mb-0 mt-6 xl:mt-0 xl:col-start-3 xl:col-span-2">
                              <Button
                                type="submit"
                                label={submitButtonLabel}
                                textsize="base"
                                px={"2"}
                              />
                            </div>
                          </form>
                        </div>
                      </FormikProvider>
                    </div>
                  </div>
                </section>
              </main>
              {editOpen && (
                <ShowPicturePreview
                  open={editOpen}
                  setOpen={setEditOpen}
                  loggedInUserId={props.loggedInUserId}
                  pictures={formik.values.artistPortfolioImages}
                  role={props?.role}
                  projectStatus={props?.projectStatus}
                  comments={allcomments}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
