import React from "react";
import Rating from "@mui/material/Rating";
import { ReactComponent as RatingIcon } from "../../../../assets/ico_rating.svg";
import { ReactComponent as RatingEmptyIcon } from "../../../../assets/ico_rating_empty.svg";
import { SERVER_URL } from "utils/constants";

const Reviews = (props) => {

  let avg = 0, count = 0;

  if (props?.avgRating && props?.avgRating.length > 0) {
    avg = Math.round(props?.avgRating[0].avgRating);
    count = props?.avgRating[0].count;
  }

  return (
    <div>
      <section
        className="lg:mt-8 lg:pb-12 px-24"
        aria-labelledby="gallery-heading"
      >
        <div>
          <div className="text-primary text-2xl text-bold mb-2">Reviews</div>
          <div>
            <Rating
              name="simple-controlled"
              value={avg}
              icon={
                <RatingIcon
                  style={{
                    width: "32px",
                    height: "32px",
                    // marginLeft: 4,
                    marginRight: 8,
                    marginBottom: 5,
                    marginTop: 5,
                  }}
                />
              }
              emptyIcon={
                <RatingEmptyIcon
                  style={{
                    width: "32px",
                    height: "32px",
                    // marginLeft: 4,
                    marginRight: 8,
                    marginBottom: 5,
                    marginTop: 5,
                  }}
                />
              }
            />
          </div>
          <div className="text-gray text-sm">{`Based on ${count} reviews`}</div>
        </div>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 mt-4">
           {props?.reviewsData?.map((artistRating) => (             
            <li key={artistRating.projectId} className="col-span-1 rounded-3xl">
              <div className="rounded-3xl overflow-hidden h-full w-full justify-center place-items-center shadow-lg">
                <div className="px-8 lg:py-4 h-auto">
                  <div className="flex flex-col lg:flex-row gap-4 pt-2">
                    <div>
                      <img
                        className="mr-4 h-12 w-12 bg-gray-300 rounded-full flex-shrink-0 border border-tertiary object-cover object-center"
                        src={
                          artistRating?.project?.user?.photoUrl
                          ? SERVER_URL + artistRating.project.user.photoUrl
                          : 
                          `${SERVER_URL}uploads/default-images/DefaultProfile.jpeg`
                        }
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col">
                        <div className="text-black text-base font-semibold font-sans">
                          {/* {artistRating?.user?.firstName} */}
                          {artistRating.project.user.firstName}
                        </div>
                        <div className="text-tertiary text-tiny font-normal font-sans">
                          {/* {artistRating?.user?.birthYear} */}
                           {artistRating.project.user.birthYear}
                        </div>
                        <Rating
                          name="simple-controlled"
                          value={artistRating.rating}
                          // onChange={(event, newValue) => { setValue(newValue) }}
                          icon={
                            <RatingIcon
                              style={{
                                width: "18px",
                                height: "18px",
                                marginLeft: 2,
                                marginRight: 2,
                                marginBottom: 5,
                                marginTop: 5,
                              }}
                            />
                          }
                          emptyIcon={
                            <RatingEmptyIcon
                              style={{
                                width: "18px",
                                height: "18px",
                                marginLeft: 2,
                                marginRight: 2,
                                marginBottom: 5,
                                marginTop: 5,
                              }}
                            />
                          }
                        />
                        <div className="flex flex-row gap-4 mt-2">
                          {artistRating.isCreative ? (
                            <div className="border-primary border rounded-md py-1 px-2 text-base font-normal font-sans">
                              Creative
                            </div>
                          ) : (
                            ""
                          )}
                          {artistRating.isClean ? (
                            <div className="border-primary border rounded-md py-1 px-2 text-base font-normal font-sans">
                              Clean
                            </div>
                          ) : (
                            ""
                          )}
                          {artistRating.isGoodCommunication ? (
                            <div className="border-primary border rounded-md py-1 px-2 text-base font-normal font-sans">
                              Good Communication
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="lg:w-80 text-tiny font-normal font-sans mb-2">
                        {artistRating.review}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default Reviews;
