/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ArtistAdminView from "./ArtistAdminView";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllArtists,
  getNewArtists,
  getInvitedArtists,
  getPendingPortfolios,
  getMyArtists,
} from "redux/actions/AdminArtistAction";
import { tabsNav } from "./CONSTANTS";
import { getCountries } from "redux/actions/CountryAction";
import { getTechniques } from "redux/actions/TechniqueAction";
import { getStyles } from "redux/actions/StyleAction";
import { getAllStudios } from "redux/actions/StudioAction";

const ArtistAdminContainer = (props) => {
  const [selectedTab, setSelectedTab] = useState(tabsNav[0].id);
  const artistState = useSelector((state) => state.artist);
  const invitedArtistState = useSelector((state) => state.invitedArtist);
  const newInviteArtistReducer = useSelector(
    (state) => state.newInviteArtistReducer
  );
  const adminArtistPortfolioState = useSelector(
    (state) => state.adminArtistPortfolio
  );

  const countries = useSelector((state) => state.country.data);
  const techniques = useSelector((state) => state.technique.data);
  const styles = useSelector((state) => state.style.stylesData);
  const studios = useSelector((state) => state.studio.getAllStudios);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedTab === tabsNav[0].id) {
      invitedArtistState.error = null;
      dispatch(getAllArtists());
    } else if (selectedTab === tabsNav[1].id) {
      invitedArtistState.error = null;
      dispatch(getNewArtists());
    } else if (selectedTab === tabsNav[2].id) {
      artistState.error = null;
      dispatch(getInvitedArtists());
    } else if (selectedTab === tabsNav[3].id) {
      invitedArtistState.error = null;
      dispatch(getMyArtists());
    } else if (selectedTab === tabsNav[4].id) {
      invitedArtistState.error = null;
      dispatch(getPendingPortfolios());
    }
  }, [selectedTab, dispatch, open, newInviteArtistReducer.success]);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getTechniques());
    dispatch(getStyles());
    dispatch(getAllStudios());
  }, [dispatch]);

  return (
    <div>
      <ArtistAdminView
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        artistState={artistState}
        newArtistState={artistState}
        myArtistState={artistState}
        invitedArtistState={invitedArtistState}
        adminArtistPortfolioState={adminArtistPortfolioState}
        loginData={props.loginData}
        countries={countries}
        techniques={techniques}
        styles={styles}
        studios={studios}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};

export default ArtistAdminContainer;
