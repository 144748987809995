import ProjectService from "services/ProjectService";

export const PROJECTS_NEW_LOADING = "PROJECTS_NEW_LOADING";
export const PROJECTS_NEW_SUCCESS = "PROJECTS_NEW_SUCCESS";
export const PROJECTS_NEW_ERROR = "PROJECTS_NEW_ERROR";

export const PROJECTS_CURRENT_LOADING = "PROJECTS_CURRENT_LOADING";
export const PROJECTS_CURRENT_SUCCESS = "PROJECTS_CURRENT_SUCCESS";
export const PROJECTS_CURRENT_ERROR = "PROJECTS_CURRENT_ERROR";

export const PROJECTS_HISTORY_LOADING = "PROJECTS_HISTORY_LOADING";
export const PROJECTS_HISTORY_SUCCESS = "PROJECTS_HISTORY_SUCCESS";
export const PROJECTS_HISTORY_ERROR = "PROJECTS_HISTORY_ERROR";

export const getNewProjects = (id) => async (dispatch) => {
  dispatch({
    type: PROJECTS_NEW_LOADING,
  });
  try {
    let res = await ProjectService.getNewProjects(id);
    return dispatch({
      type: PROJECTS_NEW_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: PROJECTS_NEW_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const getCurrentProjects = (id) => async (dispatch) => {
  dispatch({
    type: PROJECTS_CURRENT_LOADING,
  });
  try {
    let res = await ProjectService.getCurrentProjects(id);
    return dispatch({
      type: PROJECTS_CURRENT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: PROJECTS_CURRENT_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};

export const getHistoryProjects = (id) => async (dispatch) => {
  dispatch({
    type: PROJECTS_HISTORY_LOADING,
  });
  try {
    let res = await ProjectService.getHistoryProjects(id);
    return dispatch({
      type: PROJECTS_HISTORY_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    return dispatch({
      type: PROJECTS_HISTORY_ERROR,
      payload: {
        code: err.response.status,
        message: err.response.data,
      },
    });
  }
};
