import { useFormik } from "formik";
import * as yup from "yup";
import Button from "components/UI/Button";
import { useEffect, useState } from "react";
import AlertMessage from "components/AlertMessage";
import { resetSubscriber } from "redux/actions/SubscriberAction";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FAQ, SUPPORT } from "navigation/CONSTANTS";

const footerNavigation = {
  social: [
    {
      name: "Instagram",
      href: "https://www.instagram.com/2ndskintattoo/",
      icon: (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={20}
          height={20}
          fill="none"
          {...props}
        >
          <path
            fill="#D8D8D8"
            d="M10 0c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153.509.5.902 1.105 1.153 1.772.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772c-.5.508-1.105.902-1.772 1.153-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153A4.904 4.904 0 0 1 .525 16.55C.277 15.913.11 15.187.06 14.122.013 13.056 0 12.717 0 10c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 3.45.525C4.088.277 4.812.11 5.878.06 6.944.013 7.283 0 10 0Zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm6.5-.25a1.25 1.25 0 1 0-2.5 0 1.25 1.25 0 0 0 2.5 0ZM10 7a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
          />
        </svg>
      ),
    },
  ],
};

const subscriberValidationSchema = yup.object({
  email: yup.string("Enter your email").email("Enter a valid email"),
});

export default function Footer(props) {
  const [showAlert, setShowAlert] = useState(false);
  const [alertId, setAlertId] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const dispatch = useDispatch();

  const handleFormSubmit = (values) => {
    props.subscribe({ email: values.email });
  };

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: subscriberValidationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  useEffect(() => {
    if (props.subscriptionResponse.data === true) {
      formik.setFieldValue("email", "");
      setAlertId("subscription");
      setAlertMessage("Successfully Subscribed");
      setShowAlert(true);
      dispatch(resetSubscriber());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subscriptionResponse]);

  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <div className="flex pb-4 pt-20 px-4 lg:px-10 justify-center flex-col items-center">
        <h3 className="text-sm tracking-wider font-sans font-normal items-center text-center">
          Stay in touch
        </h3>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-row mt-2 w-full rounded-full border border-homeaccent overflow-hidden">
            <input
              className="text-base px-4 py-2 focus:outline-none focus:ring-0 ring-transparent focus:border-transparent overflow-hidden"
              type="email"
              name="email"
              id="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              label="Email"
              placeholder="Enter your email"
              autoComplete="true"
            />
            <Button
              type="submit"
              label="Subscribe"
              textsize="base"
              rounded="rounded-full"
              width="w-1/3"
              font="normal"
              background="homeaccent"
              px="6"
            />
          </div>
          <p className="text-red text-sm px-2 pt-1 mb-4 md:mb-0">
            {formik.errors.email && formik.touched.email && formik.errors.email}
          </p>
          <AlertMessage
            id={alertId}
            message={alertMessage}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            confirmable={false}
          />
        </form>
        <div className="grid grid-cols-3 px-8 pb-4 pt-2">
          <Link
            to="/"
            className="text-secondary hover:text-primary no-underline text-base font-sans font-normal text-center px-2"
          >
            {/* {t("landing.home")} */}
            Home
          </Link>
          <Link
            to={SUPPORT}
            className="text-secondary hover:text-primary no-underline text-base font-sans font-normal text-center px-2"
          >
            {/* {t("landing.support")} */}
            Support
          </Link>
          <Link
            to={FAQ}
            className="text-secondary hover:text-primary no-underline text-base font-sans font-normal text-center px-2"
          >
            {/* {t("landing.faq")} */}
            FAQ
          </Link>
        </div>
        <div className="flex mt-0 md:mt-2 text-tertiary text-sm">
          Follow us
          {footerNavigation.social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              target="_blank"
              rel="noreferrer"
              className="text-gray-400 hover:text-gray-500 text-link ml-2"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-8 w-8" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-1 pt-1 flex items-center justify-center lg:mt-0 w-full">
          <p className="mt-0 text-tertiary md:mt-0 md:order-1 text-sm font-sans font-normal">
            &copy; 2024 Ink Foundation, All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
