import {
  INVITE_STRING_ARTIST_ERROR,
  INVITE_STRING_ARTIST_LOADING,
  INVITE_STRING_ARTIST_SUCCESS,
  ARTIST_CURRENT_PROJECT_LOADING,
  GET_ARTIST_CURRENT_PROJECT,
  GET_ARTIST_CURRENT_PROJECT_ERROR,  
  ARTIST_HISTORY_PROJECT_LOADING,
  GET_ARTIST_HISTORY_PROJECT,
  GET_ARTIST_HISTORY_PROJECT_ERROR,  
  ARTIST_PROJECTS_LOADING,
  GET_ARTIST_PROJECTS,
  GET_ARTIST_PROJECTS_ERROR,  
} from "redux/actions/ArtistModuleAction";

const initialState = {
  loading: false,
  success: false,
  error: null,
  data: null,
  artistCurrentProjects: null,
  artistProjectsHistory: null,
  artistProjects: null
};

const ArtistModuleReducer = function (state = initialState, action) {
  //const { type, payload } = action;

  switch (action.type) {
    case ARTIST_CURRENT_PROJECT_LOADING:
    case ARTIST_HISTORY_PROJECT_LOADING:
    case INVITE_STRING_ARTIST_LOADING:
    case ARTIST_PROJECTS_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        data: null,
        error: null,
      };
    }
    case GET_ARTIST_CURRENT_PROJECT: {
      return {
        ...state,
        loading: false,
        success: true,
        artistCurrentProjects: action.payload,
        error: null,
      };
    }
    case GET_ARTIST_HISTORY_PROJECT: {
      return {
        ...state,
        loading: false,
        success: true,
        artistProjectsHistory: action.payload,
        error: null,
      };
    }
    case INVITE_STRING_ARTIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: null,
      };
    }
    case GET_ARTIST_PROJECTS: {
      return {
        ...state,
        loading: false,
        success: true,
        artistProjects: action.payload,
        error: null,
      };
    }
    case GET_ARTIST_CURRENT_PROJECT_ERROR:
    case GET_ARTIST_HISTORY_PROJECT_ERROR:
    case INVITE_STRING_ARTIST_ERROR:
    case GET_ARTIST_PROJECTS_ERROR: {
      return {
        loading: false,
        success: false,
        data: null,
        error: action.payload ? action.payload : "Unknown Error Occured",
      };
    }
    default: {
      return state;
    }
  }
};

export default ArtistModuleReducer;
