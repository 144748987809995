export const HICHEM_PROJECT_TAB = "HICHEM_PROJECT_TAB";
export const HICHEM_PROJECT_MODE = "HICHEM_PROJECT_MODE";

export const setHichemProjectTab = (tabSelected) => async (dispatch) => {
  return dispatch({
    type: HICHEM_PROJECT_TAB,
    payload: tabSelected,
  });
};

export const setHichemProjectMode = (modeSelected) => async (dispatch) => {
  return dispatch({
    type: HICHEM_PROJECT_MODE,
    payload: modeSelected,
  });
};
