import React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 10,
    borderWidth: 3,
    // width: "100% !important",
    fontSize: 16,
    textTransform: "none",
  },
}));

const ButtonHollow = (props) => {
  const classes = useStyles();
  return (
    <Button
      color="primary"
      variant="outlined"
      className={classes.button}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default ButtonHollow;
