import React from "react";
import Header from "components/Navbars/AuthNavbar";
import Footer from "components/Footers/Footer";

export default function NotFound() {
  return (
    <>
      <div className="bg-white">
        <header>
          <Header className="" />
        </header>
        <div className="grid grid-cols-4 gap-4 mt-10 py-8 px-2 sm:px-10">
          <div />
          <img
            src="/404.png"
            alt=""
            className="w-full h-full object-center object-cover col-span-3 ... lg:col-span-2 ..."
          />
          <div />
        </div>
        <Footer />
      </div>
    </>
  );
}
