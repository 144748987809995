import React from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const TopSection = ({ data }) => {
  let projectPercent = 0;
  projectPercent = Math.round(
    (data?.prvProjectCount / data?.projectCount) * 100 - 100
  );

  let revenuePercent = 0;
  revenuePercent = Math.round(
    // (data?.prvtotalMonthRevenue / data?.totalMonthRevenue) * 100 - 100
    (data?.totalMonthRevenue /
      (!data?.prvtotalMonthRevenue || data?.prvtotalMonthRevenue == 0
        ? 1
        : data?.prvtotalMonthRevenue)) *
      100 -
      100
  );

  return (
    <div className="flex flex-col md:grid md:grid-cols-12 gap-2">
      <div className="col-start-1 md:col-span-4 rounded-lg overflow-hidden shadow-md w-full px-8 md:px-2 lg:px-8 py-3 text-sm">
        <div className="flex flex-row items-center py-4 grid grid-cols-3">
          <div className="flex flex-col col-start-1 col-span-2">
            <div className="text-3xl font-black font-sans">{data?.projectCount}</div>
            <div className="text-base font-bold font-sans">Projects</div>
          </div>
          {projectPercent > 0 && (
            <div className="flex items-center justify-center rounded-lg overflow-hidden py-2 2xl:py-5 bg-lightGreenLighter text-lightGreen text-xl font-sans font-bold col-span-1">
              <ArrowDropUpIcon /> {projectPercent}%
            </div>
          )}
          {projectPercent <= 0 && (
            <div className="flex items-center justify-center rounded-lg overflow-hidden py-2 2xl:py-5 bg-redLighter text-red text-xl font-sans font-bold col-span-1">
              <ArrowDropDownIcon /> {projectPercent * -1}%
            </div>
          )}
        </div>
      </div>
      <div className="col-start-5 md:col-span-4 rounded-lg overflow-hidden shadow-md w-full px-8 md:px-2 lg:px-8 py-3 text-sm ">
        <div className="flex flex-row items-center py-4 grid grid-cols-3">
          <div className="flex flex-col col-start-1 col-span-2">
            {/* <div className="text-tertiary font-bold text-base">CHF</div> */}
            <div className="text-3xl font-black font-sans">{data?.totalMonthRevenue ? data?.totalMonthRevenue : 0}</div>
            <div className="font-sans font-bold text-base">Month Revenue</div>
          </div>
          {revenuePercent > 0 && (
            <div className="flex items-center justify-center rounded-lg overflow-hidden py-2 2xl:py-5 bg-lightGreenLighter text-lightGreen text-xl font-sans font-bold col-span-1">
              <ArrowDropUpIcon /> {revenuePercent}%
            </div>
          )}
          {revenuePercent <= 0 && (
            <div className="flex items-center justify-center rounded-lg overflow-hidden py-2 2xl:py-5 bg-redLighter text-red text-xl font-bold font-sans col-span-1">
              <ArrowDropDownIcon /> {revenuePercent * -1}%
            </div>
          )}
        </div>
      </div>
      <div className="col-start-9 col-span-4 flex flex-col rounded-lg overflow-hidden shadow-md w-full px-8 py-3 justify-center">
        <div className="text-3xl font-black font-sans">{data?.customerCount}</div>
        <div className="font-sans font-bold text-base">Total Customers</div>
      </div>
    </div>
  );
};

export default TopSection;
