/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../redux/actions/UserActions";
import { getTechniques } from "../../../redux/actions/TechniqueAction";
import SaveArtistProfileView from "./SaveArtistProfileView";
import { VIEWS } from "../../../layouts/ArtistConstants";
import { getCountries } from "redux/actions/CountryAction";
import localStorageService from "services/localStorageService";

export default function SaveArtistProfileContainer(props) {
  let history = useHistory();

  const artistSuccess = useSelector((state) => state.user.success);
  const artistError = useSelector((state) => state.user.error);
  const techniques = useSelector((state) => state.technique.data);
  const countries = useSelector((state) => state.country.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTechniques());
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    if (artistSuccess) {
      // history.replace(`/artist/artistportfolio/${VIEWS.ARTIST_PROFILE.id}`,);
      window.location.reload();
    }
  }, [artistSuccess]);

  const onSubmit = (obj) => {
    obj.id = props.loginData.id;
    dispatch(updateUser(obj)).then((res) => {
      if (res.type == "UPDATE_USER_SUCCESS") {
        localStorageService.setItem("auth_user", res.payload.user);
      }
    });
  };

  return (
    <div>
      <SaveArtistProfileView
        onSubmit={onSubmit}
        techniques={techniques}
        loginData={props.loginData}
        artistError={artistError}
        countries={countries}
      />
    </div>
  );
}
