import * as React from 'react'
import ArtistProjects from "./ArtistProjects"

const ProjectsHistory = (props) => {
    return (
       <ArtistProjects selectedTab={props.selectedTab} data={props.artistsProjects.project} />
    )
}

export default ProjectsHistory
