import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Container, Typography } from "@mui/material";
import ButtonHollow from "components/ButtonHollow";
import CustomInput from "components/CustomInput";
import { skinTheme } from "styles/muiTheme";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

const data = [
  {
    value: "general",
    label: "General",
  },
];

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    marginTop: skinTheme.spacing(6),
  },
  item: {
    marginBottom: skinTheme.spacing(2),
  },
  button: {
    marginTop: skinTheme.spacing(4),
    width: 200,
    display: "flex",
    flexDirection: "row",
  },
}));

const FAQForm = (props) => {
  const [questionOneEnglish, setQuestionOneEnglish] = useState("");
  const [questionOneGerman, setQuestionOneGerman] = useState("");
  const [answerOneEnglish, setAnswerOneEnglish] = useState("");
  const [answerOneGerman, setAnswerOneGerman] = useState("");
  const [current, setCurrent] = useState("general");

  const classes = useStyles({ skinTheme });

  const handleChange = (e, label) => {
    if (label === "Question One English") {
      setQuestionOneEnglish(e.target.value);
    } else if (label === "Question One German") {
      setQuestionOneGerman(e.target.value);
    } else if (label === "Answer One English") {
      setAnswerOneEnglish(e.target.value);
    } else if (label === "Answer One German") {
      setAnswerOneGerman(e.target.value);
    }
  };

  return (
    <Container>
      <div>
        <Typography variant="h4" color="primary" align="left">
          FAQ
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
        <Typography variant="h6" align="left" style={{ marginRight: 20 }}>
          English
        </Typography>
        <Typography variant="h6" align="left">
          German
        </Typography>
      </div>

      <form
        onSubmit={(e) => {
          props.handleRegisterSubmit(e, {
            questionOneEnglish,
            questionOneGerman,
            answerOneEnglish,
            answerOneGerman,
          });
        }}
        autoComplete="off"
        className={classes.form}
      >
        {/* <Grid container class> */}
        <TextField
          id="outlined-select-currency"
          select
          label="Select"
          value={current}
          onChange={handleChange}
          width="60%"
        >
          {data.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Typography
          variant="body1"
          align="left"
          color="primary"
          style={{ marginBottom: 10 }}
        >
          Add Question & Answer
        </Typography>
        <div className={classes.item}>
          <Typography variant="body1" align="left" style={{ marginBottom: 10 }}>
            Question in English
          </Typography>
          <CustomInput
            label="Question One"
            value={questionOneEnglish}
            required
            onChange={(value) => handleChange(value, "Question One English")}
          />
        </div>
        <div className={classes.item}>
          <Typography variant="body1" align="left" style={{ marginBottom: 10 }}>
            Question in German
          </Typography>
          <CustomInput
            label="Question One"
            value={questionOneGerman}
            required
            onChange={(value) => handleChange(value, "Question One German")}
          />
        </div>
        <div className={classes.item}>
          <Typography variant="body1" align="left" style={{ marginBottom: 10 }}>
            Answer in English
          </Typography>
          <CustomInput
            label="Answer One"
            value={answerOneEnglish}
            required
            multiline
            rows={2}
            onChange={(value) => handleChange(value, "Answer One English")}
          />
        </div>
        <div className={classes.item}>
          <Typography variant="body1" align="left" style={{ marginBottom: 10 }}>
            Answer in German
          </Typography>
          <CustomInput
            label="Answer One"
            value={answerOneGerman}
            required
            multiline
            rows={3}
            onChange={(value) => handleChange(value, "Answer One German")}
          />
        </div>
        {/* </Grid> */}
        <div className={classes.button}>
          <ButtonHollow
            type="submit"
            style={{
              marginRight: 20,
            }}
          >
            Save
          </ButtonHollow>
          <ButtonHollow
            style={{
              marginRight: 20,
            }}
          >
            Cancel
          </ButtonHollow>
        </div>
      </form>
    </Container>
  );
};

export default FAQForm;
