import axios from "../../http-config";

export const TECHNIQUE_LOADING = "TECHNIQUE_LOADING";
export const GET_TECHNIQUE_SUCCESS = "GET_TECHNIQUE_SUCCESS";
export const GET_TECHNIQUE_ERROR = "GET_TECHNIQUE_ERROR";

export const getTechniques = () => async (dispatch) => {
    dispatch({
        type: TECHNIQUE_LOADING,
    });
    try {
        let res = await axios({
            method: "GET",
            url: `/techniques`
        });

        return dispatch({
            type: GET_TECHNIQUE_SUCCESS,
            payload: res.data.techniques,
        });
    } catch (err) {
        return dispatch({
            type: GET_TECHNIQUE_ERROR,
            payload: err.response,
        });
    }
};

export const getTechnique = (id) => async (dispatch) => {
    dispatch({
        type: TECHNIQUE_LOADING,
    });
    try {
        let res = await axios({
            method: "GET",
            url: `/techniques/${id}`
        });

        return dispatch({
            type: GET_TECHNIQUE_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        return dispatch({
            type: GET_TECHNIQUE_ERROR,
            payload: err.response,
        });
    }
};