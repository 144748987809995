const CheckBox = (props) => {
  const width = props.width ? props.width : "6";
  const rounded = props.rounded ? props.rounded : "md";
  const onClickLabel = props.rounded ? props.rounded : "md";
  const labelShowAsLink = props.labelShowAsLink ? props.labelShowAsLink : false;
  const textBorder = props.textborder ? props.textborder : null;

  return (
    // <>
    <div className="flex items-center align-center font-sans">
      <input
        {...props}
        type="checkbox"
        // className={`h-6 w-6 text text-accent ring-accent focus:ring-accent border border-accent rounded bg-red hover:bg-accent focus:bg-accent`}
        // class="accent-pink-500"
        className={`form-checkbox h-${width} w-${width} rounded-${rounded} text-primary focus:ring-0 border-2 border-tertiary`}
      />
      <label
        className={`ml-1 block text-base ${
          labelShowAsLink
            ? "text-primary border-b"
            : textBorder == "selected"
            ? "text-primary border border-primary p-1 rounded-md"
            : textBorder == "unselected"
            ? "text-tertiary border border-tertiary p-1 rounded-md"
            : "text-black border-0"
        }   font-sans font-normal`}
        onClick={props.onLabelClick}
      >
        {props.label}
      </label>
    </div>
    // </>
  );
};

export default CheckBox;

// ${
//   borderedText ? "border border-primary" : ""
// }
