import React from "react";
import { ReactComponent as FemaleIcon } from "../../../../assets/ic_female.svg";
import { ReactComponent as MaleIcon } from "../../../../assets/ic_male.svg";
import { ReactComponent as GenderIcon } from "assets/ic_gender.svg";
import { SERVER_URL } from "utils/constants";
import moment from "moment";
import localStorageService from "../../../../services/localStorageService";

const SideScrollBar = ({ items, selectedItem, setSelectedItem }) => {
  return (
    <div className="w-full">
      {items?.map((item) => (
        <div
          className={`flex grid grid-cols-6 gap-1 rounded-lg overflow-hidden shadow-md w-full px-2 py-2 text-sm my-2 ${
            selectedItem.id === item.id && "border-primary border"
          }`}
          key={item.id}
          onClick={() => {
            setSelectedItem(item);
            localStorageService.setItem("general_support", item);
          }}
        >
          <div className="text-gray col-start-1 col-span-4">#{item.id}</div>

          <img
            src={
              item?.profile
                ? SERVER_URL + item?.profile
                : `${SERVER_URL}uploads/default-images/DefaultProfile.jpeg`
            }
            alt="client_profile"
            className="w-10 h-10 rounded-full overflow-hidden col-start-1 col-span-1 md:col-start-1 md:col-span-2 border border-tertiary"
          />
          <div className="flex flex-col col-start-2 md:col-start-3 col-span-2">
            <div className="flex flex-row gap-2">
              <div className="font-normal">{item?.fullName}</div>
              <div className="text-red">
                {items?.gender === "female" ? (
                  <FemaleIcon />
                ) : items?.gender === "male" ? (
                  <MaleIcon />
                ) : (
                  <GenderIcon />
                )}
              </div>
            </div>
            {item?.year ? item.year : "NA"}
          </div>
          <div className="text-gray w-full col-start-2 md:col-start-3 col-span-4">
            {" "}
            {moment(item.createdAt).format("DD MMMM hh:mm")}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SideScrollBar;
