import React, { useState } from "react";
import {
  navigations,
  userNavigations,
  USER_ROLE_MANGER_ID,
} from "../../../layouts/AdminConstants";
import SideNavBar from "components/Navbars/SideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";
import Invoices from "./components/Invoices";
import TopSection from "./components/TopSection";
import Graph from "./components/Graph";
import Table from "./components/Table";

const AccountingView = ({ 
  loginData,
  paymentMethods,
  accountingState,
  invoicesScreen,
  setInvoices,
  applyFilters,
  graphsState,
  setRefresh,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="h-full flex">
      <div className="flex-1 flex flex-col overflow-hidden h-full bg-bgg">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={loginData}
        />

        <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
          <SideNavBar
            navigations={navigations(
              loginData.userRoleId !== USER_ROLE_MANGER_ID
            )}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            bg={"bgg"}
            loginID={loginData.id}
          />
          <div className="flex-1 flex items-stretch overflow-hidden">
            {/* Main content */}
            <div className="flex flex-1 bg-bgg">
              <div className="w-full md:mx-0.1 py-2 md:py-5 md:px-4 bg-white shadow">
                <div className="mx-0 md:mx-4">
                  {invoicesScreen ? (
                    <Invoices
                      data={accountingState?.invoices?.accounts}
                      applyFilters={applyFilters}
                      setInvoices={setInvoices}
                      setRefresh={setRefresh}                      
                      loginDataId={loginData.id}
                    />
                  ) : (
                    <div className="flex grid grid-cols-12 mx-4 lg:mx-8">
                      <div className="text-2xl font-semibold font-sans text-primary mb-4 col-start-1 col-span-12">
                        Accounting
                      </div>
                      <div className="text-tertiary font sans text-base font-normal col-start-1 col-span-12 mb-4">
                        Quick Statistics
                      </div>
                      <div className="col-start-1 col-span-12 mb-4">
                        <TopSection
                          data={accountingState?.statistics?.accounts}
                        />
                      </div>
                      <div className="col-start-1 col-span-12 mb-4">
                        <Graph
                          paymentMethods={paymentMethods}
                          graphsState={graphsState}
                        />
                      </div>
                      <div className="col-start-1 col-span-12 mb-4 flex flex-row justify-between font-sans">
                        <div>Invoices</div>
                        <div
                          onClick={() => setInvoices(true)}
                          className="text-primary font-sans"
                        >
                          View all
                        </div>
                      </div>
                      <div className="col-start-1 col-span-12 mb-4 w-full">
                        <Table
                          data={accountingState?.invoices?.accounts?.slice(
                            0,
                            5
                          )}
                          disablePagination={true}
                          setRefresh={setRefresh}
                          loginDataId={loginData.id}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountingView;
