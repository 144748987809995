import * as React from "react";
import Rating from "@mui/material/Rating";
import { SERVER_URL } from "utils/constants";
import { useHistory } from "react-router-dom";
import { styled } from '@mui/material/styles';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#C7A33F',
  },
  '& .MuiRating-iconHover': {
    color: '#AFAFAF',
  },
  '& .MuiRating-iconEmpty': {
    color: '#AFAFAF',
  },
});

const CurrentArtists = (props) => {
  const [value, setValue] = React.useState(4);
  let history = useHistory();

  const artistRatingOnClick = (id) => {
    history.push("/studio/reviews/" + id);
  };

  return (
    <div className="my-10">
      <div className="flex flex-col gap-6">
        {props?.data?.user?.map((items, index) => (
          <div
            className="flex flex-row border-b border-gray-200"
            key={index.toString()}
          >
            <div className="flex flex-row gap-4 mb-6">
              <img
                className="h-12 w-12 rounded-full mt-2 object-cover object-center"
                src={
                  items?.photoUrl
                    ? SERVER_URL + items?.photoUrl
                    : SERVER_URL + "uploads/default-images/DefaultProfile.jpeg"
                }
                alt=""
              />
              <div className="flex flex-col gap-4">
                <div className="flex flex-row align-right">
                  <div className="flex flex-col">
                    <div className="flex flex-row gap-6">
                      <div className="text-xl">
                        {items.firstName} {items.lastName}
                      </div>
                    </div>
                    <div className="flex flex-row gap-2">
                      <StyledRating
                        name="simple-controlled"
                        value={items?.artist_ratings[0]?.rating}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                      />
                      <div onClick={() => artistRatingOnClick(items.id)}>
                        ({items?.artist_ratings?.length})
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-gray">
                  <div>{items.email}</div>
                  <div>{items.country}</div>
                  <div>
                    ({items.phoneCode}) {items.phone}
                  </div>
                </div>
                <div className="text-primary text-wrap break-words">
                  {items?.artist_portfolios
                    ?.map((a) => a.styleTitle)
                    .join(", ")}
                </div>
                <div className="text-gray">{items.days}</div>
              </div>
            </div>
            <div className="flex flex-row gap-4">
              {items?.artist_portfolios?.map((a, i) =>
                a.artist_portfolio_images?.map((b, j) => (
                  <img className="h-40" src={SERVER_URL + b.imageUrl} alt="" />
                ))
              )}
            </div> 
          </div>
        ))}
      </div>
    </div>
  );
};

export default CurrentArtists;
