import * as React from "react";
import Rating from "@mui/material/Rating";
import { ReactComponent as RatingIcon } from "assets/ico_rating.svg";
import { ReactComponent as RatingEmptyIcon } from "assets/ico_rating_empty.svg";
import CheckBox from "components/UI/CheckBox";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "components/UI/Button";
import { USER_ROLE_PROJECT_ADMIN, USER_ROLE_PROJECT_MYSTUDIO_ARTIST, USER_ROLE_CLIENT, USER_ROLE_ADMIN } from "../../CONSTANTS";
import { ARTIST_RATING_STATUS } from "layouts/AdminConstants";
import { styled } from '@mui/material/styles';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#C7A33F',
  },
  '& .MuiRating-iconHover': {
    color: '#AFAFAF',
  },
  '& .MuiRating-iconEmpty': {
    color: '#AFAFAF',
  },
});


const ratingsValidationSchema = yup.object({});


const Ratings = (props) => {
 
  let reviewVal,
    ratingVal = 0,
    show = true,
    unDisclose,
    rowId;

  if (props?.data?.length > 0) {
    reviewVal = props.data[0].review;
    ratingVal = Number(props.data[0].rating);
    unDisclose =
      props.data[0].artistRatingStatusId === ARTIST_RATING_STATUS.UNDISCLOSE.id
        ? true
        : false;
    rowId = props.data[0].id;
    show = false;
  }
  const [ratingValue, setRatingValue] = React.useState(ratingVal);

  const handleFormSubmit = (values) => {
    let isRatingStatus =
      ratingValue && ratingValue > 3
        ? ARTIST_RATING_STATUS.PUBLISH.id
        : ARTIST_RATING_STATUS.UNDISCLOSE.id;
    let obj = {
      ratings: {
        artistId: props.artistId,
        review: values.review,
        rating: ratingValue ?? 0,
        isClean: values.isClean,
        isCreative: values.isCreative,
        isGoodCommunication: values.isGoodCommunication,
        loggedInUserId: props.loggedInUserId,
        statusId: isRatingStatus,
      },
    };
    props.onSubmit(obj);
  };

  const handleFormUndiscloseSubmit = (values) => {
    let obj = {
      unDisclose: {
        recId: values.recId,
        loggedInUserId: props.loggedInUserId,
      },
    };
    props.onSubmit(obj);
  };

  return (
    <div className="w-full">
      {props?.role === "Client" &&
      (props?.projectStatus === "Project Done" ||
        props?.projectStatus === "Studio Done") &&
      show ? (
        <Formik
          initialValues={{
            review: "",
            rating: 0,
            isClean: false,
            isCreative: false,
            isGoodCommunication: false,
          }}
          validationSchema={ratingsValidationSchema}
          enableReinitialize
          onSubmit={(values) => {
            handleFormSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-2 w-full text-sm"
            >
              <div className="text-base text-gray">Rate Artist:</div>
              <textarea
                name="review"
                id="review"
                placeholder="Review"
                className="border rounded-lg p-2 focus:outline-none h-28 border-tertiary mb-3 text-sm text-black font-sans"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.review}
              />
              <Rating
                name="rating"
                id="rating"
                value={ratingValue}
                onChange={(event, newValue) => {
                  setRatingValue(newValue);
                }}
                size="large"
                icon={<RatingIcon className="w-6 h-6 m-1" />}
                emptyIcon={<RatingEmptyIcon className="w-6 h-6 m-1" />}
              />
              <div className="flex flex-row gap-10 text-base mt-3 mb-3">
                <CheckBox
                  id="isClean"
                  name="isClean"
                  type="checkbox"
                  size="small"
                  onChange={handleChange}
                  checked={values.isClean}
                  label="Clean"
                />
                <CheckBox
                  id="isCreative"
                  name="isCreative"
                  type="checkbox"
                  size="small"
                  onChange={handleChange}
                  label="Creative"
                  checked={values.isCreative}
                />
              </div>
              <CheckBox
                id="isGoodCommunication"
                name="isGoodCommunication"
                type="checkbox"
                onChange={handleChange}
                label="Good Communication"
                checked={values.isGoodCommunication}
              />
              <div className="self-end w-full mt-5">
                <Button label="Submit" type="submit" />
              </div>
            </form>
          )}
        </Formik>
      ) : (props?.projectStatus === "Project Done" &&
          props?.role === USER_ROLE_PROJECT_MYSTUDIO_ARTIST && !unDisclose &&
          props?.data?.length > 0) || 
          (props?.projectStatus === "Project Done" &&
          props?.role === USER_ROLE_CLIENT &&
          props?.data?.length > 0)
          ||
        ((props?.projectStatus === "Studio Done" || props?.projectStatus === "Project Done")  &&
          (props?.role === USER_ROLE_PROJECT_ADMIN || props?.role === USER_ROLE_ADMIN)  &&
          props?.data?.length > 0)
           ? (
        <div className="flex flex-col gap-2">
          <div className="text-sm text-gray">Client Review:</div>
          <StyledRating name="simple-controlled" value={ratingVal} color="primary" />
          <div className="w-full">{reviewVal}</div>
          {props?.role === USER_ROLE_PROJECT_ADMIN && unDisclose && (
            <Formik
              initialValues={{ recId: rowId }}
              enableReinitialize
              onSubmit={(values) => {
                handleFormUndiscloseSubmit(values);
              }}
            >
              {({ values, handleSubmit }) => (
                <form
                  onSubmit={handleSubmit}
                  className=" flex-col gap-2 w-full text-sm mt-4"
                >
                  <button
                    className="font-sans text-base border px-10 py-3 flex text-undisclose rounded-3xl"
                    type="submit"
                  >
                    Undisclose
                  </button>
                </form>
              )}
            </Formik>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Ratings;
