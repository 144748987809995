import {
  NEW_ARTIST_LOADING,
  ALL_ARTIST_LOADING,
  MATCHING_ARTIST_LOADING,
  ALL_ARTIST_SUCCESS,
  NEW_ARTIST_SUCCESS,
  MATCHING_ARTIST_SUCCESS,
  ALL_ARTIST_ERROR,
  NEW_ARTIST_ERROR,
  MATCHING_ARTIST_ERROR,
  MY_ARTIST_LOADING,
  MY_ARTIST_SUCCESS,
  MY_ARTIST_ERROR,
  DELETE_ARTIST_LOADING,
  BLOCK_ARTIST_LOADING,
  DELETE_ARTIST_SUCCESS,
  BLOCK_ARTIST_SUCCESS,
  DELETE_ARTIST_ERROR,
  BLOCK_ARTIST_ERROR,
  UNBLOCK_ARTIST_LOADING,
  UNBLOCK_ARTIST_SUCCESS,
  UNBLOCK_ARTIST_ERROR,
} from "../actions/AdminArtistAction";

const initialState = {
  loading: false,
  success: false,
  error: null,
  data: null,
  myartist: null,
  loadingDelete: false,
  successDelete: false,
  errorDelete: null,
  dataDelete: null,
};

const ArtistReducer = function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ALL_ARTIST_LOADING:
    case NEW_ARTIST_LOADING:
    case MATCHING_ARTIST_LOADING:
    case MY_ARTIST_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        data: null,
        error: null,
        myartist: null,
      };
    }
    case ALL_ARTIST_SUCCESS:
    case NEW_ARTIST_SUCCESS:
    case MATCHING_ARTIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: payload,
        error: null,
        myartist: null,
      };
    }
    case MY_ARTIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: null,
        myartist: payload,
        error: null,
      };
    }
    case ALL_ARTIST_ERROR:
    case NEW_ARTIST_ERROR:
    case MATCHING_ARTIST_ERROR:
    case MY_ARTIST_ERROR: {
      return {
        loading: false,
        success: false,
        data: null,
        error: payload ? payload : "Unknown Error Occured",
        myartist: null,
      };
    }
    case DELETE_ARTIST_LOADING:
    case BLOCK_ARTIST_LOADING:
    case UNBLOCK_ARTIST_LOADING: {
      return {
        ...state,
        loadingDelete: true,
        successDelete: null,
        data: null,
        errorDelete: null,
        dataDelete: null,
      };
    }
    case DELETE_ARTIST_SUCCESS:
    case BLOCK_ARTIST_SUCCESS:
    case UNBLOCK_ARTIST_SUCCESS: {
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
        errorDelete: null,
        dataDelete: payload,
      };
    }

    case DELETE_ARTIST_ERROR:
    case BLOCK_ARTIST_ERROR:
    case UNBLOCK_ARTIST_ERROR: {
      return {
        ...state,
        loadingDelete: false,
        successDelete: false,
        error: payload ? payload : "Unknown Error Occured",
        dataDelete: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default ArtistReducer;
