import React, { useEffect, useMemo, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { Body } from "./body";

export function Female(props) {
  const { nodes, materials } = useGLTF("/female.glb");

  const boneRef = useRef(null);
  const skeletonRef = useRef(null);

  useEffect(() => {
    Body(skeletonRef.current);
  }, [skeletonRef]);

  let material = materials.lambert2;

  const skeleton = useMemo(() => {
    return (
      <group
        frustumCulled={false}
        scale={props.scale}
        position-y={props.positiony}
        dispose={null}
      >
        <group
          frustumCulled={false}
          position={[0, 0.38, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.05}
        >
          <primitive ref={boneRef} object={nodes.Hips} />
          <skinnedMesh
            frustumCulled={false}
            ref={skeletonRef}
            geometry={nodes.head.geometry}
            material={material}
            skeleton={nodes.head.skeleton}
          />
          <skinnedMesh
            frustumCulled={false}
            geometry={nodes.left_leg.geometry}
            material={material}
            skeleton={nodes.left_leg.skeleton}
          />
          <skinnedMesh
            frustumCulled={false}
            geometry={nodes.right_leg.geometry}
            material={material}
            skeleton={nodes.right_leg.skeleton}
          />
          <skinnedMesh
            frustumCulled={false}
            geometry={nodes.left_arm.geometry}
            material={material}
            skeleton={nodes.left_arm.skeleton}
          />
          <skinnedMesh
            frustumCulled={false}
            geometry={nodes.left_arm001.geometry}
            material={material}
            skeleton={nodes.left_arm001.skeleton}
          />
          <skinnedMesh
            frustumCulled={false}
            geometry={nodes.torso.geometry}
            material={material}
            skeleton={nodes.torso.skeleton}
          />
        </group>
      </group>
    );
  }, []);

  return <>{skeleton}</>;
}

useGLTF.preload("/female.glb");
