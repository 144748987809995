import React from "react";
import { ReactComponent as MaleIcon } from "../../../../assets/ic_male.svg";
import { ReactComponent as FemaleIcon } from "../../../../assets/ic_female.svg";
import { ReactComponent as ChatIcon } from "../../../../assets/ic_chat.svg";
import { ReactComponent as BudgetIcon } from "../../../../assets/ic_budget.svg";
import { ReactComponent as MoneyIcon } from "../../../../assets/ic_money.svg";
import { ReactComponent as ClosedIcon } from "../../../../assets/ic_check_circle_fill_gray.svg";
import { ReactComponent as OpenIcon } from "../../../../assets/ic_circle_open.svg";
import { SERVER_URL, getUserType } from "utils/constants";
import moment from "moment";
import { ReactComponent as GenderIcon } from "../../../../assets/ic_gender.svg";

const Card = ({ data, selectedTab, projectOnClick }) => {
  // const Icon = selectedTab === 1 || selectedTab === 3 ? <MoneyIcon/> : <BudgetIcon/>;
  // const cardStyle = `rounded-lg overflow-hidden shadow-lg w-full px-8 py-4 text-sm ${
  //   selectedTab === 1 && data.projectState === "Project in Process"
  //     ? "border-lightGreen"
  //     : "border-primary"
  // } ${selectedTab === 1 && "border-l-8"}`;

  const getProjectStatusColor = (projectStatus) => {
    switch (projectStatus) {
      case 1:
        return "new";
      case 2:
        return "waiting";
      case 3:
        return "withdrawn";
      case 4:
        return "declined";
      case 5:
        return "done";
      case 6:
        return "waiting";
      case 7:
        return "progress";
      case 8:
        return "waiting";
      case 13:
        return "refunded";
      default:
        return "new";
    }
  };

  let userType = getUserType(data.userTypeId);

  return (
    <div
      key={data.id}
      className="flex grid grid-col-6 gap-0.5 rounded-2xl shadow-md py-4 mt-1 border border-cardDropShadowColor bg-bcard"
      onClick={() => projectOnClick(data.projectId)}
    >
      <div className="flex col-start-1 items-start mr-1 flex-row box-border grow-1">
        <div
          className={`w-2 bg-${getProjectStatusColor(data.statusId)} h-1/3`}
        />
        <div className="flex flex-1 col-start-1 h-full items-center mr-1 flex-col">
          <img
            className="h-12 w-12 bg-gray-300 rounded-full object-cover object-center border border-tertiary"
            src={
              data?.photoUrl
                ? SERVER_URL + data?.photoUrl
                : SERVER_URL + "uploads/default-images/DefaultProfile.jpeg"
            }
            alt=""
          />
        </div>
      </div>
      <div className="col-start-2 col-span-3 h-full flex-col box-border">
        <span className="text-tertiary text-base font-sans">
          #{data.projectId}
        </span>
        <span className="">
          <div className="flex flex-row items-center">
            <span className="text-secondary text-base font-sans font-normal mr-1">
              {data?.fullName} {data?.lastName}
            </span>
            {data?.gender === "female" ? (
              <FemaleIcon />
            ) : data?.gender === "male" ? (
              <MaleIcon />
            ) : (
              <GenderIcon />
            )}
          </div>
        </span>
        <span className="text-secondary text-sm font-sans font-semibold mr-1">
          {userType === "Artist" ? userType : data?.birthYear}
        </span>
        <div className="text-sm mt-2 mb-2 text-tertiary">
          {moment(data?.createdAt).format("D MMM, HH:mm")}
        </div>
      </div>
      <div className="col-start-6 h-full box-border">
        <div className="flex flex-row items-start">
          <div
            className={`rounded-lg border px-2 py-1 flex flex-row gap-2 items-center ${
              data.chatStatusId === 2
                ? "border-gray bg-lightGray"
                : "border-openTicket text-openTicket bg-openTicketBG"
            }`}
          >
            {data.chatStatusId === 2 ? (
              <>
                <ClosedIcon />
                Closed
              </>
            ) : (
              <>
                <OpenIcon stroke="#E9CD14" />
                Open
              </>
            )}
          </div>
        </div>
      </div>

      <div className="col-start-2 col-span-5">
      <div className="flex flex-row items-center mb-2 font-sans text-sm text-secondary whitespace-pre-line">
          {/* <div className="font-bold text-secondary font-sans text-sm"> */}
            {data.bossStatusName.replace('-',  "\n")}
          {/* </div> */}
        </div>
        <div className="text-base font-sans flex flex-row items-center -mr-10 md:mr-0">
          <MoneyIcon className="mr-1" />
          <div className="font-bold ml-1">
            {" "}
            {data?.offerPrice
              ? "Price: " + data?.offerPrice
              : `Budget: ${data?.budgetPriceFrom} - ${data?.budgetPriceTo}`}
          </div>
        </div>
      </div>

      {/* <div className="flex flex-col gap-6">
        <div className="ml-12 flex flex-row justify-between items-center gap-8 text-gray">
          <div>{data?.ticketID}</div>
          {(selectedTab === 1 || selectedTab === 2) && (
            <div
              className={`rounded-lg border-2 px-3 py-1 flex flex-row gap-2 items-center ${
                data.status === "closed"
                  ? "border-gray bg-lightGray"
                  : "border-openTicket text-openTicket bg-openTicketBG"
              }`}
            >
              {data.status === "closed" ? (
                <>
                  <ClosedIcon />
                  Closed
                </>
              ) : (
                <>
                  <OpenIcon stroke="#E9CD14" />
                  Open
                </>
              )}
            </div>
          )}
          {selectedTab === 3 && (
            <div className="flex gap-2">
              <div className="font-bold text-black">{2}</div>
              <ChatIcon fontSize="small" />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center gap-2">
            <div className="w-10 h-10 rounded-full overflow-hidden">
              <img
                src={`${SERVER_URL}uploads/default-images/DefaultProfile.jpeg`}
                alt="client_profile"
              />
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row gap-4">
                <div className="font-bold">
                  {data?.toUser?.firstName} {data?.toUser?.lastName}
                </div>
                <div className="text-red">
                  {data?.toUser?.gender === "female" ? (
                    <FemaleIcon />
                  ) : (
                    <MaleIcon />
                  )}
                </div>
              </div>
              {data?.toUser?.birthYear}
            </div>
          </div>
          <div className="ml-12">
            <div className="text-gray">{data?.date}</div>
            {selectedTab === 1 && (
              <div className="flex gap-1 mt-2 items-center">
                <OpenIcon
                  stroke={
                    data.projectState === "Project in Process"
                      ? "#0BC851"
                      : "#E9CD14"
                  }
                />
                <div className="font-bold">Accepted - </div>
                {data?.projectState}
              </div>
            )}
            {selectedTab === 3 && (
              <div className="flex gap-1 mt-2 font-bold my-6 mt-4">
                <div className="text-lightGreen text-xs">
                  <OpenIcon stroke="#0BC851" />
                </div>
                <div>Project in process - Marked as Done</div>
              </div>
            )}
            <div className="flex justify-between">
              <div className="flex gap-1 mt-2 items-center">
                <div className="text-gray">
                  {Icon}
                </div>
                {selectedTab === 1 || selectedTab === 3 ? "Price:" : " Budget:"}
                <div className="font-bold">{data?.price}</div>
              </div>
              {selectedTab === 3 && <div>2d 13h 45m</div>}
            </div>
            {selectedTab === 3 && (
              <div className="mt-4 flex flex-row items-center gap-2">
                <div className="w-6 h-6 rounded-full overflow-hidden">
                  <img
                    src={`${SERVER_URL}uploads/default-images/DefaultProfile.jpeg`}
                    alt="client_profile"
                  />
                </div>
                Ryan Name
                <div className="text-gray">Artist</div>
              </div>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Card;
