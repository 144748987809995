import * as React from "react";
import { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import Slider from "@material-ui/core/Slider";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import CustomInput from "components/CustomInput";

import { Formik } from "formik";
import { getProjectStatusByName } from "../../CONSTANTS";
import Button from "components/UI/Button";

import { DAYS, getDaysByInt } from "utils/constants";
import TimeList from "./../../common/timeCreate";

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "#C7A33F",
        width: "30px",
        height: "30px",
        marginTop: "-14px",
      },
      track: {
        color: "#C7A33F",
        backgroundColor: "none",
      },
      rail: {
        color: "#C7A33F",
      },
      markLabel: {
        fontSize: 18,
        marginLeft: "20px",
      },
      valueLabel: {
        "& > span > span": {
          color: "#C7A33F",
          backgroundColor: "white",
          fontWeight: 800,
          fontSize: 18,
          marginTop: "68px",
          marginLeft: "152px",
          marginRight: "121px",
        },
        "& > span": {
          color: "white",
          width: "0px",
          height: "0px",
        },
      },
    },
  },
});

const marks = [
  {
    value: 1250,
    label: "1250",
  },
  {
    value: 1500,
    label: "1500",
  },
];

const ArtistOfferPriceComponent = (props) => {

  //let daysData = [{day: 1, fromTime: "01:00:00", toTime: "01:30:00"}, {day: 3, fromTime: "02:00:00", toTime: "02:30:00"}, {day: 2, fromTime: "03:00:00", toTime: "03:30:00"}]
  let daysData = props.data;
  const [timeDetails, setTimeDetails] = useState({
    Monday : [{
      index: Math.random(),
      fromTime: "",
      toTime: ""
    }],
    Tuesday : [{
      index: Math.random(),
      fromTime: "",
      toTime: ""
    }],
    Wednesday : [{
      index: Math.random(),
      fromTime: "",
      toTime: ""
    }],
    Thursday : [{
      index: Math.random(),
      fromTime: "",
      toTime: ""
    }],
    Friday : [{
      index: Math.random(),
      fromTime: "",
      toTime: ""
    }],
    Saturday : [{
      index: Math.random(),
      fromTime: "",
      toTime: ""
    }],
    Sunday : [{
      index: Math.random(),
      fromTime: "",
      toTime: ""
    }]
  });

  const handleClose = () => props.setOpen(false);

  const goToPrevPage = () => {
    props.setOpen(false);
  };

  const handleFormSubmit = (values) => {
    let dates = [];
    let calendarDate;
    Object.keys(timeDetails).map((val1, idx1) => {
      let dayInt = DAYS[`${val1.toUpperCase()}`].id;      
      if (values.dayName === dayInt) {        
        calendarDate = values[`calendar-${dayInt}`];
        timeDetails[val1].map((val, idx) => {
          let dayObj = {'day' : dayInt, 'fromTime': val.fromTime, 'toTime': val.toTime};
          dates.push(dayObj);
        });
      }
    });

    let projectStatusId = (props?.page === "acceptProject") ? getProjectStatusByName.Accepted_Project_by_Artist : getProjectStatusByName.Counter_Offer_by_Artist;
  
    let obj = {
      counterOfferDates : {
        counterOfferDates: JSON.stringify(dates),
        artistId: props.loggedInUserId,
        offerDate: calendarDate,
        statusId: projectStatusId
      },
      offerPrice: {
        price: values.price,
        artistId: props.loggedInUserId
      },
      comments: {
        comment: values.comment,
        loggedInUserId: props.loggedInUserId,
      },
      status: {
        statusId: projectStatusId,
        loggedInUserId: props.loggedInUserId,
      },
    };
    props.onSubmit(obj);
  };

  const formHandleChange = (e) => {
    if (["fromTime", "toTime"].includes(e.target.name)) {
      timeDetails[e.target.dataset.day][e.target.dataset.id][e.target.name] = e.target.value;
    }
  };

  const addNewRow = (day) => {
    let initialDays = timeDetails[day];
    initialDays.push({
      index: Math.random(),
      fromTime: "",
      toTime: ""
    });

    setTimeDetails(timeDetails => ({
      ...timeDetails,
      [day]: initialDays
    }));
  };

  const clickOnDelete = (record, day) => {
    let newDays = timeDetails[day].filter((r) => r !== record);
    setTimeDetails(timeDetails => ({
      ...timeDetails,
      [day] : newDays
    }));
  }
  
  return (
    <div>
      <Transition.Root show={props.open}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex items-start justify-center max-h-screen text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-top sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block align-top bg-white rounded-3xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-top 
                sm:max-w-6xl sm:max-h-4/5 sm:w-full sm:p-6"
              >
                <Formik
                  initialValues={{ comment: "" }}
                  onSubmit={(values) => {
                    handleFormSubmit(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (
                    <form onSubmit={handleSubmit} onChange={formHandleChange}>
                      <div className="px-24">
                        <div className="text-left mt-2">
                          <Dialog.Title className="text-3xl text-primary leading-6 font-medium">
                            {props.title}
                          </Dialog.Title>
                        </div>
                        {props?.page !== "editArtists" && (
                          <div className="flex flex-col gap-10 mt-6">
                            <div className="flex flex-row gap-96">
                              <div className="text-gray text-lg">Price:</div>
                              <div className="ml-96 w-24">
                                <CustomInput
                                  label="price"
                                  type="text"
                                  name="price"
                                  id="price"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.price}
                                />
                              </div>
                            </div>
                            <div>
                              <ThemeProvider theme={muiTheme}>
                                <Slider
                                  defaultValue={1400}
                                  aria-label="Default"
                                  valueLabelDisplay="on"
                                  marks={marks}
                                  min={1250}
                                  max={1500}
                                />
                              </ThemeProvider>
                            </div>
                          </div>
                        )}       
                        <div className="mt-7">
                          <div className="text-gray text-base font-sans mb-4">
                           {"Date & Time:"}
                          </div>
                          <div className="flex flex-row gap-y-6 gap-x-10 flex-wrap">
                            {daysData.map((item) => (
                              <div style={{ width: "27rem" }}>
                                  <div className="my-auto">
                                    <label>
                                      <input
                                        type="radio"
                                        name="dayName"
                                        value={item.day}
                                        checked={values.dayName === item.day}
                                        onChange={() => setFieldValue("dayName", item.day)}
                                      />{getDaysByInt(item.day)}
                                    </label>
                                  </div>
                                  <div className="flex flex-col text-xl">                                   
                                    <div className="text-gray">
                                      <CustomInput
                                        label=""
                                        type="text"
                                        name={"calendar-"+item.day}
                                        id={"calendar-"+item.day}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.this}
                                      />
                                    </div>
                                  </div>
                                  <div className="text-primary my-auto ml-11 text-xl">
                                  {values.dayName === item.day ? 
                                  <TimeList
                                      add={(e) => { addNewRow(getDaysByInt(item.day)); }}
                                      delete={clickOnDelete.bind(this)}
                                      timeDetails={timeDetails}
                                      day={getDaysByInt(item.day)}
                                    /> : '' }
                                  </div>
                              </div>
                            ))}
                          </div>
                        </div>                 
                        <div className="mt-8">                          
                          <div className="mt-8">
                            <CustomInput
                              label="Comment"
                              type="text"
                              name="comment"
                              id="comment"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.comment}
                              multiline
                              rows={2}
                            />
                          </div>
                          <div className="flex flex-row gap-0 mt-4 lg:mt-0">
                            <div className="sm:col-span-1 sm:flex-col sm:justify-start pr-4 lg:pr-0 lg:mb-5 md:mt-5 md:mt-8 flex flex-1 ">
                              <Button
                                type="submit"
                                label={
                                  props?.page === "acceptProject"
                                    ? "Accept"
                                    : "Send"
                                }
                                textsize="base"
                              />
                            </div>
                            <div className="sm:col-span-1 sm:flex-col sm:justify-start sm:px-4 lg:mb-5 md:mt-8 flex flex-1">
                              <Button
                                type="reset"
                                label="Cancel"
                                textsize="base"
                                onClick={goToPrevPage}
                                background={"none"}
                                color={"black"}
                                bordercolor={"primary"}
                              />
                            </div>
                            <div className="lg:flex lg:flex-1" />
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
export default ArtistOfferPriceComponent;
