/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import TopNavBar from "components/Navbars/TopNavBar";
import React, { useState, useEffect } from "react";
import ArtistSideNavBar from "views/artist/components/ArtistSideNavBar";
import { userNavigations, VIEWS, TABS } from "layouts/ClientConstants";
import ArtistPortfolioListCell from "views/artist/components/ArtistPortfolioListCell";
import TabNavBar from "components/Navbars/TabNavBar";
import Reviews from "views/hichem/reviews/components/Reviews";
import {
  PORTFOLIO_STATUS,
} from "../../../layouts/ArtistConstants";
import { USER_ROLE_CLIENT } from "utils/constants";

export const ArtistProfileView = ({
  loginData,
  role,
  artistError,
  actualLoginData,
  artistReviewsAvg,
  selectedTab,
  setSelectedTab,
  artistRatings,
}) => {
  const [tabs, setTabs] = useState([]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  useEffect(() => {
    setTabs([
      {
        id: TABS.PORTFOLIO.id,
        name: TABS.PORTFOLIO.name,
        href: "#",
        current: selectedTab == TABS.PORTFOLIO.id,
      },
      {
        id: TABS.REVIEWS.id,
        name: TABS.REVIEWS.name,
        href: "#",
        current: selectedTab == TABS.REVIEWS.id,
      },
    ]);
  }, [TABS]);

  const setTab = (tabToSet) => {
    setSelectedTab(tabToSet.id);
    let updatedTabs = tabs.map((tab) =>
      tab.id === tabToSet.id
        ? { ...tab, current: true }
        : { ...tab, current: false }
    );
    setTabs(updatedTabs);
  };

  let grid;
  if (selectedTab == TABS.PORTFOLIO.id) {
    grid = (
      <div>
        <section className="mt-0 pb-0" aria-labelledby="gallery-heading">
          <ul className="">
            {loginData?.artist_portfolios?.map((artistPortfolio, index) => (
              // do not show cancelled profolios to client
              !(artistPortfolio.portfolioStatusId === PORTFOLIO_STATUS.CANCELLED && role === USER_ROLE_CLIENT) && 
              <li key={index} className="col-span-1 bg-white p-4 my-0">                
                <ArtistPortfolioListCell
                  artistPortfolio={artistPortfolio}
                  calledFrom={VIEWS.CLIENT_PEOJECT_DETAILS.id}
                  showStatus={true}
                  role={role}
                />
              </li>
            ))}
          </ul>
        </section>
      </div>
    );
  } else if (selectedTab == TABS.REVIEWS.id) {
    grid = <Reviews artistRatings={artistRatings} role={role} />;
  }

  return (
    <>
      <div className="h-full flex">
        <div className="flex-1 flex flex-col overflow-hidden h-full ">
          <TopNavBar
            userNavigations={userNavigations}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            loginData={actualLoginData}
            clientview={true}
          />
          {/* Content area */}
          <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
            <div className="w-full md:w-2/5 lg:w-2/5 xl:w-1/4 2xl:w-1/3 4xl:w-1/4 md:h-full bg-white">
              <ArtistSideNavBar
                loginData={loginData}
                avgRating={artistReviewsAvg}
                role={role}
              />
            </div>
            {/* Main content */}
            <div className="flex-1 flex items-stretch overflow-hidden bg-gradient-to-r">
              <main className="flex-1 overflow-y-auto">
                <div className="md:pt-8 px-5 md:px-12">
                  <div className="pl-4 flex flex-row items-center">
                    <TabNavBar tabs={tabs} handleChange={setTab} />
                  </div>
                  {grid}
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
