import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import ColorReducer from "./ColorReducer";
import StyleReducer from "./StyleReducer";
import ServiceReducer from "./ServiceReducer";
import TechniqueReducer from "./TechniqueReducer";
import StudioReducer from "./StudioReducer";
import ArtistReducer from "./ArtistReducer";
import MarketPlaceDecisionReducer from "./MarketPlaceDecisionReducer";
import ArtistPortfolioReducer from "./ArtistPortfolioReducer";
import ClientReducer from "./ClientReducer";
import ProjectReducer from "./ProjectReducer";
import FaqReducer from "./faqReducer";
import ArtistModuleReducer from "./ArtistModuleReducer";
import InvitedArtistReducer from "./InvitedArtistReducer";
import AdminArtistPortfolioReducer from "./AdminArtistPortfolioReducer";
import NewInviteArtistReducer from "./NewInviteArtistReducer";
import ManagerReducer from "./ManagerReducer";
import SupportReducer from "./SupportReducer";
import ProjectListReducer from "./ProjectListReducer";
import NewsletterReducer from "./NewsletterReducer";
import { AccountingReducer } from "./AccountingReducer";
import graphsReducer from "./GraphsReducer";
import ReviewsReducer from "./ReviewsReducer";
import UserReducerNew from "./UserReducerNew";
import StudioReviewsReducer from "./StudioReviewsReducer";
import CountryReducer from "./CountryReducer";
import SubscriberReducer from "./SubscriberReducer";
import UserTypeReducer from "./UserTypeReducer";
import PaymentReducer from "./PaymentReducer";
import SkinToneReducer from "./SkinToneReducer";
import TabStateReducer from "./TabStateReducer";
import NotificationReducer from "./NotificationReducer";
import LandingReducer from "./LandingReducer";

const appReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  color: ColorReducer,
  style: StyleReducer,
  service: ServiceReducer,
  technique: TechniqueReducer,
  studio: StudioReducer,
  artist: ArtistReducer,
  marketPlaceDecision: MarketPlaceDecisionReducer,
  artistPortfolio: ArtistPortfolioReducer,
  client: ClientReducer,
  project: ProjectReducer,
  faq: FaqReducer,
  artistModule: ArtistModuleReducer,
  invitedArtist: InvitedArtistReducer,
  adminArtistPortfolio: AdminArtistPortfolioReducer,
  newInviteArtistReducer: NewInviteArtistReducer,
  accounting: AccountingReducer,
  graphsData: graphsReducer,
  manager: ManagerReducer,
  support: SupportReducer,
  projectList: ProjectListReducer,
  artistrating: ReviewsReducer,
  newsletter: NewsletterReducer,
  UserReducerNew: UserReducerNew,
  studioReviews: StudioReviewsReducer,
  country: CountryReducer,
  subscriber: SubscriberReducer,
  userType: UserTypeReducer,
  payment: PaymentReducer,
  skintone: SkinToneReducer,
  TabState: TabStateReducer,
  notification: NotificationReducer,
  Landing: LandingReducer
});

const RootReducer = (state, action) => {
  if (action.type === "USER_LOGGED_OUT") {
    window.location.reload();
  }

  return appReducer(state, action);
};
export default RootReducer;
