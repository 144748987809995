import React, { useState } from "react";
import { Formik } from "formik";
import Button from "components/UI/Button";
import { sections } from "../../CONSTANTS";
import GoogleMapReact from "google-map-react";
import { defaultMapProps, MapIcon, MAPS_KEY } from "layouts/AdminConstants";
import { useEffect } from "react";

export default function LocationPage(props) {
  const [latitude, setLatitude] = useState(defaultMapProps.center.lat);
  const [longitude, setLongitude] = useState(defaultMapProps.center.lng);

  let lat = defaultMapProps.center.lat;
  let lng = defaultMapProps.center.lng;
  if (props?.storedProjectState?.locationForm) {
    lat = parseFloat(props?.storedProjectState?.locationForm?.get("latitude"));
    lng = parseFloat(props?.storedProjectState?.locationForm?.get("longitude"));
  }

  let intialValues = {
    lat: lat,
    lng: lng,
  };

  const handleFormSubmit = (values) => {
    let obj = new FormData();
    obj.append("latitude", latitude);
    obj.append("longitude", longitude);
    props.setStoredProjectState((storedProjectState) => ({
      ...storedProjectState,
      "locationForm": obj,
    }));

    if (props.loggedInUserId) {
      props.onSubmit(obj, sections.Submit, "locationForm"); // if user is logged in
    } else {
      props.onSubmit(obj, sections.Finish, "locationForm"); // if new user go to register page
    }
  };

  let markers = [];

  const loadMap = (map, maps) => {
    let position = intialValues;
    const marker = addMarker(map, maps, position);
    marker.addListener("dragend", (event) => {
      setLatitude(event.latLng.lat());
      setLongitude(event.latLng.lng());
    });

    map.addListener("click", (e) => {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }
      markers = [];

      setLatitude(e.latLng.lat());
      setLongitude(e.latLng.lng());
      let position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };

      addMarker(map, maps, position);
    });
  };

  function addMarker(map, maps, position) {
    const marker = new maps.Marker({
      position: position,
      map,
      draggable: true,
      icon: MapIcon,
    });
    markers.push(marker);
    return marker;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="h-screen w-full">
      <Formik
        initialValues={{}}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-2 h-full w-full mt-5 auto-rows-fr"
          >
            <div className="col-start-1 col-span-2 h-full">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: MAPS_KEY,
                }}
                defaultCenter={defaultMapProps.center}
                defaultZoom={defaultMapProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
              />
            </div>
            <div className="col-start-1 col-span-2 md:col-start-1 md:col-span-1">
              <div className="px-4 py-4 flex space-x-2 h-auto">
                <Button
                  label="Back"
                  textsize="base"
                  onClick={() => props.onBack(sections.DateProposal)}
                  background="white"
                  bordercolor="primary"
                  color="primary"
                />
                {props?.loggedInUserId ? (
                  <Button
                    type="submit"
                    label="Submit"
                    textsize="base"
                    bordercolor="primary"
                  />
                ) : (
                  <Button
                    type="submit"
                    label="Next"
                    textsize="base"
                    bordercolor="primary"
                  />
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
