import {
  GET_ARTISTRATINGS_LOADING,
  GET_ARTISTRATINGS_SUCCESS,
  GET_ARTISTRATINGS_ERROR,
  UPDATE_ARTISTRATING_AS_VIEWED_LOADING,
  UPDATE_ARTISTRATING_AS_VIEWED_SUCCESS,
  UPDATE_ARTISTRATING_AS_VIEWED_ERROR,
  UPDATE_ARTISTRATING_STATUS_LOADING,
  UPDATE_ARTISTRATING_STATUS_SUCCESS,
  UPDATE_ARTISTRATING_STATUS_ERROR,
  GET_ARTISTPUBLISHEDRATINGS_LOADING,
  GET_ARTISTPUBLISHEDRATINGS_SUCCESS,
  GET_ARTISTPUBLISHEDRATINGS_ERROR,
  GET_ALLRATINGSARTISTID_LOADING,
  GET_ALLRATINGSARTISTID_SUCCESS,
  GET_ALLRATINGSARTISTID_ERROR ,
} from "../actions/ReviewsAction";

const initialState = {
  reviewsLoading: false,
  reviewsSuccess: false,
  reviewsData: null,
  reviewsError: null,
  loading: false,
  success: false,
  data: null,
  error: null
};

const ReviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTISTRATINGS_LOADING:
    case GET_ARTISTPUBLISHEDRATINGS_LOADING:
    case GET_ALLRATINGSARTISTID_LOADING: {
      return {
        ...state,
        reviewsLoading: true,
        reviewsSuccess: false,
        reviewsData: null,
        reviewsError: null
      };
    }
    case GET_ARTISTRATINGS_SUCCESS:
    case GET_ARTISTPUBLISHEDRATINGS_SUCCESS: 
    case GET_ALLRATINGSARTISTID_SUCCESS : {
      return {
        ...state,
        reviewsLoading: false,
        reviewsSuccess: true,
        reviewsData: action.payload,
        reviewsError: null
      };
    }
    case GET_ARTISTRATINGS_ERROR: 
    case GET_ARTISTPUBLISHEDRATINGS_ERROR: 
    case GET_ALLRATINGSARTISTID_ERROR: {
      return {
        reviewsLoading: false,
        reviewsSuccess: false,
        reviewsData: null,
        reviewsError: action.payload ? action.payload.data.error.message : "Error Occured",
      };
    }
    case UPDATE_ARTISTRATING_STATUS_LOADING:
    case UPDATE_ARTISTRATING_AS_VIEWED_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        data: null,
        error: null
      };
    }
    case UPDATE_ARTISTRATING_STATUS_SUCCESS:
    case UPDATE_ARTISTRATING_AS_VIEWED_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        error: null
      };
    }
    case UPDATE_ARTISTRATING_STATUS_ERROR:
    case UPDATE_ARTISTRATING_AS_VIEWED_ERROR: {
      return {
        loading: false,
        success: false,
        data: null,
        error: action.payload ? action.payload.data.error.message : "Error Occured",
      };
    }
    default: {
      return state;
    }
  }
};

export default ReviewsReducer;
