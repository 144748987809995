import React, { useState } from "react";
import { USER_ROLE_PROJECT_ADMIN } from "../../CONSTANTS";
import EditTechnique from "./EditTechnique";

const Techniques = (props) => {
  const [editOpen, setEditOpen] = useState(false);
  const handleEditOpen = () => setEditOpen(true);

  const techniquesdata = [];
  let techniqueId;
  if (props?.data?.length > 0) {
    props.data.forEach(function (value, index) {
      techniquesdata.push({ id: index, name: value.technique.techniqueName });
      techniqueId = value.techniqueId;
    });
  }
  let show = false;
  if (
    props?.role === USER_ROLE_PROJECT_ADMIN &&
    props?.projectStatus === "New Project"
  ) {
    show = true;
  }
  return (
    <div className="flex flex-col gap-0 mt-6">
      <div className="flex  gap-2 text-sm">
        {techniquesdata.map((technique, index) => (
          <div
            key={index}
            className="col-span-1 bg-primary text-white px-2 py-1 rounded-lg text-base font-sans flex justify-center font-normal"
          >
            {technique.name}
          </div>
        ))}
      </div>
      {show && (
        <div
          className="text-primary text-base justify-center py-1"
          onClick={handleEditOpen}
        >
          Edit Technique
          {editOpen && (
            <EditTechnique
              open={editOpen}
              setOpen={setEditOpen}
              loggedInUserId={props.loggedInUserId}
              onSubmit={props.onSubmit}
              data={props.data}
              techniqueId={techniqueId}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Techniques;
