import axios from "../http-config";

class NewsletterService {
  getNewsletters() {
    return axios({
      method: "GET",
      url: `/newsletters`,
    });
  }

  getNewsletter(id) {
    return axios({
      method: "GET",
      url: `/newsletters/${id}`,
    });
  }

  createNewsletter(data) {
    return axios({
      method: "POST",
      url: `/newsletters`,
      data,
    });
  }

  updateNewsletter(id, data) {
    return axios({
      method: "PATCH",
      url: `/newsletters/${id}`,
      data,
    });
  }

  deleteNewsletter(id, data) {
    return axios({
      method: "PATCH",
      url: `/newsletters/${id}`,
      data,
    });
  }
}

export default new NewsletterService();
