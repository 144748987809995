import StudioReviewsService from "services/StudioReviewsService";

export const STUDIO_ARTIST_REVIEWS_LOADING = "STUDIO_ARTIST_REVIEWS_LOADING";
export const GET_STUDIO_ARTIST_REVIEWS_SUCCESS = "GET_STUDIO_ARTIST_REVIEWS_SUCCESS";
export const STUDIO_ARTIST_REVIEWS_ERROR = "STUDIO_ARTIST_REVIEWS_ERROR";
export const STUDIO_ARTIST_REVIEWS_AVG_LOADING = "STUDIO_ARTIST_REVIEWS_AVG_LOADING";
export const GET_STUDIO_ARTIST_REVIEWS_AVG_SUCCESS = "GET_STUDIO_ARTIST_REVIEWS_AVG_SUCCESS";
export const STUDIO_ARTIST_REVIEWS_AVG_ERROR = "STUDIO_ARTIST_REVIEWS_AVG_ERROR"

export const getStudioArtistReviews = (id) => async (dispatch) => {
  dispatch({
    type: STUDIO_ARTIST_REVIEWS_LOADING,
  });
  try {
    let res = await StudioReviewsService.getStudioArtistReviews(id);
    return dispatch({
      type: GET_STUDIO_ARTIST_REVIEWS_SUCCESS,
      payload: res.data.ratings
    });
  } catch (err) {
    return dispatch({
      type: STUDIO_ARTIST_REVIEWS_ERROR,
      payload: err.response,
    });
  }
};

export const getStudioArtistReviewsAvg = (id) => async (dispatch) => {
  dispatch({
    type: STUDIO_ARTIST_REVIEWS_AVG_LOADING,
  });
  try {
    let res = await StudioReviewsService.getStudioArtistReviewsAvg(id);
    return dispatch({
      type: GET_STUDIO_ARTIST_REVIEWS_AVG_SUCCESS,
      payload: res.data.ratings
    });
  } catch (err) {
    return dispatch({
      type: STUDIO_ARTIST_REVIEWS_AVG_ERROR,
      payload: err.response,
    });
  }
};

