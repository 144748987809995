import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { ReactComponent as DropdownArrow } from "../assets/ico_dropdown_arrow_primary.svg";
import { ReactComponent as RemoveIcon2 } from "../assets/ic_remove.svg";

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownArrow fill="#C7A33F" />
    </components.DropdownIndicator>
  );
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <RemoveIcon2 />
    </components.MultiValueRemove>
  );
};

export const CustomSelect = (_a) => {
  const [selectedOption, setSelect] = useState(null);

  var className = _a.className,
    placeholder = _a.placeholder,
    form = _a.form,
    field = _a.field,
    options = _a.options,
    _b = _a.isMulti,
    isMulti = _b === void 0 ? false : _b,
    approvedTech = _a.approvedTech,
    isCancelable = _a.isCancelable;

  const handleChange = (selectedOption) => {
    setSelect(selectedOption);
    form.setFieldValue(
      field.name,
      isMulti
        ? selectedOption.map(function (item) {
            return item.value;
          })
        : selectedOption.value
    );
  };
  const removeOption = (e) => {
    const newSelect = selectedOption.filter((item) => item.value !== e);
    setSelect(newSelect);
    try {
      _a.setTechniquValue(newSelect);
    } catch {}
  };

  useEffect(() => {
    var choice;
    options = _a.options;
    if (options) {
      choice = isMulti
        ? options.filter(function (option) {
            return field.value.indexOf(option.value) >= 0;
          })
        : options.find(function (option) {
            return option.value === field.value;
          });
    } else {
      choice = isMulti ? [] : "";
    }

    setSelect(choice);
  }, [_a.options]);

  return (
    <div className={`${isCancelable ? "flex items-center" : ""}`}>
      <div className={`${isCancelable ? "inline flex-1 w-full" : ""}`}>
        <Select
          {..._a}
          className={className}
          name={field.name}
          value={selectedOption}
          onChange={handleChange}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          styles={customStyles}
          controlShouldRenderValue={!isMulti}
          components={{
            Placeholder,
            DropdownIndicator,
            IndicatorSeparator: () => null,
            MultiValueRemove,
            ClearIndicator: () => null,
          }}
          menuPortalTarget={
            document.querySelector("#headlessui-portal-root")
              ? ""
              : document.querySelector("body")
          }
        />
      </div>
      {isMulti && (
        <div style={{ marginTop: 10 }}>
          {selectedOption
            ? selectedOption.map((val, index) => (
                <span
                  key={index}
                  style={{
                    borderColor: "#C7A33F",
                    borderWidth: 1,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 4,
                    paddingRight: 4,
                    borderRadius: 8,
                    color: "#C7A33F",
                    marginRight: 6,
                    marginBottom:6,
                    display: "inline-flex",
                  }}
                >
                  {val.label}
                  <button
                    type="button"
                    style={{ marginLeft: 5 }}
                    name={val.value}
                    onClick={() => removeOption(val.value)}
                  >
                    <RemoveIcon2
                      hidden={
                        approvedTech &&
                        approvedTech.length > 0 &&
                        approvedTech.indexOf(val.value) !== -1
                          ? true
                          : false
                      }
                    />
                  </button>
                </span>
              ))
            : null}
        </div>
      )}
      {isCancelable && selectedOption && (
        <RemoveIcon2
          className="ml-4"
          onClick={() => {
            setSelect(null);
            form.setFieldValue(field.name, null);
          }}
        />
      )}
    </div>
  );
};

const customStyles = {
  control: (base, state) => ({
    ...base,
    borderRadius: 10,
    borderColor: "#C7A33F",
    padding: 5,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 10,
    borderColor: "#C7A33F",
    borderWidth: 1,
  }),
  multiValue: (base) => ({
    ...base,
    borderRadius: 7,
    borderColor: "#C7A33F",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    color: "#C7A33F",
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: "#C7A33F",
  }),
};

export default CustomSelect;
