import {
  HICHEM_PROJECT_MODE,
  HICHEM_PROJECT_TAB,
} from "redux/actions/TabStateAction";

const initialState = {
  HICHEM_PROJECT_TAB: "1",
  HICHEM_PROJECT_MODE: "1",
};

const TabStateReducer = function (state = initialState, action) {
  switch (action.type) {
    case HICHEM_PROJECT_TAB: {
      return {
        ...state,
        HICHEM_PROJECT_TAB: action.payload,
      };
    }
    case HICHEM_PROJECT_MODE: {
      return {
        ...state,
        HICHEM_PROJECT_MODE: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default TabStateReducer;
