/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import CommentDetails from "./CommentDetails";
import CommentRoomChat from "./CommentRoomChat";
import { ReactComponent as ChatArrowRight } from "../../../../assets/ic_chat_arrow_right.svg";
import { ReactComponent as ChatArrowLeft } from "../../../../assets/ic_chat_arrow_left.svg";
import { BOSS_USER_NAME } from "utils/constants";
import { getUnreadCommentChatsForUser } from "redux/actions/SupportAction";
import { useDispatch } from "react-redux";

const Comment = ({
  data,
  projectStatus,
  role,
  loggedInUserId,
  users,
  projectId,
  loggedInUserName,
  loggedInUserPhotoUrl,
  handleCommentCount,
  loggedInUserType
}) => {
  const [selectedTab, setSelectedTab] = useState(users?.[0]?.id);
  const [userData, setUserData] = useState([]);
  const limit = 1;
  const [count, setCount] = useState(1);
  const [unReadCountArray, setUnReadCountArray] = useState([]);
  const [userSelected, setUserSelected] = useState(users?.[0]?.id);
  const [selectedTicketId, setSelectedTicketId] = useState(
    users?.[0]?.commentTicketId
  );
  const [msgCount, setMsgCount] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const dispatch = useDispatch();
  const [selectedTabUserType, setSelectedTabUserType] = useState(
    users?.[0]?.userType
  );

 

  useEffect(() => {
    if (!selectedTicketId) {
      setSelectedTicketId(users?.[0]?.commentTicketId);
    }
    if (!selectedTabUserType) {
      setSelectedTabUserType(users?.[0]?.userType);
    }
  }, [users]);

  useEffect(() => {
    if (projectId && loggedInUserId) {
      dispatch(getUnreadCommentChatsForUser(projectId, loggedInUserId)).then(
        (res) => {
          let resArray = res.payload;
          let iniArray = [];
          if (resArray && resArray.length > 0) {
            resArray.map((o) => {
              iniArray[o.createdBy] = o.count;
            });
          }
          setUnReadCountArray(iniArray);
          const countComment = iniArray.filter(x => x > 0).length;
          handleCommentCount(countComment);
        }
      );
    }
  }, [projectId, loggedInUserId, selectedTab]);

  useEffect(() => {
    if (count < users?.length) {
      const start = count === 1 ? 0 : count * limit;
      const end = users?.length > start + limit ? users?.length : start + limit;
      const newArray = users?.slice(start, end);
      // setSelectedTab(newArray[0].id);
      setUserData(newArray);
    }
  }, [count, users]);

  const selectChatDetails = (id, ticketId, tabUserType) => {
    setSelectedTab(id);
    setUserSelected(id);
    setSelectedTicketId(ticketId);
    setSelectedTabUserType(tabUserType);
  };

  const displayNext = () => {
    setCount((prevState) => prevState + 1);
  };

  const displayPrev = () => {
    setCount((prevState) => prevState - 1);
  };

  return (
    <div className="w-full lg:mb-20">
      <div className="flex flex-row items-center">
        {count >= 1 && (
          <div onClick={() => displayPrev()} className={`hidden md:block`}>
            <ChatArrowLeft className="mb-7 mr-2" />
          </div>
        )}
        <div className="w-full flex overflow-x-auto mb-4 grid md:grid-flow-col auto-cols-max gap-4 border-primaryBorder border-b-2 items-center">
          {userData.map((item) => (
            <div
              key={item.id}
              className={`w-full grow ${
                selectedTab === item.id
                  ? "border-primary text-primary border-b-4"
                  : "border-0"
              } text-center`}
              onClick={() => selectChatDetails(item.id, item.commentTicketId, item.userType)}
            >
              <div className={`flex flex-row gap-2 items-center w-full mb-2 font-sans`}>
                {item.userType == "Hishem" && (
                  <div className="text-base">{BOSS_USER_NAME}</div>
                )}
                {item.userType !== "Hishem" && (
                  <div className="flex flex-row">
                    <div className="text-base">{item.firstName}</div>
                    {item.userType && (
                      <div className="text-base">({item.userType})</div>
                    )}
                  </div>
                )}

                <div className="text-base font-bold font-sans text-progress">
                  {unReadCountArray[item.id] ?  <div className="p-1 bg-progress rounded-full"></div> : ''}
                </div>
              </div>
            </div>
          ))}
          <div className="flex flex-1" />
        </div>
        {count < users.length - 1 && (
          <div onClick={() => displayNext()} className={`hidden md:block`}>
            <ChatArrowRight className="mb-7 ml-2" />
          </div>
        )}
      </div>

      {selectedTab === 999999 && (
        <CommentRoomChat
          loggedInUserName={loggedInUserName}
          loggedInUserPhotoUrl={loggedInUserPhotoUrl}
          loggedInUserId={loggedInUserId}
          projectId={projectId}
          setMsgCount={setMsgCount}
          selectedTicketId={selectedTicketId}
          setIsFileUploaded={setIsFileUploaded}
          isFileUploaded={isFileUploaded}
          selectedTabUserType={selectedTabUserType}
          loggedInUserType={loggedInUserType}
        />
      )}

      {selectedTab !== 999999 && (
        <CommentDetails
          projectStatus={projectStatus}
          role={role}
          loggedInUserId={loggedInUserId}
          userData={userData}
          userSelected={userSelected}
          projectId={projectId}
          loggedInUserName={loggedInUserName}
          loggedInUserPhotoUrl={loggedInUserPhotoUrl}
          selectedTicketId={selectedTicketId}
          selectedTab={selectedTab}
          setMsgCount={setMsgCount}
          msgCount={msgCount}
          setIsFileUploaded={setIsFileUploaded}
          isFileUploaded={isFileUploaded}
          selectedTabUserType={selectedTabUserType}
          loggedInUserType={loggedInUserType}
        />
      )}
    </div>
  );
};

export default Comment;
