import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { defaultMapProps, MAPS_KEY } from "layouts/AdminConstants";
import Marker from "./Marker";

const ProjectsOnMap = (props) => {
  const [pointData, setPointData] = useState([]);
  useEffect(() => {
    if (props?.projectList) {
      let points = [];
      props?.projectList?.map((items) => {
        points.push({
          id: items.id,
          title: items.id,
          lat: items?.latitude,
          lng: items?.longitude,
        });
      });
      setPointData(points);
    }
  }, [props?.projectList]);

  // Return map bounds based on list of places
  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place) => {
      bounds.extend(new maps.LatLng(place.lat, place.lng));
    });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, places) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, places);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  return (
    <div className="mt-4 mb-4" style={{ height: '70vh', width: '100%' }}>
      <GoogleMapReact
        key={pointData}
        bootstrapURLKeys={{
          key: MAPS_KEY,
        }}
        center={{
          lat: defaultMapProps.center.lat,
          lng: defaultMapProps.center.lng,
        }}
        zoom={10}
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, pointData)}
      >
        {pointData?.map(({ lat, lng, id, title }) => {
          return (
            <Marker
              key={id}
              lat={lat}
              lng={lng}
              text={id}
              tooltip={title}
              projectOnClick={props.projectOnClick}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default ProjectsOnMap;
