import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FaqView from "./FaqView";
import { getData } from "redux/actions/faqAction";
import { createSubscriber } from "redux/actions/SubscriberAction";

const options = [
  { id: 1, name: "General", value: "general" },
  { id: 2, name: "Artists", value: "artists" },
  { id: 3, name: "Client", value: "client" },
];

const FaqContainer = (props) => {
  const [selectedOption, setSelectedOption] = useState(1);
  const faqState = useSelector((state) => state.faq);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  const subscriptionResponse = useSelector((state) => state.subscriber);

  const subscribe = (obj) => {
    dispatch(createSubscriber(obj));
  };

  return (
    <FaqView
      faqState={faqState}
      options={options}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      subscribe={subscribe}
      subscriptionResponse={subscriptionResponse}
    />
  );
};
export default FaqContainer;
