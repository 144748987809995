import axios from "../http-config";

class StyleService {
  getStyles() {
    return axios({
      method: "GET",
      url: "/styles",
    });
  }

  getStyle(id) {
    return axios({
      method: "GET",
      url: `/styles/${id}`,
    });
  }
}

export default new StyleService();
