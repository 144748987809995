import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "components/Navbars/AuthNavbar";
import TopNavBar from "components/Navbars/TopNavBar";
import { userNavigations } from "layouts/ClientConstants";
import { updatePaymentDetails } from "redux/actions/PaymentAction";
import localStorageService from "services/localStorageService";
import { PAYMENT_STATUS_SUCCESS } from "./CONSTANTS";

const PaymentSuccessView = (props) => {
  const dispatch = useDispatch();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const projectId = props.match.params.id;
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  useEffect(() => {
    let pId =
      localStorageService.getItem("pId") != undefined &&
      localStorageService.getItem("pId") != null
        ? localStorageService.getItem("pId")
        : null;
    if (pId) {
      localStorageService.removeItem("pId");
      let data = {
        loggedInUserId: props.loginData.id,
        status: PAYMENT_STATUS_SUCCESS,
      };
      dispatch(updatePaymentDetails(data, pId)).then(() => {
        if (window.top.location !== window.location) {
          const url = `${process.env.REACT_APP_WEB_SERVER_URL}client/project-details/${projectId}`;
          window.location.replace(url);
        }
      });
    }
  }, []);

  const goHome = () => {
    const url = `${process.env.REACT_APP_WEB_SERVER_URL}client/project-details/${projectId}`;
    window.location.replace(url);
  };

  return (
    <>
      <div className="bg-white">
        {/* Header section */}
        {!props.loginData && (
          <header>
            <Header className="" />
          </header>
        )}
        {props.loginData && (
          <TopNavBar
            userNavigations={userNavigations}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            loginData={props.loginData}
            clientview={false}
          />
        )}
        <main className="overflow-hidden">
          <div className="flex flex-col items-center justify-items-center">
            <div className="aspect-w-3 aspect-h-2 overflow-hidden">
              <img
                src="/Thankyou.png"
                alt=""
                className="w-45 h-45 object-center object-cover"
              />
            </div>
            <object
              type="image/svg+xml"
              data="/2ndSkin.svg"
              className="h-20 w-auto content-center mx-auto object-cover pointer-events-none m-4"
            >
              2ndSkin
            </object>
            {props?.loginData?.id && <p>Your Payment is Successfull</p>}
            <button
              className="bg-primary text-white py-2 px-4 rounded-xl m-4"
              onClick={() => goHome()}
            >
              {" "}
              Go to Home
            </button>
          </div>
        </main>
      </div>
    </>
  );
};

export default PaymentSuccessView;
