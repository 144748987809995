import React, { useState, useEffect } from "react";
import StudioHomeView from "./StudioHomeView";
import { useSelector, useDispatch } from "react-redux";
import {
  TABS,
  ADMINISTRATIONVIEWS,
  studioTypes,
} from "../../../layouts/StudioConstants";
import {
  getStudioCurrentProjects,
  getStudioProjectsHistory,
  getStudioEarnings,
  getStudio,
} from "redux/actions/StudioAction";
import {
  getManagersByStudioType,
  addManager,
  deleteManager,
  editManager,
  getManager,
} from "redux/actions/ManagerAction";
import {
  getUserById,
  updateUser,
  changePassword,
} from "redux/actions/UserActions";
import { getAllArtists } from "redux/actions/AdminArtistAction";
import localStorageService from "services/localStorageService";

const StudioHomeContainer = (props) => {
  const [selectedTab, setSelectedTab] = useState(TABS.CURRENTPROJECTS.id);
  const studioState = useSelector((state) => state.studio);
  const managerState = useSelector((state) => state.manager);
  const userDataObj = useSelector((state) => state.user.data);
  const artistState = useSelector((state) => state.artist);

  const dispatch = useDispatch();
  const [firstTime, setFirstTime] = useState(false);
  const [currentCount, setCurrentCount] = useState(0);
  const [historyCount, setHistoryCount] = useState(0);
  const [selectedAdminView, setSelectedAdminView] = useState(
    ADMINISTRATIONVIEWS.MANAGERLIST.id
  );
  const [selectedManagerId, setSelectedManagerId] = useState();
  const studio = useSelector((state) => state.studio);

  useEffect(() => {
    if (selectedTab === TABS.CURRENTPROJECTS.id) {
      dispatch(getStudioCurrentProjects(props.loginData.studioId));
    } else if (selectedTab === TABS.PROJECTHISTORY.id) {
      dispatch(getStudioProjectsHistory(props.loginData.studioId));
    } else if (selectedTab === TABS.CURRENTARTISTS.id) {
      dispatch(getAllArtists(studioTypes["My Studio"]));
    } else if (selectedTab === TABS.ADMINISTRATION.id) {
      if (selectedAdminView === ADMINISTRATIONVIEWS.MANAGERLIST.id) {
        dispatch(getManagersByStudioType(studioTypes["My Studio"]));
      } else if (
        selectedAdminView === ADMINISTRATIONVIEWS.PROFILE.id ||
        selectedAdminView === ADMINISTRATIONVIEWS.CHANGEPASSWORD.id
      ) {
        let loggedUser = localStorageService.getItem("auth_user");
        dispatch(getUserById(loggedUser.id));
      } else if (selectedAdminView === ADMINISTRATIONVIEWS.EDITMANAGER.id) {
        dispatch(getManager(selectedManagerId));
      }
    } else if (selectedTab === TABS.EARNINGS.id) {
      dispatch(getStudioEarnings());
    }
  }, [dispatch, selectedTab, selectedAdminView]);

  useEffect(() => {
    if (!firstTime) {
      dispatch(getStudioCurrentProjects(1));
      dispatch(getStudioProjectsHistory(1));
      setFirstTime(true);
    }
    setCurrentCount(studioState?.studioCurrentProjects?.project?.length);
    setHistoryCount(studioState?.studioProjectsHistory?.project?.length);
  }, [studioState]);

  useEffect(() => {
    if (props.loginData.studioId) {
      dispatch(getStudio(props.loginData.studioId));
    }
  }, [props.loginData, selectedTab]);

  const onSubmit = (obj) => {
    if (selectedAdminView === ADMINISTRATIONVIEWS.PROFILE.id) {
      obj.id = userDataObj.id;
      dispatch(updateUser(obj));
    } else if (selectedAdminView === ADMINISTRATIONVIEWS.CHANGEPASSWORD.id) {
      obj.id = userDataObj.id;
      dispatch(changePassword(obj));
    } else if (selectedAdminView === ADMINISTRATIONVIEWS.ADDMANAGER.id) {
      dispatch(addManager(obj)).then(() => {
        setSelectedAdminView(ADMINISTRATIONVIEWS.MANAGERLIST.id);
      });
    } else if (selectedAdminView === ADMINISTRATIONVIEWS.EDITMANAGER.id) {
      obj.id = selectedManagerId;
      dispatch(editManager(obj)).then(() => {
        setSelectedAdminView(ADMINISTRATIONVIEWS.MANAGERLIST.id);
      });
    }
  };

  const onDelete = (uid) => {
    dispatch(deleteManager(uid));
    setSelectedAdminView(ADMINISTRATIONVIEWS.MANAGERLIST.id);
  };

  return (
    <div>
      <StudioHomeView
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        loginData={props.loginData}
        studioState={studioState}
        currentCount={currentCount}
        historyCount={historyCount}
        managerState={managerState}
        selectedAdminView={selectedAdminView}
        setSelectedAdminView={setSelectedAdminView}
        userDataObj={userDataObj}
        onSubmit={onSubmit}
        selectedManagerId={selectedManagerId}
        setSelectedManagerId={setSelectedManagerId}
        artistState={artistState}
        studio={studio.getStudio}
        studioError={studio.error}
        onDelete={onDelete}
      />
    </div>
  );
};

export default StudioHomeContainer;
