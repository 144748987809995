/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardContent, Rating } from "@mui/material";
import { SERVER_URL, getDaysByIntShort } from "utils/constants";
import { ReactComponent as SearchIcon } from "../../../assets/ic_search.svg";
import CheckBox from "components/UI/CheckBox";
import { ReactComponent as FilledRatingIcon } from "../../../assets/ic_rating_filled.svg";
import { ReactComponent as EmptyRatingIcon } from "../../../assets/ic_rating_empty.svg";
import { styled } from '@mui/material/styles';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#C7A33F',
  },
  '& .MuiRating-iconHover': {
    color: '#AFAFAF',
  },
  '& .MuiRating-iconEmpty': {
    color: '#AFAFAF',
  },
});

const MyArtist = (props) => {
  let history = useHistory();

  const [studioOptions, setStudioOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [styleOptions, setStyleOptions] = useState([]);
  const [techniqueoptions, setTechniqueoptions] = useState([]);
  const [selectedStudioId, setSelectedStudioId] = useState("");
  const [selectedStyleId, setSelectedStyleId] = useState("");
  const [selectedTechId, setSelectedTechId] = useState("");
  const [selectedSearchText, setSelectedSearchText] = useState("");
  const [artistfilteredData, setArtistfilteredData] = useState("");
  const [selectedDay, setSelectedDay] = useState("");

  const [mon, setMon] = useState(false);
  const [tue, setTue] = useState(false);
  const [wed, setWed] = useState(false);
  const [thu, setThu] = useState(false);
  const [fri, setFri] = useState(false);
  const [sat, setSat] = useState(false);
  const [sun, setSun] = useState(false);
  const [studioValue, setStudioValue] = useState(0);
  const [styleValue, setStyleValue] = useState(0);
  const [techniqueValue, setTechniqueValue] = useState(0);
  const [searchTextValue, setSearchTextValue] = useState("");

  const onReset = () => {
    setMon(false);
    setTue(false);
    setWed(false);
    setThu(false);
    setFri(false);
    setSat(false);
    setSun(false);
    setSelectedDay("");
    setStudioValue(0);
    setStyleValue(0);
    setTechniqueValue(0);
    setSelectedStudioId(0);
    setSelectedStyleId(0);
    setSelectedTechId(0);
    setSelectedSearchText("");
    setSearchTextValue("");    
  }

  const populateStudios = () => {
    let studios = props.studios;
    let studioOptionItems = [];
    if (studios && studios.length > 0) {
      studioOptionItems = studios.map((studio) => (
        <option key={studio.id} value={studio.id}>
          {studio.placeName}
        </option>
      ));
    }

    studioOptionItems.unshift(
      <option key={0} value={0}>
        {"All Studios"}
      </option>
    );

    setStudioOptions(studioOptionItems);
  };

  const populateCountry = () => {
    let countries = props.countries;
    let countryOptionItems = [];
    if (countries && countries.length > 0) {
      countryOptionItems = countries.map((country) => (
        <option key={country.name} value={country.name}>
          {country.name}
        </option>
      ));
    }
    countryOptionItems.unshift(
      <option key={0} value={0}>
        {"All Countries"}
      </option>
    );

    setCountryOptions(countryOptionItems);
  };

  const populateStyles = () => {
    let styles = props.styles;
    let stylesOptionItems = [];
    if (styles && styles.length > 0) {
      stylesOptionItems = styles.map((style) => (
        <option key={style.id} value={style.id}>
          {style.styleName}
        </option>
      ));
    }

    stylesOptionItems.unshift(
      <option key={0} value={0}>
        {"All Styles"}
      </option>
    );

    setStyleOptions(stylesOptionItems);
  };

  const populateTechniques = () => {
    let techniques = props.techniques;
    let techniqueOptionItems = [];
    if (techniques && techniques.length > 0) {
      techniqueOptionItems = techniques.map((technique) => (
        <option key={technique.id} value={technique.id}>
          {technique.techniqueName}
        </option>
      ));
    }

    techniqueOptionItems.unshift(
      <option key={0} value={0}>
        {"All Techniques"}
      </option>
    );

    setTechniqueoptions(techniqueOptionItems);
  };

  useEffect(() => {
    populateCountry();
    populateStudios();
    populateStyles();
    populateTechniques();
  }, [props.countries, props.techniques, props.styles, props.studios]);

  const artistonClick = (artistId) => {
    history.push({
      pathname: "/admin/artist/" + artistId,
    });
  };

  const currentStudioSelection = (val) => {
    setSelectedStudioId(val);
    setStudioValue(val);
  };

  const currentStyleSelection = (val) => {
    setSelectedStyleId(val);
    setStyleValue(val);
  };

  const currentTechSelection = (val) => {
    setSelectedTechId(val);
    setTechniqueValue(val);
  };

  const currentTextSearchSelection = (val) => {
    setSelectedSearchText(val);
    setSearchTextValue(val)
  };

  const currentDaySelection = (val, day) => {
    if (val) {
      setSelectedDay(day);
    } else {
      setSelectedDay("");
    }

    switch (day) {
      case 1:
        setMon(val);
      break;
      case 2:
        setTue(val);
      break;
      case 3:
        setWed(val);
      break;
      case 4:
        setThu(val);
      break;
      case 5:
        setFri(val);
      break;
      case 6:
        setSat(val);
      break;
      case 7:
        setSun(val);
      break;
      default:
        return 0;
    }
       

  };

  useEffect(() => {
    let finalData = props.myArtists?.user;

    //studio
    if (selectedStudioId && selectedStudioId !== "0") {
      let studiofilteredData = finalData.filter((rec) => {
        return rec.studio_artists.some((aStudio) => {
          return aStudio.studioId === parseInt(selectedStudioId);
        });
      });
      finalData = studiofilteredData;
    }

    //style
    if (selectedStyleId && selectedStyleId !== "0") {
      let studiofilteredData = finalData.filter((rec) => {
        return rec.artist_portfolios.some((aPortfolio) => {
          return aPortfolio.styleId === parseInt(selectedStyleId);
        });
      });
      finalData = studiofilteredData;
    }

    //technique
    if (selectedTechId && selectedTechId !== "0") {
      let studiofilteredData = finalData.filter((rec) => {
        return rec.artist_techniques.some((aTech) => {
          return aTech.techniqueId === parseInt(selectedTechId);
        });
      });
      finalData = studiofilteredData;
    }

    //search text
    if (selectedSearchText && selectedSearchText !== "") {
      let studiofilteredData = finalData.filter((rec) => {
        return (
          (rec.firstName &&
            rec.firstName
              .toLowerCase()
              .indexOf(selectedSearchText.toLowerCase()) !== -1) ||
          (rec.lastName &&
            rec.lastName
              .toLowerCase()
              .indexOf(selectedSearchText.toLowerCase()) !== -1)
        );
      });

      finalData = studiofilteredData;
    }

    //Day
    if (selectedDay && selectedDay !== "0") {
      let studiofilteredData = finalData;
      if (selectedStudioId && selectedStudioId !== "0") {
        studiofilteredData = finalData.filter((rec) => {
          return rec.studio_artists.some((aStudio) => {
            return (
              aStudio.studioId === parseInt(selectedStudioId) &&
              aStudio.artist_studio_work_days.some((aWorkDays) => {
                return aWorkDays.day === parseInt(selectedDay);
              })
            );
          });
        });
      } else {
        studiofilteredData = finalData.filter((rec) => {
          return rec.studio_artists.some((aStudio) => {
            return aStudio.artist_studio_work_days.some((aWorkDays) => {
              return aWorkDays.day === parseInt(selectedDay);
            });
          });
        });
      }
      finalData = studiofilteredData;
    }

    setArtistfilteredData(finalData);
  }, [
    props.myArtists?.user,
    selectedStudioId,
    selectedTechId,
    selectedStyleId,
    selectedSearchText,
    selectedDay,
  ]);

  return (
    <div className="my-10">
      {/* Search Bar */}
      <div className="flex md:grid md:grid-cols-10 flex-col gap-2">
        <div className="col-start-1 col-span-3">
          <div className="py-0 px-4  mt-0">
            <div className="pl-3 rounded-xl py-2 mb-0 bg-tertiary bg-opacity-20 flex flex-row items-center">
              <SearchIcon className="h-4 w-4" />
              <input
                className="text-black mr-2 pl-2 pr-35 bg-opacity-0 flex bg-tertiary outline-none"
                placeholder="Search"
                onChange={(e) => currentTextSearchSelection(e.target.value)}
                value={searchTextValue}
              ></input>
            </div>
          </div>
          <div className="p-2 bg-white border border-tertiary mx-4 rounded-xl mt-3">
            <select
              className="w-full bg-white border-transparent focus:outline-none"
              onChange={(e) => currentStyleSelection(e.target.value)}       
              value={styleValue}      
            >
              {styleOptions}
            </select>
          </div>
        </div>
        <div className="col-start-4 col-span-3 ">
          <div className="p-2 bg-white border border-tertiary mx-4 rounded-xl">
            <select
              className="w-full bg-white border-transparent focus:outline-none"
              onChange={(e) => currentStudioSelection(e.target.value)}
              value={studioValue}
            >
              {studioOptions}
            </select>
          </div>
          <div className="p-2 bg-white border border-tertiary mx-4 rounded-xl mt-3">
            <select
              className="w-full bg-white border-transparent focus:outline-none"
              onChange={(e) => currentTechSelection(e.target.value)}
              value={techniqueValue}
            >
              {techniqueoptions}
            </select>
          </div>
        </div>
        <div className="col-start-7 col-span-3"></div>
        <div className="col-start-1 col-span-10 h-10 mt-3 mx-4 flex flex-row gap-3 md:gap-8 flex-wrap mb-5 md:mb-0">
          <CheckBox
            type="checkbox"
            label={"Mon"}
            onChange={(e) => currentDaySelection(e.target.checked, 1)}
            width={"5"}
            checked={mon}
          />
          <CheckBox
            type="checkbox"
            label={"Tue"}
            onChange={(e) => currentDaySelection(e.target.checked, 2)}
            width={"5"}
            checked={tue}
          />
          <CheckBox
            type="checkbox"
            label={"Wed"}
            onChange={(e) => currentDaySelection(e.target.checked, 3)}
            width={"5"}
            checked={wed}
          />
          <CheckBox
            type="checkbox"
            label={"Thu"}
            onChange={(e) => currentDaySelection(e.target.checked, 4)}
            width={"5"}
            checked={thu}
          />
          <CheckBox
            type="checkbox"
            label={"Fri"}
            onChange={(e) => currentDaySelection(e.target.checked, 5)}
            width={"5"}
            checked={fri}
          />
          <CheckBox
            type="checkbox"
            label={"Sat"}
            onChange={(e) => currentDaySelection(e.target.checked, 6)}
            width={"5"}
            checked={sat}
          />
          <CheckBox
            type="checkbox"
            label={"Sun"}
            onChange={(e) => currentDaySelection(e.target.checked, 7)}
            width={"5"}
            checked={sun}
          />
        </div>
      </div>
      <button
          className="text-primary text-base mt-2 px-4 mb-8"
          onClick={() => {
            onReset();
          }}
        >
          <u>Reset</u>
        </button>
      <div className="flex grid xl:grid-cols-3 md:grid-cols-2 gap-4 flex-wrap text-sm w-full">
        {artistfilteredData &&
          artistfilteredData.map((item) => {
            return (
              <Card
                style={{ borderRadius: "20px", paddingRight: "10px" }}
                className="rounded-xl"
                key={item.id}
                onClick={() => artistonClick(item.id)}
              >
                <CardContent>
                  <div className="flex flex-row gap-4">
                    <img
                      className="h-12 w-12 rounded-full mt-2 object-cover object-center"
                      src={
                        item?.photoUrl
                          ? SERVER_URL + item?.photoUrl
                          : SERVER_URL +
                            "uploads/default-images/DefaultProfile.jpeg"
                      }
                      alt="profile"
                    />
                    <div className="flex flex-col gap-1 flex-1">
                      <div className="flex flex-col lg:flex-row align-right">
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-row gap-6 item-end">
                            <div className="font-bold mr-2">
                              {item.firstName} {item.lastName} <span className="text-red">{(item.isBlocked) ? " (Blocked)" : ""}</span>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-1" />
                        <div>
                          <div className="text-white font-black">
                            <div className="flex items-center">
                              {/* Average of rating */}
                              <StyledRating
                                name="simple-controlled"
                                value={item.artist_ratings.reduce(
                                  (acc, v, i, a) => acc + v.rating / a.length,
                                  0
                                )}
                                size="small"
                                // icon={<FilledRatingIcon fontSize="inherit" />}
                                // emptyIcon={<EmptyRatingIcon fontSize="inherit" />}
                                // precision={0.5}
                              />
                              {/* Number of ratings */}
                              <span className="ml-1 text-sm text-tertiary font-normal">
                                ({item.artist_ratings.length})
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm:hidden text-tertiary text-sm mr-2 overflow-hidden truncate inline-block w-52">
                        {item.email ? item.email : ""}
                      </div>
                      <div className="hidden sm:block text-tertiary text-sm mr-2 overflow-hidden truncate inline-block w-full">
                        {item.email ? item.email : ""}
                      </div>
                      <div className="text-tertiary text-sm">
                        {item.country ? item.country : "N/A"}
                      </div>
                      <div className="text-tertiary text-sm">
                        {item.phone ? item.phone : "N/A"}
                      </div>
                      <div className="text-sm">{item?.styleTitle}</div>
                      <div className="text-primary text-wrap break-words flex flex-row flex-1">
                        {item?.artist_portfolios
                          ?.map((item) => " " + item.style.styleName)
                          .toString()}
                      </div>
                      <div>
                        <div>
                          <u>Work days</u>
                        </div>
                        {item?.studio_artists?.map((item1, index) => (
                          <div key={index}>
                            {item1?.artist_studio_work_days?.map(
                              (item2, index) => (
                                <div
                                  key={index}
                                  className="flex grid grid-cols-5 text-tertiary"
                                >
                                  <div key={index}>
                                    {getDaysByIntShort(item2.day)}
                                  </div>
                                  <div className="col-span-4">
                                    {" "}
                                    {item1.studio.placeName}{" "}
                                  </div>{" "}
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            );
          })}
      </div>
    </div>
  );
};

export default MyArtist;
