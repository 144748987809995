import React from "react";
import moment from "moment";
import { ReactComponent as MaleIcon } from "../../../../assets/ic_male.svg";
import { ReactComponent as FemaleIcon } from "../../../../assets/ic_female.svg";
import { ReactComponent as GenderIcon } from "../../../../assets/ic_gender.svg";
import { ReactComponent as MoneyIcon } from "../../../../assets/ic_money.svg";
import { ReactComponent as ChatIcon } from "../../../../assets/ic_chat.svg";
import { SERVER_URL } from "utils/constants";
import { CLIENT_PROJECT_TABS } from "layouts/AdminConstants";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#C7A33F",
  },
  "& .MuiRating-iconHover": {
    color: "#AFAFAF",
  },
  "& .MuiRating-iconEmpty": {
    color: "#AFAFAF",
  },
});

const ClientProjects = (props) => {
  const getProjectStatusColor = (projectStatus) => {
    switch (projectStatus) {
      case 1:
        return "new";
      case 2:
        return "waiting";
      case 3:
        return "withdrawn";
      case 4:
        return "declined";
      case 5:
        return "done";
      case 6:
        return "waiting";
      case 7:
        return "progress";
      case 8:
        return "waiting";
      case 13:
        return "refunded";
      default:
        return "new";
    }
  };

  const projectCard = (items) => {
    return (
      <div
        key={items.id}
        className="flex grid grid-col-6 gap-0.5 rounded-2xl shadow-md py-4 mt-1 border border-cardDropShadowColor bg-bcard"
        onClick={() => props.projectOnClick(items.id)}
      >
        <div className="col-start-1 items-start mr-1 flex flex-row box-border">
          <div
            className={`w-2 bg-${getProjectStatusColor(
              items.projectStatusId
            )} h-1/3`}
            hidden={props.selectedTab == 1}
          />
          <div className="flex flex-1 col-start-1 h-full items-center mr-1 flex-col">
            <img
              className="h-12 w-12 bg-gray-300 rounded-full object-cover object-center border border-tertiary "
              src={
                items.user.photoUrl
                  ? SERVER_URL + items.user.photoUrl
                  : SERVER_URL + "uploads/default-images/DefaultProfile.jpeg"
              }
              alt=""
            />
          </div>
        </div>
        <div className="col-start-2 col-span-5 h-full flex-col box-border">
          <div className="box-border flex flex-row">
            <span className="text-tertiary text-base font-sans">
              #{items.id}
            </span>
            <div className="flex flex-1"></div>
            <div className="flex flex-row items-start mr-2 ml-2 mb-1">
              {items.projectStatusId == 1 ? (
                <span className="px-3 py-1 bg-primary rounded-lg text-white text-sm font-bold">
                  {items.project_status.statusName}
                </span>
              ) : (
                <div className="flex items-center">
                  <div className="mr-2 font-bold text-base">
                    {(items?.user_comment_chats_statuses
                      ? items?.user_comment_chats_statuses?.length
                      : 0) +
                      (items?.user_support_chats_statuses
                        ? items?.user_support_chats_statuses?.length
                        : 0)}
                  </div>
                  <ChatIcon />
                </div>
              )}
            </div>
          </div>

          <span className="">
            <div className="flex flex-row items-center">
              <span className="text-secondary text-base font-sans font-normal mr-1">
                {items?.user?.firstName} {items?.user?.lastName}
              </span>
              {items.gender === "female" ? (
                <FemaleIcon />
              ) : items.gender === "male" ? (
                <MaleIcon />
              ) : (
                <GenderIcon />
              )}

              {items?.skin_tone?.imageUrl ? (
                <img
                  src={`${SERVER_URL}` + items?.skin_tone?.imageUrl}
                  height="20"
                  width="20"
                  alt="Skin Tone"
                />
              ) : (
                ""
              )}
            </div>
          </span>
          <span className="text-secondary text-sm font-sans font-semibold mr-1">
            {items?.user?.birthYear}
          </span>
          <div className="text-sm mt-2 mb-3 text-tertiary">
            {moment(items.createdAt).format("D MMM, HH:mm")}
          </div>

          {items.projectStatusId != 1 && (
            <div className="flex flex-1 flex-row items-center mb-5 mr-2">
              <div
                hidden={
                  props.selectedTab == CLIENT_PROJECT_TABS.PROJECTHISTORY.id
                }
                className={`border-3 flex flex-3 rounded-full min-w-4 w-4 h-4 border-${getProjectStatusColor(
                  items.projectStatusId
                )} mr-2`}
              />
              <div
                hidden={
                  props.selectedTab == CLIENT_PROJECT_TABS.CURRENTPROJECTS.id
                }
                className="font-bold flex flex-1 text-secondary font-sans text-base whitespace-pre-line"
              >
                {items.project_status?.bossStatusName
                  .replace("-", "\n")
                  .replace("for", " ")
                  .replace("Date", " ")}
              </div>
            </div>
          )}

          <div className="text-base font-sans flex flex-row items-center  md:mr-0">
            <MoneyIcon className="mr-1" />{" "}
            <div className="font-bold ml-1">
              {" "}
              {items?.offerPrice
                ? "Price: " + items?.offerPrice
                : items?.counter_offers && items?.counter_offers[0]?.offerPrice
                ? "Price: " + items?.counter_offers[0]?.offerPrice
                : `Budget: ${items?.budgetPriceFrom} - ${items?.budgetPriceTo}`}
            </div>
          </div>

          {items.projectStatusId != 1 && (
            <div className="flex flex-1 flex-row items-center md:mr-0 mt-2 gap-x-1">
              {items.finalArtist ? (
                <div className="image-tool-tip">
                  <div className="flex flex-row">
                    <img
                      className="h-6 w-6 bg-gray-300 rounded-full object-cover object-center border border-tertiary "
                      src={
                        items?.finalArtist?.photoUrl
                          ? SERVER_URL + items?.finalArtist?.photoUrl
                          : SERVER_URL +
                            "uploads/default-images/DefaultProfile.jpeg"
                      }
                      alt=""
                      // title={offer.user.firstName + " " + offer.user.lastName}
                    />
                    <div className="text-tertiary text-sm ml-2">Artist</div>
                  </div>
                  <span className="tiptext">
                    {items?.finalArtist?.firstName +
                      " " +
                      items?.finalArtist?.lastName}
                  </span>
                </div>
              ) : (
                items?.counter_offers?.map((offer) => {
                  return (
                    <div className="image-tool-tip">
                      <img
                        className="h-6 w-6 bg-gray-300 rounded-full object-cover object-center border border-tertiary "
                        src={
                          offer?.user?.photoUrl
                            ? SERVER_URL + offer?.user?.photoUrl
                            : SERVER_URL +
                              "uploads/default-images/DefaultProfile.jpeg"
                        }
                        alt=""
                        // title={offer.user.firstName + " " + offer.user.lastName}
                      />
                      <span>
                        {offer.user.firstName + " " + offer.user.lastName}
                      </span>
                    </div>
                  );
                })
              )}
              <div className="flex flex-1"></div>
              <div className="font-sant text-tertiary mr-2">
                {items.projectStatusId == 5 &&
                  (items?.artist_ratings.length == 0 ? (
                    "Unrated"
                  ) : (
                    <StyledRating
                      size="small"
                      name="simple-controlled"
                      getLabelText={(value) =>
                        `${value} Heart${value !== 1 ? "s" : ""}`
                      }
                      value={items?.artist_ratings[0]?.rating}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-row gap-16">
      <div className="flex flex-col bg-white w-full px-0">
        <section
          className="mt-2 pb-4 md:pb-8"
          aria-labelledby="gallery-heading"
        >
          <ul className="grid grid-cols-1 md:gap-6 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 3xl:grid-cols-3 5xl:grid-cols-4">
            {props?.data && props?.data?.map((items) => projectCard(items))}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default ClientProjects;
