/* eslint-disable eqeqeq */
import React from "react";
import { ReactComponent as CheckIcon } from "../../assets/ic_check_gray.svg";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TabNavBar(props) {
  const tabSpace = props.tabSpace != null ? props.tabSpace : 8;
  const tabFont = props.tabFont != undefined ? props.tabFont : "text-base";
  const showBorder = props.showBorder != undefined ? props.showBorder : true;
  const borderHeight = props.borderHeight != undefined ? props.borderHeight : 3;
  const py = props.py != undefined ? props.py : "4";
  const px = props.px != undefined ? props.px : "1";
  const mx = props.mx != undefined ? props.mx : "0";
  const hideCount = props.hideCount != undefined ? props.hideCount : false;
  const defaultValue = props.selectedTab != undefined ? props.selectedTab : "Recently Viewed"
  return (
    <>
      {/* Tabs */}
      <div className="mt-0 sm:mt-0">
        <div className={`${props.showInMobileMode ? 'hidden' : 'sm:hidden'}`}>
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md bg-transparent"
            defaultValue={defaultValue}
            onChange={(e) => {
              props.handleChange({ id: e.target.value });
            }}
          >
            {props.tabs.length !== 0 &&
              props.tabs.map(
                (tab) =>
                  !tab.isDisabled && (
                    <option key={tab.id} value={tab.id}>
                      {tab.name}{" "}
                      {!hideCount && tab.count && "(" + tab.count + ")"}
                      {props?.clientProposedDates
                        ? props?.clientProposedDates
                            .map((a) => a.day)
                            .includes(tab.id) && "\u2705"
                        : ""}
                    </option>
                  )
              )}
          </select>
        </div>
        <div className={`${props.showInMobileMode ? '' : 'hidden sm:block'}`}>
          <div
            className={`flex items-center ${
              showBorder ? "border-b" : ""
            } border-bgg`}
          >
            <nav
              className={`flex-1 -mb-px flex space-x-${tabSpace}`}
              aria-label="Tabs"
            >
              {props.tabs.length !== 0 &&
                props.tabs.map((tab) => (
                  <a
                    key={tab.id}
                    href={tab.href}
                    onClick={() => {
                      props.handleChange(tab);
                    }}
                    aria-current={tab.current ? "page" : undefined}
                    className={classNames(
                      tab.isDisabled
                        ? `sm:hidden`
                        : tab.current
                        ? `border-primary text-primary border-b-${borderHeight} `
                        : `border-transparent text-black hover:text-gray-700 hover:border-gray-300 `,
                      `whitespace-nowrap py-${py} px-${px} ${tabFont} font-primary flex flex-row mr-${mx}`
                    )}
                  >
                    {tab.name}
                    {!hideCount && (
                      <div className="ml-2 flex justify-center items-center">
                        <div className="flex px-1.5 py-0 text-white font-sans text-sm font-extrabold bg-primary rounded-full">
                          {tab.count}
                        </div>
                      </div>
                    )}
                    {props?.clientProposedDates ? (
                      props?.clientProposedDates
                        .map((a) => a.day)
                        .includes(tab.id) && <CheckIcon fill="#0BC851" />
                    ) : (
                      <></>
                    )}
                  </a>
                ))}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
