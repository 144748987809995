import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import RootReducer from "./reducers";
import setApiUrl from "../utils/setBaseUrl";
import { SERVER_URL } from "utils/constants";

const initialState = {};
const middlewares = [thunk];
let devtools = (x) => x;
setApiUrl(`${SERVER_URL}/api/`);

if (
  process.env.NODE_ENV !== "production" &&
  process.browser &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}

const Store = createStore(
  RootReducer,
  initialState,
  compose(applyMiddleware(...middlewares), devtools)
);

export default Store;
