import * as React from "react";
import TopSection from "./TopSection"
import Table from "./Table"
import PaymentMethod  from "./PaymentMethod"

const Earnings = (props) => {
  return (
    <div className="flex flex-col gap-6 w-full">
        <TopSection />
        <div className="flex flex-row gap-8">
          <div className="w-4/6">
            <Table tableData={props.data}/>
          </div>
          <div className="w-4/12">
            <PaymentMethod />
          </div>

        </div>
    </div>
  );
};

export default Earnings;
