import { Link } from "react-router-dom";
import { MAKE_TATOO } from "navigation/CONSTANTS";

export default function HeroSection() {
  return (
    <div className="flex w-full h-full md:grid md:grid-cols-1 mt-16 md:mt-32">
      <div className="ml-0 md:ml-0">
        <div className="text-4xl lg:text-6xl text-primary font-normal font-gloomythings flex justify-center text-center tracking-widen leading-tight">
          Let Your <br />
          Tattoo-Dream <br />
          Come True!!
        </div>

        <div className="mt-2 md:mt-4 text-md xl:text-lg text-black font-sans mr-0 px-2 md:px-10 lg:px-20 text-center mx-4 md:mx-16 lg:mr-48 lg:ml-48 xl:mr-60 xl:ml-60">
          The{" "}
          <a href="https://2ndskn.com/" target="_blank" rel="noreferrer">
            <span className="text-primary font-extrabold">
              Ink Foundation
            </span>
          </a>
          , stands by its name dedicated to tattoo art and helps you to realize
          your tattoo project.
        </div>
        <div className="mt-2 lg:mt-4 sm:flex mr-0 md:mr-0 flex justify-center">
          <div className="rounded-full">
            <Link
              to={MAKE_TATOO}
              className="flex items-center justify-center px-8 py-3 mt-2 border border-homeaccent text-base font-medium rounded-full text-white bg-homeaccent hover:bg-primary hover:text-white md:py-5 md:text-lg md:px-14 no-underline"
            >
              Discover
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="hidden md:block">
        <img className="w-full h-full" src="/Home/HomePageImage.jpg" alt="" />
      </div> */}
    </div>
  );
}
