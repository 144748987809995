import Swal from "sweetalert2";
import axiosInstance from "../http-config";
import { signOut } from "redux/actions/LoginActions";

let isLoggedIn = true;

const responseAxiosInterceptor = (store, error) => {
  // If user is not authenticated then sign out and redirect the user to login page.
  if (error.response.status === 401 && isLoggedIn) {
    store.dispatch(signOut()).then(() => {
      isLoggedIn = false;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Session Expired!",
        confirmButtonText: "Ok",
      });
    });
  }
  return Promise.reject(error);
};

const AxiosInterceptor = (store) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => responseAxiosInterceptor(store, error)
  );
};

export default AxiosInterceptor;
