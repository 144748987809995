import axios from "../http-config";

class ArtistService {
  getInivitations(verifyString) {
    return axios({
      method: "GET",
      url: `/invitation/verify-invitation/${verifyString}`,
    });
  }

  getInvitedArtists() {
    return axios({
      method: "GET",
      url: `/invitation/`,
    });
  }

  sendInvitation(data) {
    return axios({
      method: "POST",
      url: `/invitation/`,
      data,
    });
  }

  getAllArtists() {
    return axios({
      method: "GET",
      url: `/users/user-type/2`,
    });
  }

  getNewArtists() {
    return axios({
      method: "GET",
      url: `/users/user-type/2/new`,
    });
  }

  getPendingArtistPortfolios() {
    return axios({
      method: "GET",
      url: `/artistportfolios/status/1`,
    });
  }

  getMatchingArtistsByProjectAndStudio(id, studioId) {
    return axios({
      method: "GET",
      url: `/projects/matching-artists/${id}/${studioId}`,
    });
  }

  getArtistCurrentProjects(id) {
    return axios({
      method: "GET",
      url: `/projects/projects-current-artist/${id}`,
    });
  }

  getArtistProjectsHistory(id) {
    return axios({
      method: "GET",
      url: `/projects/projects-history-artist/${id}`,
    });
  }

  linkArtistStudio(data) {
    return axios({
      method: "POST",
      url: `/studios/studio-artist`,
      data,
    });
  }

  updateArtistPortfolioStatus(data, id) {
    return axios({
      method: "PATCH",
      url: `/artistportfolios/status/${id}`,
      data,
    });
  }

  deleteArtistPortfolio(id) {
    return axios({
      method: "PATCH",
      url: `/artistportfolios/delete/${id}`,
    });
  }

  confirmTechniqueforArtist(id, data) {
    return axios({
      method: "PATCH",
      url: `/artist-technique/confirm-technique/${id}`,
      data,
    });
  }

  getMyArtists() {
    return axios({
      method: "GET",
      url: `/users/user-type/2/myartist`,
    });
  }

  deleteInvitation(id) {
    return axios({
      method: "PATCH",
      url: `/invitation/delete/${id}`,
    });
  }

  deleteArtist(id) {
    return axios({
      method: "PATCH",
      url: `/users/delete-artist/${id}`,
    });
  }

  blockArtist(id) {
    return axios({
      method: "PATCH",
      url: `/users/block-artist/${id}`,
    });
  }

  unblockArtist(id) {
    return axios({
      method: "PATCH",
      url: `/users/unblock-artist/${id}`,
    });
  }

  deleteArtistStudioWorkDay(data) {
    return axios({
      method: "POST",
      url: `/studios/delete-artist-studio`,
      data,
    });
  }

  getAdminArtistProjects(id) {
    return axios({
      method: "GET",
      url: `/projects/projects-artist/${id}`,
    });
  }

  updateTmpArtistPortfolioStatus(data, id) {
    return axios({
      method: "PATCH",
      url: `/artistportfolios/tmp-status/${id}`,
      data,
    });
  }

  deleteTmpArtistPortfolio(id) {
    return axios({
      method: "PATCH",
      url: `/artistportfolios/tmp-delete/${id}`,
    });
  }

}

export default new ArtistService();
