import { LANDING_PAGE } from "redux/actions/LandingActions";

const initialState = {
  LANDING_PAGE: false,
};

const LandingReducer = function (state = initialState, action) {
  switch (action.type) {
    case LANDING_PAGE: {
      return {
        ...state,
        LANDING_PAGE: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default LandingReducer;
