import React, { useState, useEffect } from "react";
import ClientProjects from "./ClientProjects";

const CurrentProjects = (props) => {
  const [checkedProcess, setCheckedProcess] = useState(false);
  const [checkedAppointment, setCheckedAppointment] = useState(false);
  const [checkedWaiting, setCheckedWaiting] = useState(false);

  const [projectListData, setProjectListData] = useState(
    props?.projectList
  );
  let inProcessCount = 0;
  let inAppointmentCount = 0;
  let inWaitingCount = 0;

  useEffect(() => {
    setProjectListData(props?.projectList);
  }, [props]);

  let inProcessData =
    props?.projectList
      ? props?.projectList.filter(
          (item) => item.projectStatusId === 11
        )
      : [];
  inProcessCount = inProcessData.length;

  let inWaitingData =
    props?.projectList
      ? props?.projectList.filter(
          (item) => (item.projectStatusId === 2 || item.projectStatusId === 6)
        )
      : [];
  inWaitingCount = inWaitingData.length;

  let inAppointmentData =
    props?.projectList
      ? props?.projectList.filter(
          (item) => item.projectStatusId === 9
        )
      : [];
  inAppointmentCount = inAppointmentData.length;

  const handleChangeInProcess = () => {
    setCheckedProcess(!checkedProcess);
    refreshSearch(!checkedProcess, checkedAppointment, checkedWaiting);
  };

  const handleChangeInWaiting = () => {
    setCheckedWaiting(!checkedWaiting);
    refreshSearch(checkedProcess, checkedAppointment, !checkedWaiting);
  };

  const handleChangeInAppointment = () => {
    setCheckedAppointment(!checkedAppointment);
    refreshSearch(checkedProcess, !checkedAppointment, checkedWaiting);
  };

  const refreshSearch = (bCheckedProcess, bCheckedAppointment, bCheckedWaiting) => {
    if (bCheckedProcess  && !bCheckedAppointment && !bCheckedWaiting) {
      setProjectListData(inProcessData);
    } else if (bCheckedProcess && !bCheckedAppointment && bCheckedWaiting) {
      let data =
      props?.projectList
        ? props?.projectList.filter(
            (item) => item.projectStatusId === 11 || item.projectStatusId === 2 || item.projectStatusId === 6
          ) : [];
      setProjectListData(data);
    } else if (bCheckedProcess && bCheckedAppointment && !bCheckedWaiting) {
      let data =
      props?.projectList
        ? props?.projectList.filter(
            (item) => item.projectStatusId === 11 || item.projectStatusId === 9
          ) : [];
      setProjectListData(data);
    } else if ((bCheckedProcess && bCheckedAppointment && bCheckedWaiting) || (!bCheckedProcess  && !bCheckedAppointment && !bCheckedWaiting)) {
      setProjectListData(props?.projectList);
    } else if (!bCheckedProcess && !bCheckedAppointment && bCheckedWaiting) {
      setProjectListData(inWaitingData);
    } else if (!bCheckedProcess && bCheckedAppointment && !bCheckedWaiting) {
      setProjectListData(inAppointmentData);
    } else if (!bCheckedProcess && bCheckedAppointment && bCheckedWaiting) {
      let data =
      props?.projectList
        ? props?.projectList.filter(
            (item) => item.projectStatusId === 9 || item.projectStatusId === 2 || item.projectStatusId === 6 
          ) : [];
      setProjectListData(data);    
    }
  }

  return (
    <div>
      <div className="flex lg:space-x-6 lg:items-center flex-col lg:flex-row mt-2 space-y-2 lg:space-y-0">
        <div className="flex items-center">
          <input
            className={`h-5 w-5 border border-gray rounded bg-white mt-0 align-top float-left mr-2 form-checkbox text-primary focus:ring-0`}
            type="checkbox"
            name="isInProcess"
            id="isInProcess"
            checked={checkedProcess}
            onChange={handleChangeInProcess}
          />
          <label className="inline-block items-center" htmlFor="isInProcess">
            In Process {inProcessCount}
          </label>
        </div>
        <div className="flex items-center">
          <input
            className="h-5 w-5 border border-gray rounded bg-white mt-0 align-top float-left mr-2 form-checkbox text-primary focus:ring-0"
            type="checkbox"
            name="isInAppointment"
            id="isInAppointment"
            checked={checkedAppointment}
            onChange={handleChangeInAppointment}
          />
          <label className="inline-block" htmlFor="isInAppointment">
            Appointment Date {inAppointmentCount}
          </label>
        </div>
        <div className="flex items-center">
          <input
            className="h-5 w-5 border border-gray rounded bg-white mt-0 align-top float-left mr-2 form-checkbox text-primary focus:ring-0"
            type="checkbox"
            name="isInWaiting"
            id="isInWaiting"
            checked={checkedWaiting}
            onChange={handleChangeInWaiting}
          />
          <label className="inline-block" htmlFor="isInWaiting">
            Waiting for Confirmation {inWaitingCount}
          </label>
        </div>
      </div>
    <ClientProjects
      setSelectedTab={props.setSelectedTab}
      selectedTab={props.selectedTab}
      data={projectListData}
      projectOnClick={props.projectOnClick}
    />
    </div>
  );
};

export default CurrentProjects;
