import { TECHNIQUE_LOADING, GET_TECHNIQUE_SUCCESS, GET_TECHNIQUE_ERROR } from "../actions/TechniqueAction";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null
};

const TechniqueReducer = function (state = initialState, action) {
    switch (action.type) {
        case TECHNIQUE_LOADING: {
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null
            };
        }
        case GET_TECHNIQUE_SUCCESS: {
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };
        }
        case GET_TECHNIQUE_ERROR: {
            return {
                loading: false,
                success: false,
                data: null,
                error: action.payload ? action.payload.data.error.message : "Error Occured",
            };
        }
        default: {
            return state;
        }
    }
};

export default TechniqueReducer;