/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ArtistProjects from "./ArtistProjects";

const AdminArtistProjects = (props) => {
  const [checkedProcess, setCheckedProcess] = useState(false);
  const [checkedAppointment, setCheckedAppointment] = useState(false);
  const [checkedHistory, setCheckedHistory] = useState(false);

  const [projectListData, setProjectListData] = useState(
    props?.artistsProjects?.project
  );
  let inProcessCount = 0;
  let inAppointmentCount = 0;
  let inHistoryCount = 0;

  useEffect(() => {
    setProjectListData(props?.artistsProjects?.project);
  }, [props]);

  let inProcessData =
    props.artistsProjects && props.artistsProjects.project
      ? props.artistsProjects.project.filter(
          (item) => item.projectStatusId === 11
        )
      : [];
  inProcessCount = inProcessData.length;

  let inAppointmentData =
    props.artistsProjects && props.artistsProjects.project
      ? props.artistsProjects.project.filter(
          (item) => item.projectStatusId === 10 || item.projectStatusId === 9
        )
      : [];
  inAppointmentCount = inAppointmentData.length;

  let inHistoryData =
    props.artistsProjects && props.artistsProjects.project
      ? props.artistsProjects.project.filter(
          (item) => item.projectStatusId === 5
        )
      : [];
  inHistoryCount = inHistoryData.length;

  const handleChangeInCheckbox = (cb1, name) => {
    if (name === "inprocess") {
      setCheckedProcess(cb1);
    } else if (name === "inappointment") {
      setCheckedAppointment(cb1);
    } else if (name === "inhistory") {
      setCheckedHistory(cb1);
    }
  };

  useEffect(() => {
    if (!checkedProcess && !checkedAppointment && !checkedHistory) {
      setProjectListData(props?.artistsProjects?.project);
    }

    if (checkedProcess && !checkedAppointment && !checkedHistory) {
      setProjectListData(inProcessData);
    }
    if (!checkedProcess && checkedAppointment && !checkedHistory) {
      setProjectListData(inAppointmentData);
    }
    if (!checkedProcess && !checkedAppointment && checkedHistory) {
      setProjectListData(inHistoryData);
    }

    let finalData = props?.artistsProjects?.project;
    if (checkedProcess && checkedAppointment && !checkedHistory) {
      finalData = props.artistsProjects.project.filter(
        (item) => item.projectStatusId === 11 || item.projectStatusId === 10
      );
      setProjectListData(finalData);
    }

    if (checkedProcess && !checkedAppointment && checkedHistory) {
      finalData = props.artistsProjects.project.filter(
        (item) => item.projectStatusId === 11 || item.projectStatusId === 5
      );
      setProjectListData(finalData);
    }

    if (!checkedProcess && checkedAppointment && checkedHistory) {
      finalData = props.artistsProjects.project.filter(
        (item) => item.projectStatusId === 10 || item.projectStatusId === 5
      );
      setProjectListData(finalData);
    }
    if (checkedProcess && checkedAppointment && checkedHistory) {
      finalData = props.artistsProjects.project.filter(
        (item) =>
          item.projectStatusId === 10 ||
          item.projectStatusId === 5 ||
          item.projectStatusId === 11
      );
      setProjectListData(finalData);
    }
  }, [checkedProcess, checkedAppointment, checkedHistory]);

  return (
    <div>
      {/* <div className="flex flex-row mb-4 mt-4"> */}
      <div className="flex lg:space-x-4 lg:items-center flex-col lg:flex-row mt-2 space-y-2 lg:space-y-0">
        <div className="flex mr-4 items-center">
          <input
            className={`form-checkbox h-5 w-5 border border-gray rounded text-primary  align-top float-left mr-2 focus:ring-0`}
            type="checkbox"
            name="isInProcess"
            id="isInProcess"
            checked={checkedProcess}
            onChange={(e) =>
              handleChangeInCheckbox(e.target.checked, "inprocess")
            }
          />
          <label className="flex text-base" for="isInProcess">
            In Process {inProcessCount}
          </label>
        </div>
        <div className="flex mr-4 items-center">
          <input
            className="form-checkbox h-5 w-5 border border-gray rounded text-primary align-top float-left mr-2 focus:ring-0"
            type="checkbox"
            name="isInAppointment"
            id="isInAppointment"
            checked={checkedAppointment}
            onChange={(e) =>
              handleChangeInCheckbox(e.target.checked, "inappointment")
            }
          />
          <label className="flex text-base" for="isInAppointment">
            Appointment Date {inAppointmentCount}
          </label>
        </div>
        <div className="flex mr-4 items-center">
          <input
            className="form-checkbox h-5 w-5 border border-gray rounded text-primary align-top float-left mr-2 focus:ring-0"
            type="checkbox"
            name="isInHistory"
            id="isInHistory"
            checked={checkedHistory}
            onChange={(e) =>
              handleChangeInCheckbox(e.target.checked, "inhistory")
            }
          />
          <label className="flex text-base" for="isInHistory">
            History {inHistoryCount}
          </label>
        </div>
      </div>
      <ArtistProjects
        selectedTab={props.selectedTab}
        data={projectListData}
        role={props.role}
      />
    </div>
  );
};

export default AdminArtistProjects;
