import StyleService from "services/StyleService";

export const STYLES_LOADING = "STYLES_LOADING";
export const STYLES_SUCCESS = "STYLES_SUCCESS";
export const STYLES_ERROR = "STYLES_ERROR";
export const STYLE_LOADING = "STYLE_LOADING";
export const STYLE_SUCCESS = "STYLE_SUCCESS";
export const STYLE_ERROR = "STYLE_ERROR";

export const getStyles = () => async (dispatch) => {
  dispatch({
    type: STYLES_LOADING,
  });
  try {
    let res = await StyleService.getStyles();

    return dispatch({
      type: STYLES_SUCCESS,
      payload: res.data.styles,
    });
  } catch (err) {
    return dispatch({
      type: STYLES_ERROR,
      payload: err.response,
    });
  }
};

export const getStyle = (id) => async (dispatch) => {
  dispatch({
    type: STYLE_LOADING,
  });
  try {
    let res = await StyleService.getStyle(id);

    return dispatch({
      type: STYLE_SUCCESS,
      payload: res.data.styles,
    });
  } catch (err) {
    return dispatch({
      type: STYLE_ERROR,
      payload: err.response,
    });
  }
};
