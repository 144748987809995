import axios from "../http-config";

class MarketPlaceDecisionService {

    getMarketPlaceDecisions() {
        return axios({
            method: "GET",
            url: '/marketplacedecisions'
        });
    }
}

export default new MarketPlaceDecisionService();
