import * as React from "react";
import DateProposalPage from "./../../common/DateProposalPage";
import { useEffect } from "react";

const AddProposedDatePage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <DateProposalPage {...props} />;
};

export default AddProposedDatePage;
