import Header from "components/Navbars/AuthNavbar";
import Hero from "components/HeroSections/HeroSection";
import Footer from "components/Footers/Footer";
import { useDispatch, useSelector } from "react-redux";
import { createSubscriber } from "redux/actions/SubscriberAction";
import { setLandingState } from "redux/actions/LandingActions";
import localStorageService from "services/localStorageService";
import { useEffect } from "react";

const Landing = (props) => {
  const dispatch = useDispatch();
  const landing = useSelector((state) => state.Landing);

  const subscriptionResponse = useSelector((state) => state.subscriber);
  const subscribe = (obj) => {
    dispatch(createSubscriber(obj));
  };

  useEffect(() => {
    if (localStorageService.getItem("LANDING_PAGE") == true) {
      dispatch(setLandingState(true));
    }
  }, []);

  const saveLandingSate = () => {
    localStorageService.setItem("LANDING_PAGE", true);
    dispatch(setLandingState(true));
  };

  return (
    <>
      {!landing.LANDING_PAGE && (
        <div className={`flex flex-1 justify-center h-screen w-full"}`}>
          <div
            className="flex self-center h-20 mb-4"
            onClick={() => saveLandingSate()}
          >
            <object
              type="image/svg+xml"
              data="/2ndSkin.svg"
              className={`flex pointer-events-none`}
            >
              2ndSkin
            </object>
          </div>
        </div>
      )}
      {landing.LANDING_PAGE && (
        <div className="">
          <div className="h-full flex flex-col bg-white sm:mt-1 animate-appear-screen">
            <header>
              <Header className="" />
            </header>
            <main className="overflow-hidden">
              <Hero className="px-10" />
            </main>
            <div className="flex flex-1"></div>
            <Footer
              subscribe={subscribe}
              subscriptionResponse={subscriptionResponse}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Landing;
